import React, { FC, useEffect, useState } from 'react'
import MaterialCard from '../../../../components/MaterialCard/MaterialCard'
import {
  AutoComplete,
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Spin
} from 'antd'
import {
  useChangeMaterialMutation,
  useGetMaterialsQuery,
  useWriteOffMaterialMutation
} from '../../../../services/materialOrder.api'
import { transformDate } from '../../../../helpers/transformDate'
import { IMaterialItem } from '../../../../models/IMaterial'
import { CloseOutlined } from '@ant-design/icons'
import CreateOrder from '../CreateOrder/CreateOrder'
import { configDatePicker } from '../../../../configs/fieldRules'
import TextArea from 'antd/es/input/TextArea'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import s from './RequiredToOrder.module.scss'
import { useDebounce } from '../../../../hooks/useDebounce'
import { LabeledValue } from 'antd/es/select'
import { useLazyGetFabricsQuery } from '../../../../services/fabric.api'
import materialsInStock from '../../../../components/MaterialsInStock/MaterialsInStock'

interface IProps {
  fetchOrder: (OrderId: number) => void
  isEdit?: boolean
}

const RequiredToOrder: FC<IProps> = ({fetchOrder, isEdit = true}) => {
  const [awaitingCreate, setAwaitingCreate] = useState(false)
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenSearchProvider, setIsOpenSearchProvider] = useState(false)
  const [isWriteOff, setIsWriteOff] = useState(false)
  const [fabricIRI, setFabricIRI] = useState('')
  const [materialSearch, setMaterialSearch] = useState('')
  const [providerSearch, setProviderSearch] = useState('')
  const [api, contextHolder] = notification.useNotification()
  const [changeMaterial, {
    isSuccess: isSuccessMaterial,
    isError: isErrorMaterial
  }] = useChangeMaterialMutation()
  const [writeOffMaterial, {
    isSuccess: isSuccessWriteOffMaterial,
    isError: isErrorWriteOffMaterial
  }] = useWriteOffMaterialMutation()
  const [getFabrics, {data: allFabrics, isSuccess: isSuccessMaterials}] = useLazyGetFabricsQuery()
  const [getFabricsProvider, {data: allFabricsProvider, isSuccess: isSuccessMaterialsProvider}] = useLazyGetFabricsQuery()
  const [getFabricsSearchInStock, {data: dataFabricInStock, isLoading: isLoadingSearchFabrics}] = useLazyGetFabricsQuery()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [materialItem, setMaterialItem] = useState({} as IMaterialItem)
  const {data, isLoading: isLoadingMaterial} = useGetMaterialsQuery({page: 1, perPage: 150, status: 'new'})
  const [form] = Form.useForm()
  const quantity = Form.useWatch('quantity', form)
  const material = Form.useWatch('material', form)
  const provider = Form.useWatch('provider', form)

  useEffect(() => {
    getFabricsSearchInStock({
      page: 1,
      perPage: 5,
      name: material,
      provider: provider
    })
    getFabrics({
      page: 1,
      perPage: 5,
      name: material,
      provider: provider
    })
    getFabricsProvider({
      page: 1,
      perPage: 5,
      name: material,
      provider: provider
    })
  }, [material, provider, materialItem])
  const openOrderMaterial = (item: any) => {
    setMaterialItem(item)
    setIsModalOpen(true)
  }
  // useEffect(() => {
  //   if (isSuccessMaterial) {
  //     api.success({
  //       message: 'Заказ успешно создан',
  //       placement: 'topRight',
  //       duration: 3
  //     })
  //     setIsModalOpen(false)
  //     setAwaitingCreate(false)
  //   }
  // }, [isSuccessMaterial])
  // useEffect(() => {
  //   if (isErrorMaterial) {
  //     api.error({
  //       message: 'Заказ не удался',
  //       placement: 'topRight',
  //       duration: 3
  //     })
  //     setAwaitingCreate(false)
  //   }
  // }, [isErrorMaterial])

  const onFinish = async (values: any) => {
    try {
      setAwaitingCreate(true)

      let data: any = {
        quantity: values.quantity || '0',
        delivery_date_to: values['date'].format('YYYY-MM-DD'),
        note: values.note,
        material: {
          id: materialItem.material.materialIRI,
          name: values.material,
          provider: values.provider
        }
      }

      if (!isWriteOff) {
        data.status = 'waiting_for_load'
      }
      await changeMaterial({
        id: materialItem.id,
        data
      })

      const id = materialItem.hydraMemberID ? materialItem.hydraMemberID?.split('/').at(-1) : 0
      if (!id) return

      if (isWriteOff) {
        await writeOffMaterial({
          id: +id,
          data: {
            quantity: values.quantity,
            // totalAmount: 'materialItem.',
            measurement: materialItem.measurement,
            // status?: {},
            note: values.note,
            productionDateFrom: materialItem.productionDateFrom,
            productionDateTo: values['date'].format('YYYY-MM-DD'),
            productName: materialItem.productName,
            deliveryDateFrom: materialItem.deliveryDateFrom,
            deliveryDateTo: materialItem.deliveryDateTo,
          }
        })
      }

      if (isSuccessMaterial) {
        api.success({
          message: 'Заказ успешно создан',
          placement: 'topRight',
          duration: 3
        })
      }
      if (isErrorMaterial) {
        api.error({
          message: 'Заказ не удался',
          placement: 'topRight',
          duration: 3
        })
        setAwaitingCreate(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsModalOpen(false)
      setAwaitingCreate(false)
      setMaterialSearch('')
      setProviderSearch('')
    }
    setFabricIRI('')
  }

  const handleOk = () => {
    form.validateFields()
      .then(() => form.submit())
      .catch((errorInfo) => {
        api.error({
          message: 'Заполните обязательные поля',
          placement: 'topRight',
          duration: 3
        })
      })
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleSearch = (newValue: string, key: 'name' | 'provider') => {
    setFabricIRI('')
    // if (newValue) {
      if (key === 'name') {
        setMaterialSearch(newValue)
      } else if (key === 'provider') {
        setProviderSearch(newValue)
      }
      // getFabrics({
      //   page: 1,
      //   perPage: 50,
      //   [key]: newValue
      // })
    // }
  }

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    // handleSearch(value, 'name')
    // form.setFieldValue(['goods', fieldName, 'name'], option.key)
    //
    // if (orderToEdit?.type !== ORDER_TYPES.FOR_STOCK && nameProvider) {
    //   form.setFieldValue(['goods', fieldName, 'price'], option.price)
    // }
    // if (nameProvider !== option.provider) {
    //   form.setFieldValue(['goods', fieldName, 'provider'], null)
    // }
    setProviderSearch(materialItem.material.provider)
    setMaterialSearch(option.label)
    setFabricIRI(option.fabricIRI)
    setIsOpenSearch(false)
  }
  const onSelectProvider = (value: string | number | LabeledValue, option: any) => {
    setMaterialSearch(materialItem.material.name)
    setProviderSearch(option.label)
    setFabricIRI(option.fabricIRI)
    setIsOpenSearchProvider(false)
  }
  // const  onChangeMain = (e: any, key: 'name' | 'provider') => {
  //   return
  //   if (key === 'name') {
  //     setMaterialSearch(e.target.value)
  //     setProviderSearch(materialItem.material.provider)
  //   } else if (key === 'provider') {
  //     setMaterialSearch(materialItem.material.name)
  //     setProviderSearch(e.target.value)
  //   }
  //   // const val = key === 'name' ? nameMaterial : nameProvider
  //   // if (val !== field) {
  //   //   form.setFieldValue(['goods', fieldName, key], null)
  //   // }
  // }

  useEffect(() => {
    if (quantity) {
      setMaterialItem(val => ({...val, quantity}))
    }
  }, [quantity])

  return (
    <>
      {awaitingCreate && <LoaderFullDisplay/>}
      {contextHolder}

      <Space size={[12, 12]} direction={'vertical'} style={{width: '100%'}}>
        <div className={s.header}>
          <span>Требуется заказать</span>
          <span>{data?.length}</span>
        </div>
        <>
          {
            isLoadingMaterial &&
            <Row justify={'center'} align={'middle'} style={{width: '100%', height: '300px'}}>
              <Col span={2}>
                <Spin size="large"/>
              </Col>
            </Row>}
          {
            data && data?.map(item => (
              <MaterialCard key={item.id}
                            item={item}
                            fetchOrder={fetchOrder}
              >
                <Row gutter={20} justify={'space-between'} align={'middle'}>
                  <Col span={12} className={s.dateCreated}>
                    Дата создания: {transformDate(item.createdAt)}
                  </Col>
                  {
                    isEdit && (
                      <Col>
                        <Button type={'primary'}
                                size={'large'}
                                onClick={() => {
                                  setMaterialSearch('')
                                  setProviderSearch('')
                                  openOrderMaterial(item)
                                }}
                        >Создать заказ</Button>
                      </Col>
                    )
                  }
                </Row>
              </MaterialCard>
            ))
          }
        </>
      </Space>

      <Modal open={isModalOpen}
             onOk={handleOk}
             onCancel={() => {
               setMaterialSearch('')
               setProviderSearch('')
               form.resetFields()
               setIsModalOpen(false)
             }}
             cancelText={'Отмена'}
             okText={'Создать заказ'}
             closeIcon={
               <CloseOutlined
                 style={{background: '#F1F3F6', fontSize: '12px', borderRadius: '50%', padding: '10px'}}/>
             }
             closable={false}
             destroyOnClose={true}
             centered
      >
        <ConfigProvider theme={{
          token: {
            controlHeight: 44,
            fontSize: 16,
          },
          components: {
            Select: {
              controlHeight: 44,
            }
          },
        }}>
          <CreateOrder materialItem={materialItem}
                       closeModal={closeModal}
                       isWriteOff={isWriteOff}
                       setIsWriteOff={setIsWriteOff}
                       dataFabricInStock={dataFabricInStock}
                       isLoadingSearchFabrics={isLoadingSearchFabrics}
                       materialSearch={materialSearch}
                       providerSearch={providerSearch}
                       form={form}
          >
            <Form form={form}
                  name={'create-order'}
                  onFinish={onFinish}
                  layout="vertical"
                  preserve={false}
                  disabled={!isEdit}
            >
              <Row gutter={[12, 12]} align={'middle'} style={{marginBottom: '24px'}}>
                <Col span={10} className={s.fieldName}>Наименование</Col>
                <Col span={14}>
                  <Form.Item name="material"
                             label=""
                             initialValue={materialItem?.material?.name}
                             style={{height: '44px', margin: '0'}}
                             // validateTrigger={['onFinish', 'onSearch', 'onBlur', 'onSelect']}
                             rules={[
                               {required: true, message: 'Обязательное поле'},
                               ({ getFieldValue }) => ({
                                 async validator(_, value) {
                                   const names = await allFabrics ? allFabrics?.map(item => item.name) : []
                                   if (names && names.includes(value)) {
                                     return Promise.resolve()
                                   } else if (value && names?.length && !names.includes(value)) {
                                     return Promise.reject(new Error("Выберите из списка"))
                                   }
                                 },
                               }),
                             ]}
                  >
                    <AutoComplete
                      open={isOpenSearch}
                      style={{fontSize: '20px !important'}}
                      placeholder={'Введите название'}
                      options={
                        allFabrics
                          ? allFabrics.map((item, idx) => ({
                            id: idx,
                            key: item.fabricIRI,
                            value: item.name,
                            label: item.name,
                            fabricIRI: fabricIRI
                          }))
                          : []
                      }
                      onSearch={(e) => handleSearch(e, 'name')}
                      onSelect={onSelect}
                      backfill={true}
                      defaultValue={''}
                      onFocus={() => setIsOpenSearch(true)}
                      onBlur={() => setIsOpenSearch(false)}
                      // onChange={(e) => onChangeMain(e, 'provider')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} align={'middle'} style={{marginBottom: '24px'}}>
                <Col span={10} className={s.fieldName}>Поставщик</Col>
                <Col span={14}>
                  <Form.Item name="provider"
                             label=""
                             initialValue={materialItem?.material?.provider}
                             style={{height: '44px', margin: '0'}}
                             rules={[
                               {required: true, message: 'Обязательное поле'},
                               ({ getFieldValue }) => ({
                                 async validator(_, value) {
                                   const names = await allFabricsProvider ? allFabricsProvider?.map(item => item.provider) : []
                                   if (names && names.includes(value)) {
                                     return Promise.resolve()
                                   } else if (value && names?.length && !names.includes(value)) {
                                     return Promise.reject(new Error("Выберите из списка"))
                                   }
                                 },
                               }),
                             ]}
                  >
                    <AutoComplete
                      open={isOpenSearchProvider}
                      style={{fontSize: '20px !important'}}
                      placeholder={'Введите название'}
                      options={
                        allFabricsProvider
                          ? allFabricsProvider
                            .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.provider === obj.provider))
                            .map((item, idx) => ({
                            key: idx,
                            value: item.provider,
                            label: item.provider,
                            fabricIRI: item.fabricIRI
                          }))
                          : []
                      }
                      onSearch={(e) => handleSearch(e, 'provider')}
                      onSelect={onSelectProvider}
                      backfill={true}
                      defaultValue={''}
                      onFocus={() => setIsOpenSearchProvider(true)}
                      onBlur={() => setIsOpenSearchProvider(false)}
                      // onChange={(e) => onChangeMain(e, 'name')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item name="quantity" label="Количество" initialValue={materialItem.quantity}>
                    <Input style={{height: '44px'}} value={materialItem.quantity}/>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="date"
                             label="Ожидаемая дата доставки"
                             {...configDatePicker}
                  >
                    <DatePicker style={{width: '100%', height: '44px'}}
                                placeholder={'Выберите дату'}
                                suffixIcon={false}
                                format="DD.MM.YYYY"
                                showToday={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="note" label="Комментарий" style={{marginBottom: 0}}>
                <TextArea/>
              </Form.Item>
            </Form>
          </CreateOrder>
        </ConfigProvider>
      </Modal>
    </>
  )
}

export default RequiredToOrder