import React, { FC, useEffect, useState } from 'react'
import OrderHeader from '../OrderHeader/OrderHeader'
import { IOptions, IOrder, IProductOrderItemOption } from '../../../../models/IOrder'
import { Button, ConfigProvider, Form, Modal, notification, Row, Space, Tabs, theme } from 'antd'
import Goods from '../goods/Goods'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import IndividualPerson from '../goods/components/IndividualPerson/IndividualPerson'
import { useChangeOrderMutation } from '../../../../services/order.api'
import { OrderStatusesIRI } from '../../../../configs/enums/orderStatuses'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { materialDataForSend } from '../../../OrderCreating/configs/dataTypeForSend.type'
import { setOrderToEditMP } from '../../../../store/reducers/order/order.slice'
import { useAppDispatch } from '../../../../hooks/redux'
import { useLazyGetProductStockItemsQuery } from '../../../../services/product.api'
import s from '../../../OrderCreating/components/FormOrder/FormOrder.module.scss'

dayjs.extend(customParseFormat)

const {useToken} = theme

interface IProps {
  name?: string
  isShowForm: boolean
  closeForm: () => void
  data?: IOrder
  disabled?: boolean
  apiFromList?: any
}

const FormOrder: FC<IProps> = (props) => {
  const {name = 'orderMP', isShowForm = false, closeForm, data: dataOrder, apiFromList} = props
  const [modalIntoShipment, setModalIntoShipment] = useState(false)
  const [modalIntoProduction, setModalIntoProduction] = useState(false)
  const [awaitingChangeOrder, setAwaitingChangeOrder] = useState(false)
  const {token} = useToken()
  const [form] = Form.useForm()
  const [changeOrder, {data: changedOrder}] = useChangeOrderMutation()
  const [getStockItems, {data: stockItems, isFetching: isFetchingStockItem}] = useLazyGetProductStockItemsQuery()
  const [api, contextHolder] = notification.useNotification()
  const cost = Form.useWatch('cost', form)
  const good = Form.useWatch('good', form)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (dataOrder?.productOrderItems?.[0]?.hash) {
      getStockItems({
        hash: dataOrder?.productOrderItems?.[0]?.hash,
        status: 'new'
      })
    }
  }, [])
  useEffect(() => {
    if (changedOrder?.productOrderItems?.[0]?.hash) {
      getStockItems({
        hash: changedOrder?.productOrderItems?.[0]?.hash,
        status: 'new'
      })
    }
  }, [changedOrder])

  const getOptions = (options: IProductOrderItemOption[]) => {
    const result = []
    for (let i = 0; i < options?.length; i++) {
      const data: any = {
        optionIRI: options[i].optionIRI,
        productId: options[i].id,
        productIRI: options[i].optionIRI,
        productName: options[i].productName || options[i].productName,
        price: options[i].price,
        cost: options[i].cost,
        quantity: options[i].quantity,
        discount: options[i].discount
      }
      result.push(data)
    }
    return result
  }
  let initialValues = {
    good: [
      {
        productId: dataOrder?.productOrderItems?.[0]?.product?.id,
        productOrderItemId: dataOrder?.productOrderItems?.[0]?.id,
        // productIRI: dataOrder?.productOrderItems?.[0].product?.productIRI,
        productName: dataOrder?.productOrderItems?.[0]?.product?.name || dataOrder?.productOrderItems?.[0]?.productName || '',
        price: dataOrder?.productOrderItems?.[0]?.price,
        cost: dataOrder?.productOrderItems?.[0]?.cost,
        quantity: dataOrder?.productOrderItems?.[0]?.quantity,
        discount: dataOrder?.productOrderItems?.[0]?.discount,
        sumName: 'Итого',
      },
      ...getOptions(dataOrder?.productOrderItems?.[0]?.productOrderItemOptions || [])
    ],
    productOrderItemIRI: dataOrder?.productOrderItems?.[0]?.productOrderItemIRI,
    materialIRI: dataOrder?.productOrderItems?.[0]?.materials?.[0].materialIRI,
    materialId: dataOrder?.productOrderItems?.[0]?.materials?.[0].id,
    materialAdditionalIRI: dataOrder?.productOrderItems?.[0]?.materials[1].materialIRI,
    additionalId: dataOrder?.productOrderItems?.[0]?.materials[1].id,
    name: dataOrder?.productOrderItems?.[0]?.materials[0].name,
    provider: dataOrder?.productOrderItems?.[0]?.materials[0].provider,
    price: dataOrder ? Math.round(+dataOrder?.productOrderItems?.[0]?.materials?.[0]?.price) : '',
    additionalName: dataOrder?.productOrderItems?.[0]?.materials[1].name,
    additionalProvider: dataOrder?.productOrderItems?.[0]?.materials[1].provider,
    additionalPrice: dataOrder ? Math.round(+dataOrder?.productOrderItems?.[0]?.materials[1].price) : '',
    backwall: dataOrder?.productOrderItems?.[0]?.hasBackwall ? 'true' : '',
    productSupport: dataOrder?.productOrderItems?.[0]?.productSupport,
    size: dataOrder?.productOrderItems?.[0]?.productSize ? dataOrder?.productOrderItems?.[0].productSize : null,
    productSize: dataOrder?.productOrderItems?.[0]?.productSizeValue,
    checkboxCustomSize: dataOrder?.productOrderItems?.[0]?.productSize ? 'false' : 'true',
    note: dataOrder?.productOrderItems?.[0]?.note,
    status: dataOrder?.productOrderStatus?.cyrillicValue,
    statusIRI: dataOrder?.productOrderStatus?.orderStatusIRI,
    cost: '',
    contactSource: dataOrder?.marketplaceType,
    orderType: dataOrder?.type,
    productionDates: dataOrder?.productionDateFrom ? dayjs(dataOrder?.productionDateFrom, 'YYYY.MM.DD') : null,
    deliveryDates: dataOrder?.deliveryDateFrom ? dayjs(dataOrder?.deliveryDateFrom, 'YYYY.MM.DD') : null,
    contactLastName: dataOrder?.contact?.lastName ? dataOrder?.contact?.lastName : '',
    contactMiddleName: dataOrder?.contact?.middleName ? dataOrder?.contact?.middleName : '',
    contactFirstName: dataOrder?.contact?.firstName ? dataOrder?.contact?.firstName : '',
    phone: dataOrder?.contact?.phone ? dataOrder?.contact?.phone : '',
    email: dataOrder?.contact?.email ? dataOrder?.contact?.email : '',
    additionalContact: dataOrder?.contact?.additionalContacts ? dataOrder?.contact?.additionalContacts : '',
    deliveryAddress: dataOrder?.deliveryAddress ? dataOrder?.deliveryAddress : '',
  }

  const changeContactDataForOrder = (contactIRI: string) => {
    // if (orderData && isOrderEdit && hasRightsToEditing) {
    //   changeContactForOrder({
    //     id: order.id,
    //     data: {
    //       contact: contactIRI
    //     }
    //   })
    // }
  }
  const sendOnBlurGoods = async () => {
    if (!dataOrder?.id) return
    const getOptions = (options: IOptions[]) => {
      const result = []
      for (let i = 1; i < options?.length; i++) {
        const data: any = {
          'product_name': options[i].productName,
          'quantity': +options[i].quantity ? +options[i].quantity : 1,
          'price': `${options[i].price}`,
          'cost': `${options[i].cost}`,
          'discount': options[i]?.discount ? `${options[i].discount}` : '0',
          'summ': options[i].sum
        }
        if (options[i].productIRI) {
          data.id = options[i].productIRI
        }
        result.push(data)
      }
      return result
    }

    const materialIRI = form.getFieldValue('materialIRI')
    const additionalIRI = form.getFieldValue('additionalIRI')
    const price = form.getFieldValue('price')
    const additionalPrice = form.getFieldValue('additionalPrice')
    const additionalName = form.getFieldValue('additionalName')
    const additionalProvider = form.getFieldValue('additionalProvider')

    const material: materialDataForSend = {
      'material': 'Основная ткань',
      'name': form.getFieldValue('name'),
      'provider': form.getFieldValue('provider'),
      'price': price ? `${price}` : '0'
    }
    const materialAdditional: materialDataForSend = {
      'material': 'Дополнительная ткань',
      'name': additionalProvider ? additionalName : dataOrder?.productOrderItems?.[0].materials[1].name,
      'provider': additionalName ? additionalProvider : dataOrder?.productOrderItems?.[0].materials[1].provider,
      'price': additionalPrice ? `${additionalPrice}` : '0'
    }
    if (materialIRI) {
      material.id = materialIRI
    }
    if (additionalIRI) {
      materialAdditional.id = additionalIRI
    }
    let data: any = {
      'product_order_items': [{
        'id': form.getFieldValue('productOrderItemIRI'),
        'product_name': good?.[0].productName,
        'product_support': form.getFieldValue('productSupport') || null,
        'price': `${good?.[0].price}`,
        'quantity': +good?.[0].quantity,
        'cost': `${good?.[0].cost}`,
        'discount': !!good?.[0].discount ? `${good?.[0].discount}` : '0',
        'summ': good?.[0].sum,
        'product_size': dataOrder.productOrderItems?.[0].productSize || null,
        'product_size_value': dataOrder.productOrderItems?.[0].productSizeValue || null,
        'materials': [material, materialAdditional],
        'has_backwall': Boolean(form.getFieldValue('backwall')),
        'note': form.getFieldValue('note'),
        'product_order_item_options': getOptions(good),
      }
      ],
      'price': `${cost}`,
    }

    form.validateFields()
      .then((response) => {
        changeOrder({id: dataOrder.id, data})
        return response
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const onChangeOrder = async (type: 'toProduction' | 'toShipment') => {
    if (!dataOrder?.id) {
      return
    }
    try {
      setAwaitingChangeOrder(true)
      const res = await changeOrder({
        id: dataOrder.id,
        data: {
          product_order_items: [{
            id: dataOrder.productOrderItems?.[0].productOrderItemIRI,
            stock_item: stockItems?.[0]?.productIRI || null
          }],
          product_order_status: type === 'toProduction'
            ? OrderStatusesIRI.PRODUCTION
            : OrderStatusesIRI.WAITING_FOR_LOADING
        }
      })
      if ('data' in res) {
        api.success({
          message: `Заказ успешно отправлен`,
          placement: 'topRight',
          duration: 3
        })

        dispatch(setOrderToEditMP({edit: false, hasRightsForEdit: false, orderToEdit: null}))
      }

      if ('error' in res && 'status' in res.error) {
        api.error({
          message: `Заказ не отправлен. Ошибка ${res?.error?.status}`,
          placement: 'topRight',
          duration: 3
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setModalIntoProduction(false)
      setModalIntoShipment(false)
      setAwaitingChangeOrder(false)
    }
  }

  return (
    <ConfigProvider theme={{
      token: {
        colorText: token.colorTextLabel
      }
    }}>
      {awaitingChangeOrder && <LoaderFullDisplay/>}
      {contextHolder}
      <Form
        name={name}
        form={form}
        layout={'vertical'}
        initialValues={initialValues || {}}
        requiredMark={false}
      >
        <OrderHeader name={`${dataOrder?.id}`}
                     isEditable={true}
                     orderStatus={dataOrder?.productOrderStatus}
                     orderIRI={''}
                     contractNumber={dataOrder?.contractNumber}
                     closeForm={closeForm}
                     createDate={dataOrder?.createdAt}
                     responsibleUser={dataOrder?.responsibleUser}
        />
        <Tabs
          defaultActiveKey="1"
          items={
            [{
              key: '1',
              label: `Информация о товаре`,
              children: <Goods formName={name}
                               sendOnBlurGoods={sendOnBlurGoods}
                               dataOrder={dataOrder}
                               stockItemsLength={stockItems?.length || 0}
                               isFetchingStockItem={isFetchingStockItem}
              />
            },
              {
                key: '2',
                label: `Информация по клиенту`,
                children: <IndividualPerson dataOrder={dataOrder}
                                            changeContactDataForOrder={changeContactDataForOrder}/>
              }
            ]}
          tabBarStyle={{padding: '0 32px 0'}}
        />

        <Row className={s.buttons}>
          <Space size={24}>
            {
              stockItems && stockItems.length > 0 && (
                <Button type={'primary'} onClick={() => setModalIntoShipment(true)}>Отправить в отгрузку</Button>
              )
            }
            {
              stockItems && stockItems.length <= 0 && dataOrder?.productOrderStatus?.orderStatusIRI === OrderStatusesIRI.NEW && (
                <Button type={'primary'} onClick={() => setModalIntoProduction(true)}>Отправить в производство</Button>
              )
            }
          </Space>
        </Row>
      </Form>

      <Modal
        open={modalIntoProduction}
        onOk={() => onChangeOrder('toProduction')}
        onCancel={() => setModalIntoProduction(false)}
        okText="Да"
        cancelText="Нет"
        centered
        closable={false}
      >
        Вы действительно хотите отправить заказ в производство?
      </Modal>
      <Modal
        open={modalIntoShipment}
        onOk={() => onChangeOrder('toShipment')}
        onCancel={() => setModalIntoShipment(false)}
        okText="Да"
        cancelText="Нет"
        centered
        closable={false}
      >
        Вы действительно хотите отправить заказ в отгрузку?
      </Modal>
    </ConfigProvider>
  )
}

export default FormOrder