import React, { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'

interface IProps {
  sendOnBlurGoods: () => void
}

const Note: FC<IProps> = ({sendOnBlurGoods}) => {
  const form = Form.useFormInstance()

  return (
    <div className="p-[16px]">
      <Row className="mb-[12px] font-medium text-sm leading-[17px] text-[#8A94A2]">Примечание</Row>
      <Row>
        <Col span={24}>
          <Form.Item name={['note']}>
            <Input type={'text'}
                   className="h-[44px]"
                   onBlur={sendOnBlurGoods}
                   disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default Note