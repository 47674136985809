export enum OrderTypesCyr {
  'retail' = 'На заказ розница',
  'wholesale' = 'На заказ опт',
  'stock' = 'Со склада',
  'for_stock' = 'На склад',
  'personal' = 'Индивидуальный заказ',
  'marketplace' = 'Маркетплейс',
}

export enum ORDER_TYPES {
  RETAIL = 'retail',
  WHOLESALE = 'wholesale',
  STOCK = 'stock',
  FOR_STOCK = 'for_stock',
  PERSONAL = 'personal',
  MP = 'marketplace',
}