import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ConfigProvider, TabsProps } from 'antd'

import CurrentTab from './CurrentTab/CurrentTab'
import TabsCustom from '../TabsCustom/TabsCustom'

import { resetFilters } from '../../store/reducers/order/order.slice'
import { useAppDispatch } from '../../hooks/redux'
import { RouteNames } from '../../configs/routes/routesNames'

import ProductionsFilters from './ProductionFilters/ProductionFilters'

import s from './ProductionManagement.module.scss'

interface IProps {
  setIsShow: (value: boolean) => void
  children?: React.ReactNode
}

const ProductionManagement: FC<IProps> = ({setIsShow}) => {
  const router = useNavigate()
  const {pathname} = useLocation()
  const dispatch = useAppDispatch()

  const items: TabsProps['items'] = [
    {
      key: RouteNames.PRODUCTION,
      label: 'Текущие заказы',
      children: <CurrentTab setIsShow={setIsShow}/>
    },
    {
      key: `${RouteNames.PRODUCTION}/${RouteNames.PRODUCTION_READY}`,
      label: 'Готовые заказы',
      children: <ProductionsFilters hideData/>
    },
  ]

  const onChangeTab = (activeKey: string) => {
    dispatch(resetFilters())
    if (activeKey === RouteNames.PRODUCTION) {
      router(RouteNames.PRODUCTION)
    }
    if (activeKey === `${RouteNames.PRODUCTION}/${RouteNames.PRODUCTION_READY}`) {
      router(RouteNames.PRODUCTION_READY)
    }
  }

  return (
    <ConfigProvider theme={{token: {controlHeight: 40}}}>
      <div className={s.orderManagement}>
        <TabsCustom defaultActiveKey={pathname} items={items} onChange={onChangeTab}/>
      </div>
    </ConfigProvider>
  )
}

export default ProductionManagement