import React from 'react'
import Section from '../../../components/Section/Section'
import OrdersList from '../../../components/OrdersList/OrdersList'
import { setFormOrderCreate } from '../../../store/reducers/order/order.slice'
import { useAppDispatch } from '../../../hooks/redux'
import OrderManagement from '../../../components/OrderManagement/OrderManagement'

const OrdersPageArchive = () => {
  const dispatch = useAppDispatch()

  const setIsShow = (value: boolean) => {
    dispatch(setFormOrderCreate(value))
  }

  return (
    <Section>
      <OrderManagement setIsShow={setIsShow}/>
      <OrdersList/>
    </Section>
  )
}

export default OrdersPageArchive