import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from './api'
import { IMenu } from '../models/IMenu'

export const pagesApi = createApi({
  reducerPath: 'pages',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Pages'],
  endpoints: (build) => ({
    getPages: build.query<IMenu[], void>({
      query: () => `/pages`,
      transformResponse: (response: any) => {
        return response['hydra:member']
      },
      providesTags: result => ['Pages']
    }),
  })
})


export const {useLazyGetPagesQuery, useGetPagesQuery} = pagesApi
