import { IOrder, IOrderProduction, IOrderStatus } from '../models/IOrder'
import { apiSlice } from './api'
import { baseUrl } from '../configs/api/api.config'
import { OrderStatuses } from '../configs/enums/orderStatuses'
import { materialOrderApi } from './materialOrder.api'

interface IResponseOrderStatus {
  '@id': string
  ordering: number
  value: keyof typeof OrderStatuses
  cyrillic_value: string
}

export const orderApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrder: build.query<IOrder, number>({
      query: (id) => `/product-orders/${id}`,
      transformResponse: (response: any): IOrder => {
        return ({
          orderIRI: response?.['@id'],
          id: response.id,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          code: response.code,
          codePrefix: response.code_prefix,
          codeNumber: response.code_number,
          contractNumber: response.contract_number,
          type: response.type,
          productOrderStatus: {
            orderStatusIRI: response.product_order_status['@id'],
            value: response.product_order_status.value,
            ordering: response.product_order_status.ordering,
            cyrillicValue: response.product_order_status.cyrillic_value
          },
          productOrderSubStatus: response.product_order_sub_status,
          documents: [
            ...response?.documents?.map((item: any) => ({
              id: item.id,
              createdAt: item.created_at,
              firmName: item.name,
              link: `${baseUrl}${item.link}`,
              type: item.type,
              code: item.code,
              collection: item.collection
            }))
          ],
          paymentType: {
            paymentTypeIRI: response?.payment_type?.['@id'],
            id: response?.payment_type?.id,
            name: response?.payment_type?.name,
            cyrillicValue: response?.payment_type?.cyrillic_value
          },
          prepaymentPercent: response.prepayment_percent,
          prepaymentSum: response.prepayment_summ,
          afterPaymentSum: response.afterpayment_summ || '',
          productionDateFrom: response.production_date_from,
          productionDateTo: response.production_date_to,
          deliveryDateFrom: response.delivery_date_from,
          deliveryDateTo: response.delivery_date_to,
          note: response.note,
          responsibleUser: {
            responsibleUserIRI: response.responsible_user?.['@id'],
            firstName: response.responsible_user?.first_name,
            lastName: response.responsible_user?.last_name,
            middleName: response.responsible_user?.middle_name
          },
          workpoint: response.workpoint,
          contact: {
            hydraContactID: response?.['@id'] || '',
            id: response?.contact?.id || '',
            type: response?.contact?.type || '',
            firstName: response?.contact?.first_name || '',
            lastName: response?.contact?.second_name || '',
            middleName: response?.contact?.middle_name || '',
            fullName: response?.contact?.full_name || '',
            phone: response?.contact?.phone || '',
            email: response?.contact?.email || '',
            additionalContacts: response?.contact?.additional_contacts || '',
            address: response?.contact?.address || '',
            juriName: response?.contact?.juri_name || '',
            inn: response?.contact?.inn || '',
            kpp: response?.contact?.kpp || '',
            ogrn: response?.contact?.ogrn || '',
            bik: response?.contact?.bik || '',
            bankName: response?.contact?.bank_name || '',
            corrAccount: response?.contact?.corr_account || '',
            payAccount: response?.contact?.pay_account || '',
            yuriAddress: response?.contact?.yuri_address || '',
            managerFullname: response?.contact?.manager_fullname || '',
            createdAt: response?.contact?.created_at || '',
            updatedAt: response?.contact?.updated_at || '',
            source: {
              sourceIRI: response?.contact?.source?.['@id'] || '',
              id: response?.contact?.source?.id || '',
              name: response?.contact?.source?.name || ''
            },
          },
          price: response.price,
          productOrderItems: response.product_order_items.map((productOrderItem: any) => ({
            productOrderItemIRI: productOrderItem?.['@id'],
            id: productOrderItem.id,
            hash: productOrderItem.hash,
            createdAt: productOrderItem.created_at,
            updatedAt: productOrderItem.updated_at,
            product: productOrderItem.product
              ? {
                productIRI: productOrderItem.product?.['@id'],
                id: productOrderItem.product.id,
                name: productOrderItem.product.name,
                code: productOrderItem.product.code,
                type: productOrderItem.product.type
              }
              : null,
            productName: productOrderItem.product_name,
            productSupport: productOrderItem.product_support,
            price: productOrderItem.price,
            quantity: productOrderItem.quantity,
            cost: productOrderItem.cost,
            discount: productOrderItem.discount,
            sum: productOrderItem.summ,
            productSize: productOrderItem.product_size || null,
            productSizeValue: productOrderItem.product_size_value,
            materials: [
              {
                materialIRI: productOrderItem.materials[0]?.['@id'],
                id: productOrderItem.materials[0]?.id,
                createdAt: productOrderItem.materials[0]?.created_at,
                updatedAt: productOrderItem.materials[0]?.updated_at,
                material: productOrderItem.materials[0]?.material,
                name: productOrderItem.materials[0]?.name,
                provider: productOrderItem.materials[0]?.provider,
                price: productOrderItem.materials[0]?.price
              },
              {
                materialIRI: productOrderItem.materials[0]?.['@id'],
                id: productOrderItem?.materials[1]?.id || '',
                createdAt: productOrderItem?.materials[1]?.created_at || '',
                updatedAt: productOrderItem?.materials[1]?.updated_at || '',
                material: productOrderItem?.materials[1]?.material || '',
                name: productOrderItem?.materials[1]?.name || '',
                provider: productOrderItem?.materials[1]?.provider || '',
                price: productOrderItem?.materials[1]?.price || ''
              } || {}
            ],
            hasBackwall: productOrderItem.has_backwall,
            note: productOrderItem.note,
            productOrderItemOptions: productOrderItem.product_order_item_options.map((option: any) => ({
              id: option.id,
              optionIRI: option?.['@id'],
              createdAt: option.created_at,
              updatedAt: option.updated_at,
              product: {
                productIRI: option?.product?.['@id'] || null,
                id: option.product?.id || null,
                name: option.product?.name || '',
                code: option.product?.code || '',
                type: option.product?.type || ''
              },
              productName: option.product_name,
              productSupport: option.product_support,
              price: option.price,
              quantity: option.quantity,
              cost: option.cost,
              discount: option.discount,
              sum: option.summ,
              productSize: option.product_size || null,
              productSizeValue: option.product_size_value,
              hasBackwall: option.has_backwall,
              note: option.note,
            })),
            stockItem: {
              stockItemIRI: productOrderItem.stock_item?.['@id'],
              id: productOrderItem.stock_item?.id,
              itemCode: productOrderItem.stock_item?.item_code
            }
          })),
          prepaymentReceived: response.prepayment_received || false,
          productionFinishedAt: response.production_finished_at,
          marketplaceType: response.marketplace_type,
          marketplaceOrderId: response?.marketplace_order_id,
          deliveryAddress: response.delivery_address,
          orderTaskStatusesByRoles: response.order_task_statuses_by_roles,
          subProductOrders: response.sub_product_orders?.map((subProduct: any) => ({
            subProductIRI: subProduct['@id'],
            id: subProduct.id,
            code: subProduct.code,
            productOrderItem: subProduct.product_order_item,
            quantity: subProduct.quantity,
            futureTasks: subProduct.future_tasks,
            tasks: subProduct.tasks?.map((task: any) => ({
              taskIRI: task['@id'],
              id: task.id,
              status: task.status,
              role: {roleIRI: task.role?.['@id'], name: task.role?.name || ''},
              userEntity:
                task.user_entity
                  ? {
                    id: task.user_entity?.id,
                    email: task.user_entity?.email,
                    firstName: task.user_entity?.first_name,
                    lastName: task.user_entity?.last_name,
                    middleName: task.user_entity?.middle_name,
                    accessCode: task.user_entity?.access_code,
                  }
                  : null
            })),
          })),
          tasks: response.tasks?.map((task: any) => ({
            taskIRI: task['@id'],
            id: task.id,
            status: task.status,
            role: {roleIRI: task.role?.['@id'], name: task.role?.name || ''},
            userEntity:
              task.user_entity
                ? {
                  id: task.user_entity?.id,
                  email: task.user_entity?.email,
                  firstName: task.user_entity?.first_name,
                  lastName: task.user_entity?.last_name,
                  middleName: task.user_entity?.middle_name,
                  accessCode: task.user_entity?.access_code,
                }
                : null
          })),
          productionSubStatus: response?.production_sub_status,
        })
      },
      providesTags: result => ['Order'],
    }),
    getOrders: build.query<{ orders: IOrderProduction[], totalItems: number }, {
      company?: string,
      contractNumber?: string,
      status?: string | string[],
      subStatus?: string | string[],
      type?: string | string[],
      dateFrom?: string,
      dateTo?: string,
      productionDateFrom?: string,
      productionDateTo?: string,
      productionFinishedAtFrom?: string,
      productionFinishedAtTo?: string,
      lastName?: string,
      perPage?: number,
      page?: number,
      sort?: {
        'sort[product_order_status.ordering]'?: 'asc' | 'desc',
        'sort[production_sub_status]'?: 'asc' | 'desc',
        'sort[production_date_to]'?: 'asc' | 'desc',
        'sort[afterpayment_received]'?: 'asc' | 'desc',
        'sort[loading_date_to]'?: 'asc' | 'desc',
        'sort[delivery_date_from]'?: 'asc' | 'desc',
        'sort[delivery_date_to]'?: 'asc' | 'desc',
      },
    }>({
      query: ({
                company,
                contractNumber,
                status,
                subStatus,
                type,
                dateFrom,
                dateTo,
                productionDateFrom,
                productionDateTo,
                productionFinishedAtFrom,
                productionFinishedAtTo,
                lastName,
                sort,
                perPage = 1000,
                page = 1
              }) => {
        const params: any = {
          'documents.firm_name': company,
          contract_number: contractNumber,
          per_page: perPage,
          page,
          'contact.second_name': lastName,
          ...sort
        }
        if (dateFrom) {
          params['created_at[after]'] = `${dateFrom} 00:00:00`
        }
        if (dateTo) {
          params['created_at[before]'] = `${dateTo} 23:59:59`
        }
        if (productionDateFrom) {
          params['production_date_from[after]'] = `${productionDateFrom} 00:00:00`
        }
        if (productionDateTo) {
          params['production_date_to[before]'] = `${productionDateTo} 23:59:59`
        }
        if (productionFinishedAtFrom) {
          params['production_finished_at[after]'] = `${productionFinishedAtFrom} 00:00:00`
        }
        if (productionFinishedAtTo) {
          params['production_finished_at[before]'] = `${productionFinishedAtTo} 23:59:59`
        }
        let collectStatus = 'product_order_status[]='
        if (status) {
          collectStatus += Array.isArray(status)
            ? status.reduce((acc, i, idx) => acc += `${idx === 0 ? '' : '&'}product_order_status[]=${i}`)
            : `${status}`
        }
        let collectSubStatus = '&production_sub_status[]='
        if (subStatus) {
          collectSubStatus += Array.isArray(subStatus)
            ? subStatus.reduce((acc, i, idx) => acc += `${idx === 0 ? '' : '&'}production_sub_status[]=${i}`)
            : `${subStatus}`
        }
        let collectTypes = '&type[]='
        if (type) {
          collectTypes += Array.isArray(type)
            ? type.reduce((acc, i, idx) => acc += `${idx === 0 ? '' : '&'}type[]=${i}`)
            : `${type}`
        }

        return ({
          url: `/product-orders?${status ? collectStatus : ''}${subStatus ? collectSubStatus : ''}${type ? collectTypes : ''}`,
          params
        })
      },
      transformResponse: (response: any) => {
        return {
          orders: [
            ...response['hydra:member'].map((item: any) => ({
              orderIRI: item['@id'],
              code: item.code,
              contractNumber: item.contract_number,
              createdAt: item.created_at,
              deliveryDateFrom: item.delivery_date_from,
              deliveryDateTo: item.delivery_date_to,
              productionDateFrom: item.production_date_from,
              productionDateTo: item.production_date_to,
              loadingDateFrom: item.loading_date_from,
              loadingDateTo: item.loading_date_to,
              id: item.id,
              key: item.key,
              prepaymentSumm: item.prepayment_summ,
              afterpaymentSumm: item.afterpayment_summ,
              price: item.price,
              type: item.type,
              paymentType: {
                paymentTypeIRI: item.payment_type?.['@id'],
                id: item.payment_type?.id,
                name: item.payment_type?.name,
                cyrillicValue: item.payment_type?.cyrillic_value
              },
              productionSubStatus: item.production_sub_status,
              contact: {
                contactIRI: item?.contact?.['@id'] || '',
                firstName: item?.contact?.first_name || '',
                fullName: item?.contact?.full_name || '',
                id: item?.contact?.id || '',
                juriName: item?.contact?.juri_name || '',
                middleName: item?.contact?.middle_name || '',
                secondName: item?.contact?.second_name || '',
                type: item?.contact?.type || '',
                phone: item?.contact?.phone || '',
                address: item?.contact?.address || '',
              },
              productOrderStatus: {
                orderStatusIRI: item.product_order_status['@id'],
                ordering: item.product_order_status.ordering,
                value: item.product_order_status.value,
                cyrillicValue: item.product_order_status.cyrillic_value
              },
              productOrderItems: item.product_order_items.map((el: any) => ({
                productOrderItemIRI: el['@id'],
                productName: el.product_name,
                quantity: el.quantity,
                productSizeValue: el.product_size_value,
                materials: el.materials.map((material: any) => ({
                  material: material?.material || '',
                  name: material?.name || '',
                  provider: material?.provider || '',
                })),
                hasBackwall: el.has_backwall,
                productSupport: {
                  name: el?.product_support?.name
                },
                productOrderItemOptions: el.product_order_item_options?.map((option: any) => ({
                  optionIRI: option['@id'],
                  id: option.id,
                  createdAt: option.created_at,
                  updatedAt: option.updated_at,
                  productName: option.product_name,
                  quantity: option.quantity,
                  price: option.price,
                  cost: option.cost,
                  discount: option.discount,
                  summ: option.summ,
                  product: {
                    id: option.product?.id,
                    name: option.product?.name,
                    code: option.product?.code,
                    type: option.product?.type
                  },
                })),
                stockItem: {
                  stockItemIRI: el.stock_item?.['@id'],
                  id: el.stock_item?.id,
                  itemCode: el.stock_item?.item_code
                }
              })),
              prepaymentReceived: item?.prepayment_received,
              afterpaymentReceived: item?.afterpayment_received,
              responsibleUser: {
                responsibleUserIRI: item.responsible_user?.['@id'],
                firstName: item.responsible_user?.first_name,
                lastName: item.responsible_user?.last_name,
                middleName: item.responsible_user?.middle_name,
              },
              marketplaceType: item.marketplace_type,
              marketplaceOrderId: item?.marketplace_order_id,
            }))
          ],
          totalItems: response['hydra:totalItems']
        }
      },
      // keepUnusedDataFor: 5
      providesTags: result => ['Orders'],
      // Only have one cache entry because the arg always maps to one string
      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName
      // },
      // // Always merge incoming data to the cache entry
      // merge: (currentCache, newItems) => {
      //   console.log(currentCache)
      //   console.log(newItems)
      //   currentCache.push(...newItems)
      // },
      // // Refetch when the page arg changes
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg
      // },
    }),
    getOrdersProduction: build.query<
      { orders: IOrderProduction[], totalItems: number },
      {
        company?: string,
        contractNumber?: string,
        status?: string | string[],
        subStatus?: string | string[],
        type?: string | string[],
        dateFrom?: string,
        dateTo?: string,
        productionDateFrom?: string,
        productionDateTo?: string,
        productionFinishedAtFrom?: string,
        productionFinishedAtTo?: string,
        fullName?: string,
        perPage?: number,
        page?: number,
        sort?: {
          productOrderStatus?: 'asc' | 'desc',
          productionDateFrom?: 'asc' | 'desc',
          productionDateTo?: 'asc' | 'desc',
          loadingDateTo?: 'asc' | 'desc',
          productionFinishedAt?: 'asc' | 'desc',
          isAsap?: 'asc' | 'desc',
        },
      }>({
      query: ({
                contractNumber,
                status,
                subStatus,
                type,
                dateFrom,
                dateTo,
                productionDateFrom,
                productionDateTo,
                productionFinishedAtFrom,
                productionFinishedAtTo,
                fullName,
                sort,
                perPage = 1000,
                page = 1,
              }) => {
        const params: any = {
          contract_number: contractNumber,
          per_page: perPage,
          page,
          'contact.full_name': fullName,
          'sort[is_asap]': sort?.isAsap,
          'sort[product_order_status.value]': sort?.productOrderStatus,
          'sort[production_date_from]': sort?.productionDateFrom,
          'sort[loading_date_to]': sort?.loadingDateTo,
          'sort[production_finished_at]': sort?.productionFinishedAt,
        }
        if (dateFrom) {
          params['created_at[after]'] = `${dateFrom} 00:00:00`
        }
        if (dateTo) {
          params['created_at[before]'] = `${dateTo} 23:59:59`
        }
        if (productionDateFrom) {
          params['production_date_from[after]'] = `${productionDateFrom} 00:00:00`
        }
        if (productionDateTo) {
          params['production_date_to[before]'] = `${productionDateTo} 23:59:59`
        }
        if (productionFinishedAtFrom) {
          params['production_finished_at[after]'] = `${productionFinishedAtFrom} 00:00:00`
        }
        if (productionFinishedAtTo) {
          params['production_finished_at[before]'] = `${productionFinishedAtTo} 23:59:59`
        }
        let collectStatus = 'product_order_status[]='
        if (status) {
          collectStatus += Array.isArray(status)
            ? status.reduce((acc, i, idx) => acc += `${idx === 0 ? '' : '&'}product_order_status[]=${i}`)
            : `${status}`
        }
        let collectSubStatus = '&production_sub_status[]='
        if (subStatus) {
          collectSubStatus += Array.isArray(subStatus)
            ? subStatus.reduce((acc, i, idx) => acc += `${idx === 0 ? '' : '&'}production_sub_status[]=${i}`)
            : `${subStatus}`
        }
        let collectTypes = '&type[]='
        if (type) {
          collectTypes += Array.isArray(type)
            ? type.reduce((acc, i, idx) => acc += `${idx === 0 ? '' : '&'}type[]=${i}`)
            : `${type}`
        }

        return ({
          url: `/product-orders/production?${status ? collectStatus : ''}${subStatus ? collectSubStatus : ''}${type ? collectTypes : ''}`,
          params
        })
      },
      transformResponse: (response: any) => {
        return {
          orders: [
            ...response['hydra:member'].map((item: any) => ({
              orderIRI: item['@id'],
              code: item.code,
              contractNumber: item.contract_number,
              createdAt: item.created_at,
              deliveryDateFrom: item.delivery_date_from,
              deliveryDateTo: item.delivery_date_to,
              productionDateFrom: item.production_date_from,
              productionDateTo: item.production_date_to,
              productionFinishedAt: item.production_finished_at,
              id: item.id,
              key: item.key,
              prepaymentSumm: item.prepayment_summ,
              afterpaymentSumm: item.afterpayment_summ,
              price: item.price,
              type: item.type,
              productionSubStatus: item.production_sub_status,
              contact: {
                contactIRI: item?.contact?.['@id'] || '',
                firstName: item?.contact?.first_name || '',
                fullName: item?.contact?.full_name || '',
                id: item?.contact?.id || '',
                juriName: item?.contact?.juri_name || '',
                middleName: item?.contact?.middle_name || '',
                secondName: item?.contact?.second_name || '',
                type: item?.contact?.type || ''
              },
              productOrderStatus: {
                orderStatusIRI: item.product_order_status['@id'],
                ordering: item.product_order_status.ordering,
                value: item.product_order_status.value,
                cyrillicValue: item.product_order_status.cyrillic_value
              },
              productOrderItems: item.product_order_items.map((el: any) => ({
                productName: el.product_name,
                quantity: el.quantity,
                productSizeValue: el.product_size_value,
                materials: el.materials.map((material: any) => ({
                  material: material.material,
                  name: material.name,
                  provider: material.provider,
                })),
                hasBackwall: el.has_backwall,
                productSupport: {
                  name: el?.product_support?.name
                },
                productOrderItemOptions: el.product_order_item_options?.map((option: any) => ({
                  productOrderItemOptionsIRI: option['@id'],
                  id: option.id,
                  cost: option.cost,
                  createdAt: option.created_at,
                  discount: option.discount,
                  price: option.price,
                  productName: option.product_name,
                  quantity: option.quantity,
                  summ: option.summ,
                  updatedAt: option.updated_at,
                  product: option.product,
                }))
              })),
              prepaymentReceived: item?.prepayment_received,
              responsibleUser: {
                responsibleUserIRI: item.responsible_user?.['@id'],
                firstName: item.responsible_user?.first_name,
                lastName: item.responsible_user?.last_name,
                middleName: item.responsible_user?.middle_name,
              },
              tasks: item.tasks?.map((task: any) => ({
                taskIRI: task['@id'],
                id: task.id,
                status: task.status,
                role: {roleIRI: task.role?.['@id'], name: task.role?.name || ''},
                userEntity:
                  task.user_entity
                    ? {
                      id: task.user_entity?.id,
                      email: task.user_entity?.email,
                      firstName: task.user_entity?.first_name,
                      lastName: task.user_entity?.last_name,
                      middleName: task.user_entity?.middle_name,
                      accessCode: task.user_entity?.access_code,
                    }
                    : null
              })),
              orderTaskStatusesByRoles: item.order_task_statuses_by_roles,
              subProductOrders: item.sub_product_orders?.map((subProduct: any) => ({
                subProductIRI: subProduct['@id'],
                id: subProduct.id,
                code: subProduct.code,
                productOrderItem: {
                  productOrderItemIRI: subProduct.product_order_item['@id'],
                  id: subProduct.product_order_item.id,
                  productName: subProduct.product_order_item.product_name,
                  productSizeValue: subProduct.product_order_item.product_size_value,
                },
                quantity: subProduct.quantity,
                futureTasks: subProduct.future_tasks,
                tasks: subProduct.tasks?.map((task: any) => ({
                  taskIRI: task['@id'],
                  id: task.id,
                  status: task.status,
                  role: {roleIRI: task.role?.['@id'], name: task.role?.name || ''},
                  userEntity:
                    task.user_entity
                      ? {
                        id: task.user_entity?.id,
                        email: task.user_entity?.email,
                        firstName: task.user_entity?.first_name,
                        lastName: task.user_entity?.last_name,
                        middleName: task.user_entity?.middle_name,
                        accessCode: task.user_entity?.access_code,
                      }
                      : null
                })),
              })),
              // subProductOrders: [
              //   {
              //     subProductIRI: 'iri',
              //     code: '1144/1',
              //     productOrderItem: 'product_order_itemIRI',
              //     productName: 'productName',
              //     productSizeValue: 'productSizeValue',
              //     quantity: 10,
              //     futureTasks: [],
              //     tasks: [
              //       {
              //         taskIRI: '/api/v1/tasks/162778',
              //         'id': 162778,
              //         'userEntity': {
              //           '@id': '/api/v1/users/108',
              //           'id': 108,
              //           'email': '1738833221@mail.com',
              //           'firstName': 'Парвиз',
              //           'lastName': 'Меликдоза',
              //           'middleName': '',
              //           'accessCode': '130402'
              //         },
              //         'status': 'completed',
              //         'role': {
              //           roleIRI: '/api/v1/user-roles/16',
              //           'id': 16,
              //           'name': 'Сборщик'
              //         },
              //         'isAsap': true
              //       }
              //     ]
              //   },
              //   {
              //     subProductIRI: 'iri',
              //     code: '1144/2',
              //     productOrderItem: 'product_order_itemIRI',
              //     productName: 'productName',
              //     productSizeValue: 'productSizeValue',
              //     quantity: 100,
              //     futureTasks: [],
              //     tasks: [
              //       {
              //         taskIRI: '/api/v1/tasks/162778',
              //         'id': 162778,
              //         'userEntity': {
              //           '@id': '/api/v1/users/108',
              //           'id': 108,
              //           'email': '1738833221@mail.com',
              //           'firstName': 'Парвиз',
              //           'lastName': 'Меликдоза',
              //           'middleName': '',
              //           'accessCode': '130402'
              //         },
              //         'status': 'completed',
              //         'role': {
              //           roleIRI: '/api/v1/user-roles/16',
              //           'id': 16,
              //           'name': 'Сборщик'
              //         },
              //         'isAsap': true
              //       }
              //     ]
              //   }
              // ],
              isAsap: item.is_asap,
              futureTasks: item.future_tasks,
              marketplaceOrderId: item?.marketplace_order_id,
            }))
          ],
          totalItems: response['hydra:totalItems'],
        }
      },
      providesTags: result => ['OrderProduction'],
    }),
    createOrder: build.mutation<IOrder, any>({
      query: (data) => ({
        url: '/product-orders',
        method: 'POST',
        body: data
      }),
      transformResponse: (response: any): IOrder => {
        return {
          orderIRI: response?.['@id'],
          id: response?.id,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          code: response.code,
          codePrefix: response.code_prefix,
          codeNumber: response.code_number,
          contractNumber: response.contract_number,
          type: response.type,
          productOrderStatus: {
            orderStatusIRI: response.product_order_status['@id'],
            value: response.product_order_status.value,
            ordering: response.product_order_status.ordering,
            cyrillicValue: response.product_order_status.cyrillic_value
          },
          productOrderSubStatus: response.product_order_sub_status,
          documents: response?.documents?.length
            ? [
              ...response?.documents?.map((item: any) => ({
                id: item.id,
                createdAt: item.created_at,
                firmName: item.name,
                link: `${baseUrl}${item.link}`,
                type: item.type,
                code: item.code,
                collection: item.collection
              }))
            ]
            : [],
          price: response.price,
          paymentType: {
            paymentTypeIRI: response?.payment_type?.['@id'],
            id: response?.payment_type?.id,
            name: response?.payment_type?.name,
            cyrillicValue: response?.payment_type?.cyrillic_value
          },
          prepaymentPercent: response.prepayment_percent,
          prepaymentSum: response.prepaymentSum,
          afterPaymentSum: response.afterpayment_summ || '',
          productionDateFrom: response.production_date_from,
          productionDateTo: response.production_date_to,
          deliveryDateFrom: response.delivery_date_from,
          deliveryDateTo: response.delivery_date_to,
          note: response.note,
          responsibleUser: {
            responsibleUserIRI: response.responsible_user?.['@id'],
            firstName: response.responsible_user?.first_name,
            lastName: response.responsible_user?.lastName,
            middleName: response.responsible_user?.middle_name
          },
          workpoint: response.workpoint,
          contact: {
            hydraContactID: response?.contact?.['@id'] || '',
            id: response?.contact?.id || '',
            type: response?.contact?.type || '',
            firstName: response?.contact?.first_name || '',
            lastName: response?.contact?.second_name || '',
            middleName: response?.contact?.middle_name || '',
            fullName: response?.contact?.full_name || '',
            phone: response?.contact?.phone || '',
            email: response?.contact?.email || '',
            additionalContacts: response?.contact?.additional_contacts || '',
            address: response?.contact?.address || '',
            juriName: response?.contact?.juri_name || '',
            inn: response?.contact?.inn || '',
            kpp: response?.contact?.kpp || '',
            ogrn: response?.contact?.ogrn || '',
            bik: response?.contact?.bik || '',
            bankName: response?.contact?.bank_name || '',
            corrAccount: response?.contact?.corr_account || '',
            payAccount: response?.contact?.pay_account || '',
            yuriAddress: response?.contact?.yuri_address || '',
            managerFullname: response?.contact?.manager_fullname || '',
            createdAt: response?.contact?.created_at || '',
            updatedAt: response?.contact?.updated_at || '',
            source: response?.contact?.source || ''
          },
          productOrderItems: response.product_order_items.map((productOrderItem: any) => ({
            id: productOrderItem?.id,
            createdAt: productOrderItem.created_at,
            updatedAt: productOrderItem.updated_at,
            product: productOrderItem.product
              ? {
                '@id': productOrderItem.product?.['@id'],
                id: productOrderItem?.product?.id,
                name: productOrderItem.product.name,
                code: productOrderItem.product.code,
                type: productOrderItem.product.type
              }
              : null,
            productName: productOrderItem.product_name,
            productSupport: productOrderItem.product_support,
            price: productOrderItem.price,
            quantity: productOrderItem.quantity,
            cost: productOrderItem.cost,
            discount: productOrderItem.discount,
            sum: productOrderItem.summ,
            productSize: productOrderItem.product_size || null,
            productSizeValue: productOrderItem.product_size_value,
            materials: [
              {
                id: productOrderItem.materials[0].id,
                createdAt: productOrderItem.materials[0].created_at,
                updatedAt: productOrderItem.materials[0].updated_at,
                material: productOrderItem.materials[0].material,
                name: productOrderItem.materials[0].name,
                provider: productOrderItem.materials[0].provider,
                price: productOrderItem.materials[0].price
              },
              {
                id: productOrderItem?.materials[1]?.id || '',
                createdAt: productOrderItem?.materials[1]?.created_at || '',
                updatedAt: productOrderItem?.materials[1]?.updated_at || '',
                material: productOrderItem?.materials[1]?.material || '',
                name: productOrderItem?.materials[1]?.name || '',
                provider: productOrderItem?.materials[1]?.provider || '',
                price: productOrderItem?.materials[1]?.price || ''
              } || {}
            ],
            hasBackwall: productOrderItem.has_backwall,
            note: productOrderItem.note,
            product_order_item_options: [],
            stockItem: {
              stockItemIRI: productOrderItem?.stock_item?.['@id'],
              id: productOrderItem?.stock_item?.id,
              itemCode: productOrderItem?.stock_item?.item_code
            }
          })),
          prepaymentReceived: response.prepayment_received || false,
          productionFinishedAt: response.production_finished_at,
          marketplaceType: response.marketplace_type,
          marketplaceOrderId: response.marketplace_order_id,
          deliveryAddress: response.delivery_address,
          orderTaskStatusesByRoles: response.order_task_statuses_by_roles,
          subProductOrders: response.sub_product_orders?.map((subProduct: any) => ({
            subProductIRI: subProduct['@id'],
            code: subProduct.code,
            productOrderItem: subProduct.product_order_item,
            quantity: subProduct.quantity,
            futureTasks: subProduct.future_tasks,
            tasks: subProduct.tasks?.map((task: any) => ({
              taskIRI: task['@id'],
              id: task.id,
              status: task.status,
              role: {roleIRI: task.role?.['@id'], name: task.role?.name || ''},
              userEntity:
                task.user_entity
                  ? {
                    id: task.user_entity?.id,
                    email: task.user_entity?.email,
                    firstName: task.user_entity?.first_name,
                    lastName: task.user_entity?.last_name,
                    middleName: task.user_entity?.middle_name,
                    accessCode: task.user_entity?.access_code,
                  }
                  : null
            })),
          })),
          tasks: response.tasks?.map((task: any) => ({
            taskIRI: task['@id'],
            id: task.id,
            status: task.status,
            role: {roleIRI: task.role?.['@id'], name: task.role?.name || ''},
            userEntity:
              task.user_entity
                ? {
                  id: task.user_entity?.id,
                  email: task.user_entity?.email,
                  firstName: task.user_entity?.first_name,
                  lastName: task.user_entity?.last_name,
                  middleName: task.user_entity?.middle_name,
                  accessCode: task.user_entity?.access_code,
                }
                : null
          })),
          productionSubStatus: response?.production_sub_status,
        }
      },
      invalidatesTags: ['Orders']
    }),
    changeOrder: build.mutation<IOrder, { id: number, data: any }>({
      query: (body) => ({
        url: `/product-orders/${body.id}`,
        method: 'PUT',
        body: body.data
      }),
      transformResponse: (response: any): IOrder => {
        const transformOption = (options: any) => {
          let result = []
          for (const option in options) {
            result.push({
              optionIRI: options[option]['@id'],
              id: options[option].id,
              createdAt: options[option].created_at,
              updatedAt: options[option].updated_at,
              productName: options[option].product_name,
              quantity: options[option].quantity,
              price: options[option].price,
              cost: options[option].cost,
              discount: options[option].discount,
              summ: options[option].summ,
              product: {
                id: options[option].product?.id,
                name: options[option].product?.name,
                code: options[option].product?.code,
                type: options[option].product?.type
              },
            })
          }

          return result
        }

        return {
          orderIRI: response?.['@id'],
          id: response.id,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          code: response.code,
          codePrefix: response.code_prefix,
          codeNumber: response.code_number,
          contractNumber: response.contract_number,
          type: response.type,
          productOrderStatus: {
            orderStatusIRI: response.product_order_status?.['@id'],
            value: response.product_order_status?.value,
            ordering: response.product_order_status?.ordering,
            cyrillicValue: response.product_order_status.cyrillic_value
          },
          productOrderSubStatus: response.product_order_sub_status,
          documents: [
            ...response?.documents?.map((item: any) => ({
              id: item.id,
              createdAt: item.created_at,
              firmName: item.name,
              link: `${baseUrl}${item.link}`,
              type: item.type,
              code: item.code,
              collection: item.collection
            }))
          ],
          price: response.price,
          paymentType: {
            paymentTypeIRI: response?.payment_type?.['@id'],
            id: response?.payment_type?.id,
            name: response?.payment_type?.name,
            cyrillicValue: response?.payment_type?.cyrillic_value
          },
          prepaymentPercent: response.prepayment_percent,
          prepaymentSum: response.prepaymentSum,
          afterPaymentSum: response.afterpayment_summ || '',
          productionDateFrom: response.production_date_from,
          productionDateTo: response.production_date_to,
          deliveryDateFrom: response.delivery_date_from,
          deliveryDateTo: response.delivery_date_to,
          note: response.note,
          responsibleUser: {
            responsibleUserIRI: response?.responsible_user?.['@id'],
            firstName: response.responsible_user?.first_name,
            lastName: response.responsible_user?.lastName,
            middleName: response.responsible_user?.middle_name
          },
          workpoint: response.workpoint,
          contact: {
            hydraContactID: response?.contact?.['@id'] || '',
            id: response?.contact?.id,
            type: response?.contact?.type,
            firstName: response?.contact?.first_name,
            lastName: response?.contact?.second_name,
            middleName: response?.contact?.middle_name,
            fullName: response?.contact?.full_name,
            phone: response?.contact?.phone,
            email: response?.contact?.email,
            additionalContacts: response?.contact?.additional_contacts,
            address: response?.contact?.address,
            juriName: response?.contact?.juri_name,
            inn: response?.contact?.inn,
            kpp: response?.contact?.kpp,
            ogrn: response?.contact?.ogrn,
            bik: response?.contact?.bik,
            bankName: response?.contact?.bank_name,
            corrAccount: response?.contact?.corr_account,
            payAccount: response?.contact?.pay_account,
            yuriAddress: response?.contact?.yuri_address,
            managerFullname: response?.contact?.manager_fullname,
            createdAt: response?.contact?.created_at,
            updatedAt: response?.contact?.updated_at,
            source: response?.contact?.source
          },
          productOrderItems: response.product_order_items?.map((productOrderItem: any) => ({
            id: productOrderItem.id,
            createdAt: productOrderItem.created_at,
            updatedAt: productOrderItem.updated_at,
            product: productOrderItem.product
              ? {
                // '@id': productOrderItem.product['@id'],
                id: productOrderItem.product.id,
                name: productOrderItem.product.name,
                code: productOrderItem.product.code,
                type: productOrderItem.product.type
              }
              : null,
            productName: productOrderItem.product_name,
            productSupport: productOrderItem.product_support,
            price: productOrderItem.price,
            quantity: productOrderItem.quantity,
            cost: productOrderItem.cost,
            discount: productOrderItem.discount,
            sum: productOrderItem.summ,
            productSize: productOrderItem.product_size || null,
            productSizeValue: productOrderItem.product_size_value,
            materials: [
              {
                id: productOrderItem.materials[0].id,
                createdAt: productOrderItem.materials[0].created_at,
                updatedAt: productOrderItem.materials[0].updated_at,
                material: productOrderItem.materials[0].material,
                name: productOrderItem.materials[0].name,
                provider: productOrderItem.materials[0].provider,
                price: productOrderItem.materials[0].price
              },
              {
                id: productOrderItem?.materials[1]?.id || '',
                createdAt: productOrderItem?.materials[1]?.created_at || '',
                updatedAt: productOrderItem?.materials[1]?.updated_at || '',
                material: productOrderItem?.materials[1]?.material || '',
                name: productOrderItem?.materials[1]?.name || '',
                provider: productOrderItem?.materials[1]?.provider || '',
                price: productOrderItem?.materials[1]?.price || ''
              } || {}
            ],
            hasBackwall: productOrderItem.has_backwall,
            note: productOrderItem.note,
            productOrderItemOptions: productOrderItem?.product_order_item_options
              ? transformOption(productOrderItem.product_order_item_options)
              : null,
            hash: productOrderItem.hash
          })),
          prepaymentReceived: response.prepayment_received || false,
          productionFinishedAt: response?.production_finished_at,
          marketplaceType: response.marketplace_type,
          marketplaceOrderId: response.marketplace_order_id,
          deliveryAddress: response.delivery_address,
          orderTaskStatusesByRoles: response.order_task_statuses_by_roles,
          subProductOrders: response.sub_product_orders?.map((subProduct: any) => ({
            subProductIRI: subProduct['@id'],
            code: subProduct.code,
            productOrderItem: subProduct.product_order_item,
            quantity: subProduct.quantity,
            futureTasks: subProduct.future_tasks,
            tasks: subProduct.tasks?.map((task: any) => ({
              taskIRI: task['@id'],
              id: task.id,
              status: task.status,
              role: {roleIRI: task.role?.['@id'], name: task.role?.name || ''},
              userEntity:
                task.user_entity
                  ? {
                    id: task.user_entity?.id,
                    email: task.user_entity?.email,
                    firstName: task.user_entity?.first_name,
                    lastName: task.user_entity?.last_name,
                    middleName: task.user_entity?.middle_name,
                    accessCode: task.user_entity?.access_code,
                  }
                  : null
            })),
          })),
          tasks: response.tasks?.map((task: any) => ({
            taskIRI: task['@id'],
            id: task.id,
            status: task.status,
            role: {roleIRI: task.role?.['@id'], name: task.role?.name || ''},
            userEntity:
              task.user_entity
                ? {
                  id: task.user_entity?.id,
                  email: task.user_entity?.email,
                  firstName: task.user_entity?.first_name,
                  lastName: task.user_entity?.last_name,
                  middleName: task.user_entity?.middle_name,
                  accessCode: task.user_entity?.access_code,
                }
                : null
          })),
          productionSubStatus: response?.production_sub_status,
        }
      },
      invalidatesTags: () => ['Orders'],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(materialOrderApi.util.invalidateTags(['Materials']))
        })
      },
    }),
    changeSubProduct: build.mutation<IOrder, { id: number, data: any }>({
      query: (body) => ({
        url: `/sub-product-orders/${body.id}`,
        method: 'PUT',
        body: body.data
      }),
    }),
    deleteProductItems: build.mutation<void, number>({
      query: (id) => ({
        url: `/product-order-items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Order']
    }),
    deleteProductOrderItemOption: build.mutation<void, number>({
      query: (id) => ({
        url: `/product-order-item-options/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Order']
    }),
    getOrderName: build.mutation<{ counter: number }, { prefix: string }>({
      query: (data) => ({
        url: '/order-counters/get-next',
        method: 'POST',
        body: data
      }),
      // invalidatesTags: ['Order']
    }),
    getProductOrderStatuses: build.query<IOrderStatus[], void>({
      query: () => '/product-order-statuses',
      transformResponse: (response: any): IOrderStatus[] => response['hydra:member'].map((status: IResponseOrderStatus) => ({
        orderStatusIRI: status['@id'],
        ordering: status.ordering,
        value: status.value,
        cyrillicValue: status.cyrillic_value
      }))
    }),
    createMaterialPlate: build.query<{ link: string }, number>({
      query: (id) => `/product-orders/${id}/create-material-plate-2`
    })
  }),
})

export const {
  useLazyGetOrderQuery,
  useGetOrdersQuery,
  useGetOrdersProductionQuery,
  useLazyGetOrdersProductionQuery,
  useLazyGetOrdersQuery,
  useGetOrderNameMutation,
  useCreateOrderMutation,
  useGetProductOrderStatusesQuery,
  useChangeOrderMutation,
  useDeleteProductItemsMutation,
  useDeleteProductOrderItemOptionMutation,
  useChangeSubProductMutation,
  useLazyCreateMaterialPlateQuery,
} = orderApi

