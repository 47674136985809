import React, { FC, useEffect, useState } from 'react'
import { Checkbox, Col, ConfigProvider, Form, Input, Row, Space } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { useLazyGetProductSizeQuery } from '../../../../../../services/product.api'
import { LabeledValue } from 'antd/es/select'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'
import { useSelector } from 'react-redux'
import { getDisabledFieldsFromEditOrder } from '../../../../../../store/reducers/order/order.slice'
import { ORDER_TYPES } from '../../../../../../configs/enums/orderTypes'

import s from './GoodSize.module.scss'

interface IProps {
  fieldName: number
  sendOnBlurGoods: () => void
  orderStatus?: OrderStatusesIRI | null
  formName: string
}

const GoodSize: FC<IProps> = ({formName, fieldName, sendOnBlurGoods, orderStatus = null}) => {
  const [isCustomSize, setIsCustomSize] = useState(false)
  const disabledFields = useSelector(getDisabledFieldsFromEditOrder)
  const [disabledCheckbox, setDisabledCheckbox] = useState(false)
  const [disabledSizeField, setDisabledSizeField] = useState(false)
  // const isEditFormCreateOrder = useAppSelector(state => state.orderSlice.isEditFormCreateOrder)
  const [getSizesProduct, {data: dataSizesProduct, isLoading: isLoadingSizesProduct}] = useLazyGetProductSizeQuery()
  const form = Form.useFormInstance()
  const orderType = Form.useWatch(`orderType`, form)
  const productIRI = Form.useWatch(['goods', fieldName, 'good', 0, 'productIRI'], form)
  const ch = Form.useWatch(['goods', fieldName, 'checkboxCustomSize'], form)

  useEffect(() => {
    getSizesProduct({product: productIRI})
  }, [productIRI])

  useEffect(() => {
    setIsCustomSize(ch)
  }, [ch])

  useEffect(() => {
    if (formName === 'newOrder') {
      if (orderType === ORDER_TYPES.STOCK) {
        setDisabledCheckbox(true)
        setDisabledSizeField(true)
        form.setFieldValue(['goods', 0, 'good', 0, 'productName'], '')
        form.setFieldValue(['goods', 0, 'good', 0, 'productId'], '')
        form.setFieldValue(['goods', 0, 'name'], '')
        form.setFieldValue(['goods', 0, 'good', 0, 'quantity'], 1)
        form.setFieldValue(['goods', 0, 'provider'], '')
        form.setFieldValue(['goods', 0, 'good', 0, 'price'], '')
        form.setFieldValue(['goods', 0, 'additionalName'], '')
        form.setFieldValue(['goods', 0, 'additionalProvider'], '')
        form.setFieldValue(['goods', 0, 'additionalProvider'], '')
        form.setFieldValue(['goods', 0, 'additionalPrice'], '')
        form.setFieldValue(['goods', 0, 'backwall'], '')
        form.setFieldValue(['goods', 0, 'productSupport'], '')
        form.setFieldValue(['goods', 0, 'goods', 0], '')
        form.setFieldValue(['goods', 0, 'productSize'], '')
        form.setFieldValue(['finalPayment'], '')
      } else {
        setDisabledCheckbox(false)
        setDisabledSizeField(false)
      }
      return
    }
    if (
      orderStatus === OrderStatusesIRI.NEW ||
      orderStatus === OrderStatusesIRI.WAITING_FOR_PAYMENT ||
      orderStatus === OrderStatusesIRI.IN_PROGRESS
    ) {
      if (orderType === ORDER_TYPES.PERSONAL) {
        // form.setFieldValue(['goods', fieldName, `size`], null)
        form.setFieldValue([fieldName, 'checkboxCustomSize'], true)
        form.setFieldValue([fieldName, 'productSize'], '')
        setIsCustomSize(true)
        setDisabledCheckbox(true)
      } else if (orderType === ORDER_TYPES.STOCK) {
        setDisabledCheckbox(true)
        setIsCustomSize(false)
        form.setFieldValue([fieldName, 'checkboxCustomSize'], false)
      } else {
        setDisabledCheckbox(false)
      }
    }
  }, [orderType])

  const setFieldCustomSize = (e: CheckboxChangeEvent) => {
    setIsCustomSize(e.target.checked)
    form.setFieldValue(['goods', fieldName, `productSize`], null)
  }

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['goods', fieldName, 'productSizeIRI'], option.value)
    form.setFieldValue(['goods', fieldName, 'productSize'], option.label)
  }

  const onChangeCustomSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(['goods', fieldName, 'productSize'], e.target.value)
  }

  return (
    <ConfigProvider theme={{
      token: {
        colorText: '#8A94A2'
      }
    }}>
      <Space direction={'vertical'} size={12} style={{width: '100%', padding: '13px 16px 16px'}}>
        {/*<Row style={{width: '100%'}}>*/}
        {/*  <Col span={24}>Размер</Col>*/}
        {/*</Row>*/}
        <Row>
          <Col span={15}>
            <Space size={16} align={'start'}>
              <Form.Item
                name={[fieldName, 'productSize']}
                style={{marginBottom: 0}}
                rules={[
                  {
                    required: orderType !== ORDER_TYPES.STOCK,
                    message: orderType !== ORDER_TYPES.STOCK ? 'Обязательное поле' : ''
                  }
                ]}
                label={'Размер'}
                labelAlign={'left'}
              >
                {
                  !isCustomSize
                    ?
                    <SelectCustom
                      widthOptions={334}
                      heightOptions={44}
                      loading={isLoadingSizesProduct}
                      options={
                        dataSizesProduct?.length
                          ? dataSizesProduct.map(item => ({value: item.productSizeIRI, label: item.value}))
                          : []
                      }
                      style={{width: '334px', fontSize: '16px'}}
                      placeholder={'Выберите размер'}
                      onSelect={onSelect}
                      onBlur={sendOnBlurGoods}
                      disabled={disabledSizeField || disabledFields?.size}
                    />
                    : <Input
                      type={'text'}
                      className={s.inputCustomSize}
                      placeholder="Введите размер"
                      onChange={onChangeCustomSize}
                      onBlur={sendOnBlurGoods}
                      disabled={disabledSizeField || disabledFields?.size}
                    />
                }
              </Form.Item>
              {/*<Form.Item*/}
              {/*  name={[fieldName, 'checkboxCustomSize']}*/}
              {/*  className={s.checkboxSize}*/}
              {/*  label={' '}*/}
              {/*>*/}
              {/*  <Checkbox*/}
              {/*    onChange={setFieldCustomSize}*/}
              {/*    checked={isCustomSize}*/}
              {/*    disabled={true}*/}
              {/*  /> Другой размер*/}
              {/*</Form.Item>*/}
            </Space>
          </Col>
        </Row>
      </Space>
    </ConfigProvider>
  )
}

export default GoodSize