export const optionTypeDocument = [
  {value: 'LiveLine_regions', label: 'ЛИВ ЛАЙН РЕГИОНЫ'},
  {value: 'LiveLine_sbp', label: 'ЛИВ ЛАЙН СПБ'},
  {value: 'act', label: 'Акт'},
  {value: 'cheque', label: 'Чек на доставку'},
  {value: 'bill', label: 'Накладная'},
  {value: 'prepayment_invoice', label: 'Счет на предоплату'},
  {value: 'after_payment_invoice', label: 'Счет на доплату'},
]
export const optionTypeDocumentJur = [
  ...optionTypeDocument,
  {value: 'contract', label: 'Договор юр. лица'},
]
export const optionTypeDocumentOrfey = [
  ...optionTypeDocument,
  {value: 'facture', label: 'Счет-фактура'},
  {value: 'contract', label: 'Договор юр. лица'},
]
export const optionTypeDocumentGrachevC = [
  {value: 'contract', label: 'Договор юр. лица'},
  {value: 'act', label: 'Акт'},
  {value: 'cheque', label: 'Чек на доставку'},
]