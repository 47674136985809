import React, { FC } from 'react'

import { Button, Col, Row } from 'antd'
import Filters from '../../Filters/Filters'

import { PlusOutlined } from '@ant-design/icons'
import s from './OrdersFilters.module.scss'
import classNames from 'classnames'

interface IProps {
  setIsShow: (value: boolean) => void
}

const OrdersFilters: FC<IProps> = ({setIsShow}) => {
  return (
    <Row gutter={[20, 20]} justify={'space-between'} wrap={false}>
      <Col flex={'0 0 186px'} className={s.btnCreate}>
        <Button type={'primary'}
                size={'middle'}
                icon={<PlusOutlined/>}
                onClick={() => setIsShow(true)}
                className={classNames('w-full h-10 flex items-center bg-[#4D92EE]')}
        >
          Создать заказ
        </Button>
      </Col>

      <Col flex={'auto'}>
        <Filters />
      </Col>
    </Row>
  )
}

export default OrdersFilters