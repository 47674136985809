import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AutoComplete, Col, ConfigProvider, Form, Input, Row } from 'antd'
import { LabeledValue } from 'antd/es/select'

import { IFabric } from '../../../../../../models/IFabric'
import { useLazyGetFabricsQuery } from '../../../../../../services/fabric.api'
import { getDisabledFieldsFromEditOrder } from '../../../../../../store/reducers/order/order.slice'

import { useAppSelector } from '../../../../../../hooks/redux'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'

interface IProps {
  sendOnBlurGoods: () => void
  formName: string
}

const Materials: FC<IProps> = ({formName, sendOnBlurGoods}) => {
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenSearchProvider, setIsOpenSearchProvider] = useState(false)
  const [isOpenSearchAdditional, setIsOpenSearchAdditional] = useState(false)
  const [isOpenSearchProviderAdditional, setIsOpenSearchProviderAdditional] = useState(false)
  const [fabricsList, setFabricsList] = useState<IFabric[]>([])
  const [fabricsListAdditional, setFabricsListAdditional] = useState<IFabric[]>([])
  // const {data: dataFabrics} = useGetFabricsQuery({})
  const [getFabrics, {data: fabrics, isSuccess: isSuccessMaterials}] = useLazyGetFabricsQuery()
  const [getFabricsProvider, {data: fabricsProvider, isSuccess: isSuccessProvider}] = useLazyGetFabricsQuery()
  const [getFabricsAdditional, {
    data: materialsAdditional,
    isSuccess: isSuccessMaterialsAdditional
  }] = useLazyGetFabricsQuery()
  const [getFabricsAdditionalProvider, {
    data: materialsAdditionalProvider,
    isSuccess: isSuccessMaterialsAdditionalProvider
  }] = useLazyGetFabricsQuery()
  const form = Form.useFormInstance()
  const nameMaterial = Form.useWatch(['name'], form)
  const nameProvider = Form.useWatch(['provider'], form)
  const nameAdditional = Form.useWatch(['additionalName'], form)
  const providerAdditional = Form.useWatch(['additionalProvider'], form)

  useEffect(() => {
    getFabrics({
      name: form.getFieldValue('name') || '',
      provider: form.getFieldValue('provider') || '',
      perPage: 100
    })
    getFabricsProvider({
      name: form.getFieldValue('name') || '',
      provider: form.getFieldValue('provider') || '',
      perPage: 100
    })
    getFabricsAdditional({
      name: form.getFieldValue('additionalName') || '',
      provider: form.getFieldValue('additionalProvider') || '',
      perPage: 100
    })
    getFabricsAdditionalProvider({
      name: form.getFieldValue('additionalName') || '',
      provider: form.getFieldValue('additionalProvider') || '',
      perPage: 100
    })
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (fabrics?.length && isSuccessMaterials) {
      setFabricsList(fabrics)
    } else {
      setFabricsList([])
    }
    // eslint-disable-next-line
  }, [fabrics])
  useEffect(() => {
    if (materialsAdditional?.length && isSuccessMaterialsAdditional) {
      setFabricsListAdditional(materialsAdditional)
    } else {
      setFabricsListAdditional([])
    }
    // eslint-disable-next-line
  }, [materialsAdditional])

  const handleSearchMaterial = (newValue: string) => {
    if (newValue) {
      getFabrics({
        name: newValue,
        provider: form.getFieldValue('provider') || '',
        perPage: 100
      })
    }
  }
  const handleSearchProvider = (newValue: string) => {
    if (newValue) {
      getFabricsProvider({
        name: form.getFieldValue('name') || '',
        provider: newValue,
        perPage: 100
      })
    }
  }
  const handleSearchAdditional = (newValue: string) => {
    if (newValue) {
      getFabricsAdditional({
        name: newValue,
        provider: form.getFieldValue('additionalProvider') || '',
        perPage: 100
      })
    }
  }
  const handleSearchAdditionalProvider = (newValue: string) => {
    if (newValue) {
      getFabricsAdditionalProvider({
        name: form.getFieldValue('additionalName') || '',
        provider: newValue,
        perPage: 100
      })
    }
  }

  const onSelect = async (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['name'], option.value)

    if (nameProvider) {
      form.setFieldValue(['price'], option.price)
    }
    if (nameProvider !== option.provider) {
      form.setFieldValue(['provider'], null)
    }
    setIsOpenSearch(false)
    await handleSearchMaterial(option.value)
  }
  const onSelectProvider = async (value: string | number | LabeledValue, option: any) => {
    // await handleSearchMaterial(option.name, 'provider')
    form.setFieldValue(['provider'], option.value)

    if (nameMaterial) {
      form.setFieldValue(['price'], option.price)
    }
    if (nameMaterial !== option.name) {
      form.setFieldValue(['name'], null)
    }
    setIsOpenSearchProvider(false)
  }
  const onSelectAdditional = async (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['additionalName'], option.value)

    if (providerAdditional) {
      form.setFieldValue(['additionalPrice'], option.price)
    }
    if (providerAdditional !== option.provider) {
      form.setFieldValue(['additionalProvider'], null)
    }
    setIsOpenSearchAdditional(false)
    await handleSearchAdditional(option.name)
  }
  const onSelectAdditionalProvider = async (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['additionalProvider'], option.value)
    console.log(form.getFieldValue('additionalName'), option.name)
    if (form.getFieldValue('additionalName')) {
      form.setFieldValue(['additionalPrice'], option.price)
    }
    // if (form.getFieldValue('additionalName') !== option.name) {
    //   form.setFieldValue(['additionalName'], null)
    // }
    setIsOpenSearchProviderAdditional(false)
    await handleSearchAdditional(option.value)
  }
  const onChangeMain = (e: any, key: 'name' | 'provider', field: string) => {
    const val = key === 'name' ? nameMaterial : nameProvider
    if (val !== field) {
      form.setFieldValue([key], null)
    }
  }
  const onChangeAdditional = (e: any, key: 'additionalName' | 'additionalProvider', field: string) => {
    const val = key === 'additionalName' ? nameAdditional : providerAdditional
    if (val !== field) {
      form.setFieldValue([key], null)
    }
  }

  return (
    <ConfigProvider theme={{
      token: {
        controlHeight: 44,
        fontSize: 16,
      },
      components: {
        Select: {
          controlHeight: 44,
        }
      },
    }}>
      <div className="p-[16px]">
        <Row className="mb-[12px] font-medium text-sm leading-[17px] text-[#8A94A2]" gutter={[12, 12]}>
          <Col span={6}>Материал</Col>
          <Col span={9}>Наименование</Col>
          <Col span={6}>Поставщик</Col>
          <Col span={3}>Цена</Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item name={['material']}>
              <Input type={'text'}
                     className="w-[210px] h-[44px] block font-regular text-base text-baseColor leading-[22px] p-[10px_12px] bg-[#F1F3F6] border-1 border-[#F1F3F6] rounded-[8px]"
                     disabled={true}
                     defaultValue={'Основная ткань'}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name={['name']} rules={[{required: true, message: 'Обязательное поле'}]}>
              <AutoComplete
                open={isOpenSearch}
                style={{fontSize: '20px !important'}}
                placeholder={'Введите название'}
                options={
                  fabricsList
                    ? fabricsList.map((item, idx) => ({
                      key: idx + Math.random(),
                      value: item.name,
                      label: item.name,
                      fabricIRI: item.fabricIRI,
                      provider: item.provider,
                      price: item.price
                    }))
                    : []
                  //   : dataFabrics && dataFabrics.map((item, idx) => ({
                  //   key: idx + Math.random(),
                  //   value: item.name,
                  //   label: item.name,
                  //   provider: item.provider,
                  //   price: item.price
                  // }))
                }
                onSearch={handleSearchMaterial}
                onSelect={onSelect}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearch(true)}
                onBlur={() => {
                  setIsOpenSearch(false)
                  sendOnBlurGoods()
                }}
                // onChange={(e) => onChangeMain(e, 'provider', nameProvider)}
                disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={['provider']}
                       rules={[{required: true, message: 'Обязательное поле'}]}>
              <AutoComplete
                open={isOpenSearchProvider}
                style={{fontSize: '16px !important'}}
                placeholder={'Введите поставщика'}
                options={
                  fabricsProvider
                    ? fabricsProvider
                      .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.provider === obj.provider))
                      .map((item, idx) => ({
                        key: idx + Math.random(),
                        value: item.provider,
                        label: item.provider,
                        name: item.name,
                        price: item.price
                      }))
                    : []
                  //   : dataFabrics && dataFabrics.map((item, idx) => ({
                  //   key: idx + Math.random(),
                  //   value: item.provider,
                  //   label: item.provider,
                  //   name: item.name,
                  //   price: item.price
                  // }))
                }
                onSearch={handleSearchProvider}
                onSelect={onSelectProvider}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearchProvider(true)}
                onBlur={() => {
                  setIsOpenSearchProvider(false)
                  sendOnBlurGoods()
                }}
                // onChange={(e) => onChangeMain(e, 'name', nameMaterial)}
                disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={['price']}
                       rules={[{required: true, message: 'Обязательное поле'}]}>
              <Input type={'number'}
                     size={'large'}
                     maxLength={10}
                     className="w-full h-[44px] text-base p-[0_11px]"
                     onBlur={sendOnBlurGoods}
                     disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item name={['additionalMaterial']}>
              <Input type={'text'}
                     className="w-[210px] h-[44px] block font-regular text-base text-baseColor leading-[22px] p-[10px_12px] bg-[#F1F3F6] border-1 border-[#F1F3F6] rounded-[8px]"
                     disabled={true}
                     defaultValue={'Дополнительная ткань'}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name={['additionalName']}>
              <AutoComplete
                open={isOpenSearchAdditional}
                style={{fontSize: '16px !important'}}
                placeholder={'Введите название'}
                options={
                  materialsAdditional
                    ? materialsAdditional.map((item) => ({
                      key: item.name,
                      value: item.name,
                      label: item.name,
                      provider: item.provider,
                      price: item.price
                    }))
                    : []
                  // : dataFabrics && dataFabrics.map((item) => ({
                  // key: item.name,
                  // value: item.name,
                  // label: item.name,
                  // provider: item.provider,
                  // price: item.price
                  // }))
                }
                onSearch={handleSearchAdditional}
                onSelect={onSelectAdditional}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearchAdditional(true)}
                onBlur={() => {
                  setIsOpenSearchAdditional(false)
                  sendOnBlurGoods()
                }}
                // onChange={(e) => onChangeAdditional(e, 'additionalProvider', providerAdditional)}
                disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={['additionalProvider']}>
              <AutoComplete
                open={isOpenSearchProviderAdditional}
                style={{fontSize: '16px'}}
                placeholder={'Введите поставщика'}
                options={
                  materialsAdditionalProvider
                    ? materialsAdditionalProvider
                      .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.provider === obj.provider))
                      .map((item) => ({
                      key: item.provider,
                      value: item.provider,
                      label: item.provider,
                      name: item.name,
                      price: item.price
                    }))
                    : []
                  //   : materialsAdditional && materialsAdditional.map((item) => ({
                  //   key: item.provider,
                  //   value: item.provider,
                  //   label: item.provider,
                  //   name: item.name,
                  //   price: item.price
                  // }))
                }
                onSearch={handleSearchAdditionalProvider}
                onSelect={onSelectAdditionalProvider}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearchProviderAdditional(true)}
                onBlur={() => {
                  setIsOpenSearchProviderAdditional(false)
                  sendOnBlurGoods()
                }}
                // onChange={handleSearchAdditionalProvider}
                disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name={['additionalPrice']}>
              <Input type={'number'}
                     size={'large'}
                     maxLength={10}
                     className="w-full h-[44px] text-base p-[0_11px]"
                     onBlur={sendOnBlurGoods}
                     disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  )
}

export default Materials