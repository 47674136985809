import React, { FC, useEffect, useState } from 'react'
import { Checkbox, Col, ConfigProvider, Form, Input, Row, Space } from 'antd'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { LabeledValue } from 'antd/es/select'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useLazyGetProductSizeQuery } from '../../../../../../services/product.api'

import s from './GoodSize.module.scss'
import { useSelector } from 'react-redux'
import { getDisabledFieldsFromEditOrder } from '../../../../../../store/reducers/order/order.slice'

interface IProps {
  fieldName: number
}

const GoodSize: FC<IProps> = ({fieldName}) => {
  const [isCustomSize, setIsCustomSize] = useState(false)
  const [getSizesProduct, {data: dataSizesProduct, isLoading: isLoadingSizesProduct}] = useLazyGetProductSizeQuery()
  const form = Form.useFormInstance()
  const productIRI = Form.useWatch(['goods', fieldName, 'productIRI'], form)

  useEffect(() => {
    getSizesProduct({product: productIRI})
  }, [productIRI])

  const onSelectSize = (value: string | number | LabeledValue, option: any, fieldName: number) => {
    form.setFieldValue(['goods', fieldName, 'productSizeIRI'], option.value)
    form.setFieldValue(['goods', fieldName, 'productSize'], option.label)
  }
  const onChangeCustomSize = (e: React.ChangeEvent<HTMLInputElement>, fieldName: number) => {
    form.setFieldValue(['goods', fieldName, 'productSize'], e.target.value)
  }
  const setFieldCustomSize = (e: CheckboxChangeEvent, fieldName: number) => {
    setIsCustomSize(e.target.checked)
    form.setFieldValue(['goods', fieldName, `size`], null)
  }

  return (
    <ConfigProvider theme={{
      token: {
        colorText: '#8A94A2'
      }
    }}>
      <Space direction={'vertical'} size={12} style={{width: '100%', padding: '13px 16px 16px'}}>
        {/*<Row style={{width: '100%'}}>*/}
        {/*  <Col span={24}>Размер</Col>*/}
        {/*</Row>*/}
        <Row>
          <Col span={15}>
            <Space size={16} align={'start'}>
              <Form.Item
                name={[fieldName, 'size']}
                style={{marginBottom: 0}}
                rules={[
                  {
                    required: true,
                    message: 'Обязательное поле'
                  }
                ]}
                label={'Размер'}
                labelAlign={'left'}
              >
                {
                  !isCustomSize
                    ?
                    <SelectCustom
                      widthOptions={334}
                      heightOptions={44}
                      loading={isLoadingSizesProduct}
                      options={
                        dataSizesProduct?.length
                          ? dataSizesProduct.map(item => ({value: item.productSizeIRI, label: item.value}))
                          // : data?.length
                          //   ? data.map(item => ({value: item.productSizeIRI, label: item.value}))
                          : []
                      }
                      style={{width: '334px', fontSize: '16px'}}
                      placeholder={'Выберите размер'}
                      onSelect={(value: string | number | LabeledValue, option: any) => {
                        onSelectSize(value, option, fieldName)
                      }}
                    />
                    : <Input
                      type={'text'}
                      className={s.inputCustomSize}
                      placeholder="Введите размер"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChangeCustomSize(e, fieldName)
                      }}
                    />
                }
              </Form.Item>
              {/*<Form.Item*/}
              {/*  name={[fieldName, 'checkboxCustomSize']}*/}
              {/*  className={s.checkboxSize}*/}
              {/*  label={' '}*/}
              {/*>*/}
              {/*  <Checkbox*/}
              {/*    onChange={(e: CheckboxChangeEvent) => setFieldCustomSize(e, fieldName)}*/}
              {/*    checked={isCustomSize}*/}
              {/*    disabled={true}*/}
              {/*  /> Другой размер*/}
              {/*</Form.Item>*/}
            </Space>
          </Col>
        </Row>
      </Space>
    </ConfigProvider>
  )
}

export default GoodSize