import React, { FC } from 'react'
import { Col, Form, FormListFieldData, Input, Row } from 'antd'
import s from './Note.module.scss'
import { useSelector } from 'react-redux'
import { getDisabledFieldsFromEditOrder } from '../../../../../../store/reducers/order/order.slice'

interface IProps {
  fieldName: number
  sendOnBlurGoods: () => void
}

const Note: FC<IProps> = ({fieldName, sendOnBlurGoods}) => {
  const disabledFields = useSelector(getDisabledFieldsFromEditOrder)

  return (
    <div className={s.note}>
      <Row className={s.headerNote}>Примечание</Row>
      <Row>
        <Col span={24}>
          <Form.Item name={[fieldName, 'note']}>
            <Input type={'text'}
                   className={s.fieldInput}
                   onBlur={sendOnBlurGoods}
                   disabled={disabledFields?.note}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default Note