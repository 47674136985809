import React, { useCallback, useEffect, useState } from 'react'
import TableOrders from '../TableOrders/TableOrders'
import { IOrderTable } from '../../models/IOrder'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import {
  addOrders,
  getFetchingModuleOrders,
  getFilters,
  getIsOrderEdit,
  getIsOrderEditMP,
  getOrders,
  getOrderToEdit,
  getOrderToEditMP,
  getPageModuleOrders,
  resetOrders,
  setFetchingModuleOrders,
  setOrderToEdit,
  setOrderToEditMP,
  setPageModuleOrders
} from '../../store/reducers/order/order.slice'
import { FormOrder } from '../../modules/OrderCreating'
import { useLazyGetOrderQuery, useLazyGetOrdersQuery } from '../../services/order.api'
import { OrderStatusesIRI, OrderStatusForm } from '../../configs/enums/orderStatuses'
import LoaderFullDisplay from '../LoaderFullDisplay/LoaderFullDisplay'
import { Drawer, notification } from 'antd'
import { useLocation } from 'react-router-dom'
import { RouteNames } from '../../configs/routes/routesNames'
import { ORDER_TYPES } from '../../configs/enums/orderTypes'
import { FormOrderFromMP } from '../../modules/OrderForMP'

const PER_PAGE = 50

const OrdersList = () => {
  const isOrderToEdit = useAppSelector(getIsOrderEdit)
  const orderToEdit = useAppSelector(getOrderToEdit)
  const isOrderToEditMP = useAppSelector(getIsOrderEditMP)
  const orderToEditMP = useAppSelector(getOrderToEditMP)
  const filters = useAppSelector(getFilters)
  const page = useAppSelector(getPageModuleOrders)
  const fetching = useAppSelector(getFetchingModuleOrders)
  const orders = useAppSelector(getOrders)
  const [loading, setLoading] = useState<boolean>(false)
  const [getOrder] = useLazyGetOrderQuery()
  const [fetchOrders, {data: ordersData, isFetching: isFetchingOrders}] = useLazyGetOrdersQuery()
  const [api, contextHolder] = notification.useNotification()
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    const app = document.querySelector('#scrolling')
    dispatch(setFetchingModuleOrders(true))
    app?.addEventListener('scroll', scrollHandler)
    return () => {
      app?.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const scrollHandler = (e: any) => {
    if (fetching) return
    if (e.target.scrollHeight - (window.innerHeight + e.target.scrollTop) < 100) {
      dispatch(setFetchingModuleOrders(true))
    } else {
      dispatch(setFetchingModuleOrders(false))
    }
  }

  useEffect(() => {
    if (ordersData?.orders) {
      dispatch(addOrders(ordersData.orders))

      if (ordersData.totalItems > orders.length) {
        dispatch(setPageModuleOrders(page + 1))
      }
    }
    dispatch(setFetchingModuleOrders(false))
  }, [ordersData])
  useEffect(() => {
    dispatch(resetOrders())
    dispatch(setPageModuleOrders(1))
    dispatch(setFetchingModuleOrders(true))
  }, [filters, location])

  useEffect(() => {
    if (!fetching) return
    onFetchOrders()
  }, [fetching])

  const onFetchOrders = async () => {
    // if (ordersData && ordersData.orders.length > 0 && ordersData.totalItems <= orders.length) {
    //   return
    // }
    const query: any = {}
    if (filters.status) {
      query.status = filters.status
    } else {
      query.status = [
        OrderStatusesIRI.NEW,
        OrderStatusesIRI.WAITING_FOR_PAYMENT,
        OrderStatusesIRI.IN_PROGRESS,
        OrderStatusesIRI.PRODUCTION,
        OrderStatusesIRI.WAITING_FOR_LOADING,
        OrderStatusesIRI.READY,
      ]
    }
    if (filters.dateFrom) query.productionDateFrom = filters.dateFrom
    if (filters.dateTo) query.productionDateTo = filters.dateTo
    if (filters.contractNumber) query.contractNumber = filters.contractNumber
    if (filters.company) query.company = filters.company
    if (filters.lastName) query.lastName = filters.lastName

    if (location.pathname === RouteNames.ORDERS_ARCHIVE && !filters.status) {
      await fetchOrders({
        ...query,
        status: [OrderStatusesIRI.LOADED, OrderStatusesIRI.CANCEL],
        perPage: PER_PAGE,
        page
      })
    } else {
      await fetchOrders({
        ...query,
        type: [ORDER_TYPES.STOCK, ORDER_TYPES.PERSONAL, ORDER_TYPES.RETAIL, ORDER_TYPES.WHOLESALE, ORDER_TYPES.MP],
        perPage: PER_PAGE,
        page
      })
    }
  }

  const onRow = (record: IOrderTable) => {
    return {
      onClick: async () => {
        setLoading(true)

        try {
          const res = await getOrder(record.id)

          if (res?.data) {
            if (res.data.type === ORDER_TYPES.MP) {
              dispatch(setOrderToEditMP({
                edit: true,
                hasRightsForEdit: true,
                orderToEdit: res.data
              }))
            } else {
              const {productOrderStatus: {orderStatusIRI}} = res?.data
              const fieldsForStock = {
                docsBtn: false,
                productionDateTo: res.data.type === ORDER_TYPES.STOCK,
                size: res.data.type === ORDER_TYPES.STOCK,
                materials: {
                  material: res.data.type === ORDER_TYPES.STOCK,
                  provider: res.data.type === ORDER_TYPES.STOCK,
                  price: res.data.type === ORDER_TYPES.STOCK,
                  additionalMaterial: res.data.type === ORDER_TYPES.STOCK,
                  additionalProvider: res.data.type === ORDER_TYPES.STOCK,
                  additionalPrice: res.data.type === ORDER_TYPES.STOCK,
                }
              }
              dispatch(setOrderToEdit({
                edit: true,
                hasRightsForEdit:
                  orderStatusIRI === OrderStatusesIRI.NEW ||
                  orderStatusIRI === OrderStatusesIRI.WAITING_FOR_PAYMENT,
                orderToEdit: {
                  ...res.data,
                  disabledFields: res.data.type === ORDER_TYPES.STOCK ? {...fieldsForStock} : {docsBtn: false}
                }
              }))
            }
          } else {
            dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
            dispatch(setOrderToEditMP({edit: false, hasRightsForEdit: false, orderToEdit: null}))
          }

          if (res.error) {
            api.error({
              // @ts-ignore
              message: `Ошибка получения данных. Статус: ${res.error?.status}`
            })
          }
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }
    }
  }

  const closeForm = () => {
    dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
  }

  const closeFormMP = useCallback(() => {
    dispatch(setOrderToEditMP({edit: false, hasRightsForEdit: false, orderToEdit: null}))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {contextHolder}
      {
        loading && <LoaderFullDisplay/>
      }
      <TableOrders ordersData={orders ? orders : []}
                   onRow={onRow}
                   loading={isFetchingOrders}
      />

      <Drawer open={isOrderToEdit}
              onClose={closeForm}
              width={985}
              closable={false}
              bodyStyle={{padding: 0}}
              destroyOnClose={true}
      >
        <FormOrder
          name={'orderChanging'}
          isShowForm={isOrderToEdit && !loading}
          closeForm={closeForm}
          statusForEditing={OrderStatusForm.EDIT_ORDER}
          data={orderToEdit}
          apiFromList={api}
        />
      </Drawer>

      <Drawer open={isOrderToEditMP}
              width={985}
              onClose={closeFormMP}
              closable={false}
              bodyStyle={{padding: 0}}
              destroyOnClose={true}>
        <FormOrderFromMP
          name={'orderMP'}
          isShowForm={isOrderToEditMP}
          closeForm={closeFormMP}
          data={orderToEditMP}
          apiFromList={api}
        />
      </Drawer>
    </>
  )
}

export default OrdersList