import React, { FC } from 'react'

import { Button, Col, Row } from 'antd'
import ProductionsFilters from '../ProductionFilters/ProductionFilters'

import { PlusOutlined } from '@ant-design/icons'

import s from './CurrentTab.module.scss'

interface IProps {
  setIsShow: (value: boolean) => void
}

const CurrentTab: FC<IProps> = ({setIsShow}) => {
  return (
    <Row gutter={[20, 20]} justify={'space-between'} wrap={false}>
      <Col flex={'0 0 186px'} className={s.btnCreate}>
        <Button type={'primary'}
                size={'middle'}
                icon={<PlusOutlined/>}
                onClick={() => setIsShow(true)}
                style={{width: '100%', height: '40px', backgroundColor: '#4D92EE'}}
        >
          Создать заказ
        </Button>
      </Col>

      <Col flex={'auto'}>
        <ProductionsFilters/>
      </Col>
    </Row>
  )
}

export default CurrentTab