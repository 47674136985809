import React, { FC, useEffect, useState } from 'react'
import { IOrderTable } from '../../../../models/IOrder'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import {
  getIsOrderEdit,
  getOrderToEdit,
  getProductionFilters,
  setOrderToEdit
} from '../../../../store/reducers/order/order.slice'
import { FormOrder } from '../../../OrderCreating'
import { useLazyGetOrderQuery, useLazyGetOrdersQuery } from '../../../../services/order.api'
import { OrderProductionSubStatus, OrderStatusesIRI, OrderStatusForm } from '../../../../configs/enums/orderStatuses'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { Drawer, notification } from 'antd'
import { useLocation } from 'react-router-dom'
import { RouteNames } from '../../../../configs/routes/routesNames'
import TableProductionOrders from '../TableProductionOrders/TableProductionOrders'
import { ORDER_TYPES } from '../../../../configs/enums/orderTypes'

const ProductionOrdersList: FC = () => {
  const isOrderToEdit = useAppSelector(getIsOrderEdit)
  const orderToEdit = useAppSelector(getOrderToEdit)
  const filters = useAppSelector(getProductionFilters)
  const [loading, setLoading] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()
  const [getOrder] = useLazyGetOrderQuery()
  const [fetchOrders, {data: ordersData, isLoading: isLoadingOrders}] = useLazyGetOrdersQuery()
  const dispatch = useAppDispatch()

  const {pathname} = useLocation()
  const pageType = pathname === `${RouteNames.PRODUCTION}/${RouteNames.PRODUCTION_READY}` ? 'archive' : ''

  useEffect(() => {
    const query: any = {}
    if (filters.dateFrom && pageType !== 'archive') {
      query.productionDateFrom = filters.dateFrom
    } else if (pageType === 'archive') {
      query.productionFinishedAtFrom = filters.dateFrom
    }
    if (filters.dateTo && pageType !== 'archive') {
      query.productionDateTo = filters.dateTo
    } else if (pageType === 'archive') {
      query.productionFinishedAtTo = filters.dateTo
    }
    if (filters.contractNumber) query.contractNumber = filters.contractNumber

    if (pathname === RouteNames.PRODUCTION) {
      fetchOrders({
        ...query,
        status: OrderStatusesIRI.PRODUCTION,
        subStatus: [OrderProductionSubStatus.NEW, OrderProductionSubStatus.PROGRESS],
        sort: {
          'sort[product_order_status.ordering]': 'asc',
          'sort[production_sub_status]': 'asc',
          'sort[production_date_to]': 'asc',
        },
        perPage: 50
      })
    } else {
      fetchOrders({
        ...query,
        type: [ORDER_TYPES.FOR_STOCK, ORDER_TYPES.RETAIL, ORDER_TYPES.PERSONAL, ORDER_TYPES.WHOLESALE, ORDER_TYPES.MP],
        subStatus: OrderProductionSubStatus.READY,
        perPage: 100
      })
    }
  }, [filters, pathname])

  const onRow = (record: IOrderTable) => {
    return {
      onClick: async () => {
        setLoading(true)

        try {
          const res = await getOrder(record.id)
          if (res?.data) {
            const {productOrderStatus: {orderStatusIRI}} = res?.data
            dispatch(setOrderToEdit({
              edit: true,
              hasRightsForEdit: false,
              orderToEdit: {
                ...res.data,
                disabledFields: {
                  docsBtn: false,
                  productionDateTo: pageType === 'archive',
                  deliveryDateTo: pageType === 'archive' || res.data.type === ORDER_TYPES.FOR_STOCK,
                  deliveryDateFrom: pageType === 'archive' || res.data.type === ORDER_TYPES.FOR_STOCK,
                  size: pageType === 'archive',
                  note: pageType === 'archive',
                  backwall: pageType === 'archive',
                  productSupport: pageType === 'archive',
                  checkboxCustomSize: pageType === 'archive',
                  materials: {
                    material: pageType === 'archive',
                    provider: pageType === 'archive',
                    additionalMaterial: pageType === 'archive',
                    additionalProvider: pageType === 'archive',
                  }
                }
              },
            }))
          } else {
            dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
          }

          if (res.error) {
            api.error({
              // @ts-ignore
              message: `Ошибка получения данных. Статус: ${res.error?.status}`
            })
          }
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }
    }
  }

  const closeForm = () => {
    dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
  }

  return (
    <>
      {contextHolder}
      {
        loading && <LoaderFullDisplay/>
      }
      {
        <TableProductionOrders ordersData={ordersData?.orders ? ordersData.orders : []}
                               onRow={onRow}
                               loading={isLoadingOrders}
                               pageType={pageType}
        />
      }

      <Drawer open={isOrderToEdit}
              onClose={closeForm}
              width={985}
              closable={false}
              bodyStyle={{padding: 0}}
              destroyOnClose={true}
      >
        <FormOrder
          name={'orderChanging'}
          isShowForm={isOrderToEdit && !loading}
          closeForm={closeForm}
          statusForEditing={OrderStatusForm.EDIT_ORDER}
          data={orderToEdit}
        />
      </Drawer>
    </>
  )
}

export default ProductionOrdersList