export enum DocumentTypes {
  act = 'Акт',
  cheque = 'Чек на доставку',
  bill = 'Накладная',
  LiveLine_regions = 'ЛИВ ЛАЙН РЕГИОНЫ',
  LiveLine_sbp = 'ЛИВ ЛАЙН СПБ',
  facture = 'Счет-фактура',
  external_contract = 'Рамочный договор',
  external = 'Рамочный договор',
  plate = 'Бирка',
  prepayment_invoice = 'Счет на предоплату',
  after_payment_invoice = 'Счет на доплату',
  invoice = 'Счет',
  contract = 'Договор',
}