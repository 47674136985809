import React, { FC, useEffect, useState } from 'react'

import s from './Appliances.module.scss'
import { useGetProductsSupportsQuery } from '../../../../../../services/productSupports.api'
import { Col, Form, Row, Typography } from 'antd'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'

const {Text} = Typography

interface IProps {
  fieldName: number
}

const Appliances: FC<IProps> = ({fieldName}) => {
  const {data} = useGetProductsSupportsQuery()

  return (
    <div className={s.appliances}>
      <Row className={s.headerAppliances} gutter={[12, 12]}>
        <Col span={6}>Комплектующие</Col>
        <Col span={18}>Наименование</Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item>
            <Text className={s.appliancesName}>Задняя спинка</Text>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name={[fieldName, 'backwall']}>
            <SelectCustom
              allowClear
              options={[
                {value: 'true', label: 'Да'},
                {value: '', label: 'Нет'},
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item>
            <Text className={s.appliancesName}>Опоры</Text>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name={[fieldName, 'productSupport']} initialValue={''}>
            <SelectCustom
              allowClear
              options={data ? data?.map(item => ({id: item['@id'], value: item['@id'], label: item.name})) : []}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default Appliances