import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, notification, Row, Space, Typography } from 'antd'
import { transformDate } from '../../../../../../helpers/transformDate'
import { IResponsibleUser } from '../../../../../../models/IUser'
import { getInitialsFromName } from '../../../../../../helpers/getInitialsFromName'
import { useChangeOrderMutation } from '../../../../../../services/order.api'
import { IOrderStatus } from '../../../../../../models/IOrder'
import { getDisabledFieldsFromEditOrder, getOrderToEdit } from '../../../../../../store/reducers/order/order.slice'
import { useCreateDocumentMutation } from '../../../../../../services/document.api'
import GenerateDocument from '../../../../../../components/GenerateDocument/GenerateDocument'
import { useAppSelector } from '../../../../../../hooks/redux'
import { ColorForStatus } from '../../../../../../configs/enums/colorForStatus'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'

import s from './OrderHeader.module.scss'

import { CloseOutlined } from '@ant-design/icons'
import LoaderFullDisplay from '../../../../../../components/LoaderFullDisplay/LoaderFullDisplay'

const {Text} = Typography

interface IProps {
  ref?: React.RefObject<any>
  name: string
  orderStatus?: string
  createDate?: string
  responsibleUser?: IResponsibleUser
  isEditable: boolean
  orderStatuses: IOrderStatus[] | undefined
  orderStatusesForChange: IOrderStatus[]
  orderIRI: string
  closeForm: () => void
  apiFromList?: any
  contractNumber: string | undefined
}

const OrderHeader: FC<IProps> = (props) => {
  const {
    name,
    orderStatus = 'new',
    responsibleUser = {} as IResponsibleUser,
    createDate,
    isEditable,
    orderStatuses,
    orderStatusesForChange,
    orderIRI,
    closeForm,
    apiFromList,
    contractNumber,
  } = props
  const order = useAppSelector(getOrderToEdit)
  const disabledFields = useAppSelector(getDisabledFieldsFromEditOrder)
  const [confirmDeletedStatus, setConfirmDeletedStatus] = useState(false)
  const [open, setOpen] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [changeOrder, {isLoading}] = useChangeOrderMutation()
  const [createDoc, {
    isLoading: isLoadingGenerateDoc,
    isSuccess: isSuccessGenerateDoc,
    isError: isErrorGenerateDoc,
    error: dataDocumentError
  }] = useCreateDocumentMutation()
  const form = Form.useFormInstance()
  const orderStatusForm = Form.useWatch('status', form)

  // уведомление формирования документа ->
  useEffect(() => {
    if (isSuccessGenerateDoc) {
      api.success({
        message: 'Документ успешно сформирован',
        duration: 3
      })
      setOpen(false)
    }
  }, [isSuccessGenerateDoc])
  useEffect(() => {
    if (isErrorGenerateDoc && dataDocumentError && 'data' in dataDocumentError) {
      // @ts-ignore
      const errorMsg = dataDocumentError?.data?.detail
      api.error({
        message: errorMsg || 'Ошибка при формировании документа',
        duration: 3
      })
    }
  }, [isErrorGenerateDoc])
  // <-

  useEffect(() => {
    if (isEditable) {
      form.setFieldValue('status', order?.productOrderStatus?.orderStatusIRI)
    }
  }, [order])

  const date = transformDate(createDate, true)
  const userName = getInitialsFromName(
    responsibleUser?.firstName || '',
    responsibleUser?.lastName || '',
    responsibleUser?.middleName || '')

  const generateDocument = (company: string, document: string, contractNumber?: string, externalContractIRI?: string) => {
    if (company && document && orderIRI) {
      let data: any = {
        firm_name: company,
        document_name: document,
        product_order: orderIRI,
      }
      if (contractNumber) {
        data['contract_number'] = contractNumber
        data['external_contract'] = externalContractIRI
      }
      createDoc(data)
    }
  }

  const onConfirmDelete = async () => {
    setConfirmDeletedStatus(false)
    try {
      const res = await changeOrder({
        id: order?.id,
        data: {
          'product_order_status': OrderStatusesIRI.CANCEL
        }
      })

      if ('data' in res) {
        apiFromList?.error({
          message: 'Заказ отменен',
          placement: 'topRight',
          duration: 3
        })
        closeForm()
      }
    } catch (e: any) {
      if ('message' in e || 'error' in e) {
        api.error({
          message: e?.message || 'Ошибка при изменении заказа',
          description: e?.error?.message,
          placement: 'topRight',
          duration: 3
        })
      }
    }
  }

  const onCancelDeleted = () => {
    form.setFieldValue('status', orderStatus)
    setConfirmDeletedStatus(false)
  }
  // <-

  if (!orderStatuses) return null
  return (
    <>
      {
        isLoading && <LoaderFullDisplay/>
      }
      <div className={s.orderHeader}>
        {contextHolder}

        <Button className={s.btnClose}
                onClick={() => closeForm()}
                icon={<CloseOutlined/>}
                disabled={false}
        ></Button>

        <Row gutter={24}>
          <Col span={11} style={{marginBottom: orderIRI ? '' : '35px'}}>
            <Space direction="vertical">
              {
                isEditable && <Text className={s.orderHeaderInfo}>{date} - {userName}</Text>
              }

              <Row gutter={[0, 0]} className="flex items-end">
                <Text
                  className="h-[22px] flex items-center font-semibold text-xl leading-[28px] text-baseColor">{name || ''}</Text>
                {
                  orderIRI && (
                    <div className="p-[0_20px] leading-[17px]"
                         style={{color: ColorForStatus[orderStatuses?.find(item => item.orderStatusIRI === orderStatusForm)?.value || 'new']}}>
                      {orderStatuses?.find(item => item.orderStatusIRI === orderStatusForm)?.cyrillicValue}
                    </div>
                  )
                }
                <Form.Item name={['status']}
                           initialValue={orderStatusesForChange[0]?.orderStatusIRI}
                           className="mb-0"
                           hidden={true}>
                </Form.Item>
              </Row>
              {orderIRI && (
                <Row className="mb-3">
                  {contractNumber ? `Договор № ${contractNumber}` : 'Нет договора'}
                </Row>
              )}
            </Space>
          </Col>
          {
            order?.productOrderStatus?.orderStatusIRI === OrderStatusesIRI.NEW && (
              <Col span={5}>
                <Button type="primary"
                        className={s.cancelOrder}
                        onClick={() => setConfirmDeletedStatus(true)}
                >Отменить заказ</Button>
              </Col>
            )
          }
          {
            isEditable &&
            <Col span={6}
                 offset={order?.productOrderStatus?.orderStatusIRI === OrderStatusesIRI.NEW ? 0 : 5}>
              <Button onClick={() => setOpen(true)}
                      className={s.btnGenerateDoc}
                      disabled={disabledFields?.docsBtn}
              >Сформировать документ</Button>
            </Col>
          }
        </Row>

        <GenerateDocument open={open}
                          generateDocument={generateDocument}
                          handleCancel={() => setOpen(false)}
                          isLoadingGenerateDoc={isLoadingGenerateDoc}
                          contactType={order?.contact?.type}
        />
      </div>

      <Modal open={confirmDeletedStatus}
             onCancel={onCancelDeleted}
             onOk={onConfirmDelete}
             cancelText={'Нет'}
             okText={'Да'}
             title={<h4>Подтвердить отмену заказа <Text type={'warning'}>{name}</Text></h4>}
             closable={false}
             centered
      />
    </>
  )
}

export default OrderHeader