import React, { FC, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { IUserList } from '../../models/IUser'
import { useChangeUserMutation, useCreateUserMutation } from '../../services/users.api'
import LoaderFullDisplay from '../LoaderFullDisplay/LoaderFullDisplay'
import { NotificationInstance } from 'antd/es/notification/interface'
import { useGetUserRolesQuery } from '../../services/userRoles.api'
import { UserCodes } from '../../configs/enums/userCodes'
import classNames from 'classnames'
import { IMaskInput } from 'react-imask'
import { PHONE_MASK } from '../../configs/phoneMask'

const FormUserCreate: FC<
  {
    isOpen: boolean
    closeModal: () => void
    user?: IUserList | null
    api?: NotificationInstance
  }
> = ({
       isOpen,
       closeModal,
       user,
       api
     }) => {
  const [awaitingCreate, setAwaitingCreate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [phone, setPhone] = useState<string | undefined>('')
  const [focus, setFocus] = useState(false)
  const [form] = Form.useForm()
  const [createUser] = useCreateUserMutation()
  const [changeUser] = useChangeUserMutation()
  const {data: roles} = useGetUserRolesQuery({
    codes: [
      UserCodes.ROLE_CUTTER,
      UserCodes.ROLE_TAILOR,
      UserCodes.ROLE_QC,
      UserCodes.ROLE_FOAMER,
      UserCodes.ROLE_ASSEMBLER,
      UserCodes.ROLE_UPHOLSTERER,
      UserCodes.ROLE_ASSEMBLER_FULL,
      UserCodes.ROLE_PACKER,
      UserCodes.ROLE_SEAMSTRESS,
    ]
  })

  const handleOk = () => {
    form.validateFields()
      .then(() => form.submit())
      .catch((errorInfo) => {
        api?.error({
          message: 'Заполните обязательные поля',
          placement: 'topRight',
          duration: 3
        })
      })
  }

  const onFinish = async (values: any) => {
    console.log('finish')

    try {
      setAwaitingCreate(true)
      let res
      if (user) {
        res = await changeUser({
          id: user.id,
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          phone: values.phone,
          address: values.address,
        })
      } else {
        res = await createUser({
          firstName: values.firstName,
          lastName: values.lastName,
          middleName: values.middleName,
          phone: values.phone,
          address: values.address,
          userRole: values.userRole
        })
      }

      if ('data' in res) {
        api?.success({
          message: `Исполнитель успешно ${user ? 'изменен' : 'добавлен'}`,
          placement: 'topRight',
          duration: 3
        })
        closeModal()
      }
      if ('error' in res) {
        api?.error({
          message: `${user ? 'Изменить' : 'Создать'} исполнителя не удалось`,
          placement: 'topRight',
          duration: 3
        })
        setAwaitingCreate(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setAwaitingCreate(false)
    }
  }

  const onDelete = async () => {
    if (!user) {
      api?.error({
        message: `Исполнитель не существует`,
        placement: 'topRight',
        duration: 3
      })
      return
    }
    try {
      const res = await changeUser({id: user.id, status: 'inactive'})

      if ('data' in res) {
        api?.success({
          message: `Исполнитель успешно удален`,
          placement: 'topRight',
          duration: 3
        })
        closeModal()
      }
      if ('error' in res) {
        api?.error({
          message: `Удалить исполнителя не удалось`,
          placement: 'topRight',
          duration: 3
        })
        setAwaitingCreate(false)
      }
    } catch (e) {
      api?.error({
        message: `Удалить исполнителя не удалось`,
        placement: 'topRight',
        duration: 3
      })
    }
  }

  return (
    <Modal open={isOpen}
           closable={false}
           footer={
             <div className="grid grid-cols-2 gap-[24px]">
               <div>
                 {
                   user && !isEdit && (
                     <Button onClick={() => setIsConfirmDelete(true)} className="w-full">{'Удалить'}</Button>)
                 }
               </div>
               {
                 user && !isEdit
                   ? <Button type="primary" onClick={() => setIsEdit(true)} className="!m-0">Редактировать</Button>
                   : <Button type="primary" onClick={handleOk}
                             className="!m-0">{isEdit ? 'Сохранить' : 'Создать'}</Button>
               }
             </div>
           }
           destroyOnClose={true}
           centered
           className="relative"
    >
      {awaitingCreate && <LoaderFullDisplay/>}
      <div
        className="w-[34px] h-[34px] absolute top-[10px] right-[10px] p-[10px] bg-[#F1F3F6] rounded-full flex items-center justify-center hover:cursor-pointer"
        onClick={closeModal}>
        <CloseOutlined className="w-[32px] h-[32px] bg-[#F1F3F6] text-xs rounded-full p-[10px]"/>
      </div>
      <div className="flex items-center mb-10 font-semibold text-xl text-[#263B53]">
        {user ? `ID-${user.accessCode}` : 'Добавление исполнителя'}
      </div>
      <Form form={form}
            name={'create-employee'}
            onFinish={onFinish}
            layout="vertical"
            preserve={false}
            disabled={!!user && !isEdit}
      >
        <Row gutter={[20, 20]} align={'middle'} className="font-medium text-base text-[#263B53] mb-[16px]">
          <Col span={8} className="flex items-center">Фамилия</Col>
          <Col span={16}>
            <Form.Item name="lastName"
                       className="mb-0"
                       rules={[{required: true, message: 'Обязательное поле'}]}
                       initialValue={user?.lastName || ''}
            >
              <Input className="h-[44px]"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]} align={'middle'} className="font-medium text-base text-[#263B53] mb-[16px]">
          <Col span={8} className="flex items-center">Имя</Col>
          <Col span={16}>
            <Form.Item name="firstName"
                       className="mb-0"
                       rules={[{required: true, message: 'Обязательное поле'}]}
                       initialValue={user?.firstName || ''}
            >
              <Input className="h-[44px]"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]} align={'middle'} className="font-medium text-base text-[#263B53] mb-[16px]">
          <Col span={8} className="flex items-center">Отчество</Col>
          <Col span={16}>
            <Form.Item name="middleName"
                       className="mb-0"
                       initialValue={user?.middleName || ''}
            >
              <Input className="h-[44px]"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]} align={'middle'} className="font-medium text-base text-[#263B53] mb-[16px]">
          <Col span={8} className="flex items-center">Должность</Col>
          <Col span={16}>
            <Form.Item name="userRole"
                       className="mb-0"
                       rules={[{required: true, message: 'Обязательное поле'}]}
                       initialValue={user?.userRole?.name || ''}
            >
              <Select options={roles ? roles.map(role => ({
                value: role.userRoleIRI,
                label: role.name
              })) : []}
                      disabled={!!user}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]} align={'middle'} className="font-medium text-base text-[#263B53] mb-[16px]">
          <Col span={8} className="flex items-center">Домашний адрес</Col>
          <Col span={16}>
            <Form.Item name="address"
                       className="mb-0"
                       initialValue={user?.address || ''}
            >
              <TextArea className="!resize-none"/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]} align={'middle'} className="font-medium text-base text-[#263B53] mb-[16px]">
          <Col span={8} className="flex items-center">Телефон</Col>
          <Col span={16}>
            <Form.Item name="phone"
                       className="mb-0"
                       rules={[{required: true, message: 'Обязательное поле'}]}
                       initialValue={user?.phone || ''}
            >
              <IMaskInput
                mask={PHONE_MASK}
                onAccept={(value, mask) => console.log(value, mask)}
                placeholder="+7 (999) 999-99-90"
                className={classNames(
                  'w-full h-[44px] p-[10px_15px] md:p-[8px_11px] rounded-[10px] text-[#263B53] border border-[#d9d9d9] outline-none disabled:bg-[#0000000a] disabled:text-[#00000040]',
                  {'border border-1 !border-[#4096ff]': focus}
                )}
                disabled={!!user && !isEdit}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        open={isConfirmDelete}
        onOk={onDelete}
        onCancel={() => setIsConfirmDelete(false)}
        okText="Да"
        cancelText="Нет"
        centered
        closable={false}
      >
        <div className="font-semibold text-base text-[#263B53]">Вы действительно хотите удалить исполнителя?</div>
      </Modal>
    </Modal>
  )
}

export default FormUserCreate