import React, { FC } from 'react'
import ProductionPlanTable from '../ProductionPlanTable/ProductionPlanTable'
import InfiniteList from '../../../../components/InfiniteList/InfiniteList'

const InfiniteProductionPlanTable: FC<{ isEditable: boolean }> = ({isEditable}) => {
  return (
    // <InfiniteList>
    //   {
    //     ({page, perPage, fetching, stopFetching, incrementPage, setPage}:
    //        {
    //          page: number,
    //          perPage?: number,
    //          fetching: boolean,
    //          stopFetching: () => void,
    //          incrementPage: () => void,
    //          setPage?: (pageNum: number) => void,
    //        }) => (
          <ProductionPlanTable isEditable={isEditable}
                               // fetching={fetching}
                               // stopFetching={stopFetching}
                               // incrementPage={incrementPage}
                               // page={page}
                               // perPage={10 || perPage}
                               // setPage={setPage}
          />
      //   )
      // }
    // {/*</InfiniteList>*/}
  )
}

export default InfiniteProductionPlanTable