import { Col, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { getMaterialFilters, setMaterialFilters } from '../../store/reducers/order/order.slice'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'

const MaterialFilters = () => {
  const filters = useAppSelector(getMaterialFilters)
  const [name, setName] = useState('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (name !== filters.name) {
      dispatch(setMaterialFilters({name}))
    }
  }, [name])

  return (
    <Row gutter={[20, 20]} justify={'space-between'} wrap={false}>
      <Col flex={24}>
        <Input className="w-full"
               placeholder="Название ткани"
               onBlur={(e) => {
                 setName(e.target.value)
               }}
               onPressEnter={(e: any) => {
                 setName(e.target.value)
               }}
        />
      </Col>
    </Row>
  )
}

export default MaterialFilters