import React, { useState } from 'react'
import { Button, notification } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import FormUserCreate from '../FormUserCreate/FormUserCreate'
import classNames from 'classnames'

const EmployeeCreate = () => {
  const [isCreateEmployee, setIsCreateEmployee] = useState(false)
  const [api, contextHolder] = notification.useNotification()

  return (
    <div className="pt-4">
      {contextHolder}
      <Button type={'primary'}
              size={'middle'}
              icon={<PlusOutlined/>}
              onClick={() => setIsCreateEmployee(true)}
              className={classNames('h-10 flex items-center bg-[#4D92EE]')}
      >
        Добавить исполнителя
      </Button>

      <FormUserCreate isOpen={isCreateEmployee}
                      closeModal={() => setIsCreateEmployee(false)}
                      api={api}/>
    </div>
  )
}

export default EmployeeCreate