import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from '../modules/Authorization'
import orderSlice from './reducers/order/order.slice'
import userSlice from './reducers/user/user.slice'
import { apiSlice } from '../services/api'
import { setupListeners } from '@reduxjs/toolkit/dist/query/react'
import { productSupportsApi } from '../services/productSupports.api'
import { paymentTypesApi } from '../services/paymentTypes.api'
import { pagesApi } from '../services/pages.api'
import { documentApi } from '../services/document.api'
import { materialOrderApi } from '../services/materialOrder.api'
import { fabricApi } from '../services/fabric.api'
import { userRolesApi } from '../services/userRoles.api'
import { tasksApi } from '../services/tasks.api'
import { referenceApi } from '../services/reference.api'

export const store = configureStore({
  reducer: {
    authSlice,
    userSlice,
    orderSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [productSupportsApi.reducerPath]: productSupportsApi.reducer,
    [paymentTypesApi.reducerPath]: paymentTypesApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [userRolesApi.reducerPath]: userRolesApi.reducer,
    [materialOrderApi.reducerPath]: materialOrderApi.reducer,
    [fabricApi.reducerPath]: fabricApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [referenceApi.reducerPath]: referenceApi.reducer,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware()
      .concat(
        apiSlice.middleware,
        productSupportsApi.middleware,
        paymentTypesApi.middleware,
        pagesApi.middleware,
        documentApi.middleware,
        userRolesApi.middleware,
        materialOrderApi.middleware,
        fabricApi.middleware,
        tasksApi.middleware,
        referenceApi.middleware,
      )
  )
})

// export const store = setupStore()
setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
// export type AppStore = ReturnType<typeof store.dispatch>
export type AppDispatch = typeof store.dispatch