import { apiSlice } from './api'
import { IProduct, IProductOrderItem, IProductPrice, IProductSize, IWriteOffReasons } from '../models/IProduct'

export const productApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getProduct: build.query<IProduct, number>({
      query: (id) => `/products/${id}`,
      transformResponse: (response: any) => {
        return {
          ...response['hydra:member'],
          paymentType: response['hydra:member'].payment_type.name

        }
      },
      providesTags: result => ['Product']
    }),
    getProducts: build.query<IProduct[], { type?: string, name?: string, status?: 'in_stock' | 'new' | null }>({
      query: (params) => ({
        url: '/products',
        params
      }),
      transformResponse: (response: any) => {
        return response['hydra:member'].map((product: any) => ({
          productIRI: product['@id'],
          id: product.id,
          name: product.name,
        }))
      },
    }),
    getProductOrderItems: build.query<IProductOrderItem[], {
      status?: string,
      perPage?: number,
      productName?: string
    }>({
      query: ({status, perPage, productName}) => {
        return ({
          url: `/product-order-items`,
          params: {
            status,
            product_name: productName,
            per_page: perPage
          }
        })
      },
      transformResponse: (response: any) => {
        return [
          ...response['hydra:member'].map((item: any) => ({
            productIRI: item['@id'],
            id: item.id,
            status: item.status,
            writeOffReason: item.write_off_reason,
            product: {
              productIRI: item.product['@id'],
              id: item.product.id,
              name: item.product.name,
            },
            cost: item.cost,
            discount: item.discount,
            hasBackwall: item.has_backwall,
            note: item.note,
            price: item.price,
            productName: item.product_name,
            productSizeValue: item.product_size_value,
            quantity: item.quantity,
            summ: item.summ,
            productSize: {
              productSizeIRI: item?.product_size?.['@id'],
              id: item.product_size?.id,
              value: item.product_size?.value,
            },
            productSupport: {
              productSupportIRI: item.product_support['@id'],
              id: item.product_support.id,
              name: item.product_support.name,
            },
            productOrderItemOptions: item.product_order_item_options,
            materials: item.materials.map((material: any) => ({
              id: material.id,
              material: material.material,
              name: material.name,
              price: material.price,
              provider: material.provider,
            }))
          }))
        ]
      },
    }),
    getProductStockItems: build.query<IProductOrderItem[], {
      status?: string,
      perPage?: number,
      productName?: string,
      product?: string,
      productSize?: string,
      backwall?: boolean,
      productSupport?: string,
      hash?: string,
      sort?: {
        quantity?: string,
        productionAt?: string
      }
    }>({
      query: ({
                status,
                perPage,
                productName,
                product,
                productSize,
                productSupport,
                backwall,
                sort,
                hash
      }) => {
        return ({
          url: `/stock-items`,
          params: {
            status,
            'product.name': productName,
            product,
            backwall,
            'item_hash': hash,
            product_size: productSize,
            product_support: productSupport,
            per_page: perPage,
            'sort[production_at]': sort?.productionAt,
          }
        })
      },
      transformResponse: (response: any) => {
        return [
          ...response['hydra:member'].map((item: any) => ({
            productIRI: item['@id'],
            id: item.id,
            itemCode: item.item_code,
            productionAt: item.production_at,
            status: item.status,
            writeOffReason: item.write_off_reason,
            product: {
              productIRI: item.product['@id'],
              id: item.product.id,
              name: item.product.name,
            },
            cost: item.cost,
            discount: item.discount,
            hasBackwall: item.has_backwall,
            note: item.note,
            price: item.price,
            productName: item.product_name,
            productSizeValue: item.product_size_value,
            quantity: item.quantity,
            summ: item.summ,
            productSize: {
              productSizeIRI: item?.product_size?.['@id'],
              id: item.product_size?.id,
              value: item.product_size?.value,
            },
            productSupport: {
              productSupportIRI: item.product_support?.['@id'],
              id: item.product_support?.id,
              name: item.product_support?.name,
            },
            productOrderItemOptions: item.product_order_item_options,
            materials: item.materials.map((material: any) => ({
              id: material.id,
              material: material.material,
              name: material.name,
              price: material.price,
              provider: material.provider,
            }))
          }))
        ]
      },
      providesTags: result => ['StockItems']
    }),
    changeProductStockItem: build.mutation<
      IProductOrderItem[],
      {
        id: number,
        data: any
      }
    >({
      query: ({id, data}) => {
        return ({
          url: `/stock-items/${id}`,
          method: 'PUT',
          body: {...data}
        })
      }
    }),
    getProductSize: build.query<IProductSize[], { product?: string | string[] | null }>({
      query: ({product}) => ({
        url: `/product-sizes`,
        params: {
          product
        },
      }),
      transformResponse: (response: any) => {
        return response['hydra:member'].map((size: any) => ({
          productSizeIRI: size['@id'],
          id: size.id,
          value: size.value
        }))
      },
    }),
    getProductPrices: build.query<
      IProductPrice[],
      {
        type?: string,
        product: string,
        productSize?: string,
        materialPrice?: string
      }>({
      query: (data) => ({
        url: `/product-prices`,
        params: {
          type: data.type,
          product: data.product,
          product_size: data.productSize,
          material_price_search: data.materialPrice
        },
      }),
      transformResponse: (response: any) => {
        return response['hydra:member']
      },
    }),
    getWriteOff: build.query<IWriteOffReasons[], void>({
      query: (data) => ({
        url: `/write-off-reasons`,
      }),
      transformResponse: (response: any) => {
        return response['hydra:member'].map((item: any) => ({
          writeOffReasonIRI: item['@id'],
          id: item.id,
          createdAt: item.created_at,
          reason: item.reason,
          responsibleUser: {
            responsibleUserIRI: item.responsible_user?.['@id'],
            firstName: item.responsible_user?.first_name,
            lastName: item.responsible_user?.last_name,
            middleName: item.responsible_user?.middle_name
          },
          stockItem: item.stock_item
            ? {
              stockItemIRI: item?.stock_item?.['@id'],
              id: item?.stock_item?.id,
              hasBackwall: item?.stock_item?.has_backwall,
              product: {
                productIRI: item?.stock_item?.product?.['@id'],
                id: item?.stock_item?.product?.id,
                name: item?.stock_item?.product?.name
              },
              productSize: {
                productSizeIRI: item.stock_item?.product_size?.['@id'],
                id: item.stock_item?.product_size?.id,
                value: item.stock_item?.product_size?.value
              },
              materials: item.stock_item?.materials?.map((material: any) => ({
                materialIRI: material?.['@id'],
                id: material.id,
                name: material.name,
                material: material.material,
                provider: material.provider
              })),
              productSupport: {
                productSupportIRI: item.stock_item?.product_support?.['@id'],
                id: item.stock_item?.product_support?.id,
                name: item.stock_item?.product_support?.name
              }
            }
            : null,
          productOrderItem: item.product_order_item
            ? {
              productOrderItemIRI: item.product_order_item?.['@id'],
              id: item.product_order_item?.id,
              hasBackwall: item.product_order_item?.has_backwall,
              productName: item.product_order_item?.product_name,
              productSizeValue: item.product_order_item?.product_size_value,
              productSupport: {
                productSupportIRI: item.product_order_item?.product_support?.['@id'],
                id: item.product_order_item?.product_support?.id,
               name: item.product_order_item?.product_support?.name
              },
              productOrder: {
                productOrderIRI: item.product_order_item?.product_order?.['@id'],
                id: item.product_order_item?.product_order?.id,
                contractNumber: item.product_order_item?.product_order?.contract_number,
              },
              materials: item.product_order_item?.materials?.map((material: any) => ({
                materialIRI: material?.['@id'],
                id: material?.id,
                name: material?.name,
                material: material?.material,
                provider: material?.provider
              }))
            }
            : null
        }))
      },
    }),
    writeOffReasons: build.mutation<
      { reason: string, stock_item: string },
      { reason: string, stockItem: string }>({
      query: ({reason, stockItem}) => ({
        url: `/write-off-reasons`,
        method: 'POST',
        body: {reason, stock_item: stockItem}
      }),
      invalidatesTags: (result) => ['StockItems']
    }),
  })
})

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useLazyGetProductSizeQuery,
  useLazyGetProductPricesQuery,
  useLazyGetProductOrderItemsQuery,
  useLazyGetProductStockItemsQuery,
  useGetProductStockItemsQuery,
  useChangeProductStockItemMutation,
  useWriteOffReasonsMutation,
  useGetWriteOffQuery
} = productApi
