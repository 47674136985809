import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button, Col, Popover, Row } from 'antd'
import { UserRoles, UserRolesConst } from '../../../../../../configs/enums/userRoles'
import { IOrderTask, ISubProduct } from '../../../../../../models/IOrder'
import { IUserListObj } from '../../ProductionPlanTable'
import Cell from '../Cell/Cell'

import CircleQuestion from '../../../../../../ui/icons/CircleQuestion'

interface IProps {
  product: ISubProduct
  code: string
  isEditable: boolean
  users: IUserListObj
  getUpdatedOrder: (id: number) => void
  orderId: number
}

const SubTaskRow: FC<IProps> = ({product, code, isEditable, users, getUpdatedOrder, orderId}) => {
  const [roles, setRoles] = useState<{ [key: string]: IOrderTask }>({
    tailor: { // Раскройщик
      taskIRI: '',
      id: 0,
      status: '', // new in_progress completed
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
    seamstress: { // Швея
      taskIRI: '',
      id: 0,
      status: '',
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
    cutter: { // Распиловщик
      taskIRI: '',
      id: 0,
      status: '',
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
    assembler: { // Сборщик
      taskIRI: '',
      id: 0,
      status: '',
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
    foamer: { // Поролонщик
      taskIRI: '',
      id: 0,
      status: '',
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
    upholsterer: { // Обивщик
      taskIRI: '',
      id: 0,
      status: '',
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
    assemblerFull: { // Сборка гот.
      taskIRI: '',
      id: 0,
      status: '',
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
    otk: { // ОТК
      taskIRI: '',
      id: 0,
      status: '',
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
    packer: { // Упаковщик
      taskIRI: '',
      id: 0,
      status: '',
      userEntity: null,
      role: {
        roleIRI: '',
        name: '',
      }
    },
  })

  const getDataTask = (task: IOrderTask): IOrderTask => {
    return ({
      taskIRI: task.taskIRI,
      id: task.id,
      status: task.status,
      userEntity: task.userEntity,
      role: {
        roleIRI: task.role.roleIRI,
        name: task.role.name,
      }
    })
  }

  useEffect(() => {
    product.tasks.forEach((task) => {
      if (task.role.roleIRI === UserRoles.ROLE_TAILOR) {
        setRoles(state => ({
          ...state,
          tailor: getDataTask(task)
        }))
      } else if (task.role.roleIRI === UserRoles.ROLE_SEAMSTRESS) {
        setRoles(state => ({
          ...state,
          seamstress: getDataTask(task)
        }))
      } else if (task.role.roleIRI === UserRoles.ROLE_CUTTER) {
        setRoles(state => ({
          ...state,
          cutter: getDataTask(task)
        }))
      } else if (task.role.roleIRI === UserRoles.ROLE_ASSEMBLER) {
        setRoles(state => ({
          ...state,
          assembler: getDataTask(task)
        }))
      } else if (task.role.roleIRI === UserRoles.ROLE_FOAMER) {
        setRoles(state => ({
          ...state,
          foamer: getDataTask(task)
        }))
      } else if (task.role.roleIRI === UserRoles.ROLE_UPHOLSTERER) {
        setRoles(state => ({
          ...state,
          upholsterer: getDataTask(task)
        }))
      } else if (task.role.roleIRI === UserRoles.ROLE_ASSEMBLER_FULL) {
        setRoles(state => ({
          ...state,
          assemblerFull: getDataTask(task)
        }))
      } else if (task.role.roleIRI === UserRoles.ROLE_OTK) {
        setRoles(state => ({
          ...state,
          otk: getDataTask(task)
        }))
      } else if (task.role.roleIRI === UserRoles.ROLE_PACKER) {
        setRoles(state => ({
          ...state,
          packer: getDataTask(task)
        }))
      }
    })
  }, [product])

  return (
    <div className={classNames('col-span-10 bg-[#F1F3F6]', {})}>
      <Row>
        {isEditable && <Col flex={'62px'} className="bg-grey1 border-r-2 border-b-2 border-baseLightGrey"></Col>}
        <Col flex={'120px'}
             className="max-w-[120px] flex items-center justify-between gap-1 font-medium text-lg text-baseColor leading-[18px] p-[10px_16px] bg-grey1 border-b-2 border-baseLightGrey">
          <div>{product.code}</div>
          <Popover placement={'right'}
                   content={<>
                     <div
                       className="text-sm text-baseColor">Товар: {product.productOrderItem.productName}</div>
                     <div
                       className="text-sm text-baseColor">Размер: {product.productOrderItem.productSizeValue}</div>
                     <div className="text-sm text-baseColor">Количество: {product.quantity}</div>
                   </>}>
            <Button className="h-full flex items-center border-none p-0 !bg-transparent shadow-none">
              <CircleQuestion width={20} height={20}/>
            </Button>
          </Popover>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium border-r-2 border-baseLightGrey bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.tailor}
                code={code}
                users={users.tailor}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_TAILOR}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium border-r-2 border-baseLightGrey bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.seamstress}
                code={code}
                users={users.seamstress}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_SEAMSTRESS}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium border-r-2 border-baseLightGrey bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.cutter}
                code={code}
                users={users.cutter}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_CUTTER}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium border-r-2 border-baseLightGrey bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.assembler}
                code={code}
                users={users.assembler}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_ASSEMBLER}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium border-r-2 border-baseLightGrey bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.foamer}
                code={code}
                users={users.foamer}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_FOAMER}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium border-r-2 border-baseLightGrey bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.upholsterer}
                code={code}
                users={users.upholsterer}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_UPHOLSTERER}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium border-r-2 border-baseLightGrey bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.assemblerFull}
                code={code}
                users={users.assemblerFull}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_ASSEMBLER_FULL}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium border-r-2 border-baseLightGrey bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.otk}
                code={code}
                users={users.otk}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_OTK}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
        <Col flex={'1 0 50px'}
             className={classNames('h-10 text-base font-medium bg-[#c0c4ca] col-span-1')}>
          <Cell productId={product.id}
                data={roles.packer}
                code={code}
                users={users.packer}
                isEditable={isEditable}
                futureTasks={product.futureTasks}
                role={UserRolesConst.ROLE_PACKER}
                getUpdatedOrder={getUpdatedOrder}
                orderId={orderId}/>
        </Col>
      </Row>
    </div>
  )
}

export default SubTaskRow