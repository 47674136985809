import React, { Suspense, useCallback } from 'react'
import Section from '../../components/Section/Section'
import PageHeader from '../../components/PageHeader/PageHeader'
import { Outlet, useLocation } from 'react-router-dom'
import { Drawer } from 'antd'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import {
  getIsShowFormCreateOrderToWarehouse,
  setFormCreateOrderToWarehouse
} from '../../store/reducers/order/order.slice'
import FormOrderToWarehouse
  from '../../modules/CreateOrderToWarehouse/components/FormOrderToWarehouse/FormOrderToWarehouse'
import { useGetPagesQuery } from '../../services/pages.api'

const Production = () => {
  const isShowFormCreateOrderToWarehouse = useAppSelector(getIsShowFormCreateOrderToWarehouse)
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const {data} = useGetPagesQuery()

  const closeForm = useCallback(() => {
    dispatch(setFormCreateOrderToWarehouse(false))
    // eslint-disable-next-line
  }, [])

  if (!data || data.find(item => '/' + pathname.split('/')[1] === item.url) === undefined) {
    return null
  }

  return (
    <Section>
      <Suspense fallback={<div>Загрузка....</div>}>
        <PageHeader title="Модуль производства"/>
        <Outlet/>

        <Drawer open={isShowFormCreateOrderToWarehouse}
                width={985}
                onClose={closeForm}
                closable={false}
                bodyStyle={{padding: 0}}>
          <FormOrderToWarehouse closeForm={closeForm}/>
        </Drawer>
      </Suspense>
    </Section>
  )
}

export default Production