import React, { FC, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ConfigProvider, TabsProps } from 'antd'

import TabsCustom from '../TabsCustom/TabsCustom'

import s from './PageManagement.module.scss'
import { RouteNames } from '../../configs/routes/routesNames'


interface IProps {
  items: TabsProps['items']
  onChangeTab: (activeKey: string) => void
  defaultActiveKey?: string
}

const PageManagement: FC<IProps> = ({ items, onChangeTab, defaultActiveKey }) => {
  const {pathname} = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (defaultActiveKey) {
      navigate(defaultActiveKey)
    }
  }, [])

  return (
    <ConfigProvider theme={{token: {controlHeight: 40}}}>
      <div className={s.pageManagement}>
        <TabsCustom defaultActiveKey={defaultActiveKey || pathname} items={items} onChange={onChangeTab}/>
      </div>
    </ConfigProvider>
  )
}

export default PageManagement