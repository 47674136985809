import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Col, Form, Input, Row, Typography } from 'antd'
import { IContact, IContactSource } from '../../../../../../models/IContact'
import { LabeledValue } from 'antd/es/select'
import Mail from '../../../../../../ui/icons/Mail'
import { useChangeContactMutation } from '../../../../../../services/contact.api'
import { IOrder } from '../../../../../../models/IOrder'
import { useAppSelector } from '../../../../../../hooks/redux'
import {
  getHasRights,
  getHasRightsMP,
  getIsOrderEdit,
  getIsOrderEditMP
} from '../../../../../../store/reducers/order/order.slice'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'

const {Text} = Typography

interface IProps {
  dataOrder: IOrder | undefined
  changeContactDataForOrder: (contactIRI: string) => void
}

const IndividualPerson: FC<IProps> = ({
                                        dataOrder,
                                        changeContactDataForOrder
                                      }) => {
  const isOrderEditMP = useAppSelector(getIsOrderEditMP)
  const hasRightsToEditing = useAppSelector(getHasRightsMP)
  const [contact, setContact] = useState({} as IContact)
  const form = Form.useFormInstance()
  const [changeContact, {
    isSuccess: isSuccessChangeContactData,
    isError: isErrorChangeContactData
  }] = useChangeContactMutation()

  useEffect(() => {
    if (contact.phone) {
      form.setFieldValue(['email'], contact.email)
      form.setFieldValue(['contacts'], contact.additionalContacts)
      form.setFieldValue(['addressDelivery'], contact.address)
      form.setFieldValue(['contactFirstName'], contact.firstName)
      form.setFieldValue(['contactLastName'], contact.lastName)
      form.setFieldValue(['contactMiddleName'], contact.middleName)
    }
  }, [contact.phone])

  const onBlurField = (value: string, field: string) => {
    let dataFullName: { 'full_name'?: string } = {}
    const lastName = form.getFieldValue('contactLastName')
    const firstName = form.getFieldValue('contactFirstName')
    const middleName = form.getFieldValue('contactMiddleName')
    const fullName = `${lastName} ${firstName} ${middleName}`
    if (['first_name', 'middle_name', 'second_name'].includes(field)) {
      dataFullName['full_name'] = fullName
    }

    if (dataOrder && isOrderEditMP && hasRightsToEditing) {
      changeContact({
        id: dataOrder.contact.id,
        data: {
          contact: dataOrder.contact.hydraContactID,
          [field]: value,
          ...dataFullName
        }
      })
    }
  }

  const onSelectContact = (value: any, option: any) => {
    setContact(option)
    changeContactDataForOrder(option.hydraContactID)
  }

  const onSelectSource = (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['contactSource'], option.id)
    onBlurField(option.id, 'source')
  }

  return (
    <div className="p-[32px]">
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Text className="block font-medium text-sm text-baseGrey mb-3">Источник клиента</Text>
          <Form.Item name={'contactSource'}>
            <Input className="w-full h-[44px] font-regular text-base leading-[22px]" disabled/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className="block font-medium text-sm text-baseGrey mb-3">Фамилия</Text>
          <Form.Item name={'contactLastName'}>
            <Input className="w-full h-[44px] font-regular text-base leading-[22px]"
                   onBlur={(e) => onBlurField(e.target.value, 'second_name')}
                   disabled/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className="block font-medium text-sm text-baseGrey mb-3">Имя</Text>
          <Form.Item name={'contactFirstName'}>
            <Input type={'text'}
                   className="w-full h-[44px] font-regular text-base leading-[22px]"
                   onBlur={(e) => onBlurField(e.target.value, 'first_name')}
                   disabled/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className="block font-medium text-sm text-baseGrey mb-3">Отчество</Text>
          <Form.Item name={'contactMiddleName'}>
            <Input type={'text'}
                   className="w-full h-[44px] font-regular text-base leading-[22px]"
                   onBlur={(e) => onBlurField(e.target.value, 'middle_name')}
                   disabled/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Text className="block font-medium text-sm text-baseGrey mb-3">Почта</Text>
          <Form.Item name={'email'}>
            <Input type={'text'}
                   className="w-full h-[44px] font-regular text-base leading-[22px]"
                   placeholder={'Введите почту'}
                   prefix={<Mail/>}
                   onBlur={(e) => onBlurField(e.target.value, 'email')}
                   disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className="block font-medium text-sm text-baseGrey mb-3">Доп. контакты</Text>
          <Form.Item name={'additionalContact'}>
            <Input type={'text'}
                   className="w-full h-[44px] font-regular text-base leading-[22px]"
                   onBlur={(e) => onBlurField(e.target.value, 'additional_contacts')}
                   disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text className="block font-medium text-sm text-baseGrey mb-3">Адрес доставки</Text>
          <Form.Item name={'deliveryAddress'} rules={[{required: true, message: 'Обязательное поле'}]}>
            <Input type={'text'}
                   className="w-full h-[44px] font-regular text-base leading-[22px]"
                   onBlur={(e) => onBlurField(e.target.value, 'address')}
                   disabled
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default IndividualPerson