export enum UserRoles {
  ROLE_TAILOR = "/api/v1/user-roles/13", // Раскройщик
  ROLE_SEAMSTRESS = "/api/v1/user-roles/14", // Швея
  ROLE_CUTTER = "/api/v1/user-roles/12", // Распиловщик
  ROLE_ASSEMBLER = "/api/v1/user-roles/16", // Сборщик
  ROLE_FOAMER = "/api/v1/user-roles/15", // Поролонщик
  ROLE_UPHOLSTERER = "/api/v1/user-roles/17", // Обивщик
  ROLE_ASSEMBLER_FULL = "/api/v1/user-roles/20", // Сборка гот.
  ROLE_OTK = "/api/v1/user-roles/18", // Обивщик
  ROLE_PACKER = "/api/v1/user-roles/19", // Упаковщик
  ROLE_SALES_HEAD = "/api/v1/user-roles/6", // РОП - Руководитель отдела продаж
  ROLE_PRODUCTION_MANAGER = "/api/v1/user-roles/4", // Начальник производства
  ROLE_OWNER = "/api/v1/user-roles/2", // Владелец
  ROLE_ADMINISTRATOR = "/api/v1/user-roles/1", // Сис админ
  ROLE_TECHNOLOGIST = "/api/v1/user-roles/5", // Технолог
  ROLE_PURCHASER = "/api/v1/user-roles/9", // Закупщик-операционист
}
export enum UserRolesConst {
  ROLE_TAILOR = "ROLE_TAILOR", // Раскройщик
  ROLE_SEAMSTRESS = "ROLE_SEAMSTRESS", // Швея
  ROLE_CUTTER = "ROLE_CUTTER", // Распиловщик
  ROLE_ASSEMBLER = "ROLE_ASSEMBLER", // Сборщик
  ROLE_FOAMER = "ROLE_FOAMER", // Поролонщик
  ROLE_UPHOLSTERER = "ROLE_UPHOLSTERER", // Обивщик
  ROLE_ASSEMBLER_FULL = "ROLE_ASSEMBLER_FULL", // Сборка гот.
  ROLE_OTK = "ROLE_QC", // ОТК
  ROLE_PACKER = "ROLE_PACKER", // Упаковщик
  ROLE_PURCHASER = "ROLE_PURCHASER", // Закупщик-операционист
}