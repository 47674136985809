import React from 'react'
import { Button, ConfigProvider, Popover, Table, TableProps } from 'antd'

import { useGetWriteOffQuery } from '../../services/product.api'

import CircleQuestion from '../../ui/icons/CircleQuestion'

import s from './TableFinishedGoodsArhive.module.scss'
import { IWriteOffReasons } from '../../models/IProduct'
import dayjs from 'dayjs'
import { getInitialsFromName } from '../../helpers/getInitialsFromName'

const TableFinishedGoodsArchive = () => {
  const {data: ordersData, isLoading: isLoadingOrders} = useGetWriteOffQuery()

  const columns: TableProps<IWriteOffReasons>['columns'] = [
    {
      title: '№',
      className: '!text-[#263B53]',
      width: 80,
      render: (text, row, index) => {
        return (
          <div className={s.cellId}>
            <span>{index + 1}</span>
            <Popover placement="rightTop"
                     content={
                       <div>
                         <div className={s.goodTextInfo}>
                           Поставщик ткани
                           (осн): {row.stockItem ? row?.stockItem?.materials?.[0]?.provider : row.productOrderItem?.materials?.[0]?.provider}
                         </div>
                         <div className={s.goodTextInfo}>
                           Наименование ткани
                           (осн): {row.stockItem ? row?.stockItem?.materials?.[0]?.name : row.productOrderItem?.materials?.[0]?.name}
                         </div>
                         {
                           (row.stockItem?.materials?.[1]?.name || row.productOrderItem?.materials?.[1]?.name) && (
                             <>
                               <div className={s.goodTextInfo}>
                                 Поставщик ткани
                                 (доп): {row.stockItem ? row.stockItem?.materials?.[1]?.provider : row.productOrderItem?.materials?.[1]?.provider}
                               </div>
                               <div className={s.goodTextInfo}>
                                 Наименование ткани
                                 (доп): {row.stockItem ? row.stockItem?.materials?.[1]?.name : row.productOrderItem?.materials?.[1]?.name}
                               </div>
                             </>
                           )
                         }
                         <div className={s.goodTextInfo}>Задняя спинка: {row.stockItem
                           ? row.stockItem?.hasBackwall ? 'Да' : 'Нет'
                           : row.productOrderItem?.hasBackwall ? 'Да' : 'Нет'}</div>
                         {row.stockItem?.productSupport?.name && (
                           <div
                             className={s.goodTextInfo}>Опоры: {row.stockItem?.productSupport?.name}</div>
                         )}
                         {row.productOrderItem?.productSupport?.name && (
                           <div
                             className={s.goodTextInfo}>Опоры: {row.productOrderItem?.productSupport?.name}</div>
                         )}
                       </div>
                     }>
              <Button className={s.questionButton}><CircleQuestion/></Button>
            </Popover>
          </div>
        )
      }
    },
    {
      title: 'Наименование',
      className: '!text-[#263B53]',
      width: 255,
      render: (text, row) => {
        return <>{row.stockItem ? row.stockItem?.product.name : row.productOrderItem?.productName}</>
      }
    },
    {
      title: 'Размер',
      className: '!text-[#263B53]',
      width: 218,
      render: (text, row) => <>{row.stockItem ? row.stockItem?.productSize.value : row.productOrderItem?.productSizeValue}</>
    },
    {
      title: 'Дата списания',
      className: '!text-[#263B53]',
      width: 180,
      render: (text, row) => <>{dayjs(row.createdAt).format('DD.MM.YYYY')}</>
    },
    {
      title: 'Ответственный',
      className: '!text-[#263B53]',
      width: 180,
      render: (text, row) => <>
        {row.responsibleUser && getInitialsFromName(row.responsibleUser.firstName, row.responsibleUser.lastName, row.responsibleUser.middleName)}
      </>
    },
    {
      title: '№ договора',
      className: '!text-[#263B53]',
      width: 112,
      render: (text, row) => <>
        {row.productOrderItem?.productOrder.contractNumber || '-'}
      </>
    },
    {
      dataIndex: 'reason',
      title: 'Причина списания',
      className: '!text-[#263B53]',
      width: 204
    }
  ]

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            padding: 10,
            fontSize: 16,
            colorText: '#263B53'
          },
        },
      }}
    >
      <Table<IWriteOffReasons>
        columns={columns}
        dataSource={ordersData?.map(item => ({...item, key: item.id}))}
        pagination={false}
        loading={isLoadingOrders}
        scroll={{x: 900}}
        className="pb-[60px]"
      />
    </ConfigProvider>
  )
}

export default TableFinishedGoodsArchive