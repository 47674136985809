import { apiSlice, IResponseWithIRI } from './api'
import { IMaterialItem } from '../models/IMaterial'
import { fabricApi } from './fabric.api'

export const materialOrderApi = apiSlice.injectEndpoints({
  // reducerPath: 'materialOrder',
  // baseQuery: baseQueryWithReauth,
  // tagTypes: ['Materials'],
  // refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getMaterials: build.query<IMaterialItem[], {
      page: number,
      perPage?: number,
      status?: string | string[],
      sort?: {
        deliveryDateTo?: 'asc' | 'desc',
        completedAt?: 'asc' | 'desc',
      }
    }>({
      query: ({page, perPage, status, sort}) => ({
        url: '/material-orders',
        params: {
          page,
          per_page: perPage,
          status,
          'sort[delivery_date_to]': sort?.deliveryDateTo,
          'sort[completed_at]': sort?.completedAt
        }
      }),
      transformResponse: (response: IResponseWithIRI<any>): IMaterialItem[] => {
        return response['hydra:member'].map(item => ({
          hydraMemberID: item['@id'],
          id: item.id,
          createdAt: item.created_at,
          updatedAt: item.updated_at,
          quantity: item.quantity,
          measurement: item.measurement,
          status: item.status,
          note: item.note || '',
          productionDateFrom: item.production_date_from,
          productionDateTo: item.production_date_to,
          product: item.product,
          productName: item.product_name,
          deliveryDateFrom: item.delivery_date_from,
          deliveryDateTo: item.delivery_date_to,
          materialFromStock: item.material_from_stock,
          material: {
            materialIRI: item.material['@id'],
            material: item.material.material,
            name: item.material.name,
            price: item.material.price,
            provider: item.material.provider,
          },
          productOrder: {
            productOrderIRI: item.product_order['@id'],
            contractNumber: item.product_order.contract_number,
            id: item.product_order.id,
            code: item.product_order.code,
            quantity: item.product_order.quantity
          },
          productOrderItem: {
            productOrderIRI: item.product_order_item['@id'],
            id: item.product_order_item.id,
            quantity: item.product_order_item.quantity,
            productSizeValue: item.product_order_item.product_size_value,
            productSize: {
              productSizeIRI: item.product_order_item.product_size?.['@id'],
              id: item.product_order_item.product_size?.id,
              value: item.product_order_item.product_size?.value
            }
          }
        }))
      },
      providesTags: () => ['Materials']
    }),
    changeMaterial: build.mutation<IMaterialItem, { id: number, data: any }>({
      query: ({id, data}) => ({
        url: `/material-orders/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: () => ['Materials']
    }),
    writeOffMaterial: build.mutation<IMaterialItem, {
      id: number,
      data: {
        quantity: string,
        totalAmount?: string,
        measurement: string,
        status?: {},
        note: string,
        productionDateFrom: string,
        productionDateTo: string,
        productName: string,
        deliveryDateFrom: string,
        deliveryDateTo: string,
      }
    }>({
      query: ({id, data}) => ({
        url: `/material-orders/${id}/load`,
        method: 'POST',
        body: {
          quantity: data.quantity,
          total_amount: data.totalAmount,
          measurement: data.measurement,
          status: data.status,
          note: data.note,
          production_date_from: data.productionDateFrom,
          production_date_to: data.productionDateTo,
          product_name: data.productName,
          delivery_date_from: data.deliveryDateFrom,
          delivery_date_to: data.deliveryDateTo,
        }
      }),
      invalidatesTags: () => ['Materials'],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(
            fabricApi.util.invalidateTags(['Fabric'])
          )
        })
      },
    })
  })
})

export const {
  useGetMaterialsQuery,
  useChangeMaterialMutation,
  useWriteOffMaterialMutation
} = materialOrderApi