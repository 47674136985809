import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useChangeOrderMutation } from '../../../../../../services/order.api'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { Col, DatePicker, Form, notification, Row } from 'antd'
import { getOrderToEditMP } from '../../../../../../store/reducers/order/order.slice'
import Calendar from '../../../../../../ui/icons/Calendar'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'

dayjs.extend(customParseFormat)

const DatesOrder = () => {
  const order = useSelector(getOrderToEditMP)
  const [api, contextHolder] = notification.useNotification()
  const [changeOrder, {
    isSuccess: isSuccessChangeOrder,
    isError: isErrorChangeOrder,
    error
  }] = useChangeOrderMutation()
  const form = Form.useFormInstance()

  useEffect(() => {
    if (isSuccessChangeOrder) {
      api.success({
        message: 'Заказ успешно изменен',
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isSuccessChangeOrder])
  useEffect(() => {
    if (error && 'status' in error) {
      api.error({
        message: `Не удалось изменить данные. Статус: ${error.status}`,
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isErrorChangeOrder])

  const onCalendarChangeProduction = (dates: any, dateStrings: any) => {
    changeOrder({
      id: order.id,
      data: {
        production_date_from: dateStrings || null,
        production_date_to: dateStrings || null
      }
    })
  }
  const onCalendarChangeDelivery = (dates: any, dateStrings: any) => {
    changeOrder({
      id: order.id,
      data: {
        delivery_date_from: dateStrings,
        delivery_date_to: dateStrings
      }
    })
  }

  return (
    <div className="w-[612px] p-[0_32px] mb-[32px]">
      {contextHolder}
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item name={'productionDates'}
                     label={<>Дата изготовления</>}
                     labelAlign={'left'}
          >
            <DatePicker
              placeholder={''}
              format="DD.MM.YYYY"
              allowClear={true}
              onChange={onCalendarChangeProduction}
              suffixIcon={<Calendar/>}
              disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={'deliveryDates'}
                     label={<>Дата доставки</>}
                     labelAlign={'left'}
          >
            <DatePicker
              placeholder={''}
              format="DD.MM.YYYY"
              allowClear={true}
              onChange={onCalendarChangeDelivery}
              suffixIcon={<Calendar/>}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default DatesOrder