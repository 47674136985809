import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import { useGetPagesQuery } from '../../../../services/pages.api'
import { baseUrl } from '../../../../configs/api/api.config'
import s from './sideMenu.module.scss'
import { useAppDispatch } from '../../../../hooks/redux'
import { apiSlice } from '../../../../services/api'

interface IMenuAnt {
  id: number
  key: string
  icon: any
  label: React.ReactElement
  className: string
}

const SideMenu = () => {
  const [menu, setMenu] = useState<IMenuAnt[]>([])
  const {pathname} = useLocation()
  const {data, isSuccess} = useGetPagesQuery(undefined, {refetchOnMountOrArgChange: true})
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data?.length) {
      const sortMenu = data.map((item, index) => ({
        id: item.id,
        key: item.url,
        icon: <img src={baseUrl + item.icon}/>,
        label: <NavLink to={item.url}>{item.title}</NavLink>,
        className: 'menu-item',
      }))
      setMenu(sortMenu.sort((a, b) => a.id - b.id))
    }
  }, [data, pathname])

  useEffect(() => {
    return () => {
      dispatch(apiSlice.util.resetApiState())
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={['/' + pathname.split('/')[1]]}
      items={menu}
      className={s.menuItem}
    />
  )
}

export default SideMenu