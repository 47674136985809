import React, { FC } from 'react'
import { Col, Row, Typography } from 'antd'

import s from './PageHeader.module.scss'

const PageHeader: FC<{ title: string }> = ({title}) => {
  return (
    <div className={s.pageHeader}>
      <Row justify={'space-between'} align={'middle'}>
        <Col flex={'auto'}>
          <Typography className={s.title}>
            {title}
          </Typography>
        </Col>
      </Row>
    </div>
  )
}

export default PageHeader