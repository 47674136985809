import React, { FC, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setFormOrderCreate } from '../../store/reducers/order/order.slice'
import { Drawer } from 'antd'
import { FormOrder } from '../../modules/OrderCreating'
import { OrderStatusForm } from '../../configs/enums/orderStatuses'
import InfiniteList from '../../components/InfiniteList/InfiniteList'
import OrdersList from '../../components/OrdersList/OrdersList'
import Section from '../../components/Section/Section'
import OrderManagement from '../../components/OrderManagement/OrderManagement'
import { useLocation } from 'react-router-dom'
import { useGetPagesQuery } from '../../services/pages.api'

const OrdersPage: FC = () => {
  const isShowFormCreateOrder = useAppSelector(state => state.orderSlice.isShowFormCreateOrder)
  const dispatch = useAppDispatch()
  const {pathname} = useLocation()
  const {data} = useGetPagesQuery()

  const closeForm = useCallback(() => {
    dispatch(setFormOrderCreate(false))
    // eslint-disable-next-line
  }, [])

  const setIsShow = (value: boolean) => {
    dispatch(setFormOrderCreate(value))
  }

  if (!data || data.find(item => '/' + pathname.split('/')[1] === item.url) === undefined) {
    return null
  }

  return (
    <>
      <InfiniteList>
        {
          ({fetching, stopFetching}: { fetching: boolean, stopFetching: () => void }) => (
            <>
              <Section>
                <OrderManagement setIsShow={setIsShow}/>
                <OrdersList/>
              </Section>
            </>
          )
        }
      </InfiniteList>
      <Drawer open={isShowFormCreateOrder} width={985} onClose={closeForm} closable={false} bodyStyle={{padding: 0}}>
        <FormOrder
          name={'newOrder'}
          isShowForm={isShowFormCreateOrder}
          closeForm={closeForm}
          statusForEditing={OrderStatusForm.NEW}
        />
      </Drawer>
    </>
  )
}

export default OrdersPage