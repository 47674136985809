import { apiSlice } from './api'
import { IContact, IContactSource } from '../models/IContact'
import { IOrder } from '../models/IOrder'

export const contactsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createContact: build.mutation<IContact, any>({
      query: (data) => ({
        url: `/contacts`,
        method: 'POST',
        body: {...data}
      }),
      transformResponse: (response: any): IContact => {
        return ({
          hydraContactID: response['@id'] || '',
          id: response?.id,
          additionalContacts: response?.additional_contacts || '',
          address: response?.address || '',
          bankName: response?.bank_name || '',
          bik: response?.bik || '',
          corrAccount: response?.corr_account || '',
          createdAt: response?.created_at || '',
          email: response?.email || '',
          firstName: response?.first_name || '',
          lastName: response?.second_name || '',
          middleName: response?.middle_name || '',
          fullName: response?.full_name || '',
          inn: response?.inn || '',
          juriName: response?.juri_name || '',
          kpp: response?.kpp || '',
          managerFullname: response?.manager_fullname || '',
          ogrn: response?.ogrn || '',
          payAccount: response?.pay_account || '',
          phone: response?.phone || '',
          yuriAddress: response?.yuri_address || '',
          type: response?.type || '',
          updatedAt: response?.updated_at || '',
          source: response?.source
        })
      }
    }),
    getContact: build.query<IContact, { id: number }>({
      query: ({id}) => `/contacts/${id}`,
      transformResponse: (response: any): IContact => {
        return ({
          hydraContactID: response['@id'],
          id: response?.id,
          additionalContacts: response?.additional_contacts || '',
          address: response?.address || '',
          bankName: response?.bank_name || '',
          bik: response?.bik || '',
          corrAccount: response?.corr_account || '',
          createdAt: response?.created_at || '',
          email: response?.email || '',
          firstName: response?.first_name || '',
          lastName: response?.second_name || '',
          middleName: response?.middle_name || '',
          fullName: response?.full_name || '',
          inn: response?.inn || '',
          juriName: response?.juri_name || '',
          kpp: response?.kpp || '',
          managerFullname: response?.manager_fullname || '',
          ogrn: response?.ogrn || '',
          payAccount: response?.pay_account || '',
          phone: response?.phone || '',
          yuriAddress: response?.yuri_address || '',
          type: response?.type || '',
          updatedAt: response?.updated_at || '',
          source: response?.source
        })
      }
    }),
    getContacts: build.query<IContact[], { page: number, phone?: string, juriName?: string, type: string }>({
      query: ({page, phone, juriName, type}) => ({
        url: '/contacts',
        params: {
          page,
          phone,
          'juri_name': juriName,
          type
        }
      }),
      transformResponse: (response: any): IContact[] => {
        return response['hydra:member'].map((item: any) => ({
          hydraContactID: item['@id'],
          id: item?.id,
          additionalContacts: item?.additional_contacts || '',
          address: item?.address || '',
          bankName: item?.bank_name || '',
          bik: item?.bik || '',
          corrAccount: item?.corr_account || '',
          createdAt: item?.created_at || '',
          email: item?.email || '',
          firstName: item?.first_name || '',
          lastName: item?.second_name || '',
          middleName: item?.middle_name || '',
          fullName: item?.full_name || '',
          inn: item?.inn || '',
          juriName: item?.juri_name || '',
          kpp: item?.kpp || '',
          managerFullname: item?.manager_fullname || '',
          ogrn: item?.ogrn || '',
          payAccount: item?.pay_account || '',
          phone: item?.phone || '',
          yuriAddress: item?.yuri_address || '',
          type: item?.type || '',
          updatedAt: item?.updated_at || '',
        }))
      }
    }),
    changeContact: build.mutation<IOrder, { id: number, data: any }>({
      query: (body) => ({
        url: `/contacts/${body.id}`,
        method: 'PUT',
        body: body.data
      }),
      invalidatesTags: ['Contact', 'Order']
    }),
    getContactSource: build.query<IContactSource[], void>({
      query: () => '/contact-sources',
      transformResponse: (response: any): IContactSource[] => {
        return response['hydra:member'].map((item: {'@id': string, id: number, name: string}) => ({
          sourceIRI: item['@id'],
          id: item.id,
          name: item.name
        }))
      }
    }),
  }),
})

export const {
  useCreateContactMutation,
  useLazyGetContactQuery,
  useChangeContactMutation,
  useLazyGetContactsQuery,
  useGetContactSourceQuery
} = contactsApi

