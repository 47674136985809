import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Input, Modal, Row, Space } from 'antd'
import SelectCustom from '../../ui/SelectCustom/SelectCustom'
import {
  optionTypeDocument,
  optionTypeDocumentOrfey, optionTypeDocumentJur, optionTypeDocumentGrachevC
} from '../../configs/documents/optionTypeDocument'
import {
  optionNameCompanyJur,
  optionNameCompanyPhys
} from '../../configs/documents/optionNameCompany'
import { useAppSelector } from '../../hooks/redux'
import { getDisabledFieldsFromEditOrder } from '../../store/reducers/order/order.slice'
import { useGetExternalContractsQuery } from '../../services/externalContract.api'
import classNames from 'classnames'

import s from '../../modules/OrderCreating/components/Goods/components/OrderHeader/OrderHeader.module.scss'
import { ContactType } from '../../configs/enums/contactType'

interface IProps {
  open: boolean
  generateDocument: (company: string, document: string, contractNumber?: string, externalContractIRI?: string) => void
  handleCancel: () => void
  isLoadingGenerateDoc: boolean
  contactType: keyof typeof ContactType
}

const GenerateDocument: FC<IProps> = ({
                                        open,
                                        generateDocument,
                                        handleCancel,
                                        isLoadingGenerateDoc,
                                        contactType
                                      }) => {
  const disabledFields = useAppSelector(getDisabledFieldsFromEditOrder)
  const [company, setCompany] = useState<string>('liveline')
  const [document, setDocument] = useState('LiveLine_sbp')
  const [contractNumber, setContractNumber] = useState('')
  const [contractNumberIRI, setContractNumberIRI] = useState('')
  const [error, setError] = useState('')
  const {data: contracts} = useGetExternalContractsQuery()

  const onChangeComp = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // @ts-ignore
    if (e === company) return
    setCompany(`${e}`)
    if (document !== 'external_contract') {
      setContractNumber('')
    }
  }
  const onChangeDoc = (e: React.ChangeEvent<HTMLSelectElement>, option: any) => {
    if (option.label.split(' ')[0] === 'Договор') {
      setDocument(option.value)
      setContractNumberIRI(option.contractIRI)
    } else {
      setContractNumber('')
      setDocument(`${e}`)
    }
  }

  useEffect(() => {
    setDocument(
      company === 'grachevV_c' && contactType === ContactType[2]
        ? 'contract'
        : 'LiveLine_sbp'
    )
  }, [company])

  const onGenerateDocs = () => {
    if (document.split('/')[0] === 'external_contract' && !contractNumber) {
      setError('Обязательное поле')
    } else {
      generateDocument(company, document.split('/')[0], contractNumber, contractNumberIRI)
    }
  }

  const getExternalContratList = (contracts: any) => {
    return contracts
      .filter((item: any) => item.firm === company)
      .map((item: any) => ({
        value: `external_contract/${item.number}`,
        label: `Договор ${item.juriName} ${item.number}`,
        contractIRI: item.contractIRI
      }))
  }

  return (
    <Modal
      open={open}
      closable={false}
      centered
      footer={[
        <Button key="back" onClick={handleCancel} disabled={disabledFields?.docsBtn}>
          Отмена
        </Button>,
        <Button key="submit"
                type="primary"
                onClick={onGenerateDocs}
                loading={isLoadingGenerateDoc}
                disabled={disabledFields?.docsBtn}
        >
          Сформировать
        </Button>
      ]}
      bodyStyle={{zIndex: 1000}}
    >
      <Space size={16} direction={'vertical'} style={{width: '100%'}}>
        <Row align="middle">
          <Col span={8} flex={2}>Компания</Col>
          <Col span={16}>
            <SelectCustom
              className={s.select}
              heightOptions={44}
              defaultValue={company}
              options={contactType === ContactType[2] ? optionNameCompanyJur : optionNameCompanyPhys}
              onSelect={onChangeComp}
              disabled={disabledFields?.docsBtn}
            />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={8}>Вид документа</Col>
          <Col span={16}>
            <SelectCustom
              style={{width: '100%'}}
              heightOptions={44}
              value={document}
              defaultValue={document}
              options={
                company === 'orfej'
                  ? [
                    ...optionTypeDocumentOrfey,
                    ...(contracts
                      ? getExternalContratList(contracts)
                      : [])
                  ]
                  : contactType === ContactType[1]
                    ? optionTypeDocument
                    : company === 'liveline' || company === 'grachevV'
                      ? [
                        ...optionTypeDocumentJur,
                        ...(contracts
                          ? getExternalContratList(contracts)
                          : [])
                      ]
                      : company === 'grachevV_c'
                        ? optionTypeDocumentGrachevC
                        : []

              }
              onSelect={onChangeDoc}
              disabled={disabledFields?.docsBtn}
            />
          </Col>
        </Row>
        {
          document.split('/')[0] === 'external_contract' && (
            <Row align="middle">
              <Col span={8}>Номер заказа</Col>
              <Col span={16}>
                <Input className={classNames('h-[44px]', {
                  'border-basePink focus:border-basePink hover:border-basePink': error
                })}
                       disabled={false}
                       onChange={e => {
                         setContractNumber(e.target.value)
                         setError('')
                       }}
                />
                {error && (<div className="text-sm text-basePink">{error}</div>)}
              </Col>
            </Row>
          )
        }
      </Space>
    </Modal>
  )
}

export default GenerateDocument