import React, { FC, useEffect, useRef, useState } from 'react'

const InfiniteList: FC<{
  children: ({
               fetching,
               stopFetching,
               perPage,
               page
             }: {
    fetching: boolean
    setFetching: () => void
    stopFetching: () => void
    incrementPage: () => void
    setPage: (pageNum: number) => void
    resetPage: () => void
    perPage: number
    page: number
  }) => JSX.Element
}> = ({children}) => {
  const [fetching, setFetching] = useState(false)
  const [page, setPage] = useState<number | null>(1)
  const ref = useRef<any>()

  useEffect(() => {
    const app = document.querySelector('#scrolling')
    if (!ref) return

    app?.addEventListener('scroll', scrollHandler)
    return () => {
      app?.removeEventListener('scroll', scrollHandler)
    }
  }, [])
  useEffect(() => {
    if (!page) {
      setFetching(true)
    }
  }, [page])

  const scrollHandler = (e: any) => {
    if (fetching) return
    if (e.target.scrollHeight - (window.innerHeight + e.target.scrollTop) < 100) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }

  return (
    <div ref={ref} className="w-full flex">
      {children({
        fetching: fetching,
        setFetching: () => setFetching(true),
        stopFetching: () => setFetching(false),
        incrementPage: () => setPage(v => v ? v + 1 : 1),
        resetPage: () => {
          setPage(null)
          if (!page) {
            setFetching(true)
          }
        },
        setPage: (pageNum: number) => setPage(pageNum),
        page: page || 1,
        perPage: 90
      })}
    </div>
  )
}

export default InfiniteList