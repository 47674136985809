import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useChangeOrderMutation } from '../../../../../../services/order.api'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import locale from 'antd/es/date-picker/locale/ru_RU'

import { Col, DatePicker, Form, notification, Row, Typography } from 'antd'
import { getDisabledFieldsFromEditOrder, getOrderToEdit } from '../../../../../../store/reducers/order/order.slice'

import s from './DatesOrder.module.scss'
import Calendar from '../../../../../../ui/icons/Calendar'
import { ORDER_TYPES } from '../../../../../../configs/enums/orderTypes'

dayjs.extend(customParseFormat)

const {RangePicker} = DatePicker
const {Text} = Typography

interface IProps {
  dates: {
    deliveryDateFrom: string
    deliveryDateTo: string
    productionDateFrom: string
  }
  isEditable: boolean
  formName: string
}

const DatesOrder: FC<IProps> = ({formName, dates, isEditable}) => {
  const [disableDateProduction, setDisableDateProduction] = useState(false)
  const order = useSelector(getOrderToEdit)
  const disabledFields = useSelector(getDisabledFieldsFromEditOrder)
  const [api, contextHolder] = notification.useNotification()
  const form = Form.useFormInstance()
  const orderType = Form.useWatch(`orderType`, form)
  const [changeOrder, {
    isSuccess: isSuccessChangeOrder,
    isError: isErrorChangeOrder,
    error
  }] = useChangeOrderMutation()

  useEffect(() => {
    if (isSuccessChangeOrder) {
      api.success({
        message: 'Заказ успешно изменен',
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isSuccessChangeOrder])
  useEffect(() => {
    if (error && 'status' in error) {
      api.error({
        message: `Не удалось изменить данные. Статус: ${error.status}`,
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isErrorChangeOrder])
  useEffect(() => {
    if (formName === 'newOrder') {
      if (orderType === ORDER_TYPES.STOCK) {
        setDisableDateProduction(true)
      } else {
        setDisableDateProduction(false)
      }
      return
    }
  }, [orderType])

  useEffect(() => {
    if (dates?.productionDateFrom) {
      form.setFieldValue('productionDates', dayjs(dates?.productionDateFrom, 'YYYY.MM.DD'))
    }
    if (dates?.deliveryDateFrom && dates?.deliveryDateTo) {
      form.setFieldValue('deliveryDates',
        [dayjs(dates?.deliveryDateFrom, 'YYYY.MM.DD'), dayjs(dates?.deliveryDateTo, 'YYYY.MM.DD')])
    }
  }, [])
  const onCalendarChangeProduction = (dates: any, dateStrings: any) => {
    if (isEditable) {
      changeOrder({
        id: order.id,
        data: {
          production_date_from: dateStrings,
          production_date_to: dateStrings
        }
      })
    }
  }
  const onCalendarChangeDelivery = (dates: any, dateStrings: any) => {
    if (isEditable) {
      const data: any = {
        delivery_date_from: dateStrings[0]
      }
      if (dateStrings[1]) {
        data['delivery_date_to'] = dateStrings[1]
      }

      changeOrder({id: order.id, data})
    }
  }

  return (
    <div className={s.datesOrder}>
      {contextHolder}
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item name={'productionDates'}
                     label={<>Дата изготовления<Text type={'danger'} style={{fontSize: '16px'}}>*</Text></>}
                     labelAlign={'left'}
                     rules={[{required: orderType !== ORDER_TYPES.STOCK, message: 'Обязательное поле'}]}
          >
            <DatePicker
              placeholder={'Выберите дату'}
              format="DD.MM.YYYY"
              allowClear={!isEditable}
              onChange={onCalendarChangeProduction}
              suffixIcon={<Calendar/>}
              disabled={disableDateProduction || disabledFields?.productionDateTo}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={'deliveryDates'}
                     label={<>Дата доставки<Text type={'danger'} style={{fontSize: '16px'}}>*</Text></>}
                     labelAlign={'left'}
                     rules={[{
                       type: 'array' as const,
                       required: orderType !== ORDER_TYPES.FOR_STOCK,
                       message: 'Обязательное поле'
                     }]}
          >
            <RangePicker
              locale={locale}
              allowEmpty={[false, false]}
              placeholder={['Выберите дату', 'Выберите дату']}
              onCalendarChange={onCalendarChangeDelivery}
              format="DD.MM.YYYY"
              allowClear={!isEditable}
              suffixIcon={<Calendar/>}
              disabled={disabledFields?.deliveryDateTo && disabledFields?.deliveryDateFrom}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default DatesOrder


