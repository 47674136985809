import React, { FC, HTMLAttributes, TdHTMLAttributes } from 'react'
import { Button, ConfigProvider, Popover, SpinProps, Table, TableProps, Typography } from 'antd'
import { ColorForStatus } from '../../configs/enums/colorForStatus'
import { ORDER_TYPES, OrderTypesCyr } from '../../configs/enums/orderTypes'
import { transformDate } from '../../helpers/transformDate'
import { IOrderProduction, IOrderTable } from '../../models/IOrder'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import CircleQuestion from '../../ui/icons/CircleQuestion'

import s from './TableOrders.module.scss'
import { OrderStatusesIRI } from '../../configs/enums/orderStatuses'

dayjs.locale('ru')
dayjs.extend(utc)
dayjs.utc()
const {Text} = Typography

interface IProps {
  ordersData: IOrderProduction[]
  onRow: (record: IOrderTable) => HTMLAttributes<any> | TdHTMLAttributes<any>
  loading?: boolean | SpinProps
}

const TableOrders: FC<IProps> = ({ordersData, onRow, loading = false}) => {
  const columns: TableProps<IOrderProduction>['columns'] = [
    {
      title: '№',
      dataIndex: 'id',
      width: 140,
      sorter: {
        compare: (a, b) => {
          return a.id - b.id
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => {
        return (
          <div className={s.cellId}>
            <span>{row.id}</span>
            <Popover placement="rightTop"
                     content={
                       <div>
                         <div className={s.date}>{dayjs(row.createdAt).format('DD.MM.YYYY HH:mm ')}</div>
                         {
                           row.productOrderItems.map((product, idx) => (
                             <React.Fragment key={idx}>
                               <div className={s.goodTextInfo}>Товар: {product?.productName} {product.quantity} шт.
                               </div>
                               <div className={s.goodTextInfo}>Размер: {product.productSizeValue}</div>
                               <div className={s.goodTextInfo}>
                                 Поставщик ткани (осн): {product.materials[0]?.provider}
                               </div>
                               <div className={s.goodTextInfo}>Наименование ткани
                                 (осн): {product.materials?.[0]?.name}</div>
                               {product?.materials[1]?.provider && (
                                 <div className={s.goodTextInfo}>Поставщик ткани
                                   (доп): {product.materials?.[1]?.provider}</div>
                               )}
                               {product.materials?.[1]?.name && (
                                 <div className={s.goodTextInfo}>Наименование ткани
                                   (доп): {product.materials[1]?.name}</div>
                               )}
                               <div className={s.goodTextInfo}>Задняя спинка: {product.hasBackwall ? 'Да' : 'Нет'}</div>
                               {product.productSupport.name && (
                                 <div className={s.goodTextInfo}>Опоры: {product.productSupport.name}</div>
                               )}
                               {
                                 product.productOrderItemOptions && product.productOrderItemOptions.map((item, idx) => (
                                   <div key={idx} className={s.goodTextInfo}>Доп.
                                     опция {idx + 1}: {item.productName} {item.quantity} шт.</div>
                                 ))
                               }
                             </React.Fragment>
                           ))
                         }
                       </div>
                     }>
              <Button className={s.questionButton}><CircleQuestion/></Button>
            </Popover>
          </div>
        )
      }
    },
    {
      title: '№ договора',
      width: 80,
      sorter: {
        compare: (a, b) => {
          return +(a.contractNumber) - +(b.contractNumber)
        },
      },
      className: 'max-w-[140px]',
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => (
        <Popover placement="topLeft"
                 content={row.type === ORDER_TYPES.MP ? row.marketplaceOrderId : row.contractNumber || 'Нет договора'}>
          <div
            className="truncate">{row.type === ORDER_TYPES.MP ? row.marketplaceOrderId : row.contractNumber || 'Нет договора'}</div>
        </Popover>
      )
    },
    {
      title: 'Тип заказа',
      dataIndex: 'type',
      width: 230,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      render: (text, row) => <>{row.type === ORDER_TYPES.MP ? row.marketplaceType : OrderTypesCyr[row?.type]}</>
    },
    {
      title: 'Клиент',
      width: 230,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      render: (text, row) => {
        const firstNameInitial = row.contact.firstName[0] ? `${row.contact.firstName[0]}. ` : ''
        const middleNameInitial = row.contact.middleName[0] ? `${row.contact.middleName[0]}. ` : ''
        return <>
          {
            row.contact.type === 'juridical'
              ? row.contact.juriName
              : row.type === ORDER_TYPES.MP
                ? row.contact.firstName
                : `${row.contact.secondName} ${firstNameInitial}${middleNameInitial}`}
        </>
      }
    },
    {
      title: 'Аванс (₽)',
      dataIndex: 'prepaymentSumm',
      sorter: {
        compare: (a, b) => +a.prepaymentSumm - +b.prepaymentSumm,
      },
      width: 190,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => (
        <span className={row?.prepaymentReceived ? 'text-[#63B690]' : ''}>
        {row.type === ORDER_TYPES.MP ? '-' : row?.prepaymentSumm}
      </span>
      )
      // filterResetToDefaultFilteredValue: true
    },
    {
      title: 'Доплата (₽)',
      dataIndex: 'afterpaymentSumm',
      sorter: {
        compare: (a, b) => +a.afterpaymentSumm - +b.afterpaymentSumm,
      },
      width: 190,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => (
        <span
          className={row.afterpaymentReceived
            ? 'text-[#63B690]'
            : !row.afterpaymentReceived &&
            (+(row.afterpaymentSumm) > 0 || !row.afterpaymentSumm) &&
            row.productOrderStatus.orderStatusIRI === OrderStatusesIRI.LOADED
              ? 'text-[#B66363]'
              : ''}
        >
        {row.type === ORDER_TYPES.MP ? '-' : row?.afterpaymentSumm}
      </span>
      )
      // filterResetToDefaultFilteredValue: true
    },
    {
      title: 'Сумма (₽)',
      dataIndex: 'price',
      sorter: {
        compare: (a, b) => +a.price - +b.price,
      },
      width: 170,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend'],
      // filterResetToDefaultFilteredValue: true
    },
    {
      title: 'Дата изготовления',
      sorter: {
        compare: (a, b) => +new Date(b.productionDateTo) - +new Date(a.productionDateTo),
      },
      width: 180,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => (
        <>{row.productionDateTo ? `${transformDate(row.productionDateTo)}` : '-'}</>
      )
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      sorter: {
        compare: (a, b) => a.productOrderStatus.ordering - b.productOrderStatus.ordering,
      },
      width: 240,
      className: 'min-w-[140px]',
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => (
        <Text style={{color: ColorForStatus[row?.productOrderStatus?.value], fontWeight: 500}}>
          {row?.productOrderStatus?.cyrillicValue}
        </Text>
      )
    }
  ]

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            padding: 10
          },
        },
      }}
    >
      <Table<IOrderProduction>
        className={s.table}
        columns={columns}
        dataSource={
          ordersData?.map((item, idx) => ({...item, key: idx}))
        }
        pagination={false}
        onRow={onRow}
        loading={loading}
        scroll={{x: 900}}
        bordered
      />
    </ConfigProvider>
  )
}

export default TableOrders