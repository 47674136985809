import React, { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ConfigProvider, TabsProps } from 'antd'

import OrdersFilters from './OrdersFilters/OrdersFilters'
import TabsCustom from '../TabsCustom/TabsCustom'
import Filters from '../Filters/Filters'

import { resetFilters } from '../../store/reducers/order/order.slice'
import { useAppDispatch } from '../../hooks/redux'
import { RouteNames } from '../../configs/routes/routesNames'

import s from './OrderManagement.module.scss'

interface IProps {
  setIsShow: (value: boolean) => void
  children?: React.ReactNode
}

const OrderManagement: FC<IProps> = ({setIsShow}) => {
  const router = useNavigate()
  const {pathname} = useLocation()
  const dispatch = useAppDispatch()

  const items: TabsProps['items'] = [
    {
      key: RouteNames.ORDERS,
      label: 'Активные заказы',
      children: <OrdersFilters setIsShow={setIsShow} />
    },
    {
      key: RouteNames.ORDERS_ARCHIVE,
      label: 'Архив заказов',
      children: <Filters hideData/>
    },
  ];

  const onChangeTab = (activeKey: string) => {
    dispatch(resetFilters())
    if (activeKey === RouteNames.ORDERS) {
      router(RouteNames.ORDERS)
    }
    if (activeKey === RouteNames.ORDERS_ARCHIVE) {
      router(RouteNames.ORDERS_ARCHIVE)
    }
  }

  return (
    <ConfigProvider theme={{token: {controlHeight: 40}}}>
      <div className={s.orderManagement}>
        <TabsCustom defaultActiveKey={pathname} items={items} onChange={onChangeTab}/>
      </div>
    </ConfigProvider>
  )
}

export default OrderManagement