import React, { FC } from 'react'
import { Col, Collapse, Form, Input, Row, Space, Typography } from 'antd'
import GoodSize from './components/GoodSize/GoodSize'
import HeaderPanel from '../HeaderPanel/HeaderPanel'
import Good from './components/Good/Good'
import Materials from './components/Materials/Materials'
import Note from './components/note/Note'
import Appliances from './components/Appliances/Appliances'
import { IOrder } from '../../../../models/IOrder'
import DatesOrder from './components/DatesOrder/DatesOrder'

const {Text} = Typography
const {Panel} = Collapse

interface IProps {
  dataOrder: IOrder | undefined
  formName: string
  sendOnBlurGoods: () => void
  stockItemsLength: number
  isFetchingStockItem: boolean
}

const Goods: FC<IProps> = ({dataOrder, formName, sendOnBlurGoods, stockItemsLength, isFetchingStockItem}) => {
  return (
    <div>
      <Row className="p-[16px_32px]" justify={'end'}>
        <Col>
          <Space size={8} className="font-regular text-15 leading-[22px] text-baseColor">
            <Text className="text-15 leading-[28px] text-[#263B53]">Тип заказа:</Text>
            <Text className="font-medium text-15 leading-[28px] text-[#263B53]">{dataOrder?.marketplaceType}</Text>
          </Space>
        </Col>
      </Row>

      <Collapse
        defaultActiveKey={'0'}
        className="p-0"
        // accordion={!isEditable}
        destroyInactivePanel={true}
      >
        <Panel header={
          <HeaderPanel
            goodName={dataOrder?.productOrderItems?.[0]?.productName || ''}
            stockItemsLength={stockItemsLength}
            isFetchingStockItem={isFetchingStockItem}
          />}
               key={0}
               className="w-full p-0 bg-[#F1F3F6] border-1 border-[#E6E9EE]"
               showArrow={false}
               forceRender={true}
        >
          <Good formName={formName} sendOnBlurGoods={sendOnBlurGoods}/>
          <GoodSize/>
          <Materials formName={formName} sendOnBlurGoods={sendOnBlurGoods}/>
          <Appliances sendOnBlurGoods={sendOnBlurGoods} formName={formName}/>
          <Note sendOnBlurGoods={sendOnBlurGoods}/>
        </Panel>
      </Collapse>

      <div className="p-[32px]">
        <Row justify={'space-between'} align={'middle'} className="mb-[24px]">
          <Col>
            <Text className="font-semibold text-base leading-[19px] text-baseColor">Сумма и оплата</Text>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={5}>
            <Form.Item name={'cost'}
                       label={'Стоимость заказа'}
                       labelAlign={'left'}
            >
              <Input type={'text'} size={'large'} maxLength={10}
                     className="w-full h-[44px] disabled:text-baseGreen disabled:font-semibold disabled:text-base disabled:leading-[22px] disabled:border-none"
                     disabled/>
            </Form.Item>
          </Col>
        </Row>
      </div>

      <DatesOrder/>
    </div>
  )
}

export default Goods