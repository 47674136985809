import React, { FC, useEffect } from 'react'

import { IMaterialItem } from '../../models/IMaterial'
import { transformDate } from '../../helpers/transformDate'
import { downloader } from '../../helpers/downloader'
import { useCreateDocumentMutation } from '../../services/document.api'

import { Card, Col, Input, Row, Space } from 'antd'
import { ArrowRightOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'

import s from '../../modules/MaterialsOrder/components/RequiredToOrder/RequiredToOrder.module.scss'

export interface IPropsMaterial {
  item: IMaterialItem
  children?: React.ReactNode
  openChangeOrder?: () => void
  closeChangeOrder?: () => void
  fetchOrder?: (orderId: number) => void
  ref?: any
  moduleType?: 'archive'
}

const MaterialCard: FC<IPropsMaterial> = ({
                                            moduleType,
                                            item,
                                            openChangeOrder,
                                            fetchOrder,
                                            ref,
                                            children
                                          }) => {
  const [createDocument, {data}] = useCreateDocumentMutation()
  const productName = `${item.productName}${item.productOrderItem?.quantity
    ? `, ${item.productOrderItem?.quantity}\u00A0шт`
    : ''}`

  useEffect(() => {
    if (data) {
      downloader(data[0].link, data[0].name, '_blank', false)
    }
  }, [data])

  const onClickOrder = () => {
    if (fetchOrder) {
      fetchOrder(item.productOrder.id)
    }
  }

  const onCreateDocument = () => {
    createDocument({
      document_name: 'plate',
      material_order: item.hydraMemberID,
      product_order: item.productOrder.productOrderIRI
    })
  }

  return (
    <div ref={ref}>
      <Card title={
        <Row justify={'space-between'}>
          <Col span={21}>
            <Space size={12} className={s.orderName}>
              Договор №{item.productOrder.contractNumber || `- (${item.productOrder.id})`} <ArrowRightOutlined onClick={onClickOrder}/>
            </Space>
          </Col>
          {
            (item.status === 'waiting_for_load' || item.status === 'loaded') &&
            <Col span={1}>
              <DownloadOutlined style={{fontSize: 18}} onClick={onCreateDocument}/>
            </Col>
          }
          {
            (moduleType !== 'archive' && item.status === 'waiting_for_load') && (
              <Col span={1} offset={1}>
                <EditOutlined style={{fontSize: 18}} onClick={openChangeOrder}/>
              </Col>
            )
          }
        </Row>
      }
            headStyle={{borderBottom: '1px solid #D3D7E0'}}
            className={s.card}
      >
        <Space size={16} direction={'vertical'} style={{width: '100%'}}>
          <Row>
            {
              moduleType === 'archive' && (
                <div className={s.materialFromStock}>Ткань: {item.materialFromStock ? 'из наличия' : 'на заказ'}</div>
              )
            }
          </Row>
          <Space size={16} direction={'vertical'} style={{width: '100%'}}>
            <Row gutter={[12, 12]} align={'middle'}>
              <Col span={10} className={s.fieldName}>Товар</Col>
              <Col span={14}>
                <TextArea value={productName}
                          disabled={true}
                          className={s.field}
                          autoSize={{minRows: 1}}
                />
              </Col>
            </Row>
            <Row gutter={[12, 12]} align={'middle'}>
              <Col span={10} className={s.fieldName}>Размер</Col>
              <Col span={14}>
                <Input value={item.productOrderItem.productSize.value || item.productOrderItem.productSizeValue} disabled={true} className={s.field}/>
              </Col>
            </Row>
            <Row gutter={[12, 12]} align={'middle'}>
              <Col span={10} className={s.fieldName}>Дата изготовления</Col>
              <Col span={14}>
                <Input value={transformDate(item.productionDateTo)} disabled={true} className={s.field}/>
              </Col>
            </Row>
            <Row gutter={[12, 12]} align={'middle'}>
              <Col span={10} className={s.fieldName}>Материал</Col>
              <Col span={14}>
                <Input value={item.material.material} disabled={true} className={s.field}/>
              </Col>
            </Row>
            <Row gutter={[12, 12]} align={'middle'}>
              <Col span={10} className={s.fieldName}>Наименование</Col>
              <Col span={14}>
                <Input value={item.material.name} disabled={true} className={s.field}/>
              </Col>
            </Row>
            <Row gutter={[12, 12]} align={'middle'}>
              <Col span={10} className={s.fieldName}>Поставщик</Col>
              <Col span={14}>
                <Input value={item.material.provider} disabled={true} className={s.field}/>
              </Col>
            </Row>
          </Space>

          {children}

        </Space>
      </Card>
    </div>
  )
}

export default MaterialCard