import React from 'react'
import { Col, ConfigProvider, Form, Input, Row, Space } from 'antd'

const GoodSize = () => {
  return (
    <ConfigProvider theme={{
      token: {
        colorText: '#8A94A2'
      }
    }}>
      <Space direction={'vertical'} size={12} style={{width: '100%', padding: '13px 16px 16px'}}>
        {/*<Row style={{width: '100%'}}>*/}
        {/*  <Col span={24}>Размер</Col>*/}
        {/*</Row>*/}
        <Row>
          <Col span={15}>
            <Space size={16} align={'start'}>
              <Form.Item
                name={['productSize']}
                style={{marginBottom: 0}}
                rules={[{required: true, message: 'Обязательное поле'}]}
                label={'Размер'}
                labelAlign={'left'}
              >
                {<Input
                  type={'text'}
                  className="w-[334px] h-[44px] text-sm leading-[22px]"
                  placeholder="Введите размер"
                  disabled={true}
                />}
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Space>
    </ConfigProvider>
  )
}

export default GoodSize