import React, { Suspense } from 'react'
import Section from '../../components/Section/Section'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import PageHeader from '../../components/PageHeader/PageHeader'
import PageManagement from '../../components/PageManagement/PageManagement'
import { TabsProps } from 'antd'
import { RouteNames } from '../../configs/routes/routesNames'
import { useGetPagesQuery } from '../../services/pages.api'

const FinishedGoods = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const {data} = useGetPagesQuery()

  const items: TabsProps['items'] = [
    {
      key: RouteNames.FINISHED_GOODS,
      label: 'Наличие товаров',
    },
    {
      key: `${RouteNames.FINISHED_GOODS}/${RouteNames.FINISHED_GOODS_ARCHIVE}`,
      label: 'Архив товаров',
    },
  ]

  const onChangeTab = (activeKey: string) => {
    if (activeKey === RouteNames.FINISHED_GOODS) {
      navigate(RouteNames.FINISHED_GOODS)
    } else if (activeKey === `${RouteNames.FINISHED_GOODS}/${RouteNames.FINISHED_GOODS_ARCHIVE}`) {
      navigate(RouteNames.FINISHED_GOODS_ARCHIVE)
    } else if (activeKey === `${RouteNames.FINISHED_GOODS}/store`) {
      navigate('store')
    }
  }

  if (!data || data.find(item => '/' + pathname.split('/')[1] === item.url) === undefined) {
    return null
  }

  return (
    <Section>
      <Suspense fallback={<div>Загрузка....</div>}>
        <PageHeader title={'Склад готовой продукции'}/>
        <PageManagement items={items} onChangeTab={onChangeTab}/>
        <Outlet />
      </Suspense>
    </Section>
  )
}

export default FinishedGoods