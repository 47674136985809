import React, { FC, useState } from 'react'
import { IconProps } from './icons.types'

const Mail: FC<IconProps> = ({
                                 width = 16,
                                 height = 16,
                                 fill = 'none',
                                 stroke = '#B2BAC3',
                                 strokeHover = '#4D92EE'
                               }) => {
  const [hover, setHover] = useState(false)

  return (
    <svg width={width}
         height={height}
         fill={fill}
         onMouseMove={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         viewBox="0 0 16 16"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M4.66675 5.66699L6.62809 6.82661C7.77156 7.50267 8.2286 7.50267 9.37207 6.82661L11.3334 5.66699"
            stroke={hover ? strokeHover : stroke} strokeLinecap="round" strokeLinejoin="round"/>
      <path
        d="M1.34401 8.9844C1.38759 11.0281 1.40938 12.0499 2.16347 12.8069C2.91755 13.5638 3.96705 13.5902 6.06605 13.6429C7.3597 13.6754 8.64063 13.6754 9.93428 13.6429C12.0333 13.5902 13.0828 13.5638 13.8369 12.8069C14.5909 12.0499 14.6127 11.0281 14.6563 8.9844C14.6703 8.32728 14.6703 7.67404 14.6563 7.01691C14.6127 4.97322 14.5909 3.95138 13.8369 3.19443C13.0828 2.43747 12.0333 2.4111 9.93428 2.35836C8.64063 2.32586 7.3597 2.32586 6.06604 2.35836C3.96705 2.41109 2.91755 2.43746 2.16346 3.19442C1.40938 3.95137 1.38759 4.97322 1.34401 7.0169C1.32999 7.67403 1.32999 8.32727 1.34401 8.9844Z"
        stroke={hover ? strokeHover : stroke} strokeLinejoin="round"/>
    </svg>
  )
}

export default Mail