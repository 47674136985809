import { FC, useState } from 'react'
import { IconProps } from './icons.types'

const CircleQuestion: FC<IconProps> = ({
                                         width = 22,
                                         height = 22,
                                         fill = 'none',
                                         stroke = '#A5A5A5',
                                         strokeHover = '#4D92EE'
                                       }) => {
  const [hover, setHover] = useState(false)

  return (
    <svg width={width}
         height={height}
         fill={fill}
         onMouseMove={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         viewBox="0 0 22 22"
         xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <circle cx="11" cy="11.0635" r="10" stroke={hover ? strokeHover : stroke} strokeWidth="1.5"/>
      <path
        d="M9 8.06348C9 6.95891 9.89543 6.06348 11 6.06348C12.1046 6.06348 13 6.95891 13 8.06348C13 8.46163 12.8837 8.83261 12.6831 9.14427C12.0854 10.0731 11 10.9589 11 12.0635V12.5635"
        stroke={hover ? strokeHover : stroke} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M10.992 16.0635H11.001" stroke={hover ? strokeHover : stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CircleQuestion