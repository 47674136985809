export enum OrderStatuses {
  'new' = 'Новый',
  'waiting_for_payment' = 'Ожидает предоплату',
  'production' = 'В производстве',
  'waiting_for_afterpayment' = 'Ожидает доплату',
  'waiting_for_load' = 'Ожидает отгрузку',
  'loaded' = 'Отгружен',
  'cancel' = 'Отменен',
  'deleted' = 'Удален',
  'ready' = 'Готов'
}

export enum OrderStatusForm {
  NEW = 'new',
  EDIT_ORDER = 'orderChanging'
}

export enum OrderStatusesIRI {
  NEW = '/api/v1/product-order-statuses/1',
  WAITING_FOR_PAYMENT = '/api/v1/product-order-statuses/2',
  IN_PROGRESS = '/api/v1/product-order-statuses/10', // В обработке
  PRODUCTION = '/api/v1/product-order-statuses/3',
  WAITING_FOR_LOADING = '/api/v1/product-order-statuses/5',
  LOADED = '/api/v1/product-order-statuses/6',
  CANCEL = '/api/v1/product-order-statuses/7',
  READY = '/api/v1/product-order-statuses/9',
}

export enum OrderProductionSubStatus {
  NEW = 'new',
  PROGRESS = 'progress',
  READY = 'ready'
}

export enum OrderProductionSubStatusCyr {
  NEW = 'Новый',
  IN_PROGRESS = 'В работе',
  READY = 'Готово'
}