export enum RouteNames {
  AUTHORIZATION = '/sign-in',
  ORDERS = '/orders',
  ORDERS_ARCHIVE = '/orders/archive',
  MATERIALS = '/materials',
  MATERIALS_IN_STOCK = 'in-stock',
  MATERIALS_ARCHIVE = 'archive',
  PRODUCTION = '/production',
  PRODUCTION_READY = 'ready',
  PRODUCTION_PLAN = '/production-plan',
  PRODUCTION_PLAN_ARCHIVE = 'archive',
  FINISHED_GOODS = '/finished-goods',
  FINISHED_GOODS_ARCHIVE = 'archive',
  RESHIPMENT = '/reshipment',
  EMPLOYEE_DASHBOARD = '/employee-dashboard',
  EMPLOYEE_DASHBOARD_ASSORTMENT_AND_PRICES = 'assortment-and-prices',
  EMPLOYEE_DASHBOARD_MATERIALS = 'material',
  EMPLOYEE_DASHBOARD_PIECEWORK_PAYMENT = 'piecework-payment',
  MP = 'marketplace',
  STORE = 'store',
  NOT_FOUND = '/not-found'
}
