import React, { FC, useEffect, useState } from 'react'
import { Col, Drawer, notification, Row, Spin } from 'antd'
import TableRow from './components/Row/TableRow'
import {
  useGetOrdersProductionQuery,
  useLazyGetOrderQuery
} from '../../../../services/order.api'
import { FormOrder } from '../../../OrderCreating'
import { OrderProductionSubStatus, OrderStatusesIRI, OrderStatusForm } from '../../../../configs/enums/orderStatuses'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { getIsOrderEdit, getOrderToEdit, setOrderToEdit } from '../../../../store/reducers/order/order.slice'

import { useGetUsersQuery } from '../../../../services/users.api'

import { UserRoles } from '../../../../configs/enums/userRoles'
import { IUserList } from '../../../../models/IUser'

import { socket } from '../../../../services/socket'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { IOrderProduction } from '../../../../models/IOrder'
import { layoutContentElRef } from '../../../../components/LayoutCustom/LayoutCustom'
import classNames from 'classnames'

export interface IUserListObj {
  tailor: IUserList[],
  seamstress: IUserList[],
  cutter: IUserList[],
  foamer: IUserList[],
  assembler: IUserList[],
  upholsterer: IUserList[],
  assemblerFull: IUserList[],
  otk: IUserList[],
  packer: IUserList[],
}

interface IProps {
  // fetching: boolean
  // stopFetching: () => void
  // incrementPage: () => void
  // setPage?: (pageNum: number) => void
  // perPage: number
  // page: number
  isEditable: boolean
}

const PER_PAGE = 5

const ProductionPlanTable: FC<IProps> = ({
                                           isEditable = true,
                                           // fetching,
                                           // stopFetching,
                                           // incrementPage,
                                           // setPage,
                                           // perPage = 10,
                                           // page = 1
                                         }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(false)
  const [fetching, setFetching] = useState(false)
  const [orders, setOrders] = useState<{ orders: IOrderProduction[], totalItems: number }>({
    orders: [],
    totalItems: 0
  })
  const [page, setPage] = useState(1)
  const isOrderToEdit = useAppSelector(getIsOrderEdit)
  const orderToEdit = useAppSelector(getOrderToEdit)
  // const [fetchOrders, {data: ordersData, isLoading: isLoadingOrders}] = useLazyGetOrdersProductionQuery()

  const {data: rolesData} = useGetUsersQuery({
    roles: [
      UserRoles.ROLE_TAILOR,
      UserRoles.ROLE_SEAMSTRESS,
      UserRoles.ROLE_CUTTER,
      UserRoles.ROLE_ASSEMBLER,
      UserRoles.ROLE_FOAMER,
      UserRoles.ROLE_UPHOLSTERER,
      UserRoles.ROLE_ASSEMBLER_FULL,
      UserRoles.ROLE_OTK,
      UserRoles.ROLE_PACKER,
    ]
  })
  const [getOrder] = useLazyGetOrderQuery()
  const [getUpdatedOrder, {data: newOrderData}] = useLazyGetOrderQuery()
  const dispatch = useAppDispatch()
  const [api, contextHolder] = notification.useNotification()
  const [users, setUsers] = useState<IUserListObj>({
    tailor: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
    seamstress: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
    cutter: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
    assembler: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
    foamer: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
    upholsterer: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
    assemblerFull: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
    otk: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
    packer: [{
      userIRI: '',
      id: 0,
      accessCode: '-',
      firstName: '',
      lastName: '',
      middleName: '',
      status: '',
      phone: '',
      address: '',
      userRole: {
        userRoleIRI: '',
        id: 0,
        name: '',
        code: '',
      }
    }],
  })
  const {data: ordersData, isLoading, refetch} = useGetOrdersProductionQuery({
    status: isEditable ? OrderStatusesIRI.PRODUCTION : [OrderStatusesIRI.WAITING_FOR_LOADING, OrderStatusesIRI.LOADED],
    subStatus: isEditable ? OrderProductionSubStatus.PROGRESS : OrderProductionSubStatus.READY,
    sort: {
      isAsap: isEditable ? 'desc' : undefined,
      productionDateFrom: isEditable ? 'asc' : undefined,
      productionFinishedAt: !isEditable ? 'asc' : undefined,
    },
    perPage: 15,
    page
  })

  useEffect(() => {
    if (!orders.orders.length && page === 1) {
      refetch()
    }
  }, [page])
  useEffect(() => {
    if (newOrderData) {
      setOrders(state => ({
          ...state,
          totalItems: newOrderData?.productionSubStatus === OrderProductionSubStatus.READY
            ? state.totalItems - 1
            : state.totalItems,
          orders: newOrderData?.productionSubStatus === OrderProductionSubStatus.READY
            ? state.orders.filter(order => order.id !== newOrderData.id)
            : state.orders.map(order => order.id === newOrderData.id
              ? ({
                ...order,
                tasks: newOrderData.tasks,
                subProductOrders: newOrderData.subProductOrders,
                orderTaskStatusesByRoles: newOrderData.orderTaskStatusesByRoles
              })
              : order),
        })
      )
    }
  }, [newOrderData])
  useEffect(() => {
    if (ordersData) {
      if (page === 1) {
        setOrders(state => ({orders: ordersData.orders, totalItems: ordersData.totalItems}))
      } else {
        setOrders(state => ({orders: [...state.orders, ...ordersData.orders], totalItems: ordersData.totalItems}))
      }
    }
    setIsLoadingOrders(false)
    setFetching(false)
  }, [ordersData])
  useEffect(() => {
    socket.on('message', (mess) => {
      if (mess.message?.orderId) {
        getUpdatedOrder(mess.message?.orderId)
      }
    })
    return () => {
      socket.off('message', (val: any) => console.log(val))
    }
  }, [])

  const showOrder = async (id: number) => {
    setLoading(true)

    try {
      const res = await getOrder(id)

      if (res?.data) {
        dispatch(setOrderToEdit({
          edit: true,
          hasRightsForEdit: false,
          orderToEdit: {
            ...res.data,
            disabledFields: {
              docsBtn: false,
              materials: {
                price: true,
                additionalPrice: true,
              },
              checkboxCustomSize: true,
              prepaymentReceived: true,
              goods: {
                quantity: true
              }
            }
          }
        }))
      } else {
        dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
      }

      if (res.error) {
        api.error({
          // @ts-ignore
          message: `Ошибка получения данных. Статус: ${res.error?.status}`
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const closeForm = () => {
    dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
  }

  useEffect(() => {
    if (isLoadingOrders && ordersData && orders.orders.length < ordersData.totalItems) {
      setFetching(true)
      setPage((prevPage) => prevPage + 1)
    }
  }, [isLoadingOrders])

  useEffect(() => {
    const container = layoutContentElRef.current
    if (!container) return
    const handleScroll = (e: any) => {
      if (isLoadingOrders) return
      if (e.target.scrollHeight - (window.innerHeight + e.target.scrollTop) < 100) {
        setIsLoadingOrders(true)
      }
    }
    container.addEventListener('scroll', handleScroll)
    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  // добавить все роли
  useEffect(() => {
    if (rolesData) {
      rolesData.users.forEach(user => {
        if (user.userRole.userRoleIRI === UserRoles.ROLE_TAILOR) {
          setUsers(state => ({...state, tailor: state.tailor.concat(user)}))
        } else if (user.userRole.userRoleIRI === UserRoles.ROLE_SEAMSTRESS) {
          setUsers(state => ({...state, seamstress: state.seamstress.concat(user)}))
        } else if (user.userRole.userRoleIRI === UserRoles.ROLE_CUTTER) {
          setUsers(state => ({...state, cutter: state.cutter.concat(user)}))
        } else if (user.userRole.userRoleIRI === UserRoles.ROLE_ASSEMBLER) {
          setUsers(state => ({...state, assembler: state.assembler.concat(user)}))
        } else if (user.userRole.userRoleIRI === UserRoles.ROLE_FOAMER) {
          setUsers(state => ({...state, foamer: state.foamer.concat(user)}))
        } else if (user.userRole.userRoleIRI === UserRoles.ROLE_UPHOLSTERER) {
          setUsers(state => ({...state, upholsterer: state.upholsterer.concat(user)}))
        } else if (user.userRole.userRoleIRI === UserRoles.ROLE_ASSEMBLER_FULL) {
          setUsers(state => ({...state, assemblerFull: state.assemblerFull.concat(user)}))
        } else if (user.userRole.userRoleIRI === UserRoles.ROLE_OTK) {
          setUsers(state => ({...state, otk: state.otk.concat(user)}))
        } else if (user.userRole.userRoleIRI === UserRoles.ROLE_PACKER) {
          setUsers(state => ({...state, packer: state.packer.concat(user)}))
        }
      })
    }
  }, [rolesData])

  return (
    <div className="w-full">
      <div className="w-full bg-baseLightGrey rounded-t-[10px]">
        {contextHolder}
        {loading && <LoaderFullDisplay/>}
        <Row style={{flexWrap: 'nowrap'}}>
          <Col flex={'1 0 50px'}
               className="p-[27px_14px] bg-[#f1f3f6] rounded-tl-[10px]">{isEditable && 'Приоритет'}</Col>
          {isEditable && <Col flex={'62px'} className="w-[62px] p-[27px_14px] bg-[#f1f3f6]"></Col>}
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6]">Раскрой</Col>
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6]">Пошив</Col>
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6]">Распиловка</Col>
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6]">Сборка</Col>
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6]">Поролонка</Col>
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6]">Обивка</Col>
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6]">Сборка гот.</Col>
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6]">ОТК</Col>
          <Col flex={'1 0 50px'} className="p-[27px_14px] bg-[#f1f3f6] rounded-tr-[10px]">Упаковка</Col>
        </Row>

        {
          <div className={classNames('relative z-1 opacity-100', {
            'opacity-50': fetching
          })}>
            {
              isLoading
                ? <div className="flex justify-center items-center"><Spin size="large"/></div>
                : <>
                  {fetching &&
                    <div className="absolute top-0 left-0 z-10 w-full h-full flex items-center justify-center"></div>}
                  {
                    orders.orders.map((order, index) => (
                      <TableRow
                        key={order.id}
                        orderId={order.id}
                        code={order.code}
                        contractNumber={order.contractNumber}
                        subProduct={order.subProductOrders}
                        isAsap={order.isAsap}
                        showOrder={showOrder}
                        users={users}
                        api={api}
                        isEditable={isEditable}
                        orderTaskStatusesByRoles={order.orderTaskStatusesByRoles}
                        getUpdatedOrder={getUpdatedOrder}
                        refetchOrders={() => {
                          setOrders(state => ({orders: [], totalItems: 0}))
                          if (page === 1) {
                            refetch()
                          } else {
                            setPage(1)
                          }
                        }}
                      />
                    ))
                  }
                </>
            }
          </div>
        }
        <Drawer open={isOrderToEdit}
                onClose={closeForm}
                width={985}
                closable={false}
                bodyStyle={{padding: 0}}
                destroyOnClose={true}
        >
          <FormOrder
            name={'orderChanging'}
            isShowForm={isOrderToEdit && !loading}
            closeForm={closeForm}
            statusForEditing={OrderStatusForm.EDIT_ORDER}
            data={orderToEdit}
          />
        </Drawer>
      </div>
    </div>
  )
}

export default ProductionPlanTable