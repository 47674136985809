import { IUser, IUserList } from '../models/IUser'
import { createApi } from '@reduxjs/toolkit/query/react'
import { apiSlice, baseQueryWithReauth } from './api'

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    me: build.query<IUser, void>({
      query: () => ({
        url: '/users/me',
      }),
      transformResponse: (response: any): IUser => {
        return ({
          ...response,
          firstName: response.first_name,
          lastName: response.last_name,
          middleName: response.middle_name,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          userRole: {
            userRoleIRI: response?.user_role?.['@id'],
            id: response?.user_role?.id,
            name: response?.user_role?.name,
            code: response?.user_role?.code,
          },
          workpoint: {
            ...response.workpoint,
            shortName: response.workpoint.short_name
          }
        })
      },
    }),
    getUser: build.query<IUserList, number>({
      query: (id) => ({
        url: `/users/${id}`
      }),
      transformResponse: (response: any): IUserList => {
        return ({
          userIRI: response['@id'],
          id: response.id,
          accessCode: response.access_code,
          firstName: response.first_name,
          lastName: response.last_name,
          middleName: response.middle_name,
          status: response.status,
          phone: response.phone,
          address: response.address,
          userRole: {
            userRoleIRI: response.user_role['@id'],
            id: response.user_role.id,
            name: response.user_role.name,
            code: response.user_role.code
          }
        })
      },
      providesTags: () => ['Users']
    }),
    getUsers: build.query<
      { users: IUserList[], totalItems: number },
      { roles?: string | string[], status?: string | string[], sort?: {createdAt?: 'asc' | 'desc'} }
    >({
      query: ({roles, status, sort}) => {
        let collectRoles = ''
        if (roles) {
          collectRoles += Array.isArray(roles)
            ? roles.reduce((acc, i, idx) => acc += `${idx === 0 ? '' : '&'}user_role[]=${i}`, '')
            : `user_role[]=${roles}`
        }
        let collectStatus = ''
        if (status) {
          collectStatus += Array.isArray(status)
            ? status.reduce((acc, i, idx) => acc += `${(idx === 0 && !collectRoles) ? '' : '&'}status[]=${i}`, '')
            : `${collectRoles && '&'}status[]=${status}`
        }

        return {
          url: `/users/?${collectRoles ? collectRoles : ''}${collectStatus ? collectStatus : ''}`,
          params: {
            'sort[created_at]': sort?.createdAt
          }
        }
      },
      transformResponse: (response: any) => {
        return ({
          users: response['hydra:member'].map((user: any) => ({
            userIRI: user['@id'],
            id: user.id,
            accessCode: user.access_code,
            firstName: user.first_name,
            lastName: user.last_name,
            middleName: user.middle_name,
            status: user.status,
            phone: user.phone,
            userRole: {
              userRoleIRI: user.user_role['@id'],
              id: user.user_role.id,
              name: user.user_role.name,
              code: user.user_role.code
            }
          })),
          totalItems: response['hydra:totalItems']
        })
      },
      providesTags: () => ['Users']
    }),
    createUser: build.mutation<IUserList, {
      firstName: string
      lastName: string
      middleName: string
      phone: string
      address: string
      userRole: string
    }>({
      query: body => {
        return {
          method: 'POST',
          url: '/users',
          body: {
            first_name: body.firstName,
            last_name: body.lastName,
            middle_name: body.middleName,
            phone: body.phone,
            address: body.address,
            user_role: body.userRole
          }
        }
      },
      invalidatesTags: () => ['Users']
    }),
    changeUser: build.mutation<IUserList, {
      id: number
      firstName?: string
      lastName?: string
      middleName?: string
      phone?: string
      address?: string
      userRole?: string
      status?: 'active' | 'inactive'
    }>({
      query: body => {
        return {
          method: 'PUT',
          url: `/users/${body.id}`,
          body: {
            first_name: body.firstName,
            last_name: body.lastName,
            middle_name: body.middleName,
            phone: body.phone,
            address: body.address,
            user_role: body.userRole,
            status: body.status,
          }
        }
      },
      invalidatesTags: () => ['Users']
    })
  })
})

export const {
  useLazyMeQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useChangeUserMutation,
  useLazyGetUserQuery
} = usersApi
