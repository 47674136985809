import React, { FC } from 'react'
import { IOrderTable, IPaymentType } from '../../../../models/IOrder'
import { LabeledValue } from 'antd/es/select'
import SelectCustom from '../../../../ui/SelectCustom/SelectCustom'

const PaymentForm: FC<
  { row: IOrderTable, paymentTypes: IPaymentType[], onChangeOrder: (id: number, data: {}) => void }
> = ({row, paymentTypes, onChangeOrder}) => {

  const onSelect = async (value: string | number | LabeledValue, option: any) => {
    onChangeOrder(row.id, {payment_type: option.value})
  }

  return <SelectCustom
    // allowClear
    defaultValue={row.paymentType.cyrillicValue}
    placeholder={'Выберите форму'}
    options={paymentTypes ? paymentTypes?.map(item => ({
      key: item?.paymentTypeIRI,
      value: item?.paymentTypeIRI,
      label: item?.cyrillicValue
    })) : []}
    onSelect={onSelect}
    heightOptions={44}
    disabled={row?.afterpaymentReceived || +(row.afterpaymentSumm) === 0}
  />
}
 export default PaymentForm