import React, { FC, useEffect } from 'react'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { ITypeOrder } from '../../../../../../models/IOrder'
import { useDeleteProductOrderItemOptionMutation } from '../../../../../../services/order.api'

import RenderGoodItem from './RenderGoodItem/RenderGoodItem'

import s from './Good.module.scss'

interface IProps {
  formName: string
  fieldName: number
  orderType: ITypeOrder
  sendOnBlurGoods: () => void
}

const Good: FC<IProps> = ({formName, fieldName, orderType, sendOnBlurGoods}) => {
  const [api, contextHolder] = notification.useNotification({
    placement: 'topRight'
  })
  const [deleteGoodOption, {
    isSuccess: isSuccessDeleteOption,
    isError: isErrorDeleteOption,
    error: errorDeleteOption
  }] = useDeleteProductOrderItemOptionMutation()
  const formInstance = Form.useFormInstance()
  const goods = Form.useWatch(['goods', fieldName], formInstance)

  // уведомление удаления опции--->
  useEffect(() => {
    if (isSuccessDeleteOption) {
      api.success({
        message: `Товар успешно удалён`,
        duration: 3
      })
    }
  }, [isSuccessDeleteOption])
  useEffect(() => {
    if (errorDeleteOption && 'status' in errorDeleteOption) {
      api.error({
        message: `Не удалось удалить товар. Статус: ${errorDeleteOption.status}`,
        duration: 3
      })
    }
  }, [isErrorDeleteOption])
  // <--- уведомление удаления опции

  useEffect(() => {
    if (goods) {
      const good = goods.good
      const sum = good?.reduce((acc: number, item: { sum: string }) => {
        return acc + Number(item?.sum)
      }, 0)
      formInstance.setFieldValue(['goods', fieldName, 'good', 0, 'summative'], sum || '')
    }
  }, [goods])

  return (
    <>
      {contextHolder}

      <div style={{padding: '8px 16px 0'}} key={fieldName}>
        <Form.List name={[fieldName, 'good']}>
          {(fields, {add, remove}) => (
            <>
              {
                fields.map((field, index) => (
                  <RenderGoodItem key={field.name}
                                  formName={formName}
                                  field={field}
                                  indexField={index}
                                  goodsIndex={fieldName}
                                  removeField={remove}
                                  sendOnBlurGoods={sendOnBlurGoods}
                                  deleteGoodOption={deleteGoodOption}
                  />
                ))
              }

              <Form.Item>
                <Row gutter={[12, 12]} justify={'space-between'} align={'middle'}>
                  <Col span={5}>
                    <Button
                      type={'ghost'}
                      onClick={() => add({
                        productName: '',
                        price: '',
                        quantity: '1',
                        cost: '',
                        discount: '',
                        sum: '',
                      })}
                      icon={<PlusOutlined/>}
                      className={s.addFieldGood}
                    >
                      Добавить поле
                    </Button>
                  </Col>

                  <Col flex={'245px'}>
                    <Row gutter={[12, 0]}>
                      <Col flex={'95px'}>
                        <Form.Item name={[0, 'sumName']} style={{marginBottom: 'unset'}}>
                          <Input className={s.summativeHead} disabled size={'large'}/>
                        </Form.Item>
                      </Col>
                      <Col flex={'150px'}>
                        <Form.Item name={[0, 'summative']} style={{marginBottom: 'unset'}}>
                          <Input disabled className={s.summative} size={'large'}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </>
  )
}

export default Good