import React, { FC, useEffect } from 'react'
import { Col, Row, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useLazyGetDocumentsQuery } from '../../services/document.api'
import { FirmNames } from '../../configs/enums/firmNames'
import { DocumentTypes } from '../../configs/enums/documentTypes'
import { FilePdfFilled } from '@ant-design/icons'
import { transformDate } from '../../helpers/transformDate'
import s from './Documents.module.scss'
import { useLazyGetOrderQuery } from '../../services/order.api'

interface DataType {
  key: number
  name: string
  type: string
  firmName: string
  date: string
  link: string
  collection: keyof typeof DocumentTypes
  templateType: keyof typeof DocumentTypes
  number: string
  code: string
}

interface IProps {
  orderIRI: string
  orderId: number | undefined
}

const Documents: FC<IProps> = ({orderIRI, orderId}) => {
  const [getDoc, {data}] = useLazyGetDocumentsQuery()
  const [getOrder, {data: order}] = useLazyGetOrderQuery()

  useEffect(() => {
    if (orderId) {
      getOrder(orderId)
    }
  }, [data])

  const columns: ColumnsType<DataType> = [
    {
      title: '№ документа',
      render: (text: string, record) => (
        <div>
          {
            (record.collection === 'external_contract')
              ? <Row align={'middle'}>
                <Col span={4}>
                  <FilePdfFilled color={'#4D92EE'}/>
                </Col>
                <Col span={20}>
                  {record.code}
                </Col>
              </Row>
              : <a download href={record.link}>
                <Row align={'middle'}>
                  <Col span={4}>
                    <FilePdfFilled color={'#4D92EE'}/>
                  </Col>
                  <Col span={20}>
                    {record.name}
                  </Col>
                </Row>
              </a>
          }
        </div>
      )
    },
    {
      title: 'Вид документа',
      dataIndex: 'collection',
      render: (text, record) => <div>{DocumentTypes[record.templateType]}</div>
    },
    {
      title: 'Юр.лицо',
      dataIndex: 'firmName',
    },
    {
      title: 'Дата создания',
      dataIndex: 'date',
    },
  ]

  useEffect(() => {
    getDoc({product: orderIRI, page: 1})
    // eslint-disable-next-line
  }, [orderIRI])

  return (
    <div className={s.documents}>
      <Table columns={columns}
             dataSource={data
               && data.map(item => ({
                 key: item.id,
                 name: item.name,
                 type: item.type,
                 firmName: FirmNames[item.firmName],
                 date: transformDate(item.updatedAt, true),
                 link: item.link,
                 collection: item.collection,
                 templateType: item.templateType,
                 number: item.number,
                 code: item.code,
               }))
             }
             pagination={false}
             bordered
             tableLayout={'fixed'}
      />
    </div>
  )
}

export default Documents