import React, { FC, useRef, useState } from 'react'
import { Button, ConfigProvider, Modal, notification, Popover, Table, TableProps } from 'antd'
import { useGetProductStockItemsQuery, useWriteOffReasonsMutation } from '../../services/product.api'
import useOnClickOutside from '../../hooks/useOnClickOutside'

import CircleQuestion from '../../ui/icons/CircleQuestion'
import TextArea from 'antd/es/input/TextArea'

import { MoreOutlined } from '@ant-design/icons'

import s from './TableFinishedGoods.module.scss'
import { IProductOrderItem } from '../../models/IProduct'
import Info from '../../ui/icons/info'
import { transformDate } from '../../helpers/transformDate'

interface IProps {
  row: IProductOrderItem
}

const CellWithModal: FC<IProps> = ({row}) => {
  const [modal, setModal] = useState(false)
  const [popover, setPopover] = useState(false)
  const [reason, setReason] = useState('')
  const ref = useRef(null)
  const [api, contextHolder] = notification.useNotification()
  const [writeOff] = useWriteOffReasonsMutation()

  useOnClickOutside(ref, () => setPopover(false))
  const handleOk = async (e: React.MouseEvent<HTMLElement>) => {
    if (reason.length < 5) {
      api.warning({message: 'Введите от 5 до 40 символов'})
      return
    }

    try {
      const res = await writeOff({
        reason: reason,
        stockItem: row.productIRI
      })
      if ('data' in res) {
        api.success({
          message: 'Товар успешно списан',
          placement: 'topRight',
          duration: 3
        })
      } else {
        api.error({
          message: 'Ошибка',
          placement: 'topRight',
          duration: 3
        })
      }
    } catch (e: any) {
      api.error({
        message: e?.message || 'Списать не удалась',
        description: e?.error?.message,
        placement: 'topRight',
        duration: 3
      })
    }

    setReason('')
    setModal(false)
  }

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    setReason('')
    setModal(false)
  }

  return (
    <div className={s.quantityBlock}>
      {contextHolder}
      <div>{transformDate(row.productionAt)}</div>
      <div className={s.btnWrap}>
        <Popover placement="left"
                 open={popover}
                 content={<Button ref={ref}
                                  onClick={(e) => {
                                    setModal(true)
                                    setPopover(false)
                                  }}>Списать товар</Button>}
        >
          <Button className={s.writeGoodBtn}
                  icon={<MoreOutlined/>}
                  onClick={() => {
                    setPopover(true)
                  }}
          />
        </Popover>
      </div>
      <ConfigProvider
        theme={{
          token: {
            fontSize: 16,
            colorText: 'rgba(38, 59, 83, 0.88)'
          }
        }}
      >
        <Modal
          title={<div className="mb-4">
            <div className="mb-[13px]">
              <Info />
            </div>
            Укажите причину списания товара
        </div>}
          open={modal}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          okText={<div className="text-sm">Списать</div>}
          cancelText={<div className="text-sm text-[#4D92EE] border-[#4D92EE]">Отмена</div>}
          centered
        >
          <TextArea placeholder={'Введите текст'}
                    onChange={(e) => {
                      const val = e.target.value
                      if (val.length > 40) {
                        return
                      }
                      setReason(val)
                    }}
                    value={reason}
                    className={'!h-[134px] !resize-none text-sm mb-[12px]'}
          />
        </Modal>
      </ConfigProvider>
    </div>
  )
}

const TableFinishedGoods = () => {
  const {data: ordersData, isLoading: isLoadingOrders} = useGetProductStockItemsQuery({
    perPage: 100,
    status: 'new',
    sort: {productionAt: 'desc'}
  })

  const columns: TableProps<IProductOrderItem>['columns'] = [
    {
      title: 'ID товара',
      className: '!text-[#263B53]',
      width: 120,
      render: (text, row, index) => {
        return (
          <div className={s.cellId}>
            <span>{row.itemCode}</span>
            <Popover placement="rightTop"
                     content={
                       <div>
                         <div className={s.goodTextInfo}>
                           Поставщик ткани (осн): {row.materials?.[0]?.provider}
                         </div>
                         <div className={s.goodTextInfo}>
                           Наименование ткани (осн): {row.materials?.[0]?.name}
                         </div>
                         {
                           row.materials?.[1] && (
                             <>
                               <div className={s.goodTextInfo}>
                                 Поставщик ткани (доп): {row.materials?.[1].provider}
                               </div>
                               <div className={s.goodTextInfo}>
                                 Наименование ткани (доп): {row.materials?.[1].name}
                               </div>
                             </>
                           )
                         }
                         <div className={s.goodTextInfo}>Задняя спинка: {row.hasBackwall ? 'Да' : 'Нет'}</div>
                         {row.productSupport.name && (
                           <div className={s.goodTextInfo}>Опоры: {row.productSupport.name}</div>
                         )}
                       </div>
                     }>
              <Button className={s.questionButton}><CircleQuestion/></Button>
            </Popover>
          </div>
        )
      }
    },
    {
      title: 'Наименование',
      className: '!text-[#263B53]',
      width: 365,
      render: (text, row) => <>{row.product.name}</>
    },
    {
      title: 'Размер',
      className: '!text-[#263B53]',
      width: 365,
      render: (text, row) => <>{row.productSize.value}</>
    },
    {
      title: 'Наименование осн. ткани',
      className: '!text-[#263B53]',
      width: 365,
      render: (text, row) => <>{row.materials?.[0]?.name}</>
    },
    {
      title: 'Поставщик ткани',
      className: '!text-[#263B53]',
      width: 365,
      render: (text, row) => <>{row.materials?.[0]?.provider}</>
    },
    {
      title: 'Дата производства',
      width: 365,
      className: '!text-[#263B53]',
      // sorter: {
      //   compare: (a, b) => {
      //     return a.productOrderItems?.[0].quantity - b.productOrderItems?.[0].quantity
      //   },
      // },
      // sortDirections: ['ascend', 'descend'],
      render: (text, row) => <CellWithModal row={row}/>
    }
  ]

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            padding: 10,
            fontSize: 16,
            colorText: '#263B53'
          },
        },
      }}
    >
      <Table<IProductOrderItem>
        columns={columns}
        dataSource={
          ordersData?.map(item => ({...item, key: item.id}))
        }
        pagination={false}
        loading={isLoadingOrders}
        scroll={{x: 900}}
        className="pb-[60px]"
      />
    </ConfigProvider>
  )
}

export default TableFinishedGoods