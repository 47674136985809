import { IUserList } from '../models/IUser'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './api'

export const referenceApi = createApi({
  reducerPath: 'reference',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Reference'],
  endpoints: (build) => ({
    downloadFabric: build.query<{ file: string }, void>({
      query: () => ({url: '/import/fabric'})
    }),
    // uploadFabric: build.mutation<void, { file: any }>({
      // query: async ({file}) => {
      //   const bodyFormData = new FormData()
      //   bodyFormData.append('file', file)
      //   if (file) {
      //     bodyFormData.append('type', 'single')
      //   }
      //   // const getLink = await fetch('https://liveline.dev.migra.ru/api/v1/export/fabric')
      //
      //   return {
      //     method: 'POST',
      //     url: `/import/fabric`,
      //     body: bodyFormData,
      //     headers: {
      //       'Content-Type': 'application/json'
      //     }
      //   }
      // },
    // })
  })
})

export const {useLazyDownloadFabricQuery} = referenceApi
