import React, { FC, useEffect, useRef, useState } from 'react'
import { IMaskInput } from 'react-imask'

import { Col, Form, Input, Row, Typography } from 'antd'
import { IContact, IContactSource } from '../../../../../../models/IContact'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { LabeledValue } from 'antd/es/select'
import Mail from '../../../../../../ui/icons/Mail'
import { PHONE_MASK } from '../../../../../../configs/phoneMask'
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside'

import classNames from 'classnames'
import s from './IndividualPerson.module.scss'
import { useAppSelector } from '../../../../../../hooks/redux'
import { getDisabledFieldsFromEditOrder, getHasRights } from '../../../../../../store/reducers/order/order.slice'

const {Text} = Typography

interface IProps {
  formName?: string
  dataContact: IContact[]
  dataContactSource: IContactSource[]
  onBlurField: (e: string, fieldName: string) => void
  onSearchContact: (e: any) => void
  changeContactDataForOrder: (contactIRI: string) => void
}

const IndividualPerson: FC<IProps> = ({
                                        dataContact,
                                        dataContactSource,
                                        onBlurField,
                                        onSearchContact,
                                        changeContactDataForOrder,
                                        formName
                                      }) => {
  const hasRightForEdit = useAppSelector(getHasRights)
  const [contact, setContact] = useState({} as IContact)
  const [focus, setFocus] = useState(false)
  const form = Form.useFormInstance()
  const ref = useRef(null)

  useOnClickOutside(ref, () => setFocus(false))

  useEffect(() => {
    if (contact.phone) {
      form.setFieldValue(['email'], contact.email)
      form.setFieldValue(['additionalContact'], contact.additionalContacts)
      form.setFieldValue(['addressDelivery'], contact.address)
      form.setFieldValue(['contactFirstName'], contact.firstName)
      form.setFieldValue(['contactLastName'], contact.lastName)
      form.setFieldValue(['contactMiddleName'], contact.middleName)
    }
  }, [contact.phone])

  // const onSelectContact = (value: any, option: any) => {
  const onSelectContact = (option: IContact) => {
    form.setFieldValue(['phone'], option.phone)
    setContact(option)
    changeContactDataForOrder(option.hydraContactID)
    setFocus(false)
  }

  const onSelectSource = (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['contactSource'], option.id)
    onBlurField(option.id, 'source')
  }

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <Text className={s.fieldName}>Источник клиента</Text>
          <Form.Item name={'contactSource'}>
            <SelectCustom
              allowClear
              heightOptions={44}
              options={dataContactSource
                ? dataContactSource?.map(item => ({id: item.sourceIRI, value: item.sourceIRI, label: item.name}))
                : []}
              onSelect={onSelectSource}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Фамилия<Text type={'danger'}>*</Text></Text>
          <Form.Item name={'contactLastName'}
                     initialValue={''}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <Input className={s.field} onBlur={(e) => onBlurField(e.target.value, 'second_name')}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Имя<Text type={'danger'}>*</Text></Text>
          <Form.Item name={'contactFirstName'}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <Input type={'text'} className={s.field} onBlur={(e) => onBlurField(e.target.value, 'first_name')}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Отчество</Text>
          <Form.Item name={'contactMiddleName'}>
            <Input type={'text'} className={s.field} onBlur={(e) => onBlurField(e.target.value, 'middle_name')}/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Text className={s.fieldName}>Телефон<Text type={'danger'}>*</Text></Text>
          <Form.Item name={'phone'}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <div className="relative" ref={ref}>
              <IMaskInput
                value={form.getFieldValue('phone')}
                mask={PHONE_MASK}
                onAccept={(value, mask) => {
                  form.setFieldValue(['phone'], value)
                  onSearchContact(value)
                }}
                placeholder="+7 (999) 999-99-90"
                onFocus={() => setFocus(true)}
                onBlur={(e) => onBlurField(e.target.value, 'phone')}
                className={classNames(
                  'invalid:border-red w-full h-[44px] p-[10px_15px] md:p-[8px_11px] rounded-[6px] text-baseGrey text-medium border border-[#d9d9d9] outline-none disabled:bg-[#0000000a] disabled:text-[#00000040]',
                  {'border border-1 !border-[#4096ff]': focus}
                )}
                disabled={!hasRightForEdit && formName !== 'newOrder'}
              />
              {
                focus && dataContact && (
                  <div
                    className="w-full max-h-[180px] overflow-y-auto absolute top-full left-0 z-10 flex flex-col gap-1 bg-white rounded-[8px] p-1">
                    {
                      dataContact.map((item) => (
                        <div key={item.id} className={classNames('p-[2px_5px] hover:bg-[#c1c1c1] hover:cursor-pointer')}
                             onClick={() => onSelectContact(item)}
                        >
                          {item.firstName || item.fullName}: {item.phone}
                        </div>)
                      )
                    }
                  </div>
                )
              }
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Почта</Text>
          <Form.Item name={'email'}>
            <Input type={'text'}
                   className={s.field}
                   placeholder={'Введите почту'}
                   prefix={<Mail/>}
                   onBlur={(e) => onBlurField(e.target.value, 'email')}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Доп. контакты</Text>
          <Form.Item name={'additionalContact'}>
            <Input type={'text'} className={s.field}
                   onBlur={(e) => onBlurField(e.target.value, 'additional_contacts')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text className={s.fieldName}>Адрес доставки<Text type={'danger'}>*</Text></Text>
          <Form.Item name={'deliveryAddress'}
                     rules={[{required: true, message: 'Обязательное поле'}]}>
            <Input type={'text'} className={s.field}
                   onBlur={(e) => onBlurField(e.target.value, 'address')}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default IndividualPerson