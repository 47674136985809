import React, { FC, HTMLAttributes, TdHTMLAttributes, useEffect, useState } from 'react'


import { IOrderTable } from '../../../../models/IOrder'
import { ColorForStatus } from '../../../../configs/enums/colorForStatus'
import {
  OrderProductionSubStatus,
  OrderProductionSubStatusCyr,
  OrderStatusesIRI
} from '../../../../configs/enums/orderStatuses'
import { useChangeOrderMutation, useLazyCreateMaterialPlateQuery } from '../../../../services/order.api'

import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Popover,
  Row,
  SpinProps,
  Table,
  TableProps,
  Typography
} from 'antd'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import s from './TableProductionOrders.module.scss'

import CircleQuestion from '../../../../ui/icons/CircleQuestion'
import { ORDER_TYPES, OrderTypesCyr } from '../../../../configs/enums/orderTypes'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import SelectCustom from '../../../../ui/SelectCustom/SelectCustom'
import { LabeledValue } from 'antd/es/select'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { downloader } from '../../../../helpers/downloader'

dayjs.locale('ru')
dayjs.extend(utc)
dayjs.utc()
const {Text} = Typography
const { confirm } = Modal

interface IProps {
  ordersData: IOrderTable[]
  onRow: (record: IOrderTable) => HTMLAttributes<any> | TdHTMLAttributes<any>
  loading?: boolean | SpinProps
  pageType?: 'archive' | ''
}

const TableProductionOrders: FC<IProps> = ({ordersData, onRow, loading = false, pageType}) => {
  const [changeOrder, {
    isSuccess: isSuccessChangeOrder,
    isError: isErrorChangeOrder,
    isLoading: isLoadingSplit,
    error
  }] = useChangeOrderMutation()
  const [createMaterialPlate] = useLazyCreateMaterialPlateQuery()
  const [isModalSplit, setIsModalSplit] = useState(false)
  const [orderToSplit, setOrderToSplit] = useState<IOrderTable | null>(null)
  const [api, contextHolder] = notification.useNotification()
  const [form] = Form.useForm()

  useEffect(() => {
    if (isSuccessChangeOrder) {
      api.success({
        message: 'Заказ успешно изменен',
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isSuccessChangeOrder])
  useEffect(() => {
    if (error && 'status' in error) {
      api.error({
        message: orderToSplit
          ? `Ошибка. Статус: ${error.status}`
          : `Не удалось изменить статус заказа. Статус: ${error.status}`,
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isErrorChangeOrder])

  const changeOrderToProduction = async (order: IOrderTable) => {
    const dataOrderItems = order.productOrderItems.map((item: any, idx) => ({
      product_order_item: item.productOrderItemIRI,
      code: `${order.id}/${idx + 1}`,
      quantity: +item.quantity,
    }))

    try {
      const res = await changeOrder({
        id: order.id,
        data: {
          sub_product_orders: dataOrderItems,
          production_sub_status: OrderProductionSubStatus.PROGRESS
        }
      })
      const plate = await createMaterialPlate(order.id)
      if ('data' in plate && plate.data) {
        downloader(plate.data.link, plate.data.link, '_blank', false)
      }
      if ('data' in res) {
        api.success({message: 'Заказ отправлен в работу'})
      }
    } catch (e: any) {
      console.log(e)
    }
  }
  const onFinish = async (fieldsValues: any) => {
    const goods = fieldsValues.goods.reduce((acc: any, curr: any) => {
      acc[curr.productOrderItemIRI] = +(acc[curr.productOrderItemIRI] || 0) + +curr.quantity
      return acc
    }, {})
    const checkDiff = orderToSplit?.productOrderItems
      .filter(item => item.quantity === goods[item.productOrderItemIRI])

    if (checkDiff?.length !== orderToSplit?.productOrderItems.length) {
      api.error({message: 'Не все товары разбиты'})
      return
    }
    if (!orderToSplit) return

    try {
      const data = fieldsValues.goods.map((item: any) => ({
        product_order_item: item.productOrderItemIRI,
        code: item.code,
        quantity: +item.quantity,
      }))

      const res = await changeOrder({
        id: orderToSplit.id,
        data: {
          sub_product_orders: data,
          production_sub_status: OrderProductionSubStatus.PROGRESS
        }
      })
      const plate = await createMaterialPlate(orderToSplit.id)

      if ('data' in plate && plate.data) {
        downloader(plate.data.link, plate.data.link, '_blank', false)
      }
      if ('data' in res) {
        api.success({message: 'Заказ отправлен в работу'})

        setIsModalSplit(false)
      }
    } catch (e) {
      api.error({message: 'Ошибка'})
    }
  }

  const initialValues = {
    goods: [{
      productOrderItemIRI: '',
      product: '',
      code: `${orderToSplit?.id}/1`,
      quantity: '',
    }]
  }

  const columns: TableProps<IOrderTable>['columns'] = [
    {
      title: '№',
      dataIndex: 'id',
      width: 120,
      sorter: {
        compare: (a, b) => {
          return a.id - b.id
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => {
        return (
          <div className={s.cellId}>
            <span>{row.id}</span>
            {
              pageType === 'archive' && (
                <Popover placement="rightTop"
                         content={
                           <div>
                             <div className={s.date}>{dayjs(row.createdAt).format('DD.MM.YYYY HH:mm ')}</div>
                             {
                               row.productOrderItems.map((product, idx) => (
                                 <React.Fragment key={idx}>
                                   <div className={s.goodTextInfo}>Товар: {product.productName} {product.quantity} шт.
                                   </div>
                                   <div className={s.goodTextInfo}>Размер: {product.productSizeValue}</div>
                                   <div className={s.goodTextInfo}>
                                     Поставщик ткани (осн): {product.materials?.[0]?.provider}
                                   </div>
                                   <div className={s.goodTextInfo}>Наименование ткани
                                     (осн): {product.materials?.[0]?.name}
                                   </div>
                                   {product.materials?.[1]?.provider && (
                                     <div className={s.goodTextInfo}>
                                       Поставщик ткани (доп): {product.materials?.[1]?.provider}
                                     </div>
                                   )}
                                   {product.materials?.[1]?.name && (
                                     <div className={s.goodTextInfo}>Наименование ткани
                                       (доп): {product.materials?.[1]?.name}</div>
                                   )}
                                   <div className={s.goodTextInfo}>Задняя
                                     спинка: {product.hasBackwall ? 'Да' : 'Нет'}</div>
                                   {product.productSupport.name && (
                                     <div className={s.goodTextInfo}>Опоры: {product.productSupport.name}</div>
                                   )}
                                 </React.Fragment>
                               ))
                             }
                           </div>
                         }>
                  <Button className={s.questionButton}><CircleQuestion/></Button>
                </Popover>
              )
            }
          </div>
        )
      }
    },
    {
      title: '№ договора',
      dataIndex: 'contractNumber',
      width: 120,
      className: 'max-w-[140px]',
      sorter: {
        compare: (a, b) => {
          return +(a.contractNumber) - +(b.contractNumber)
        },
      },
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => (
        <Popover placement="topLeft"
                 content={row.type === ORDER_TYPES.MP ? row.marketplaceOrderId : row.contractNumber || 'Нет договора'}>
          <div
            className="truncate">{row.type === ORDER_TYPES.MP ? row.marketplaceOrderId : row.contractNumber || 'Нет договора'}</div>
        </Popover>
      )
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      width: 150,
      render: (text, row) => (
        <>{dayjs(row.createdAt).format('DD.MM.YYYY')}</>
      )
    },
    {
      title: 'Тип заказа',
      dataIndex: 'type',
      width: 200,
      showSorterTooltip: false,
      sortDirections: ['descend'],
      render: (text, row) => <>
        {row.type === ORDER_TYPES.MP ? row.marketplaceType : OrderTypesCyr[row.type]}
      </>
    },
    {
      title: 'Менеджер',
      dataIndex: 'prepaymentSumm',
      width: 220,
      render: (text, row) => {
        const firstNameInitial = row.responsibleUser.firstName[0] ? `${row.responsibleUser.firstName[0]}. ` : ''
        const middleNameInitial = row.responsibleUser.middleName[0] ? `${row.responsibleUser.middleName[0]}. ` : ''
        return <>
          {`${row.responsibleUser.lastName} ${firstNameInitial}${middleNameInitial}`}
        </>
      }
    },
    {
      title: pageType === 'archive' ? 'Дата готовности' : 'Дата изготовления',
      sorter: {
        compare: (a, b) => +new Date(b.productionDateTo) - +new Date(a.productionDateTo),
      },
      width: 150,
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => {
        const date = row.productOrderStatus.orderStatusIRI === OrderStatusesIRI.READY ? row.productionFinishedAt : row.productionDateTo
        return <>{date ? dayjs(date).format('DD.MM.YYYY') : ''}</>
      }
    },
    {
      title: 'Статус',
      sorter: {
        compare: (a, b) => a.productOrderStatus.ordering - b.productOrderStatus.ordering,
      },
      width: 200,
      render: (text, row) => (
        <Row gutter={16} align="middle">
          <Col>
            <Text style={{
              color: row.productionSubStatus === OrderProductionSubStatus.NEW
                ? ColorForStatus[OrderProductionSubStatus.NEW]
                : row.productionSubStatus === OrderProductionSubStatus.READY
                  ? ColorForStatus[OrderProductionSubStatus.READY]
                  : ColorForStatus[row?.productOrderStatus?.value],
              fontWeight: 500
            }}>
              {
                row.productionSubStatus === OrderProductionSubStatus.NEW
                  ? OrderProductionSubStatusCyr.NEW
                  : row.productionSubStatus === OrderProductionSubStatus.READY
                    ? OrderProductionSubStatusCyr.READY
                    : row?.productOrderStatus?.cyrillicValue
              }
            </Text>
          </Col>
          <Col>
            {
              row.productionSubStatus === OrderProductionSubStatus.NEW && (
                <Button type="default"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation()
                          const goods = row.productOrderItems.filter(i => i.quantity > 1)
                          if (goods.length) {
                            setOrderToSplit(row)
                            setIsModalSplit(true)
                          } else {
                            changeOrderToProduction(row)
                          }
                        }}
                        style={{width: '90px', height: '32px'}}
                >
                  В работу
                </Button>
              )
            }
          </Col>
        </Row>
      )
    }
  ]

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            padding: 10
          },
        },
      }}
    >
      {isLoadingSplit && <LoaderFullDisplay/>}
      {contextHolder}
      <Table<IOrderTable>
        className={s.table}
        columns={columns}
        dataSource={
          ordersData?.map(item => ({...item, key: item.id}))
        }
        pagination={false}
        onRow={onRow}
        loading={loading}
        scroll={{x: 900}}
      />

      <Modal
        open={isModalSplit}
        onOk={() => form.submit()}
        onCancel={() => {
          confirm({
            content: <>Вы действительно хотите отменить разбивку заказа?</>,
            centered: true,
            okText: "Да",
            cancelText: "Нет",
            onOk() {
              setIsModalSplit(false)
            },
          })
        }}
        okText="Сохранить"
        cancelText="Отмена"
        centered
        closable={false}
        className="relative max-h-[80vh] overflow-auto"
        width={604}
        destroyOnClose={true}
      >
        <CloseOutlined
          className="absolute top-[24px] right-[24px] w-[32px] h-[32px] bg-[#F1F3F6] text-xs rounded-full p-[10px]"
          onClick={() => {
            setOrderToSplit(null)
            setIsModalSplit(false)
          }}
        />
        <div className="mb-4 pt-4 font-semibold text-xl text-baseColor">Разбивка заказа №{orderToSplit?.id}</div>
        <div className="mb-3">
          <Row gutter={16} className="mb-3">
            <Col span={8} className="font-medium text-sm text-baseGrey">Товар</Col>
            <Col span={11} className="font-medium text-sm text-baseGrey">Размер</Col>
            <Col span={5} className="font-medium text-sm text-baseGrey">Кол-во, шт.</Col>
          </Row>
          {orderToSplit?.productOrderItems?.map((order, idx) => (
            <React.Fragment key={idx}>
              <Row gutter={16} className="p-[12px_0]">
                <Col span={8} className="gutter-row text-15 text-baseColor">{idx + 1}. {order.productName}</Col>
                <Col span={11} className="gutter-row text-15 text-baseColor">{order.productSizeValue}</Col>
                <Col span={5} className="gutter-row text-15 text-baseColor">{order.quantity}</Col>
              </Row>
              {
                (orderToSplit?.productOrderItems?.length - 1 > idx) && <Divider className="m-0"/>
              }
            </React.Fragment>
          ))}
        </div>
        <div className="mb-[74px]">
          <div className="mb-4 font-semibold text-xl text-baseColor p-[16px_0]">Разбивка</div>
          <Row gutter={16} className="mb-3">
            <Col span={5} className="font-medium text-sm text-baseGrey">№ разбивки</Col>
            <Col span={14} className="font-medium text-sm text-baseGrey">Товар и размер</Col>
            <Col span={5} className="font-medium text-sm text-baseGrey">Кол-во, шт.</Col>
          </Row>
          <Form
            name={'split-goods'}
            form={form}
            onFinish={onFinish}
            layout={'vertical'}
            initialValues={initialValues}
            requiredMark={false}
            preserve={false}
          >
            <Form.List name={'goods'}>
              {(fields, {add, remove}, {errors}) => (
                <>
                  {fields.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <Row gutter={16} className="p-[12px_0]">
                        <Col span={5} className="gutter-row text-15 text-baseColor">
                          <Form.Item name={[item.name, 'code']}
                                     className="mb-0">
                            <Input
                              className="w-full disabled:text-baseColor disabled:border-transparent pointer-events-none"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col span={14} className="gutter-row text-15 text-baseColor">
                          <Form.Item name={[item.name, 'product']} className="mb-0">
                            <SelectCustom
                              options={
                                orderToSplit?.productOrderItems.map((item, idx) => ({
                                  key: item.id,
                                  value: `${idx + 1}.${item.productName} ${item.productSizeValue}`,
                                  label: `${idx + 1}.${item.productName} ${item.productSizeValue}`,
                                  productOrderItemIRI: item.productOrderItemIRI
                                }))
                              }
                              className="!w-full text-[#263B53]"
                              placeholder={'Выберите товар'}
                              onSelect={(value: string | number | LabeledValue, option: any) => {
                                form.setFieldValue(['goods', item.name, 'productOrderItemIRI'], option.productOrderItemIRI)
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4} className="gutter-row text-15 text-baseColor">
                          <Form.Item name={[item.name, 'quantity']} className="mb-0">
                            <Input
                              className="w-full h-10"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (!(/^\d+(\d+)?$/.test(e.target.value))) {
                                  return
                                }
                              }}
                              maxLength={3}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1} className="flex items-center justify-end !p-0">
                          {
                            (fields.length - 1 === idx) && (
                              <Button type={'ghost'}
                                      className="w-[20px] flex items-center"
                                      onClick={() => remove(idx)}
                                      icon={<CloseOutlined/>}/>
                            )
                          }
                        </Col>
                      </Row>
                    </React.Fragment>
                  ))}
                  <Form.Item className="pl-[112px]">
                    <Button
                      type={'ghost'}
                      onClick={() => add({
                        productOrderItemIRI: '',
                        product: '',
                        code: `${orderToSplit?.id}/${fields.length + 1}`,
                        quantity: '',
                      })}
                      icon={<PlusOutlined/>}
                      className="text-baseBlue"
                    >
                      Добавить разбивку
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </div>
      </Modal>
    </ConfigProvider>
  )
}

export default TableProductionOrders