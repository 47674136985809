import React, { FC, useEffect, useRef, useState } from 'react'

import useOnClickOutside from '../../../../../../hooks/useOnClickOutside'
import { Modal, Popover } from 'antd'
import { IOrderTask } from '../../../../../../models/IOrder'
import { IUserList } from '../../../../../../models/IUser'
import { useChangeTaskMutation } from '../../../../../../services/tasks.api'
import { getInitialsFromName } from '../../../../../../helpers/getInitialsFromName'
import { taskStatuses } from '../../../../../../configs/enums/taskStatuses'

import classNames from 'classnames'
import { socket } from '../../../../../../services/socket'
import { useChangeSubProductMutation } from '../../../../../../services/order.api'

import s from './Cell.module.scss'

interface IProps {
  productId: number
  data: IOrderTask
  code: string
  users: IUserList[]
  isEditable: boolean
  futureTasks: {
    [key: string]: string
  }
  role: string
  getUpdatedOrder: (id: number) => void
  orderId: number
}

const Cell: FC<IProps> = ({
                            productId,
                            data,
                            code,
                            users,
                            futureTasks,
                            isEditable,
                            role,
                            getUpdatedOrder,
                            orderId,
                          }) => {
  const [isPopover, setIsPopover] = useState(false)
  const [isList, setIsList] = useState(false)
  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const [newUser, setNewUser] = useState<string | null>(null)
  const [newUserAccessCode, setNewUserAccessCode] = useState<string>('')
  const [coors, setCoors] = useState(0)
  const ref = useRef<any>(null)
  const refList = useRef<any>(null)
  const [changeTask] = useChangeTaskMutation()
  const [changeSubProduct] = useChangeSubProductMutation()

  useOnClickOutside(ref, () => {
    if (!isConfirmModal) {
      setIsList(false)
    }
  })

  useEffect(() => {
    if (ref?.current && isList) {
      const rectEl = ref.current.getBoundingClientRect()
      setCoors(window.innerHeight - rectEl.bottom - 40)
    }
  }, [isList])

  const setOpenConfirmModal = (newUserId: string, accessCode: string) => {
    setNewUser(newUserId)
    setNewUserAccessCode(accessCode)
    // setIsList(false)
    setIsConfirmModal(true)
  }

  const onChangeTask = async () => {
    try {
      if (!data.id || !isEditable) {
        await changeSubProduct({
          id: productId,
          data: {
            future_tasks: {
              ...futureTasks,
              [role]: newUserAccessCode !== '-' ? newUserAccessCode : null,
            }
          }
        })
        return
      }

      const res = await changeTask({
        id: data.id,
        userEntity: newUser ? newUser : null,
        status: taskStatuses.NEW
      })

      if ('data' in res) {
        socket.emit('message', {
          message: {
            update: 'ALL_TASKS'
          }
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsConfirmModal(false)
      setIsList(false)
      getUpdatedOrder(orderId)
    }
  }

  return (
    <Popover open={isPopover}
             placement="bottom"
             content={<div>
               {getInitialsFromName(
                 data.userEntity?.firstName || '',
                 data.userEntity?.lastName || '',
                 data.userEntity?.middleName || ''
               )}
             </div>}>
      <div className={classNames(`h-full flex items-center text-white p-[10px_16px] cursor-pointer relative`, {
        'bg-[#C0C4CA]': data.status === taskStatuses.NEW,
        'bg-[#F0BF2E]': data.status === taskStatuses.IN_PROGRESS,
        'bg-[#63B690]': data.status === taskStatuses.COMPLETED,
        [`${s.arrow}`]: data.status !== taskStatuses.IN_PROGRESS && data.status !== taskStatuses.COMPLETED
      })}
           onClick={() => {
             if (
               data.status === taskStatuses.IN_PROGRESS ||
               data.status === taskStatuses.COMPLETED ||
               !isEditable
             ) {
               return
             }
             if (users.length > 1) {
               setIsPopover(false)
               setIsList(true)
             }
           }}
           onMouseMove={() => {
             if (data.userEntity && !isList) {
               setIsPopover(true)
             }
           }}
           onMouseLeave={() => setIsPopover(false)}
           ref={ref}>
        <div>{
          (data.userEntity && data.userEntity?.accessCode !== '-')
            ? data.userEntity.accessCode
            : futureTasks[role]
        }</div>

        {
          <div
            className={classNames('w-0 opacity-0 transition delay-50 bg-white flex flex-col border-1 border-transparent rounded-[8px] absolute top-full right-0 z-[20] overflow-y-auto shadow-shadowUserList', {
              '!w-[232px] h-auto !top-full !bottom-auto p-1 opacity-100': isList && coors > refList.current.getBoundingClientRect().height,
              '!w-[232px] h-auto !top-auto !bottom-full p-1 opacity-100': isList && coors < refList.current.getBoundingClientRect().height,
            })}
            ref={refList}>
            {
              users.length > 1 && users.map((user, index) => (
                <span key={index}
                      className={s.usersItem}
                      onClick={() => setOpenConfirmModal(user.userIRI, user.accessCode)}>
                {user.accessCode}{user.accessCode !== '-' &&
                  ' - ' + getInitialsFromName(user.firstName, user.lastName, user.middleName)}
              </span>
              ))
            }
          </div>
        }

        <Modal open={isConfirmModal}
               cancelText="Нет"
               okText="Да"
               onOk={onChangeTask}
               onCancel={() => setIsConfirmModal(false)}
               title={
                 `${newUser ? 'Поставить' : 'Убрать'} задачу по заказу № ${code} 
                 у исполнителя ID ${newUser ? newUserAccessCode : data.userEntity?.accessCode || ''}?`
               }
               centered
        />
      </div>
    </Popover>
  )
}

export default Cell