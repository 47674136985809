import React, { useState } from 'react'
import { ConfigProvider, notification, Table, TableProps } from 'antd'
import LoaderFullDisplay from '../LoaderFullDisplay/LoaderFullDisplay'
import { useGetUsersQuery, useLazyGetUserQuery } from '../../services/users.api'
import { IUserList } from '../../models/IUser'
import { UserRoles } from '../../configs/enums/userRoles'
import { getInitialsFromName } from '../../helpers/getInitialsFromName'
import FormUserCreate from '../FormUserCreate/FormUserCreate'
import { useAppSelector } from '../../hooks/redux'
import { selectUser } from '../../store/reducers/user/user.slice'

const TableEmployee = () => {
  const [loading, setLoading] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [userToEdit, setUserToEdit] = useState<IUserList | null>(null)
  const [isShowUser, setIsShowUser] = useState(false)
  const {data: users} = useGetUsersQuery({
    roles: [
      UserRoles.ROLE_TAILOR,
      UserRoles.ROLE_SEAMSTRESS,
      UserRoles.ROLE_CUTTER,
      UserRoles.ROLE_FOAMER,
      UserRoles.ROLE_ASSEMBLER,
      UserRoles.ROLE_UPHOLSTERER,
      UserRoles.ROLE_OTK,
      UserRoles.ROLE_PACKER,
      UserRoles.ROLE_ASSEMBLER_FULL,
    ],
    status: 'active',
    sort: {
      createdAt: 'desc'
    }
  })
  const [getUser] = useLazyGetUserQuery()
  const user = useAppSelector(selectUser)

  const onRow = (record: IUserList) => {
    return {
      onClick: async () => {
        setLoading(true)
        try {
          const res = await getUser(record.id)

          if (res?.data) {
            setUserToEdit(res.data)
            setIsShowUser(true)
          }
          if ('error' in res) {
            api.error({
              message: `Не удалось получить данные пользователя`,
              placement: 'topRight',
              duration: 3
            })
          }
        } catch (e: any) {
          api.error({message: e?.error?.message || 'Ошибка'})
        } finally {
          setLoading(false)
        }
      }
    }
  }

  const columns: TableProps<IUserList>['columns'] = [
    {
      dataIndex: 'id',
      title: '№',
      className: '!text-[#263B53]',
      width: 80,
    },
    {
      dataIndex: 'accessCode',
      title: 'ID',
      className: '!text-[#263B53]',
      width: 280,
    },
    {
      title: 'ФИО',
      className: '!text-[#263B53]',
      width: 280,
      render: (text, row) => <>{getInitialsFromName(row.firstName, row.lastName, row.middleName)}</>
    },
    {
      dataIndex: 'position',
      title: 'Должность',
      className: '!text-[#263B53]',
      width: 280,
      render: (text, row) => <>{row.userRole.name}</>
    },
    {
      dataIndex: 'phone',
      title: 'Телефон',
      className: '!text-[#263B53]',
      width: 280,
    },
  ]

  if (!user.userRole?.userRoleIRI ||
    (
      user.userRole?.userRoleIRI !== UserRoles.ROLE_ADMINISTRATOR &&
      user.userRole?.userRoleIRI !== UserRoles.ROLE_OWNER &&
      user.userRole?.userRoleIRI !== UserRoles.ROLE_PRODUCTION_MANAGER &&
      user.userRole?.userRoleIRI !== UserRoles.ROLE_TECHNOLOGIST &&
      user.userRole?.userRoleIRI !== UserRoles.ROLE_PURCHASER
    )
  ) {
    return null
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            padding: 10,
            fontSize: 16,
            colorText: '#263B53'
          },
        },
      }}
    >
      {contextHolder}
      {loading && <LoaderFullDisplay/>}
      <Table<IUserList>
        columns={columns}
        dataSource={users && users.users.map(item => ({...item, key: item.id}))}
        pagination={false}
        // loading={isLoadingOrders}
        scroll={{x: 900}}
        onRow={onRow}
      />

      {
        isShowUser && <FormUserCreate isOpen={isShowUser}
                                      closeModal={() => {
                                        setIsShowUser(false)
                                        setUserToEdit(null)
                                      }}
                                      api={api}
                                      user={userToEdit}/>
      }
    </ConfigProvider>
  )
}

export default TableEmployee