import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AutoComplete, Col, ConfigProvider, Form, Input, Row } from 'antd'
import { LabeledValue } from 'antd/es/select'
import { useLazyGetFabricsQuery } from '../../../../../../services/fabric.api'
import { getDisabledFieldsFromEditOrder } from '../../../../../../store/reducers/order/order.slice'

import { useAppSelector } from '../../../../../../hooks/redux'
import { ORDER_TYPES } from '../../../../../../configs/enums/orderTypes'

import s from './Materials.module.scss'
import { useChangeOrderMutation } from '../../../../../../services/order.api'
import { useChangeProductStockItemMutation } from '../../../../../../services/product.api'

interface IProps {
  fieldName: number
  sendOnBlurGoods: () => void
  formName: string
}

const Materials: FC<IProps> = ({formName, fieldName, sendOnBlurGoods}) => {
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenSearchProvider, setIsOpenSearchProvider] = useState(false)
  const [isOpenSearchAdditional, setIsOpenSearchAdditional] = useState(false)
  const [isOpenSearchProviderAdditional, setIsOpenSearchProviderAdditional] = useState(false)
  const [isMaterialDisabled, setIsMaterialDisabled] = useState(false)
  const [isProviderDisabled, setIsProviderDisabled] = useState(false)
  const [isAdditionalMaterialDisabled, setIsAdditionalMaterialDisabled] = useState(false)
  const [isAdditionalProviderDisabled, setIsAdditionalProviderDisabled] = useState(false)
  const [isPriceDisabled, setIsPriceDisabled] = useState(false)
  const [isAdditionalPriceDisabled, setIsAdditionalPriceDisabled] = useState(false)
  const disabledFields = useSelector(getDisabledFieldsFromEditOrder)
  const orderToEdit = useAppSelector(state => state.orderSlice.orderToEdit)
  const [getFabrics, {data: fabrics}] = useLazyGetFabricsQuery()
  const [getFabricsProvider, {data: fabricsProvider}] = useLazyGetFabricsQuery()
  const [getFabricsAdditional, {data: fabricsAdditional}] = useLazyGetFabricsQuery()
  const [getFabricsAdditionalProvider, {data: fabricsAdditionalProvider}] = useLazyGetFabricsQuery()
  const form = Form.useFormInstance()
  const nameMaterial = Form.useWatch(['goods', fieldName, 'name'], form)
  const nameProvider = Form.useWatch(['goods', fieldName, 'provider'], form)
  const nameAdditional = Form.useWatch(['goods', fieldName, 'additionalName'], form)
  const providerAdditional = Form.useWatch(['goods', fieldName, 'additionalProvider'], form)
  const orderType = Form.useWatch(`orderType`, form)

  useEffect(() => {
    if (nameMaterial && nameProvider) {
      getFabrics({name: nameMaterial, provider: nameProvider})
      getFabricsProvider({name: nameMaterial, provider: nameProvider})
    }
    if (nameAdditional && providerAdditional) {
      getFabricsAdditional({name: nameAdditional, provider: providerAdditional})
      getFabricsAdditionalProvider({name: nameAdditional, provider: providerAdditional})
    }
  }, [nameMaterial, nameProvider, nameAdditional, providerAdditional])
  useEffect(() => {
    if (formName === 'newOrder') {
      if (orderType === ORDER_TYPES.STOCK) {
        setIsMaterialDisabled(true)
        setIsProviderDisabled(true)
        setIsAdditionalMaterialDisabled(true)
        setIsAdditionalProviderDisabled(true)
      } else {
        setIsMaterialDisabled(false)
        setIsProviderDisabled(false)
        setIsAdditionalMaterialDisabled(false)
        setIsAdditionalProviderDisabled(false)
        setIsPriceDisabled(false)
        setIsAdditionalPriceDisabled(false)
      }
      return
    }
  }, [orderType])

  const handleSearch = (newValue: string) => {
    getFabrics({name: newValue, provider: form.getFieldValue(['goods', fieldName, 'provider']) || ''})
  }
  const handleSearchProvider = (newValue: string) => {
    getFabricsProvider({provider: newValue, name: form.getFieldValue(['goods', fieldName, 'name']) || ''})
  }
  const handleSearchAdditional = (newValue: string) => {
    getFabricsAdditional({
      name: newValue,
      provider: form.getFieldValue(['goods', fieldName, 'additionalProvider']) || ''
    })
  }
  const handleSearchAdditionalProvider = (newValue: string) => {
    getFabricsAdditionalProvider({
      provider: newValue,
      name: form.getFieldValue(['goods', fieldName, 'additionalName']) || ''
    })
  }

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    handleSearch(`${value}`)
    handleSearchProvider(nameProvider || '')
    form.setFieldValue(['goods', fieldName, 'name'], option.key)

    if (orderToEdit?.type !== ORDER_TYPES.FOR_STOCK && nameProvider) {
      form.setFieldValue(['goods', fieldName, 'price'], option.price)
    }
    if (nameProvider !== option.provider) {
      form.setFieldValue(['goods', fieldName, 'provider'], null)
    }
    setIsOpenSearch(false)
  }
  const onSelectProvider = (value: string | number | LabeledValue, option: any) => {
    handleSearch(nameMaterial || '')
    handleSearchProvider(`${value}`)
    form.setFieldValue(['goods', fieldName, 'provider'], option.key)

    if (orderToEdit?.type !== ORDER_TYPES.FOR_STOCK && nameMaterial) {
      form.setFieldValue(['goods', fieldName, 'price'], option.price)
    }
    if (nameMaterial !== option.name) {
      form.setFieldValue(['goods', fieldName, 'name'], null)
    }
    setIsOpenSearchProvider(false)
  }
  const onSelectAdditional = (value: string | number | LabeledValue, option: any) => {
    handleSearchAdditional(`${value}`)
    handleSearchAdditionalProvider(providerAdditional || '')
    form.setFieldValue(['goods', fieldName, 'additionalName'], option.key)

    if (orderToEdit?.type !== ORDER_TYPES.FOR_STOCK && providerAdditional) {
      form.setFieldValue(['goods', fieldName, 'additionalPrice'], option.price)
    }
    if (providerAdditional !== option.provider) {
      form.setFieldValue(['goods', fieldName, 'additionalProvider'], null)
    }
    setIsOpenSearchAdditional(false)
  }
  const onSelectAdditionalProvider = (value: string | number | LabeledValue, option: any) => {
    handleSearchAdditional(nameAdditional || '')
    handleSearchAdditionalProvider(`${value}`)
    form.setFieldValue(['goods', fieldName, 'additionalProvider'], option.key)

    if (orderToEdit?.type !== ORDER_TYPES.FOR_STOCK && nameAdditional) {
      form.setFieldValue(['goods', fieldName, 'additionalPrice'], option.price)
    }
    if (nameAdditional !== option.name) {
      form.setFieldValue(['goods', fieldName, 'additionalName'], null)
    }
    setIsOpenSearchProviderAdditional(false)
  }
  const onChangeMain = (e: any, key: 'name' | 'provider', field: string) => {
    const val = key === 'name' ? nameMaterial : nameProvider
    if (val !== field) {
      form.setFieldValue(['goods', fieldName, key], null)
    }
  }
  const onChangeAdditional = (e: any, key: 'additionalName' | 'additionalProvider', field: string) => {
    const val = key === 'additionalName' ? nameAdditional : providerAdditional
    if (val !== field) {
      form.setFieldValue(['goods', fieldName, key], null)
    }
  }

  return (
    <ConfigProvider theme={{
      token: {
        controlHeight: 44,
        fontSize: 16,
      },
      components: {
        Select: {
          controlHeight: 44,
        }
      },
    }}>
      <div className={s.materials}>
        <Row className={s.headerMaterials} gutter={[12, 12]}>
          <Col span={6}>Материал</Col>
          <Col span={9}>Наименование</Col>
          <Col span={6}>Поставщик</Col>
          {
            orderType !== ORDER_TYPES.FOR_STOCK && (
              <Col span={3}>Цена</Col>
            )
          }
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item name={[fieldName, 'material']}>
              <Input type={'text'}
                     className={s.materialsName}
                     disabled={true}
                     defaultValue={'Основная ткань'}
                     onBlur={sendOnBlurGoods}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name={[fieldName, 'name']}
                       rules={[
                         {required: orderType !== ORDER_TYPES.STOCK, message: 'Обязательное поле'},
                         ({getFieldValue}) => ({
                           async validator(_, value) {
                             const names = await fabrics ? fabrics?.map(item => item.name) : []
                             if ((names && names.includes(value)) || orderType === ORDER_TYPES.STOCK) {
                               return Promise.resolve()
                             } else if (value && names?.length && !names.includes(value)) {
                               return Promise.reject(new Error('Выберите из списка'))
                             } else if (value && names?.length === 0) {
                               return Promise.reject(new Error('Материал не найден'))
                             }
                           },
                         }),
                       ]}>
              <AutoComplete
                open={isOpenSearch}
                style={{fontSize: '20px !important'}}
                placeholder={'Введите название'}
                options={
                  fabrics
                    ? fabrics.map((item) => ({
                      key: item.name,
                      value: item.name,
                      label: item.name,
                      provider: item.provider,
                      price: item.price
                    }))
                    : []
                }
                onSearch={handleSearch}
                onSelect={onSelect}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearch(true)}
                onBlur={() => {
                  setIsOpenSearch(false)
                  sendOnBlurGoods()
                }}
                onChange={(e) => onChangeMain(e, 'provider', nameProvider)}
                disabled={isMaterialDisabled || disabledFields?.materials?.material}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'provider']}
                       rules={[
                         {required: orderType !== ORDER_TYPES.STOCK, message: 'Обязательное поле'},
                         ({getFieldValue}) => ({
                           async validator(_, value) {
                             const names = await fabricsProvider ? fabricsProvider?.map(item => item.provider) : []
                             if ((names && names.includes(value)) || orderType === ORDER_TYPES.STOCK) {
                               return Promise.resolve()
                             } else if (value && names?.length && !names.includes(value)) {
                               return Promise.reject(new Error('Выберите из списка'))
                             } else if (value && names?.length === 0) {
                               return Promise.reject(new Error('Поставщик не найден'))
                             }
                           },
                         }),
                       ]}
            >
              <AutoComplete
                open={isOpenSearchProvider}
                style={{fontSize: '16px !important'}}
                placeholder={'Введите поставщика'}
                options={
                  fabricsProvider
                    ? fabricsProvider
                      .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.provider === obj.provider))
                      .map((item) => ({
                        key: item.provider,
                        value: item.provider,
                        label: item.provider,
                        name: item.name,
                        price: item.price
                      }))
                    : []
                }
                onSearch={handleSearchProvider}
                onSelect={onSelectProvider}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearchProvider(true)}
                onBlur={() => {
                  setIsOpenSearchProvider(false)
                  sendOnBlurGoods()
                }}
                onChange={(e) => onChangeMain(e, 'name', nameMaterial)}
                disabled={isProviderDisabled || disabledFields?.materials?.provider}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            {
              orderType !== ORDER_TYPES.FOR_STOCK && (
                <Form.Item name={[fieldName, 'price']}
                           rules={[{
                             required: orderType !== ORDER_TYPES.STOCK && orderType !== ORDER_TYPES.FOR_STOCK,
                             message: 'Обязательное поле'
                           }]}>
                  <Input type={'number'}
                         size={'large'}
                         maxLength={10}
                         className={s.fieldInput}
                         onBlur={sendOnBlurGoods}
                         disabled={isPriceDisabled || disabledFields?.materials?.price}
                  />
                </Form.Item>
              )
            }
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item name={[fieldName, 'additionalMaterial']}>
              <Input type={'text'}
                     className={s.materialsName}
                     disabled={true}
                     defaultValue={'Дополнительная ткань'}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item name={[fieldName, 'additionalName']}
                       rules={[
                         {required: !!providerAdditional, message: 'Обязательное поле'},
                         ({getFieldValue}) => ({
                           async validator(_, value) {
                             const names = await fabricsAdditional ? fabricsAdditional?.map(item => item.name) : []
                             if (names && names.includes(value)) {
                               return Promise.resolve()
                             } else if (value && names?.length && !names.includes(value)) {
                               return Promise.reject(new Error('Выберите из списка'))
                             } else if (value && names?.length === 0) {
                               return Promise.reject(new Error('Материал не найден'))
                             }
                           },
                         }),
                       ]}
            >
              <AutoComplete
                open={isOpenSearchAdditional}
                style={{fontSize: '16px !important'}}
                placeholder={'Введите название'}
                options={
                  fabricsAdditional
                    ? fabricsAdditional.map((item) => ({
                      key: item.name,
                      value: item.name,
                      label: item.name,
                      provider: item.provider,
                      price: item.price
                    }))
                    : []
                }
                onSearch={handleSearchAdditional}
                onSelect={onSelectAdditional}
                backfill={true}
                defaultValue={''}
                onFocus={() => {
                  setIsOpenSearchAdditional(true)
                  sendOnBlurGoods()
                }}
                onBlur={() => setIsOpenSearchAdditional(false)}
                onChange={(e) => onChangeAdditional(e, 'additionalProvider', providerAdditional)}
                disabled={isAdditionalMaterialDisabled || disabledFields?.materials?.additionalMaterial}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'additionalProvider']}
                       rules={[
                         {required: !!nameAdditional, message: 'Обязательное поле'},
                         ({getFieldValue}) => ({
                           async validator(_, value) {
                             const names = await fabricsAdditionalProvider ? fabricsAdditionalProvider?.map(item => item.provider) : []
                             if (names && names.includes(value)) {
                               return Promise.resolve()
                             } else if (value && names?.length && !names.includes(value)) {
                               return Promise.reject(new Error('Выберите из списка'))
                             } else if (value && names?.length === 0) {
                               return Promise.reject(new Error('Поставщик не найден'))
                             }
                           },
                         }),
                       ]}
            >
              <AutoComplete
                open={isOpenSearchProviderAdditional}
                style={{fontSize: '16px'}}
                placeholder={'Введите поставщика'}
                options={
                  fabricsAdditionalProvider
                    ? fabricsAdditionalProvider
                      .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.provider === obj.provider))
                      .map((item) => ({
                        key: item.provider,
                        value: item.provider,
                        label: item.provider,
                        name: item.name,
                        price: item.price
                      }))
                    : []
                }
                onSearch={handleSearchAdditionalProvider}
                onSelect={onSelectAdditionalProvider}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearchProviderAdditional(true)}
                onBlur={() => {
                  setIsOpenSearchProviderAdditional(false)
                  sendOnBlurGoods()
                }}
                onChange={(e) => onChangeAdditional(e, 'additionalName', nameAdditional)}
                disabled={isAdditionalProviderDisabled || disabledFields?.materials?.additionalProvider}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            {
              orderType !== ORDER_TYPES.FOR_STOCK && (
                <Form.Item name={[fieldName, 'additionalPrice']}>
                  <Input type={'number'}
                         size={'large'}
                         maxLength={10}
                         className={s.fieldInput}
                         onBlur={sendOnBlurGoods}
                         disabled={isAdditionalPriceDisabled || disabledFields?.materials?.additionalPrice}
                  />
                </Form.Item>
              )
            }
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  )
}

export default Materials