import React, { FC } from 'react'
import { useAppDispatch } from '../../../../hooks/redux'
import { setFormOrderCreate } from '../../../../store/reducers/order/order.slice'
import ProductionManagement from '../../../../components/ProductionManagement/ProductionManagement'

interface IProps {
  setIsShow: (value: boolean) => void
}

const ProductionControlPanel: FC<IProps> = ({setIsShow}) => {
  // const {isShowFormCreateOrder} = useAppSelector(state => state.orderSlice)
  const dispatch = useAppDispatch()

  // const setIsShowOrderCreate = (value: boolean) => {
  //   dispatch(setIsShow(value))
  // }
  return (
    <>
      <ProductionManagement setIsShow={setIsShow}/>
    </>
  )
}

export default ProductionControlPanel