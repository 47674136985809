import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth, IResponseWithIRI } from './api'
import { IDocument } from '../models/IDocument'
import { baseUrl } from '../configs/api/api.config'
import { orderApi } from './order.api'

export const documentApi = createApi({
  reducerPath: 'document',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Document'],
  endpoints: (build) => ({
    getDocuments: build.query<IDocument[], { page: number, product?: string }>({
      query: (data) => ({
        url: '/order-documents',
        params: {
          page: data.page,
          product_order: data.product
        }
      }),
      transformResponse: (response: IResponseWithIRI<IDocument>): IDocument[] => {
        return [
          ...response['hydra:member'].map((item: any) => ({
              '@id': item['@id'],
              id: item.id,
              createdAt: item.created_at,
              updatedAt: item.updated_at,
              firmName: item.firm_name,
              code: item.code,
              link: `${baseUrl}${item.link}`,
              name: item.name,
              type: item.type,
              collection: item.collection,
              number: item.number,
              templateType: item.template_type
            })
          )]
      },
      providesTags: result => ['Document'],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(orderApi.util.invalidateTags(['Order']))
        })
      },
    }),
    getDocument: build.query<IDocument, any>({
      query: (id) => `/order-documents/${id}`,
    }),
    createDocument: build.mutation<IDocument[], {
      firm_name?: string,
      document_name: string,
      product_order?: string,
      material_order?: string,
      contract_number?: string | null,
    }>({
      query: (data) => ({
        url: '/order-documents',
        method: 'POST',
        body: data
      }),
      transformResponse: (response: any) => {
        return response.result
      },
      invalidatesTags: ['Document']
    }),
  })
})

export const {
  useLazyGetDocumentsQuery,
  useCreateDocumentMutation
} = documentApi