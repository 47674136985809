import React, { FC, useEffect, useState } from 'react'
import { AutoComplete, Col, ConfigProvider, Form, Input, Row, Typography } from 'antd'

import { LabeledValue } from 'antd/es/select'
import { useGetFabricsQuery, useLazyGetFabricsQuery } from '../../../../../../services/fabric.api'

import { IFabric } from '../../../../../../models/IFabric'
import s from './Materials.module.scss'
import { useDebounce } from '../../../../../../hooks/useDebounce'

const {Text} = Typography

interface IProps {
  fieldName: number
  goodsIndex: number
}

const Materials: FC<IProps> = ({fieldName, goodsIndex}) => {
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isOpenSearchProvider, setIsOpenSearchProvider] = useState(false)
  const [isOpenSearchAdditional, setIsOpenSearchAdditional] = useState(false)
  const [isOpenSearchProviderAdditional, setIsOpenSearchProviderAdditional] = useState(false)
  const [fabricsList, setFabricsList] = useState<IFabric[]>([])
  const [fabricsListAdditional, setFabricsListAdditional] = useState<IFabric[]>([])
  const {data: dataFabrics} = useGetFabricsQuery({})
  const [getFabrics, {data: fabrics, isSuccess: isSuccessMaterials}] = useLazyGetFabricsQuery()
  const [getFabricsProvider, {data: fabricsProvider, isSuccess: isSuccessMaterialsProvider}] = useLazyGetFabricsQuery()
  const [getFabricsAdditional, {
    data: fabricsAdditional,
    isSuccess: isSuccessFabricsAdditional
  }] = useLazyGetFabricsQuery()
  const [getFabricsAdditionalProvider, {
    data: fabricsAdditionalProvider,
    isSuccess: isSuccessFabricsAdditionalProvider
  }] = useLazyGetFabricsQuery()
  const form = Form.useFormInstance()
  const nameMaterial = Form.useWatch(['goods', fieldName, 'name'], form)
  const nameProvider = Form.useWatch(['goods', fieldName, 'provider'], form)
  const nameAdditional = Form.useWatch(['goods', fieldName, 'additionalName'], form)
  const providerAdditional = Form.useWatch(['goods', fieldName, 'additionalProvider'], form)

  // const userName = Form.useWatch('username', form)
  // const { data: options } = useSWR(`/api/user/${userName}`, fetcher)

  useEffect(() => {
    if (fabrics?.length && isSuccessMaterials) {
      setFabricsList(fabrics)
    } else {
      setFabricsList([])
    }
    // eslint-disable-next-line
  }, [fabrics])

  useEffect(() => {
    if (fabricsAdditional?.length && isSuccessFabricsAdditional) {
      setFabricsListAdditional(fabricsAdditional)
    } else {
      setFabricsListAdditional([])
    }
    // eslint-disable-next-line
  }, [fabricsAdditional])

  // useEffect(() => {
  //   getFabrics({
  //     page: 1,
  //     perPage: 50,
  //   })
  // }, [])


  const handleSearch = (newValue: string) => {
    // if (newValue) {
      getFabrics({name: newValue, provider: form.getFieldValue(['goods', fieldName, 'provider']) || ''})
    // }
  }
  const handleSearchProvider = (newValue: string) => {
    // if (newValue) {
      getFabricsProvider({provider: newValue, name: form.getFieldValue(['goods', fieldName, 'name']) || ''})
    // }
  }
  const handleSearchAdditional = (newValue: string) => {
    // if (newValue) {
      getFabricsAdditional({name: newValue, provider: form.getFieldValue(['goods', fieldName, 'additionalProvider']) || ''})
    // }
  }
  const handleSearchAdditionalProvider = (newValue: string) => {
    console.log(form.getFieldValue(['goods', fieldName, 'additionalName']))
    // if (newValue) {
      getFabricsAdditionalProvider({provider: newValue, name: form.getFieldValue(['goods', fieldName, 'additionalName']) || ''})
    // }
  }

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    handleSearch(`${value}`)
    handleSearchProvider(option.provider)
    form.setFieldValue(['goods', fieldName, 'name'], option.key)
    form.setFieldValue(['goods', fieldName, 'price'], option.price)
    if (nameProvider !== option.provider) {
      form.setFieldValue(['goods', fieldName, 'provider'], null)
    }
    setIsOpenSearch(false)
  }
  const onSelectProvider = (value: string | number | LabeledValue, option: any) => {
    handleSearch(option.name)
    handleSearchProvider(`${value}`)
    form.setFieldValue(['goods', fieldName, 'provider'], option.key)
    form.setFieldValue(['goods', fieldName, 'price'], option.price)
    if (nameMaterial !== option.name) {
      form.setFieldValue(['goods', fieldName, 'name'], null)
    }
    setIsOpenSearchProvider(false)
  }
  const onSelectAdditional = (value: string | number | LabeledValue, option: any) => {
    handleSearchAdditional(`${value}`)
    handleSearchAdditionalProvider(option.provider)
    form.setFieldValue(['goods', fieldName, 'additionalName'], option.key)
    form.setFieldValue(['goods', fieldName, 'additionalPrice'], option.price)
    if (providerAdditional !== option.provider) {
      form.setFieldValue(['goods', fieldName, 'additionalProvider'], null)
    }
    setIsOpenSearchAdditional(false)
  }
  const onSelectAdditionalProvider = (value: string | number | LabeledValue, option: any) => {
    handleSearchAdditional(option.name)
    handleSearchAdditionalProvider(`${value}`)
    form.setFieldValue(['goods', fieldName, 'additionalProvider'], option.key)
    form.setFieldValue(['goods', fieldName, 'additionalPrice'], option.price)
    if (nameAdditional !== option.name) {
      form.setFieldValue(['goods', fieldName, 'additionalName'], null)
    }
    setIsOpenSearchProviderAdditional(false)
  }
  const onChangeMain = (e: any, key: 'name' | 'provider', field: string) => {
    const val = key === 'name' ? nameMaterial : nameProvider
    if (val !== field) {
      form.setFieldValue(['goods', fieldName, key], null)
    }
  }
  const onChangeAdditional = (e: any, key: 'additionalName' | 'additionalProvider', field: string) => {
    const val = key === 'additionalName' ? nameAdditional : providerAdditional
    if (val !== field) {
      form.setFieldValue(['goods', fieldName, key], null)
    }
  }

  return (
    <ConfigProvider theme={{
      token: {
        controlHeight: 44,
        fontSize: 16,
      },
      components: {
        Select: {
          controlHeight: 44,
        }
      },
    }}>
      <div className={s.materials}>
        <Row className={s.headerMaterials} gutter={[12, 12]}>
          <Col span={6}>Материал</Col>
          <Col span={12}>Наименование</Col>
          <Col span={6}>Поставщик</Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item name={[fieldName, 'material']}>
              <Input type={'text'}
                     className={s.materialsName}
                     disabled={true}
                     defaultValue={'Основная ткань'}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={[fieldName, 'name']}
                       rules={[
                         {required: true, message: 'Обязательное поле'},
                         ({ getFieldValue }) => ({
                           async validator(_, value) {
                             const names = await fabrics ? fabrics?.map(item => item.name) : []
                             if (names && names.includes(value)) {
                               return Promise.resolve()
                             } else if (value) {
                               return Promise.reject(new Error("Выберите из списка"))
                             }
                           },
                         }),
                       ]}
                       labelAlign={'left'}
            >
              <AutoComplete
                open={isOpenSearch}
                className={s.fieldInputAutocomplete}
                placeholder={'Введите название'}
                style={{fontSize: '20px !important'}}
                options={
                  fabrics
                    ? fabrics.map((item) => ({
                      key: item.name,
                      value: item.name,
                      label: item.name,
                      provider: item.provider,
                      price: item.price
                    }))
                    : []
                }
                onSearch={handleSearch}
                onSelect={(value: string | number | LabeledValue, option: any) => {
                  // form.validateFields([`goods.0.name`])
                  onSelect(value, option)
                }}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearch(true)}
                onBlur={() => {
                  form.validateFields([`${fieldName}.name`])
                  setIsOpenSearch(false)
                }}
                onChange={(e) => onChangeMain(e, 'provider', nameProvider)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'provider']}
                       rules={[
                         {required: true, message: 'Обязательное поле'},
                         ({ getFieldValue }) => ({
                           async validator(_, value) {
                             const names = await fabricsProvider ? fabricsProvider?.map(item => item.provider) : []
                             if (names && names.includes(value)) {
                               return Promise.resolve()
                             } else if (value) {
                               return Promise.reject(new Error("Выберите из списка"))
                             }
                           },
                         }),
                       ]}
                       labelAlign={'left'}
            >
              <AutoComplete
                open={isOpenSearchProvider}
                className={s.fieldInputAutocomplete}
                placeholder={'Введите поставщика'}
                style={{fontSize: '16px !important'}}
                options={
                  fabricsProvider
                    ? fabricsProvider
                      .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.provider === obj.provider))
                      .map((item) => ({
                        key: item.provider,
                        value: item.provider,
                        label: item.provider,
                        name: item.name,
                        price: item.price
                      }))
                    : []
                }
                onSearch={handleSearchProvider}
                onSelect={onSelectProvider}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearchProvider(true)}
                onBlur={() => setIsOpenSearchProvider(false)}
                onChange={(e) => onChangeMain(e, 'name', nameMaterial)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item name={[fieldName, 'additionalMaterial']}>
              <Input type={'text'}
                     className={s.materialsName}
                     disabled={true}
                     defaultValue={'Дополнительная ткань'}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={[fieldName, 'additionalName']}
                       rules={[
                         ({ getFieldValue }) => ({
                           async validator(_, value) {
                             const names = await fabricsAdditional ? fabricsAdditional?.map(item => item.name) : []
                             if (!value || (names && names.includes(value))) {
                               return Promise.resolve()
                             } else if (value) {
                               return Promise.reject(new Error("Выберите из списка"))
                             }
                           },
                         }),
                       ]}
                       labelAlign={'left'}
            >
              <AutoComplete
                open={isOpenSearchAdditional}
                className={s.fieldInputAutocomplete}
                placeholder={'Введите название'}
                style={{fontSize: '16px'}}
                options={
                  fabricsAdditional
                    ? fabricsAdditional.map((item) => ({
                      key: item.name,
                      value: item.name,
                      label: item.name,
                      provider: item.provider,
                      price: item.price
                    }))
                    : []
                }
                onSearch={handleSearchAdditional}
                onSelect={onSelectAdditional}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearchAdditional(true)}
                onBlur={() => setIsOpenSearchAdditional(false)}
                onChange={(e) => onChangeAdditional(e, 'additionalProvider', providerAdditional)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'additionalProvider']}
                       labelAlign={'left'}
                       rules={[
                         ({ getFieldValue }) => ({
                           async validator(_, value) {
                             const names = await fabricsAdditionalProvider ? fabricsAdditionalProvider?.map(item => item.provider) : []
                             if (!value || (names && names.includes(value))) {
                               return Promise.resolve()
                             } else if (value) {
                               return Promise.reject(new Error("Выберите из списка"))
                             }
                           },
                         }),
                       ]}
            >
              <AutoComplete
                open={isOpenSearchProviderAdditional}
                className={s.fieldInputAutocomplete}
                placeholder={'Введите поставщика'}
                style={{fontSize: '16px !important'}}
                options={
                  fabricsAdditionalProvider
                    ? fabricsAdditionalProvider
                      .filter((obj, idx, arr) => idx === arr.findIndex((t) => t.provider === obj.provider))
                      .map((item) => ({
                        key: item.provider,
                        value: item.provider,
                        label: item.provider,
                        name: item.name,
                        price: item.price
                      }))
                    : []
                }
                onSearch={handleSearchAdditionalProvider}
                onSelect={onSelectAdditionalProvider}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearchProviderAdditional(true)}
                onBlur={() => setIsOpenSearchProviderAdditional(false)}
                onChange={(e) => onChangeAdditional(e, 'additionalName', nameAdditional)}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  )
}

export default Materials