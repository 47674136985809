import React, { FC } from 'react'
import { IconProps } from './icons.types'

const Circle: FC<IconProps> = ({
                                 width = 20,
                                 height = 20,
                                 fill = '#B66363',
                               }) => {
  return (
    <svg width={width}
         height={height}
         viewBox="0 0 20 20"
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill={fill}/>
    </svg>
  )
}

export default Circle