import React, { FC, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  theme,
  Typography
} from 'antd'
import { CloseOutlined, PlusCircleFilled, TagsFilled } from '@ant-design/icons'
import { IProduct } from '../../../../models/IProduct'
import { useGetProductsQuery, useLazyGetProductsQuery } from '../../../../services/product.api'
import { LabeledValue } from 'antd/es/select'
import Basket from '../../../../ui/icons/Basket'
import GoodSize from './components/GoodSize/GoodSize'
import Materials from './components/Materials/Materials'
import Appliances from './components/Appliances/Appliances'
import { configDatePicker } from '../../../../configs/fieldRules'
import Calendar from '../../../../ui/icons/Calendar'

import s from './FormOrderToWarehouse.module.scss'
import { OrderProductionSubStatus, OrderStatusesIRI } from '../../../../configs/enums/orderStatuses'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { useCreateOrderMutation, useGetOrderNameMutation } from '../../../../services/order.api'
import { useAppSelector } from '../../../../hooks/redux'
import { selectWorkpoint } from '../../../../store/reducers/user/user.slice'
import { ORDER_TYPES } from '../../../../configs/enums/orderTypes'

const {useToken} = theme
const {Text} = Typography
const {Panel} = Collapse

interface IProps {
  closeForm: () => void
}

const FormOrderToWarehouse: FC<IProps> = ({closeForm}) => {
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [productsList, setProductsList] = useState<IProduct[]>([])
  const [saveForm, setSaveForm] = useState(false)
  const [awaitingCreateOrder, setAwaitingCreateOrder] = useState(false)
  const [getOrderName, {data: orderName}] = useGetOrderNameMutation()
  const workpoint = useAppSelector(selectWorkpoint)

  const {data: dataProducts} = useGetProductsQuery({})
  const [
    getProducts, {data: products, isSuccess: isSuccessProducts}
  ] = useLazyGetProductsQuery()
  const [createOrder] = useCreateOrderMutation()

  const {token} = useToken()
  const [api, contextHolder] = notification.useNotification()
  const [form] = Form.useForm()

  let initialValues = {
    goods: [{
      productId: null,
      productIRI: '',
      productName: '',
      quantity: 1,
      backwall: '',
      size: '',
      productSize: '',
      checkboxCustomSize: false,
      materialId: null,
      materialAdditionalId: null,
      name: '',
      provider: '',
      price: '',
      additionalName: '',
      additionalProvider: '',
      additionalPrice: '',
      productSupport: '',
      note: '',
    }],
  }

  useEffect(() => {
    if (workpoint) {
      getOrderName({prefix: workpoint.shortName})
    }
  }, [workpoint])

  useEffect(() => {
    if (products?.length && isSuccessProducts) {
      setProductsList(products)
    } else {
      setProductsList([])
    }
    // eslint-disable-next-line
  }, [products])

  const addGood = (addFn: ({}: {
    productId: number | null,
    productIRI: string,
    productName: string,
    quantity: number,
    backwall: string,
  }) => void, fieldsLength: number) => {
    if (fieldsLength < 15) {
      addFn({
          productId: null,
          productIRI: '',
          productName: '',
          quantity: 1,
          backwall: '',
        }
      )
    }
  }
  const onSelect = (value: string | number | LabeledValue, option: any, goodsIndex: number) => {
    form.setFieldValue(['goods', goodsIndex, 'productIRI'], option.key)
    setIsOpenSearch(false)
  }
  const onFocusProduct = () => {
    setIsOpenSearch(true)
  }
  const onBlurProduct = () => {
    setIsOpenSearch(false)
  }
  const onChangeProductName = (e: any, goodsIndex: number) => {
    form.setFieldValue(['goods', goodsIndex, 'productIRI'], null)
  }

  const onSave = () => {
    form.validateFields()
      .then((values) => {
        setSaveForm(true)
      })
      .catch((errorInfo) => {
        api.error({
          message: 'Заполните все поля',
          placement: 'topRight',
          duration: 3
        })
      })
  }

  const handleSearch = (newValue: string) => {
    if (newValue) {
      getProducts({name: newValue, type: 'main', status: 'in_stock'})
    }
  }

  const handleFormSubmit = () => {
    setSaveForm(false)
    form.submit()
  }

  const onFinish = async (fieldsValue: any) => {
    setAwaitingCreateOrder(true)

    const data: any = {
      'code_prefix': workpoint['shortName'],
      'code_number': orderName?.counter,
      'type': ORDER_TYPES.FOR_STOCK,
      'product_order_status': OrderStatusesIRI.PRODUCTION,
      'production_sub_status': OrderProductionSubStatus.NEW,
      'production_date_from': fieldsValue.productionDates.format('YYYY-MM-DD'),
      'production_date_to': fieldsValue.productionDates.format('YYYY-MM-DD'),
      'workpoint': workpoint['@id'],
      'product_order_items': [
        ...fieldsValue?.goods?.map((item: any) => ({
          'product': item?.productIRI || null,
          'product_name': item.productName,
          'quantity': +item.quantity,
          'product_support': item.productSupport || null,
          'product_size': item.productSizeIRI || null,
          'product_size_value': item.productSize,
          'has_backwall': Boolean(item.backwall),
          'note': item.note || '',
          'materials': [
            {
              'material': 'Основная ткань',
              'name': item.name,
              'provider': item.provider,
              'price': item.price || '0'
            },
            {
              'material': 'Дополнительная ткань',
              'name': item.additionalName || '',
              'provider': item.additionalProvider || '',
              'price': item.additionalPrice || '0'
            }
          ],
        }))
      ],
    }

    try {
      const createOrderData = await createOrder(data)

      // @ts-ignore
      if (createOrderData?.data) {
        api.success({
          message: `Заказ успешно создан`,
          placement: 'topRight',
          duration: 3
        })
        form.resetFields()
        closeForm()
      } else {
        api.error({
          message: 'Заказ не создан',
          placement: 'topRight',
          duration: 3
        })
      }
    } catch (e: any) {
      console.log(e)
      api.error({
        message: e?.message,
        // @ts-ignore
        description: e?.error?.message,
        placement: 'topRight',
        duration: 3
      })
    } finally {
      setAwaitingCreateOrder(false)
    }
  }

  return (
    <ConfigProvider theme={{
      token: {
        colorText: token.colorTextLabel,
      },
      components: {
        Collapse: {
          borderRadiusLG: 0
        }
      }
    }}>
      {awaitingCreateOrder && <LoaderFullDisplay/>}
      {contextHolder}

      <Form
        name={'toWarehouse'}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        initialValues={initialValues}
        // disabled={isOrderEdit && !hasRights}
        requiredMark={false}
        autoComplete="off"
      >
        <Row gutter={20} justify={'space-between'} className={s.orderHeader}>
          <Col>
            <Text className={s.orderName}>Заказ на склад</Text>
          </Col>
          <Col>
            <Button className={s.btnClose}
                    onClick={() => closeForm()}
                    icon={<CloseOutlined/>}
                    disabled={false}
            />
          </Col>
        </Row>

        <Form.List name={'goods'}>
          {(fields, {add, remove}, {errors}) => (
            <>
              <Row className={s.headerGoods} justify={'space-between'}>
                <Col>
                  <Space className={s.addGood} size={8} align={'center'}>
                    Товары
                    <Button shape="circle"
                            className={s.addGoodBtn}
                            icon={<PlusCircleFilled className={s.addBtnIcon}/>}
                            onClick={() => addGood(add, fields.length)}
                    />
                  </Space>
                </Col>
                <Col>
                  <Text className={s.typeOrder}>
                    Тип заказа: <span>На склад</span>
                  </Text>
                </Col>
              </Row>
              <Collapse
                defaultActiveKey={['0']}
                className={s.collapse}
                accordion={true}
                destroyInactivePanel={true}
              >
                {fields.map((field, index) => (
                  <Panel header={
                    <>
                      <Row className={s.rowHeaderGoodPanel} align={'middle'}>
                        <Col span={23}>
                          <Space size={24}>
                            <TagsFilled className={s.goodIcon}/>
                            <Space direction={'vertical'}>
                              <Typography className={s.productName}>
                                {form.getFieldValue(['goods', field.name, 'productName']) || 'Товар'}
                              </Typography>
                            </Space>
                          </Space>
                        </Col>
                        {
                          fields.length >= 2 &&
                          <Col span={1}>
                            {
                              <Button shape="circle"
                                      className={s.deleteGoodBtn}
                                      icon={<Basket/>}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        remove(index)
                                      }}
                              />
                            }
                          </Col>
                        }
                      </Row>
                    </>}
                         key={field.name}
                         className={s.panel}
                         showArrow={false}
                    // forceRender={isEditable}
                  >
                    <Row gutter={[12, 12]} style={{width: '100%', padding: '13px 16px 16px'}}>
                      <Col flex={'0 0 286px'}>
                        <ConfigProvider theme={{
                          token: {
                            controlHeight: 15,
                          },
                          components: {
                            Select: {
                              controlHeight: 44
                            },
                          },
                        }}>
                          <Form.Item name={[field.name, 'productName']}
                                     rules={[{required: true, message: 'Обязательное поле'}]}
                                     label={<>Наименование товара<Text type={'danger'}>*</Text></>}
                                     labelAlign={'left'}
                          >
                            <AutoComplete
                              open={isOpenSearch}
                              className={s.input}
                              placeholder={'Выберите товар'}
                              options={
                                productsList
                                  ? productsList.map((item) => ({
                                    key: item.productIRI,
                                    value: item.name,
                                    label: item.name
                                  }))
                                  : dataProducts && dataProducts.map((item) => ({
                                  key: item.productIRI,
                                  value: item.name,
                                  label: item.name
                                }))
                              }
                              onSearch={handleSearch}
                              onSelect={(value: string | number | LabeledValue, option: any) => {
                                onSelect(value, option, index)
                              }}
                              backfill={true}
                              defaultValue={''}
                              onFocus={onFocusProduct}
                              onBlur={onBlurProduct}
                              onChange={(e) => onChangeProductName(e, index)}
                            />
                          </Form.Item>
                        </ConfigProvider>
                      </Col>
                      <Col flex={'95px'}>
                        <Form.Item name={[field.name, 'quantity']}
                                   style={{marginBottom: '12px'}}
                                   label={'Кол-во'}
                                   rules={[{required: true, message: 'Введите кол-во'}]}
                        >
                          <InputNumber min={1} max={99999}
                                       size={'large'}
                                       className={s.input}
                                       style={{width: '100%', height: '44px'}}
                                       maxLength={5}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <GoodSize fieldName={field.name}/>
                    <Materials fieldName={field.name} goodsIndex={index}/>
                    <Appliances fieldName={field.name}/>
                    <div className={s.note}>
                      <Row className={s.headerNote}>Примечание</Row>
                      <Row>
                        <Col span={24}>
                          <Form.Item name={[field.name, 'note']}>
                            <Input type={'text'} className={s.fieldInput}/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </>
          )}
        </Form.List>

        <Row className={s.datesOrder}>
          <Col span={8}>
            <Form.Item name={'productionDates'}
                       label={<>Дата изготовления<Text type={'danger'}
                                                       style={{fontSize: '16px'}}>*</Text></>}
                       labelAlign={'left'}
                       {...configDatePicker}
            >
              <DatePicker
                placeholder={'Выберите дату'}
                format="DD.MM.YYYY"
                allowClear={true}
                // onChange={onCalendarChangeProduction}
                suffixIcon={<Calendar/>}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row className={s.buttons}>
          <Space size={24}>
            <Button type={'primary'} onClick={onSave} className={s.btnSave}>Сохранить заказ</Button>
          </Space>
        </Row>

        <Modal
          open={saveForm}
          onOk={handleFormSubmit}
          onCancel={() => setSaveForm(false)}
          okText="Да"
          cancelText="Нет"
          centered
          closable={false}
        >
          Вы действительно хотите сохранить заказ?
        </Modal>
      </Form>
    </ConfigProvider>
  )
}

export default FormOrderToWarehouse