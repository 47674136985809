import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row, Typography } from 'antd'
import s from './Appliances.module.scss'
import { useGetProductsSupportsQuery } from '../../../../../../services/productSupports.api'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { OrderProductionSubStatus, OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'
import { ORDER_TYPES } from '../../../../../../configs/enums/orderTypes'
import { useSelector } from 'react-redux'
import { getDisabledFieldsFromEditOrder } from '../../../../../../store/reducers/order/order.slice'

const {Text} = Typography

interface IProps {
  fieldName: number
  sendOnBlurGoods: () => void
  orderStatus?: OrderStatusesIRI | null
  orderSubStatus?: OrderProductionSubStatus | null
  formName: string
}

const Appliances: FC<IProps> = ({formName, fieldName, sendOnBlurGoods, orderStatus = null, orderSubStatus = null}) => {
  const [isDisableFromOrderType, setIsDisableFromOrderType] = useState(false)
  const disabledFields = useSelector(getDisabledFieldsFromEditOrder)
  const {data} = useGetProductsSupportsQuery()
  const form = Form.useFormInstance()
  const orderType = Form.useWatch(`orderType`, form)

  useEffect(() => {
    if (formName === 'newOrder') {
      if (orderType === ORDER_TYPES.STOCK) {
        setIsDisableFromOrderType(true)
      } else {
        setIsDisableFromOrderType(false)
      }
      return
    }
    if (
      orderStatus === OrderStatusesIRI.NEW ||
      orderStatus === OrderStatusesIRI.WAITING_FOR_PAYMENT ||
      orderStatus === OrderStatusesIRI.IN_PROGRESS
    ) {
      if (orderType === ORDER_TYPES.STOCK) {
        setIsDisableFromOrderType(true)
      } else {
        setIsDisableFromOrderType(disabledFields?.backwall || false)
      }
    } else if (orderStatus === OrderStatusesIRI.PRODUCTION &&
      orderSubStatus === OrderProductionSubStatus.NEW &&
      orderType === ORDER_TYPES.FOR_STOCK) {
      setIsDisableFromOrderType(disabledFields?.backwall || false)
    } else {
      setIsDisableFromOrderType(disabledFields?.backwall || true)
    }
  }, [orderType])

  return (
    <div className={s.appliances}>
      <Row className={s.headerAppliances} gutter={[12, 12]}>
        <Col span={6}>Комплектующие</Col>
        <Col span={18}>Наименование</Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item>
            <Text className={s.appliancesName}>Задняя спинка</Text>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name={[fieldName, 'backwall']}>
            <SelectCustom
              allowClear
              options={[
                {value: 'true', label: 'Да'},
                {value: '', label: 'Нет'},
              ]}
              onBlur={sendOnBlurGoods}
              disabled={isDisableFromOrderType || disabledFields?.backwall}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item>
            <Text className={s.appliancesName}>Опоры</Text>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name={[fieldName, 'productSupport']} initialValue={''}>
            <SelectCustom
              allowClear
              options={data ? data?.map(item => ({id: item['@id'], value: item['@id'], label: item.name})) : []}
              onBlur={sendOnBlurGoods}
              disabled={isDisableFromOrderType || disabledFields?.productSupport}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default Appliances