import React, { createRef, FC, useEffect, useRef } from 'react'
import { Layout } from 'antd'
import HeaderApp from '../HeaderApp/HeaderApp'
import LeftMenu from '../../modules/LeftMenu'
import { Outlet } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/redux'

export const layoutContentElRef = createRef<HTMLDivElement>()

const LayoutCustom: FC = () => {
  return (
    <div className="h-full app" id="scrolling" ref={layoutContentElRef}>
      <Layout className="layout">
        <HeaderApp/>
        <LeftMenu/>
        <Outlet/>
      </Layout>
    </div>
  )
}

export default LayoutCustom