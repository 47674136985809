export enum UserCodes {
  ROLE_CUTTER = 'ROLE_CUTTER', // Распиловщик
  ROLE_TAILOR = 'ROLE_TAILOR', // Раскройщик
  ROLE_SEAMSTRESS = 'ROLE_SEAMSTRESS', // Швея
  ROLE_FOAMER = 'ROLE_FOAMER', // Поролонщик
  ROLE_ASSEMBLER = 'ROLE_ASSEMBLER', // Сборщик
  ROLE_UPHOLSTERER = 'ROLE_UPHOLSTERER', // Обивщик
  ROLE_ASSEMBLER_FULL = 'ROLE_ASSEMBLER_FULL', // Сборщик гот.
  ROLE_QC = 'ROLE_QC', // ОТК
  ROLE_PACKER = 'ROLE_PACKER', // Упаковщик
}