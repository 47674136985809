import { apiSlice } from './api'
import { IExternalContracts } from '../models/IExternalContracts'

export const externalContractApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getContract: build.query<IExternalContracts, { id: number }>({
      query: ({id}) => `/external-contracts/${id}`,
      transformResponse: (response: any): IExternalContracts => {
        return ({
          contractIRI: response['hydra:member']['@id'],
          id: response['hydra:member'].id,
          juriName: response['hydra:member'].juri_name,
          number: response['hydra:member'].number,
          firm: response['hydra:member'].firm
        })
      }
    }),
    getExternalContracts: build.query<IExternalContracts[], void>({
      query: () => ({
        url: '/external-contracts',
      }),
      transformResponse: (response: any): IExternalContracts[] => {
        return response['hydra:member'].map((contract: any) => ({
          contractIRI: contract['@id'],
          id: contract.id,
          juriName: contract.juri_name,
          number: contract.number,
          firm: contract.firm
        }))
      }
    }),
  }),
})

export const {
  useGetContractQuery,
  useGetExternalContractsQuery,
  useLazyGetExternalContractsQuery,
} = externalContractApi

