import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth, IResponseWithIRI } from './api'
import { IDocument } from '../models/IDocument'
import { IFabric } from '../models/IFabric'

export const fabricApi = createApi({
  reducerPath: 'fabric',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Fabric'],
  endpoints: (build) => ({
    getFabrics: build.query<
      IFabric[],
      {
        page?: number
        perPage?: number
        material?: string
        name?: string
        provider?: string
        quantity?: string
        sort?: {
          quantity?: 'asc' | 'desc',
          name?: 'asc' | 'desc',
        }
      }>({
      query: (params) => ({
        url: '/fabrics',
        params: {
          page: params.page,
          per_page: params.perPage,
          material: params.material,
          name: params.name,
          provider: params.provider,
          quantity: params.quantity,
          'sort[quantity]': params?.sort?.quantity,
          'sort[name]': params?.sort?.name,
        }
      }),
      transformResponse: (response: IResponseWithIRI<IFabric>): IFabric[] => {
        return [
          ...response['hydra:member'].map((item: any) => ({
              fabricIRI: item['@id'],
              id: item.id,
              name: item.name,
              price: item.price,
              provider: item.provider,
              color: item.color,
              quantity: item.quantity,
              createdAt: item.created_at,
              updatedAt: item.updated_at
            })
          )]
      },
      providesTags: result => ['Fabric']
    }),
    getFabric: build.query<IDocument, any>({
      query: (id) => `/fabrics/${id}`,
      providesTags: result => ['Fabric']
    }),
    changeFabric: build.mutation<IDocument, { id: number, data: any }>({
      query: ({id, data}) => ({
        url: `/fabrics/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, {id}) => [{type: 'Fabric', id}]
    })
  })
})

export const {
  useLazyGetFabricQuery,
  useLazyGetFabricsQuery,
  useGetFabricsQuery,
  useChangeFabricMutation
} = fabricApi