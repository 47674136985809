import React, { Suspense } from 'react'
import Section from '../../components/Section/Section'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TabsProps } from 'antd'
import { RouteNames } from '../../configs/routes/routesNames'
import PageHeader from '../../components/PageHeader/PageHeader'
import PageManagement from '../../components/PageManagement/PageManagement'
import EmployeeManagementWithCreate from '../../components/EmployeeCreate/EmployeeCreate'
import { UserRoles } from '../../configs/enums/userRoles'
import { useAppSelector } from '../../hooks/redux'
import { selectUser } from '../../store/reducers/user/user.slice'
import { useGetPagesQuery } from '../../services/pages.api'

const EmployeeDashboard = () => {
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const {pathname} = useLocation()
  const {data} = useGetPagesQuery()

  const itemsOwner: TabsProps['items'] = [
    {
      key: RouteNames.EMPLOYEE_DASHBOARD,
      label: 'Исполнители',
      children: <EmployeeManagementWithCreate/>
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_ASSORTMENT_AND_PRICES}`,
      label: 'Ассортимент и цены',
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_MATERIALS}`,
      label: 'Ткань',
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_PIECEWORK_PAYMENT}`,
      label: 'Сдельная оплата',
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.MP}`,
      label: 'Маркетплейсы',
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.STORE}`,
      label: 'Склад',
    },
  ]
  const itemsFull: TabsProps['items'] = [
    {
      key: RouteNames.EMPLOYEE_DASHBOARD,
      label: 'Исполнители',
      children: <EmployeeManagementWithCreate/>
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_ASSORTMENT_AND_PRICES}`,
      label: 'Ассортимент и цены',
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_MATERIALS}`,
      label: 'Ткань',
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_PIECEWORK_PAYMENT}`,
      label: 'Сдельная оплата',
    },
  ]
  const itemsTechnologist: TabsProps['items'] = [
    {
      key: RouteNames.EMPLOYEE_DASHBOARD,
      label: 'Исполнители',
      children: <EmployeeManagementWithCreate/>
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_MATERIALS}`,
      label: 'Ткань',
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_PIECEWORK_PAYMENT}`,
      label: 'Сдельная оплата',
    },
  ]
  const itemsManager: TabsProps['items'] = [
    {
      key: RouteNames.EMPLOYEE_DASHBOARD,
      label: 'Исполнители',
      children: <EmployeeManagementWithCreate/>
    },
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_PIECEWORK_PAYMENT}`,
      label: 'Сдельная оплата',
    },
  ]
  const itemsROP: TabsProps['items'] = [
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_ASSORTMENT_AND_PRICES}`,
      label: 'Ассортимент и цены',
    }
  ]
  const itemsPurchaser: TabsProps['items'] = [
    {
      key: `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_MATERIALS}`,
      label: 'Ткань',
    }
  ]

  const onChangeTab = (activeKey: string) => {
    if (activeKey === RouteNames.EMPLOYEE_DASHBOARD) {
      navigate(RouteNames.EMPLOYEE_DASHBOARD)
    } else if (activeKey === `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_ASSORTMENT_AND_PRICES}`) {
      navigate(RouteNames.EMPLOYEE_DASHBOARD_ASSORTMENT_AND_PRICES)
    } else if (activeKey === `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_MATERIALS}`) {
      navigate(RouteNames.EMPLOYEE_DASHBOARD_MATERIALS)
    } else if (activeKey === `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_PIECEWORK_PAYMENT}`) {
      navigate(RouteNames.EMPLOYEE_DASHBOARD_PIECEWORK_PAYMENT)
    } else if (activeKey === `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.MP}`) {
      navigate(RouteNames.MP)
    } else if (activeKey === `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.STORE}`) {
      navigate(RouteNames.STORE)
    }
  }

  if (!user.userRole?.userRoleIRI) {
    return null
  }

  const getTabs = (role: string) => {
    switch (role) {
      case UserRoles.ROLE_OWNER:
        return itemsOwner
      case UserRoles.ROLE_ADMINISTRATOR:
        return itemsFull
      case UserRoles.ROLE_PRODUCTION_MANAGER:
        return itemsManager
      case UserRoles.ROLE_SALES_HEAD:
        return itemsROP
      case UserRoles.ROLE_TECHNOLOGIST:
        return itemsTechnologist
      case UserRoles.ROLE_PURCHASER:
        return itemsPurchaser
      default:
        return []
    }
  }

  const getDefaultKey = (role: string) => {
    switch (role) {
      case UserRoles.ROLE_SALES_HEAD:
        return `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_ASSORTMENT_AND_PRICES}`
      case UserRoles.ROLE_PURCHASER:
        return `${RouteNames.EMPLOYEE_DASHBOARD}/${RouteNames.EMPLOYEE_DASHBOARD_MATERIALS}`
      default:
        return undefined
    }
  }

  if (!data || data.find(item => '/' + pathname.split('/')[1] === item.url) === undefined) {
    return null
  }

  return (
    <Section>
      <Suspense fallback={<div>Загрузка....</div>}>
        <PageHeader title={'Панель управления'}/>
        <PageManagement items={getTabs(user.userRole?.userRoleIRI)}
                        onChangeTab={onChangeTab}
                        defaultActiveKey={getDefaultKey(user.userRole?.userRoleIRI)}
        />
        <Outlet/>
      </Suspense>
    </Section>
  )
}

export default EmployeeDashboard