import React, { Suspense } from 'react'
import Section from '../../components/Section/Section'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { TabsProps } from 'antd'
import { RouteNames } from '../../configs/routes/routesNames'
import PageHeader from '../../components/PageHeader/PageHeader'
import PageManagement from '../../components/PageManagement/PageManagement'
import { useGetPagesQuery } from '../../services/pages.api'

const ProductionPlan = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const {data} = useGetPagesQuery()

  const items: TabsProps['items'] = [
    {
      key: RouteNames.PRODUCTION_PLAN,
      label: 'В производстве',
    },
    {
      key: `${RouteNames.PRODUCTION_PLAN}/${RouteNames.PRODUCTION_PLAN_ARCHIVE}`,
      label: 'Архив',
    },
  ]

  const onChangeTab = (activeKey: string) => {
    if (activeKey === RouteNames.PRODUCTION_PLAN) {
      navigate(RouteNames.PRODUCTION_PLAN)
    } else if (activeKey === `${RouteNames.PRODUCTION_PLAN}/${RouteNames.PRODUCTION_PLAN_ARCHIVE}`) {
      navigate(RouteNames.PRODUCTION_PLAN_ARCHIVE)
    }
  }

  if (!data || data.find(item => '/' + pathname.split('/')[1] === item.url) === undefined) {
    return null
  }

  return (
    <Section>
      <Suspense fallback={<div>Загрузка....</div>}>
        <PageHeader title={'Модуль плана производства'}/>
        <PageManagement items={items} onChangeTab={onChangeTab}/>
        <Outlet/>
      </Suspense>
    </Section>
  )
}

export default ProductionPlan