import React, { FC, useState } from 'react'
import { Col, Form, Row, Typography } from 'antd'
import { useGetProductsSupportsQuery } from '../../../../../../services/productSupports.api'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'

const {Text} = Typography

interface IProps {
  sendOnBlurGoods: () => void
  formName: string
}

const Appliances: FC<IProps> = ({formName, sendOnBlurGoods}) => {
  const {data} = useGetProductsSupportsQuery()
  const form = Form.useFormInstance()

  return (
    <div className="p-[16px]">
      <Row className="mb-3 font-medium text-sm leading-[17px] text-baseGrey" gutter={[12, 12]}>
        <Col span={6}>Комплектующие</Col>
        <Col span={18}>Наименование</Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item>
            <Text
              className="w-[210px] h-[44px] block font-regular text-base leading-[22px] text-baseColor p-[10px_12px] bg-[#F1F3F6] border border-1 border-[#F1F3F6] rounded-[8px]">
              Задняя спинка
            </Text>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name={['backwall']}>
            <SelectCustom
              allowClear
              options={[
                {value: 'true', label: 'Да'},
                {value: '', label: 'Нет'},
              ]}
              onBlur={sendOnBlurGoods}
              disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item>
            <Text
              className="w-[210px] h-[44px] block font-regular text-base leading-[22px] text-baseColor p-[10px_12px] bg-[#F1F3F6] border border-1 border-[#F1F3F6] rounded-[8px]">
              Опоры
            </Text>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name={['productSupport']} initialValue={''}>
            <SelectCustom
              allowClear
              options={data ? data?.map(item => ({id: item['@id'], value: item['@id'], label: item.name})) : []}
              onBlur={sendOnBlurGoods}
              disabled={form.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default Appliances