import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppDispatch } from '../../hooks/redux'
import { IOrderStatus } from '../../models/IOrder'
import { optionNameCompany } from '../../configs/documents/optionNameCompany'
import { useGetProductOrderStatusesQuery } from '../../services/order.api'
import { resetFilters, setFilters } from '../../store/reducers/order/order.slice'
import { RouteNames } from '../../configs/routes/routesNames'
import { OrderStatusesIRI } from '../../configs/enums/orderStatuses'

import { Col, DatePicker, Input, Row, theme } from 'antd'
import SelectCustom from '../../ui/SelectCustom/SelectCustom'
import Calendar from '../../ui/icons/Calendar'

import { CaretDownOutlined, CloseOutlined, FilterOutlined } from '@ant-design/icons'

import locale from 'antd/es/date-picker/locale/ru_RU'

import s from './Filters.module.scss'

const {useToken} = theme
const {RangePicker} = DatePicker

const Filters: FC<{ hideData?: boolean }> = ({hideData = false}) => {
  const {data: orderStatuses} = useGetProductOrderStatusesQuery()
  const [orderStatusesCheck, setOrderStatusesCheck] = useState<IOrderStatus[]>()
  const [lastNameSearched, setLastNameSearched] = useState({
    contractNumber: '',
    lastName: ''
  })
  const {token} = useToken()
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetFilters())
    }
  }, [])

  useEffect(() => {
    if (location.pathname === RouteNames.ORDERS_ARCHIVE && orderStatuses?.length) {
      setOrderStatusesCheck(orderStatuses
        .filter(item => (
          item.orderStatusIRI === OrderStatusesIRI.LOADED ||
          item.orderStatusIRI === OrderStatusesIRI.CANCEL
        )))
    } else if (location.pathname === RouteNames.ORDERS && orderStatuses?.length) {
      setOrderStatusesCheck(orderStatuses
        .filter(item => (
          item.orderStatusIRI === OrderStatusesIRI.NEW ||
          item.orderStatusIRI === OrderStatusesIRI.IN_PROGRESS ||
          item.orderStatusIRI === OrderStatusesIRI.PRODUCTION ||
          item.orderStatusIRI === OrderStatusesIRI.WAITING_FOR_LOADING
        )))
    }
  }, [location, orderStatuses])

  // Изменение фильтров для поиска заказов
  const onBlurInput = (e: React.ChangeEvent<HTMLInputElement>, key: 'contractNumber' | 'lastName') => {
    if (lastNameSearched[key] !== e.target.value) {
      dispatch(setFilters({[key]: e.target.value}))
      setLastNameSearched(state => ({...state, [key]: e.target.value}))
    }
  }
  const onPressEnter = (e: any, key: 'contractNumber' | 'lastName') => {
    if (lastNameSearched[key] !== e.target.value) {
      dispatch(setFilters({[key]: e.target.value}))
      setLastNameSearched(state => ({...state, [key]: e.target.value}))
    }
  }
  const onChangeDate = (values: any, formatString: [string, string]) => {
    dispatch(setFilters({dateFrom: formatString[0], dateTo: formatString[1]}))
  }
  const onSelect = (value: string) => {
    dispatch(setFilters({status: value}))
  }
  const onClearStatus = () => {
    dispatch(setFilters({status: ''}))
  }
  const onClearCompany = () => {
    dispatch(setFilters({company: ''}))
  }

  const onChangeComp = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setFilters({company: e}))
  }

  return (
    <Row gutter={[20, 20]} wrap={false}>
      <Col flex={location.pathname === RouteNames.ORDERS_ARCHIVE ? 2 : '0 0 150px'}>
        <SelectCustom
          className={s.select}
          heightOptions={40}
          placeholder={'Компания'}
          showArrow={false}
          dropdownMatchSelectWidth={false}
          allowClear
          onClear={onClearCompany}
          clearIcon={<CloseOutlined/>}
          suffixIcon={<FilterOutlined/>}
          options={optionNameCompany}
          onSelect={onChangeComp}
        />
      </Col>
      <Col flex={2}>
        <Input className={s.field}
               placeholder="Фамилия клиента"
               onBlur={(e) => onBlurInput(e, 'lastName')}
               onPressEnter={(e) => onPressEnter(e, 'lastName')}
        />
      </Col>
      <Col flex={2}>
        <Input className={s.field}
               placeholder="№ договора"
               onBlur={(e) => onBlurInput(e, 'contractNumber')}
               onPressEnter={(e) => onPressEnter(e, 'contractNumber')}
        />
      </Col>
      {
        !hideData && (
          <Col flex={5}>
            <RangePicker className={s.field}
                         locale={locale}
                         onChange={onChangeDate}
                         format="DD.MM.YYYY"
                         suffixIcon={<Calendar/>}
                         placeholder={['Начальная', 'Конечная дата']}
            />
          </Col>
        )
      }
      <Col style={{width: '150px'}}>
        <SelectCustom
          style={{paddingLeft: token.controlPaddingHorizontal}}
          className={s.field}
          heightOptions={40}
          dropdownMatchSelectWidth={false}
          placeholder={<div className="flex gap-1 items-center justify-between">
            <div className="flex gap-1">
              <div>
                <FilterOutlined/>
              </div>
              Статус
            </div>
            <div>
              <CaretDownOutlined/>
            </div>
          </div>}
          showArrow={false}
          clearIcon={<CloseOutlined/>}
          allowClear
          options={
            orderStatusesCheck ? orderStatusesCheck?.map((status: IOrderStatus) => ({
              key: status.orderStatusIRI,
              value: status.orderStatusIRI,
              label: status.cyrillicValue
            })) : []
          }
          optionLabelProp="label"
          onSelect={onSelect}
          onClear={onClearStatus}
          suffixIcon={<FilterOutlined/>}
        />
      </Col>
    </Row>
  )
}

export default Filters