import React, { useEffect, useState } from 'react'
import { ConfigProvider, Drawer, notification, Table, TableProps } from 'antd'

import { IOrderTable } from '../../models/IOrder'
import { useChangeOrderMutation, useLazyGetOrderQuery, useLazyGetOrdersQuery } from '../../services/order.api'
import { OrderStatusesIRI, OrderStatusForm } from '../../configs/enums/orderStatuses'
import dayjs from 'dayjs'

import s from './TableReshipment.module.scss'
import { ColorForStatus } from '../../configs/enums/colorForStatus'
import { useGetPaymentTypesQuery } from '../../services/paymentTypes.api'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { FormOrder } from '../../modules/OrderCreating'
import { getIsOrderEdit, getOrderToEdit, setOrderToEdit } from '../../store/reducers/order/order.slice'
import PaymentForm from './components/PaymentForm/PaymentForm'
import CellWithModal from './components/CellWithModal/CellWithModal'
import LoaderFullDisplay from '../LoaderFullDisplay/LoaderFullDisplay'
import { ORDER_TYPES } from '../../configs/enums/orderTypes'

const TableReshipment = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const isOrderToEdit = useAppSelector(getIsOrderEdit)
  const orderToEdit = useAppSelector(getOrderToEdit)
  const [fetchOrders, {data: ordersData, isLoading: isLoadingOrders}] = useLazyGetOrdersQuery()
  const {data: paymentTypes} = useGetPaymentTypesQuery()
  const [changeOrder] = useChangeOrderMutation()
  const [api, contextHolder] = notification.useNotification()
  const [getOrder] = useLazyGetOrderQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    fetchOrders({
      status: [OrderStatusesIRI.WAITING_FOR_LOADING, OrderStatusesIRI.LOADED],
      type: [ORDER_TYPES.STOCK, ORDER_TYPES.PERSONAL, ORDER_TYPES.RETAIL, ORDER_TYPES.WHOLESALE, ORDER_TYPES.MP],
      sort: {
        'sort[product_order_status.ordering]': 'asc',
        'sort[afterpayment_received]': 'asc',
        'sort[loading_date_to]': 'asc',
        'sort[delivery_date_from]': 'asc',
      },
      perPage: 100
    })
  }, [])

  const onChangeOrder = async (id: number, data: {}) => {
    try {
      const res = await changeOrder({id, data})

      if ('data' in res) {
        api.success({message: 'Изменения сохранены'})
      }
      if ('errors' in res) {
        api.error({message: 'Ошибка при сохранении'})
      }
    } catch (e: any) {
      api.error({
        message: e?.message || 'Списать не удалась',
        description: e?.error?.message,
        placement: 'topRight',
        duration: 3
      })
    }
  }

  const closeForm = () => {
    dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
  }

  const onRow = (record: IOrderTable) => {
    return {
      onClick: async () => {
        setLoading(true)
        try {
          const res = await getOrder(record.id)

          if (res?.data) {
            dispatch(setOrderToEdit({
              edit: true,
              hasRightsForEdit: false,
              orderToEdit: {
                ...res.data,
                disabledFields: {
                  docsBtn: false
                }
              }
            }))
          } else {
            dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
          }
          if ('error' in res) {
            api.error({
              // @ts-ignore
              message: `Ошибка получения данных. Статус: ${res.error?.status}`,
              placement: 'topRight',
              duration: 3
            })
          }
        } catch (e: any) {
          api.error({message: e?.error?.message || 'Ошибка'})
        } finally {
          setLoading(false)
        }
      }
    }
  }

  const columns: TableProps<IOrderTable>['columns'] = [
    {
      title: '№',
      className: '!text-[#263B53]',
      width: 80,
      render: (text, row, index) => {
        return (
          <div className={s.cellId}>{row.id}</div>
        )
      }
    },
    {
      dataIndex: 'contractNumber',
      title: '№ договора',
      className: '!text-[#263B53]',
      width: 110
    },
    {
      title: 'Статус',
      className: '!text-[#263B53]',
      width: 106,
      render: (text, row) => (
        <div style={{
          color: ColorForStatus[row.productOrderStatus.orderStatusIRI === OrderStatusesIRI.WAITING_FOR_LOADING ? 'new' : row.productOrderStatus.value]
        }}>
          {row.productOrderStatus.orderStatusIRI === OrderStatusesIRI.WAITING_FOR_LOADING ? 'Ожидает доставку' : row.productOrderStatus?.cyrillicValue}
        </div>
      )
    },
    {
      title: 'Данные клиента',
      className: '!text-[#263B53]',
      width: 245,
      render: (text, row) => {
        const firstName = row.contact?.firstName || ''
        const secondName = row.contact?.secondName || ''
        const middleName = row.contact?.middleName || ''
        const fullName = (!firstName && !secondName && !middleName) ? '-' : `${secondName} ${firstName} ${middleName}`
        return (
          <div>
            <div className={s.clientText}>ФИО: {row.contact.type === 'juridical' ? <>{row.contact.fullName}</> : fullName}</div>
            <div className={s.clientText}>Телефон: {row.contact.phone || '-'}</div>
            <div className={s.clientText}>Адрес: {row.contact.address || '-'}</div>
          </div>
        )
      }
    },
    {
      title: 'Дата плановой отгрузки',
      className: '!text-[#263B53]',
      width: 147,
      render: (text, row) => (
        <>
          {row.deliveryDateFrom && dayjs(row.deliveryDateFrom).format('DD.MM.YYYY')} {row.deliveryDateTo && `- ${dayjs(row.deliveryDateTo).format('DD.MM.YYYY')}`}
        </>
      )
    },
    {
      dataIndex: 'afterpaymentSumm',
      title: 'Сумма доплаты',
      className: '!text-[#263B53]',
      width: 98,
      render: (text, row) => (
        <>{
          paymentTypes && <div className={classNames({
            'text-[#B66363]': !row.afterpaymentReceived &&
              (+(row.afterpaymentSumm) > 0 || !row.afterpaymentSumm) &&
              row.productOrderStatus.orderStatusIRI === OrderStatusesIRI.LOADED,
            'text-[#63B690]': row.afterpaymentReceived || +(row.afterpaymentSumm) === 0,
          })}>{row.afterpaymentSumm}</div>
        }</>
      )
    },
    {
      title: 'Форма доплаты',
      className: '!text-[#263B53]',
      width: 187,
      onCell: record => {
        return {
          onClick: event => {
            if (record.productOrderStatus.orderStatusIRI !== OrderStatusesIRI.LOADED) {
              event.stopPropagation()
            }
          }
        }
      },
      render: (text, row) => (
        <>
          {
            paymentTypes && (
              <PaymentForm row={row} paymentTypes={paymentTypes} onChangeOrder={onChangeOrder}/>
            )
          }
        </>
      )
    },
    {
      title: 'Дата доставки',
      className: '!text-[#263B53]',
      width: 260,
      onCell: record => {
        return {
          onClick: event => {
            if (record.productOrderStatus.orderStatusIRI !== OrderStatusesIRI.LOADED) {
              event.stopPropagation()
            }
          }
        }
      },
      render: (text, row) => <CellWithModal row={row} onChangeOrder={onChangeOrder}/>
    }
  ]

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            padding: 10,
            fontSize: 16,
            colorText: '#263B53'
          },
        },
      }}
    >
      {contextHolder}
      {loading && <LoaderFullDisplay/>}
      <Table<IOrderTable>
        columns={columns}
        dataSource={
          ordersData?.orders.map(item => ({...item, key: item.id}))
        }
        pagination={false}
        loading={isLoadingOrders}
        scroll={{x: 900}}
        onRow={onRow}
      />

      <Drawer open={isOrderToEdit}
              onClose={closeForm}
              width={985}
              closable={false}
              bodyStyle={{padding: 0}}
              destroyOnClose={true}
      >
        <FormOrder
          name={'orderChanging'}
          isShowForm={isOrderToEdit && !loading}
          closeForm={closeForm}
          statusForEditing={OrderStatusForm.EDIT_ORDER}
          data={orderToEdit}
        />
      </Drawer>
    </ConfigProvider>
  )
}

export default TableReshipment