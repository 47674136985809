import React, { FC, useEffect } from 'react'
import { Col, Layout, Row } from 'antd'
import FormSignIn from '../FormSignIn/FormSignIn'
import { useAppSelector } from '../../../../hooks/redux'
import { useNavigate } from 'react-router-dom'
import s from './Auth.module.scss'

const Auth: FC = () => {
  const {token} = useAppSelector(state => state.authSlice)
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      navigate('/orders')
    }
  }, [token])

  return (
    <Layout className="w-full h-screen flex m-[0_auto] bg-transparent">
      <Row align={'middle'} className="w-full h-screen flex">
        <Col flex="40%" className="w-[40%] max-w-[874px] h-full flex items-center justify-center bg-[#FAFBFD]">
          <div className={s.bg}></div>
        </Col>
        <Col flex="auto" className="h-full flex items-center justify-center bg-[#FAFBFD]">
          <FormSignIn/>
        </Col>
      </Row>
    </Layout>
  )
}

export default Auth