import React, { Suspense } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import Section from '../../components/Section/Section'
import PageHeader from '../../components/PageHeader/PageHeader'
import PageManagement from '../../components/PageManagement/PageManagement'
import { TabsProps } from 'antd'
import { RouteNames } from '../../configs/routes/routesNames'
import MaterialFilters from '../../components/MaterialsInStock/MaterialFilters'
import { useGetPagesQuery } from '../../services/pages.api'

const MaterialsPage = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const {data} = useGetPagesQuery()

  const items: TabsProps['items'] = [
    {
      key: RouteNames.MATERIALS,
      label: 'Заказ ткани',
    },
    {
      key: `${RouteNames.MATERIALS}/${RouteNames.MATERIALS_IN_STOCK}`,
      label: 'Наличие ткани',
      children: <MaterialFilters />
    },
    {
      key: `${RouteNames.MATERIALS}/${RouteNames.MATERIALS_ARCHIVE}`,
      label: 'Архив ткани',
    },
  ]

  const onChangeTab = (activeKey: string) => {
    if (activeKey === RouteNames.MATERIALS) {
      navigate(RouteNames.MATERIALS)
    } else if (activeKey === `${RouteNames.MATERIALS}/${RouteNames.MATERIALS_IN_STOCK}`) {
      navigate(RouteNames.MATERIALS_IN_STOCK)
    } else if (activeKey === `${RouteNames.MATERIALS}/${RouteNames.MATERIALS_ARCHIVE}`) {
      navigate(RouteNames.MATERIALS_ARCHIVE)
    }
  }

  if (!data || data.find(item => '/' + pathname.split('/')[1] === item.url) === undefined) {
    return null
  }

  return (
    <Section>
      <Suspense fallback={<div>Загрузка....</div>}>
        <PageHeader title="Модуль снабжения производства"/>
        <PageManagement items={items} onChangeTab={onChangeTab}/>
        <Outlet/>
      </Suspense>
    </Section>
  )
}

export default MaterialsPage