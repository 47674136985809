import React, { FC, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'antd'

import { IOrderTaskStatusesByRoles, ISubProduct } from '../../../../../../models/IOrder'

import Circle from '../../../../../../ui/icons/Circle'

import { IUserListObj } from '../../ProductionPlanTable'
import { useChangeOrderMutation } from '../../../../../../services/order.api'
import { socket } from '../../../../../../services/socket'
import classNames from 'classnames'
import { UpOutlined } from '@ant-design/icons'
import SubTaskRow from '../SubTaskRow/SubTaskRow'
import { UserRolesConst } from '../../../../../../configs/enums/userRoles'

interface IProps {
  orderId: number
  code: string
  contractNumber: string
  isAsap: boolean
  subProduct: ISubProduct[]
  showOrder: (id: number) => void
  refetchOrders: () => void
  getUpdatedOrder: (id: number) => void
  users: IUserListObj
  api: any
  isEditable: boolean
  orderTaskStatusesByRoles: IOrderTaskStatusesByRoles
}

const TableRow: FC<IProps> = ({
                                isAsap,
                                orderId,
                                code,
                                contractNumber,
                                subProduct,
                                showOrder,
                                users,
                                api,
                                isEditable,
                                orderTaskStatusesByRoles,
                                refetchOrders,
                                getUpdatedOrder
                              }) => {
  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const [isOpenSubTasks, setIsOpenSubTasks] = useState(true)
  const [changeOrder] = useChangeOrderMutation()

  const onChangeOrder = async () => {
    try {
      const res = await changeOrder({
        id: orderId,
        data: {
          is_asap: !isAsap
        }
      })

      if ('data' in res) {
        socket.emit('message', {
          message: {
            update: 'ALL_TASKS'
          }
        })
        refetchOrders()
        api.success({message: 'Приоритет успешно изменен'})
      }
      if ('error' in res) {
        api.warning({message: 'Приоритет не удалось изменить'})
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsConfirmModal(false)
    }
  }

  return (
    <>
      <>
        <Row>
          <Col flex={'0 0 120px'}
               className={classNames('flex items-center justify-center text-base font-medium border-b-2 border-r-2 border-baseLightGrey bg-baseWhite col-span-1 row-span-2')}>
            {
              isEditable && (
                <div className="hover:cursor-pointer" onClick={() => setIsConfirmModal(true)}>
                  <Circle fill={isAsap ? '#B66363' : '#F0BF2E'}/>
                </div>
              )
            }
          </Col>
          <Col flex={'auto'}>
            <Row onClick={() => setIsOpenSubTasks(v => !v)} className="hover:cursor-pointer">
              <Col span={7} className={classNames('h-10 bg-baseWhite')}></Col>
              <Col span={5}
                   className={classNames('h-10 flex justify-center items-center gap-11 text-base font-medium border-r-2 border-transparent bg-baseWhite col-span-9 p-[10px_16px]')}
              >
                <button
                  className="text-base leading-[20px] font-medium text-[#263B53] bg-none border-none cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    showOrder(orderId)
                  }}>
                  {`Заказ № ${orderId}`}
                </button>
              </Col>
              <Col span={12}
                   className={classNames('h-10 flex justify-start items-center gap-11 text-base font-medium border-r-2 border-transparent bg-baseWhite col-span-9 p-[10px_16px]')}
              >
                <div className={classNames('transition-all', {'rotate-[180deg]': isOpenSubTasks})}><UpOutlined/></div>
              </Col>
            </Row>
            <Row className="bg-baseWhite">
              {isEditable && <Col flex={'0 0 62px'}
                   className={classNames('h-4 text-base font-medium border-r border-b-2 border-baseLightGrey bg-baseWhite col-span-1')}>
              </Col>}
              <Col flex={'1 0 50px'} // Раскройщик
                   className={classNames('h-4 text-base font-medium border-r-2 border-b-2 border-baseLightGrey bg-[#c0c4ca] rounded-tl-[8px] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_TAILOR] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_TAILOR] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_TAILOR] === 2,
                   })}>
              </Col>
              <Col flex={'1 0 50px'} // Швея
                   className={classNames('h-4 text-base font-medium border-r-2 border-b-2 border-baseLightGrey bg-[#c0c4ca] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_SEAMSTRESS] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_SEAMSTRESS] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_SEAMSTRESS] === 2,
                   })}>
              </Col>
              <Col flex={'1 0 50px'} // Распиловщик
                   className={classNames('h-4 text-base font-medium border-r-2 border-b-2 border-baseLightGrey bg-[#c0c4ca] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_CUTTER] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_CUTTER] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_CUTTER] === 2,
                   })}>
              </Col>
              <Col flex={'1 0 50px'} // Сборщик
                   className={classNames('h-4 text-base font-medium border-r-2 border-b-2 border-baseLightGrey bg-[#c0c4ca] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_ASSEMBLER] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_ASSEMBLER] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_ASSEMBLER] === 2,
                   })}>
              </Col>
              <Col flex={'1 0 50px'} // Поролонщик
                   className={classNames('h-4 text-base font-medium border-r-2 border-b-2 border-baseLightGrey bg-[#c0c4ca] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_FOAMER] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_FOAMER] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_FOAMER] === 2,
                   })}>
              </Col>
              <Col flex={'1 0 50px'} // Обивщик
                   className={classNames('h-4 text-base font-medium border-r-2 border-b-2 border-baseLightGrey bg-[#c0c4ca] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_UPHOLSTERER] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_UPHOLSTERER] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_UPHOLSTERER] === 2,
                   })}>
              </Col>
              <Col flex={'1 0 50px'} // Сборка гот.
                   className={classNames('h-4 text-base font-medium border-r-2 border-b-2 border-baseLightGrey bg-[#c0c4ca] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_ASSEMBLER_FULL] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_ASSEMBLER_FULL] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_ASSEMBLER_FULL] === 2,
                   })}>
              </Col>
              <Col flex={'1 0 50px'} // ОТК
                   className={classNames('h-4 text-base font-medium border-r-2 border-b-2 border-baseLightGrey bg-[#c0c4ca] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_OTK] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_OTK] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_OTK] === 2,
                   })}>
              </Col>
              <Col flex={'1 0 50px'} // Упаковщик
                   className={classNames('h-4 text-base font-medium border-b-2 border-baseLightGrey bg-[#c0c4ca] rounded-tr-[8px] col-span-1', {
                     '!bg-grey2': orderTaskStatusesByRoles[UserRolesConst.ROLE_PACKER] === 0,
                     '!bg-baseYellow': orderTaskStatusesByRoles[UserRolesConst.ROLE_PACKER] === 1,
                     '!bg-baseGreen': orderTaskStatusesByRoles[UserRolesConst.ROLE_PACKER] === 2,
                   })}>
              </Col>
            </Row>
          </Col>
        </Row>
        <div>
          {
            isOpenSubTasks && (
              subProduct.map((item, idx) => <React.Fragment key={idx}>
                <SubTaskRow product={item}
                            code={contractNumber ? `${contractNumber}` : `${orderId}`}
                            isEditable={isEditable}
                            users={users}
                            getUpdatedOrder={getUpdatedOrder}
                            orderId={orderId}
                />
              </React.Fragment>)
            )
          }
        </div>
      </>

      <Modal open={isConfirmModal}
             cancelText="Нет"
             okText="Да"
             onOk={onChangeOrder}
             onCancel={() => setIsConfirmModal(false)}
             title={`${isAsap ? 'Убрать' : 'Установить'} высший приоритет у заказа № ${contractNumber ? contractNumber : `- (${orderId})`}?`}
             centered
      />
    </>
  )
}

export default TableRow