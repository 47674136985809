import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './api'
import { ITask } from '../models/ITask'
import { orderApi } from './order.api'

export const tasksApi = createApi({
  reducerPath: 'tasks',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Tasks'],
  endpoints: (build) => ({
    getTasks: build.query<ITask[], { page: number, product?: string }>({
      query: (data) => ({
        url: '/tasks',
        params: {
          page: data.page,
          product_order: data.product
        }
      }),
      transformResponse: (response: any): ITask[] => {
        return [
          ...response['hydra:member'].map((item: any) => ({
              taskIRI: item['@id'],
              id: item.id,
              createdAt: item.created_at,
              updatedAt: item.updated_at,
              productOrder: item.product_order,
              userEntity: {
                id: item.user_entity.id,
                accessCode: item.user_entity.access_code
              },
              status: item.status,
              payment: item.payment,
              reward: item.reward,
              role: item.role,
              completedAt: item.completed_at,
              isAsap: item.is_asap
            })
          )]
      },
      providesTags: result => ['Tasks']
    }),
    getTask: build.query<ITask, any>({
      query: (id) => `/tasks/${id}`,
    }),
    changeTask: build.mutation<ITask[], {
      id: number
      productOrder?: string,
      userEntity?: string | null,
      status?: string,
      role?: string,
      isAsap?: boolean
    }>({
      query: (data) => ({
        url: `/tasks/${data.id}`,
        method: 'PUT',
        body: {
          product_order: data.productOrder,
          user_entity: data.userEntity,
          status: data.status,
          role: data.role,
          is_asap: data.isAsap
        }
      }),
      transformResponse: (response: any) => {
        return response.result
      },
      invalidatesTags: ['Tasks'],
      // onQueryStarted: (arg, api) => {
      //   api.queryFulfilled.then(() => {
      //     api.dispatch(
      //       orderApi.util.invalidateTags(['OrderProduction'])
      //     )
      //   })
      // },
    }),
  })
})

export const {
  useGetTasksQuery,
  useChangeTaskMutation
} = tasksApi