import { FC } from 'react'
import { IconProps } from './icons.types'

const Exit: FC<IconProps> = ({
                               width = 24,
                               height = 25,
                               fill = 'none',
                             }) => {
  return (
    <svg width={width}
         height={height}
         fill={fill}
         viewBox="0 0 24 25"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 3.56348L10.3374 3.79732C7.75867 4.70748 6.46928 5.16255 5.73464 6.20089C5 7.23924 5 8.60657 5 11.3412V13.7857C5 16.5204 5 17.8877 5.73464 18.9261C6.46928 19.9644 7.75867 20.4195 10.3374 21.3296L11 21.5635"
        stroke="#8A94A2" strokeWidth="1.5" strokeLinecap="round"/>
      <path
        d="M21 12.5635L11 12.5635M21 12.5635C21 11.8632 19.0057 10.555 18.5 10.0635M21 12.5635C21 13.2637 19.0057 14.5719 18.5 15.0635"
        stroke="#8A94A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Exit