import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Space, Typography } from 'antd'
import { DeleteOutlined, TagsFilled } from '@ant-design/icons'
import { useAppSelector } from '../../../../../../hooks/redux'
import { getIsOrderEdit } from '../../../../../../store/reducers/order/order.slice'
import s from './HeaderPanel.module.scss'
import Basket from '../../../../../../ui/icons/Basket'

const {Text} = Typography

interface IProps {
  index: number
  countGoods: number
  goodsIDs: number[]
  form?: any
  removeGood: (ids: number | number[]) => void
  deleteGood: (id: number) => void
  formName: string
}

const HeaderPanel: FC<IProps> = ({
                                   index,
                                   removeGood,
                                   countGoods,
                                   goodsIDs,
                                   deleteGood,
                                   formName,
                                 }) => {
  const isEditable = useAppSelector(getIsOrderEdit)
  const isOrderToEdit = useAppSelector(getIsOrderEdit)
  const orderToEdit = useAppSelector(state => state.orderSlice.orderToEdit)
  const [goodName, setGoodName] = useState('')
  const [isModalDeleteGood, setIsModalDeleteGood] = useState(false)

  const form = Form.useFormInstance()
  const goods = Form.useWatch(`goods`, form)
  const summary = Form.useWatch([`goods`, index, 'good', 0, 'summative'], form)

  useEffect(() => {
    if (goods) {
      const name = form.getFieldValue(['goods', index, 'good', 0, 'productName'])
      setGoodName(name)
    }
  }, [goods])

  const onDeleteGood = () => {
    const productIdForDelete = goods?.[index]?.good?.[0]?.productId

    if (goodsIDs.includes(productIdForDelete)) {
      deleteGood(productIdForDelete)
      removeGood(index)
    } else {
      removeGood(index)
    }
    setIsModalDeleteGood(false)
  }

  const onRemoveField = (e: any) => {
    e.stopPropagation()

    if (isEditable) {
      setIsModalDeleteGood(true)
    } else {
      removeGood(index)
    }
  }

  return (
    <>
      <Row className={s.rowHeaderGoodPanel} align={'middle'}>
        <Col span={23}>
          <Space size={24}>
            <TagsFilled className={s.goodIcon}/>

            <Space direction={'vertical'}>
              <Typography className={s.productName}>
                {goodName || `Товар ${index + 1}`}{
                (formName !== 'newOrder' && isOrderToEdit)
                  ? orderToEdit?.productOrderItems?.[index].stockItem.itemCode
                    ? `- ${orderToEdit?.productOrderItems?.[index].stockItem.itemCode}`
                    : ' - Нет кода товара'
                  : ''
              }
              </Typography>

              {
                isOrderToEdit &&
                <Space direction={'horizontal'}>
                  <Text className={s.productNamePrice}>
                    Итоговая стоимость:
                  </Text>
                  <span className={s.productPrice}>{summary}</span>
                </Space>
              }
            </Space>
          </Space>
        </Col>

        {
          countGoods >= 2 &&
          <Col span={1}>
            {
              <Button shape="circle"
                      className={s.deleteGoodBtn}
                      icon={<Basket/>}
                      onClick={onRemoveField}
              />
            }
          </Col>
        }
      </Row>

      <Modal
        open={isModalDeleteGood}
        onOk={onDeleteGood}
        onCancel={() => setIsModalDeleteGood(false)}
        okText="Да"
        cancelText="Нет"
        centered
        closable={false}
      >
        Вы действительно хотите удалить {goodName}?
      </Modal>
    </>
  )
}

export default HeaderPanel