import React, { useEffect } from 'react'

import { selectUser, setUserData } from '../../store/reducers/user/user.slice'
import { getInitialsFromName } from '../../helpers/getInitialsFromName'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { useLazyMeQuery } from '../../services/users.api'

import { Button, Col, Layout, Row, Space } from 'antd'
import { logout } from '../../modules/Authorization'

import s from './HeaderApp.module.scss'

import Exit from '../../ui/icons/Exit'

const {Header} = Layout

const HeaderApp = () => {
  const [me, {data: dataUser, isSuccess, error}] = useLazyMeQuery()
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!user?.firstName) {
      me()
    }
  }, [])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserData(dataUser))
    }
  }, [dataUser])

  const onLogout = () => {
    dispatch(logout())
  }

  return (
    <Header className={s.header}>
      <div className={s.user}>
        <Row gutter={12} align={'middle'} justify="end">
          <Col flex={250}>
            <Space size={12} className={s.name}>
              {
                getInitialsFromName(user?.firstName, user?.lastName, user?.middleName)
              }
            </Space>
          </Col>
          <Col flex={50} className={s.btnWrap}>
            <Button type={'ghost'}
                    onClick={onLogout}
                    icon={<Exit/>}
            />
          </Col>
        </Row>
      </div>
    </Header>
  )
}

export default HeaderApp