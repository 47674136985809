import React, { lazy, useEffect, useRef } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { RouteNames } from './configs/routes/routesNames'
import { basicTheme } from './configs/themes/basic'
import { Auth, setCredentials } from './modules/Authorization'
import { useAppDispatch } from './hooks/redux'
import { RoutesProps } from './configs/routes/router.types'
import 'dayjs/locale/ru'
import locale from 'antd/locale/ru_RU'

import OrdersPageArchive from './pages/Orders/OrdersArchive/OrdersPageArchive'
import PrivateRouter from './components/PrivateRouter/PrivateRouter'
import LayoutCustom from './components/LayoutCustom/LayoutCustom'
import MaterialsPage from './pages/Materials/MaterialsPage'
import NotFound from './components/NotFound/NotFound'
import OrdersPage from './pages/Orders/OrdersPage'

import './styles/index.scss'
import Production from './pages/Production/Production'
import ProductionPlan from './pages/ProductionPlan/ProductionPlan'
import FinishedGoods from './pages/FinishedGoods/FinishedGoods'
import Reshipment from './pages/Reshipment/Reshipment'
import EmployeeDashboard from './pages/EmployeeDashboard/EmployeeDashboard'
import { InfiniteProductionOrdersList } from './modules/InfiniteProductionOrderList'
import InfiniteProductionPlanTable
  from './modules/InfiniteProductionPlanOrderList/components/InfiniteProductionPlanTable/InfiniteProductionPlanTable'
import TableFinishedGoods from './components/TableFinishedGoods/TableFinishedGoods'
import TableFinishedGoodsArchive from './components/TableFinishedGoodsArchive/TableFinishedGoodsArchive'
import MaterialsArchive from './components/MaterialsArchive/MaterialsArchive'
import TableEmployee from './components/TableEmployee/TableEmployee'
import AssortmentAndPrices from './components/AssortmentAndPrices/AssortmentAndPrices'
import DashboardMaterials from './components/DashboardMaterials/DashboardMaterials'
import DashboardPieceworkPayment from './components/DashboardPieceworkPayment/DashboardPieceworkPayment'
import DashboardMP from './components/DashboardMP/DashboardMP'
import InfiniteListWrap from './components/MaterialsInStock/InfiniteListWrap'
import DashboardStore from './components/DashboardStore/DashboardStore'

const Materials = lazy(() => {
  return import('./modules/MaterialsOrder')
    .then(({Materials}) => ({default: Materials}))
})

export const routes: RoutesProps[] = [
  {
    path: RouteNames.ORDERS,
    Component: OrdersPage,
  },
  {
    path: RouteNames.ORDERS_ARCHIVE,
    Component: OrdersPageArchive,
  },
  {
    path: RouteNames.NOT_FOUND,
    Component: NotFound,
  },
]

const App = () => {
  const tokensLocal = JSON.parse(localStorage.getItem('tokensLL') || '{}')
  const tokensSession = JSON.parse(sessionStorage.getItem('tokensLL') || '{}')
  const navigate = useNavigate()
  const {pathname} = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (tokensLocal || tokensSession) {
      const tokens = tokensLocal.token ? tokensLocal : tokensSession
      dispatch(setCredentials(tokens))
      navigate(pathname)
    } else {
      navigate(RouteNames.AUTHORIZATION)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <ConfigProvider theme={basicTheme} locale={locale}>
        <Routes>
          <Route index path="/sign-in" element={<Auth/>}/>

          <Route element={<PrivateRouter/>}>
            <Route element={<LayoutCustom/>}>
              {
                routes.map(({path, Component}, index) => (
                  <Route key={index} path={path} element={<Component/>}/>
                ))
              }
              <Route path={RouteNames.MATERIALS} element={<MaterialsPage/>}>
                <Route index element={<Materials/>}/>
                <Route path={RouteNames.MATERIALS_IN_STOCK} element={<InfiniteListWrap />}/>
                <Route path={RouteNames.MATERIALS_ARCHIVE} element={<MaterialsArchive/>}/>
              </Route>
              <Route path={RouteNames.PRODUCTION} element={<Production/>}>
                <Route index element={<InfiniteProductionOrdersList/>}/>
                <Route path={RouteNames.PRODUCTION_READY} element={<InfiniteProductionOrdersList/>}/>
              </Route>
              <Route path={RouteNames.PRODUCTION_PLAN} element={<ProductionPlan/>}>
                <Route index element={<InfiniteProductionPlanTable isEditable={true}/>}/>
                <Route path={RouteNames.PRODUCTION_PLAN_ARCHIVE}
                       element={<InfiniteProductionPlanTable isEditable={false}/>}/>
              </Route>
              <Route path={RouteNames.FINISHED_GOODS} element={<FinishedGoods/>}>
                <Route index element={<TableFinishedGoods/>}/>
                <Route path={RouteNames.FINISHED_GOODS_ARCHIVE} element={<TableFinishedGoodsArchive/>}/>
              </Route>
              <Route index path={RouteNames.RESHIPMENT} element={<Reshipment/>}/>
              <Route path={RouteNames.EMPLOYEE_DASHBOARD} element={<EmployeeDashboard/>}>
                <Route index element={<TableEmployee/>}/>
                <Route path={RouteNames.EMPLOYEE_DASHBOARD_ASSORTMENT_AND_PRICES} element={<AssortmentAndPrices/>}/>
                <Route path={RouteNames.EMPLOYEE_DASHBOARD_MATERIALS} element={<DashboardMaterials/>}/>
                <Route path={RouteNames.EMPLOYEE_DASHBOARD_PIECEWORK_PAYMENT} element={<DashboardPieceworkPayment/>}/>
                <Route path={RouteNames.MP} element={<DashboardMP/>}/>
                <Route path={RouteNames.STORE} element={<DashboardStore/>}/>
              </Route>
              <Route path={'/'} element={<Navigate to={RouteNames.ORDERS} replace/>}/>
              <Route path={'*'} element={<NotFound/>}/>
            </Route>
          </Route>
        </Routes>
      </ConfigProvider>
    </div>
  )
}

export default App
