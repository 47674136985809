import React, { FC, useEffect, useState } from 'react'
import s from '../../../OrderCreating/components/Goods/components/OrderHeader/OrderHeader.module.scss'
import { Button, Col, Form, Modal, notification, Row, Space, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { OrderStatusesIRI } from '../../../../configs/enums/orderStatuses'
import { IResponsibleUser } from '../../../../models/IUser'
import { IOrderStatus } from '../../../../models/IOrder'
import { transformDate } from '../../../../helpers/transformDate'
import { getInitialsFromName } from '../../../../helpers/getInitialsFromName'
import { useAppSelector } from '../../../../hooks/redux'
import { useChangeOrderMutation, useGetProductOrderStatusesQuery } from '../../../../services/order.api'
import { getOrderToEditMP } from '../../../../store/reducers/order/order.slice'
import { ColorForStatus } from '../../../../configs/enums/colorForStatus'
import classNames from 'classnames'

const {Text} = Typography

interface IProps {
  ref?: React.RefObject<any>
  name: string
  orderStatus?: IOrderStatus
  createDate?: string
  responsibleUser?: IResponsibleUser
  isEditable: boolean
  orderIRI: string
  contractNumber: string | undefined
  closeForm: () => void
  apiFromList?: any
}

const OrderHeader: FC<IProps> = (props) => {
  const {
    name,
    orderStatus,
    responsibleUser = {} as IResponsibleUser,
    createDate,
    isEditable,
    orderIRI,
    closeForm,
    apiFromList,
    contractNumber
  } = props
  const [confirmDeletedStatus, setConfirmDeletedStatus] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const order = useAppSelector(getOrderToEditMP)
  const [changeOrder] = useChangeOrderMutation()
  const {data: orderStatuses} = useGetProductOrderStatusesQuery()

  const userName = getInitialsFromName(
    responsibleUser?.firstName || '',
    responsibleUser?.lastName || '',
    responsibleUser?.middleName || '')
  const onConfirmDelete = async () => {
    setConfirmDeletedStatus(false)
    try {
      const res = await changeOrder({
        id: order?.id,
        data: {
          'product_order_status': OrderStatusesIRI.CANCEL
        }
      })

      if ('data' in res) {
        apiFromList?.error({
          message: 'Заказ отменен',
          placement: 'topRight',
          duration: 3
        })
        closeForm()
      }
    } catch (e: any) {
      if ('message' in e || 'error' in e) {
        api.error({
          message: e?.message || 'Ошибка при изменении заказа',
          description: e?.error?.message,
          placement: 'topRight',
          duration: 3
        })
      }
    }
  }
  const onCancelDeleted = () => {
    // form.setFieldValue('status', orderStatus)
    setConfirmDeletedStatus(false)
  }

  if (!orderStatuses) return null
  return (
    <>
      <div className="relative p-[32px_32px_0]">
        {contextHolder}

        <Button
          className="w-[34px] h-[34px] flex items-center justify-center absolute top-[32px] right-[42px] text-[13px] bg-[#f1f3f6] rounded-full border-none text-[#8a94a2]"
          onClick={() => closeForm()}
          icon={<CloseOutlined/>}
          disabled={false}
        ></Button>

        <Row gutter={24}>
          <Col span={11}>
            <Space direction="vertical">
              {
                isEditable && <Text
                  className="font-semibold text-sm leading-[21px] text-[#8A94A2]">{transformDate(createDate, true)} - {userName}</Text>
              }

              <Row gutter={[0, 0]}>
                <Text className="font-semibold text-xl leading-[28px] text-[#263B53]">{name || ''}</Text>
                <div className={classNames('flex items-end p-[0_20px] font-medium text-base')}
                     style={{color: ColorForStatus[orderStatuses?.find(item => item.orderStatusIRI === orderStatus?.orderStatusIRI)?.value || 'new']}}>
                  {orderStatus?.cyrillicValue}
                </div>
              </Row>

              <Row className="mb-3">
                {contractNumber ? `Договор № ${contractNumber}` : 'Нет договора'}
              </Row>
            </Space>
          </Col>
          {
            orderStatus?.orderStatusIRI === OrderStatusesIRI.NEW && (
              <Col offset={5} span={5}>
                <Button type="primary"
                        className={s.cancelOrder}
                        onClick={() => setConfirmDeletedStatus(true)}
                >Отменить заказ</Button>
              </Col>
            )
          }
        </Row>
      </div>

      <Modal open={confirmDeletedStatus}
             onCancel={onCancelDeleted}
             onOk={onConfirmDelete}
             cancelText={'Нет'}
             okText={'Да'}
             title={<h4>Подтвердить отмену заказа <Text type={'warning'}>{name}</Text></h4>}
             closable={false}
             centered
      />
    </>
  )
}

export default OrderHeader