import React, { useEffect, useRef } from 'react'
import { useAppDispatch } from '../../../../hooks/redux'
import { setFormCreateOrderToWarehouse } from '../../../../store/reducers/order/order.slice'
import ProductionOrdersList from '../ProductionOrdersList/ProductionOrdersList'
import ProductionControlPanel from '../ProductionControlPanel/ProductionControlPanel'

export const InfiniteProductionOrdersList = () => {
  const dispatch = useAppDispatch()
  const setIsShow = (value: boolean) => {
    dispatch(setFormCreateOrderToWarehouse(value))
  }

  const ref = useRef<any>()
  useEffect(() => {
    // console.log('scrolls')
    if (!ref) return

    ref?.current?.addEventListener('scroll', scrollHandler)
    return () => {
      // console.log('unmount')
      ref?.current?.removeEventListener('scroll', scrollHandler)
    }
  }, [dispatch])

  const scrollHandler = (e: any) => {
    // console.log(e.target.offsetHeight)
  }

  return (
    // <div ref={ref} style={{display: 'flex', height: '100vh', overflowY: 'scroll'}}>
    <>
      <ProductionControlPanel setIsShow={setIsShow}/>
      <ProductionOrdersList/>
    </>
    // </div>
  )
}
