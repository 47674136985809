import { IOrderTable } from '../../../../models/IOrder'
import React, { FC, useEffect, useState } from 'react'
import { OrderStatusesIRI } from '../../../../configs/enums/orderStatuses'
import s from '../../TableReshipment.module.scss'
import { Button, DatePicker, Modal, Popover } from 'antd'
import Calendar from '../../../../ui/icons/Calendar'
import dayjs from 'dayjs'
import { MoreOutlined } from '@ant-design/icons'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

interface IProps {
  row: IOrderTable,
  onChangeOrder: (id: number, data: {}) => void
}

const CellWithModal: FC<IProps> = ({row, onChangeOrder}) => {
  const [isLoadedConfirm, setIsLoadedConfirm] = useState(false)
  const [afterPaymentConfirm, setIsAfterPaymentConfirm] = useState(false)
  const [date, setDate] = useState(row.loadingDateFrom)
  // const ref = useRef(null)

  // useOnClickOutside(ref, () => setPopover(false))

  const onLoaded = async () => {
    setDate(dayjs().format('YYYY-MM-DDTHH:mm:ss+00:00'))
    onChangeOrder(row.id, {
      product_order_status: OrderStatusesIRI.LOADED,
      loading_date_from: dayjs().format('DD.MM.YYYYTHH:mm:ss'),
      loading_date_to: dayjs().format('DD.MM.YYYYTHH:mm:ss')
    })
    setIsLoadedConfirm(false)
  }
  const onReceivedAfterPayment = async () => {
    onChangeOrder(row.id, {afterpayment_received: true})
    setIsAfterPaymentConfirm(false)
  }

  const onChangeDate = (dates: any, dateStrings: any) => {
    setDate(dates)
    onChangeOrder(row.id, {
      loading_date_from: dateStrings,
      loading_date_to: dateStrings
    })
  }

  return (
    <div className={s.quantityBlock}>
      <div>
        <DatePicker
          placeholder={''}
          format="DD.MM.YYYY"
          allowClear={false}
          onChange={onChangeDate}
          suffixIcon={<Calendar/>}
          value={date ? dayjs(date) : null}
          className="min-w-[115px] h-[44px]"
          disabled={row?.productOrderStatus.orderStatusIRI !== OrderStatusesIRI.WAITING_FOR_LOADING}
          placement={'bottomRight'}
        />
      </div>
      <div className={s.btnWrap}
        // ref={ref}
      >
        <div className="w-[38px] h-[38px]">
          {
            ((!row.afterpaymentReceived && +(row.afterpaymentSumm) > 0) ||
              row.productOrderStatus.orderStatusIRI === OrderStatusesIRI.WAITING_FOR_LOADING) && (
              <Popover placement="left"
                // open={popover}
                       trigger={'click'}
                       content={<div className={s.btnsWrap}
                                     onClick={(e) => e.stopPropagation()}>
                         {
                           (!row.afterpaymentReceived && +(row.afterpaymentSumm) > 0) && (
                             <Button onClick={(e) => {
                               setIsAfterPaymentConfirm(true)
                             }}>Подтвердить доплату</Button>
                           )
                         }
                         {
                           row.productOrderStatus.orderStatusIRI === OrderStatusesIRI.WAITING_FOR_LOADING && (
                             <Button onClick={(e) => {
                               e.stopPropagation()
                               setIsLoadedConfirm(true)
                             }}>Заказ отгружен</Button>
                           )
                         }
                       </div>}
              >
                <Button className={s.writeGoodBtn}
                        icon={<MoreOutlined/>}
                        onClick={(e) => e.stopPropagation()}
                />
              </Popover>
            )
          }
        </div>
      </div>

      <Modal
        open={afterPaymentConfirm}
        onOk={onReceivedAfterPayment}
        onCancel={() => setIsAfterPaymentConfirm(false)}
        okText="Да"
        cancelText="Нет"
        centered
        closable={false}
        className="z-[1040]"
      >
        Вы действительно хотите подтвердить доплату?
      </Modal>
      <Modal
        open={isLoadedConfirm}
        onOk={onLoaded}
        onCancel={() => setIsLoadedConfirm(false)}
        okText="Да"
        cancelText="Нет"
        centered
        closable={false}
      >
        Вы действительно хотите подтвердить отгрузку?
      </Modal>
    </div>
  )
}

export default CellWithModal