import React, { FC } from 'react'
import { Col, Form, Row, Space, Spin, Typography } from 'antd'
import { TagsFilled } from '@ant-design/icons'

const {Text} = Typography

interface IProps {
  goodName: string
  stockItemsLength: number
  isFetchingStockItem: boolean
}

const HeaderPanel: FC<IProps> = ({goodName, stockItemsLength, isFetchingStockItem}) => {
  const form = Form.useFormInstance()
  const summary = Form.useWatch(['good', 0, 'summative'], form)

  return (
    <>
      <Row className="w-full p-[13px_16px] rounded-none" align={'middle'}>
        <Col span={23}>
          <Space size={24}>
            <TagsFilled className="text-[26px] text-baseColor"/>

            <Space direction={'vertical'}>
              <Typography>
                <div className="font-medium text-lg text-baseColor">
                  {goodName || 'Товар'} -
                  {isFetchingStockItem
                    ? <Spin size="small"/>
                    : stockItemsLength
                      ? <span className="font-medium text-lg text-baseGreen"> Есть в наличии</span>
                      : <span className="font-medium text-lg text-baseRed"> Нет в наличии</span>}
                </div>
              </Typography>

              {
                <Space direction={'horizontal'}>
                  <Text className="font-medium text-sm text-baseColor leading-[17px]">
                    Итоговая стоимость:
                  </Text>
                  <span className="font-medium text-sm leading-[17px] text-baseGreen">{summary || 0}</span>
                </Space>
              }
            </Space>
          </Space>
        </Col>
      </Row>
    </>
  )
}

export default HeaderPanel