import { createSlice } from '@reduxjs/toolkit'
import { IOrder, IOrderProduction } from '../../../models/IOrder'
import { RootState } from '../../store'

interface IDisabledFields {
  productionDateTo?: boolean
  deliveryDateFrom?: boolean
  deliveryDateTo?: boolean
  size?: boolean
  note?: boolean
  checkboxCustomSize?: boolean
  prepaymentReceived?: boolean
  docsBtn?: boolean
  backwall?: boolean
  productSupport?: boolean
  phone?: boolean
  materials?: {
    material?: boolean
    provider?: boolean
    additionalMaterial?: boolean
    additionalProvider?: boolean
    price?: boolean
    additionalPrice?: boolean
  }
  goods?: {
    quantity?: boolean
  }
}

interface OrderState {
  orders: IOrderProduction[]
  orderToEdit: IOrder & { disabledFields: IDisabledFields }
  hasRightsForEdit: boolean
  isOrderEdit: boolean
  orderToEditMP: IOrder & { disabledFields: IDisabledFields }
  hasRightsForEditMP: boolean
  isOrderEditMP: boolean
  isShowFormCreateOrder: boolean
  isEditFormCreateOrder: boolean
  isShowFormMP: boolean
  isShowFormCreateOrderToWarehouse: boolean
  filters: {
    contractNumber: string
    dateTo: string
    dateFrom: string
    status: string
    company: string
    lastName: string
  }
  materialFilters: {
    name: string
  }
  productionFilters: {
    contractNumber: string
    dateTo: string
    dateFrom: string
  }
  fetchingModuleOrders: boolean
  pageModuleOrders: number
}

const initialState: OrderState = {
  orders: [],
  isOrderEdit: false,
  hasRightsForEdit: false,
  orderToEdit: {
    disabledFields: {
      productionDateTo: false,
      deliveryDateFrom: false,
      deliveryDateTo: false,
      size: false,
      note: false,
      checkboxCustomSize: false,
      prepaymentReceived: false,
      backwall: false,
      productSupport: false,
      phone: false,
      materials: {
        material: false,
        provider: false,
        additionalMaterial: false,
        additionalProvider: false,
        price: false,
        additionalPrice: false
      },
      goods: {
        quantity: false
      },
      docsBtn: false
    }
  } as IOrder & { disabledFields: IDisabledFields },
  orderToEditMP: {
    disabledFields: {
      productionDateTo: false,
      deliveryDateFrom: false,
      deliveryDateTo: false,
      size: false,
      note: false,
      checkboxCustomSize: false,
      prepaymentReceived: false,
      backwall: false,
      productSupport: false,
      phone: false,
      materials: {
        material: false,
        provider: false,
        additionalMaterial: false,
        additionalProvider: false,
        price: false,
        additionalPrice: false
      },
      goods: {
        quantity: false
      },
      docsBtn: false
    }
  } as IOrder & { disabledFields: IDisabledFields },
  hasRightsForEditMP: false,
  isOrderEditMP: false,
  isShowFormCreateOrder: false,
  isShowFormMP: false,
  isEditFormCreateOrder: true,
  isShowFormCreateOrderToWarehouse: false,
  filters: {
    contractNumber: '',
    dateTo: '',
    dateFrom: '',
    status: '',
    company: '',
    lastName: ''
  },
  materialFilters: {
    name: ''
  },
  productionFilters: {
    contractNumber: '',
    dateTo: '',
    dateFrom: '',
  },
  fetchingModuleOrders: false,
  pageModuleOrders: 1
}

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setFormOrderCreate: (state, action) => {
      state.isShowFormCreateOrder = action.payload
    },
    setFormOrderForMP: (state, action) => {
      state.isShowFormMP = action.payload
    },
    setEditFormOrderCreate: (state, action) => {
      state.isEditFormCreateOrder = action.payload.isEditFormCreateOrder
    },
    setFormCreateOrderToWarehouse: (state, action) => {
      state.isShowFormCreateOrderToWarehouse = action.payload
    },
    setOrderToEdit: (state, action) => {
      state.isOrderEdit = action.payload.edit
      state.hasRightsForEdit = action.payload.hasRightsForEdit
      state.orderToEdit = action.payload.orderToEdit
    },
    setOrderToEditMP: (state, action) => {
      state.isOrderEditMP = action.payload.edit
      state.hasRightsForEditMP = action.payload.hasRightsForEdit
      state.orderToEditMP = action.payload.orderToEdit
    },
    setFilters: (state, action) => {
      state.filters = {...state.filters, ...action.payload}
    },
    setMaterialFilters: (state, action) => {
      state.materialFilters = {...state.filters, ...action.payload}
    },
    setProductionFilters: (state, action) => {
      state.productionFilters = {...state.productionFilters, ...action.payload}
    },
    resetFilters: (state) => {
      state.filters = {
        contractNumber: '',
        dateTo: '',
        dateFrom: '',
        status: '',
        company: '',
        lastName: ''
      }
      state.productionFilters = {
        contractNumber: '',
        dateTo: '',
        dateFrom: '',
      }
    },
    addOrders: (state, action) => {state.orders = [...state.orders, ...action.payload]},
    resetOrders: (state) => {state.orders = []},
    setPageModuleOrders: (state, action) => {state.pageModuleOrders = action.payload},
    setFetchingModuleOrders: (state, action) => {state.fetchingModuleOrders = action.payload},
  }
})

export const {
  setFormOrderCreate,
  setFormOrderForMP,
  setEditFormOrderCreate,
  setOrderToEdit,
  setOrderToEditMP,
  setFormCreateOrderToWarehouse,
  setFilters,
  setMaterialFilters,
  setProductionFilters,
  resetFilters,
  addOrders,
  resetOrders,
  setPageModuleOrders,
  setFetchingModuleOrders,
} = orderSlice.actions
export default orderSlice.reducer
export const getOrderToEdit = (state: RootState) => state.orderSlice?.orderToEdit
export const getOrderToEditMP = (state: RootState) => state.orderSlice?.orderToEditMP
export const getOrderToEditDoc = (state: RootState) => state.orderSlice?.orderToEdit?.documents
export const getHasRights = (state: RootState) => state.orderSlice.hasRightsForEdit
export const getIsOrderEdit = (state: RootState) => state.orderSlice.isOrderEdit
export const getHasRightsMP = (state: RootState) => state.orderSlice.hasRightsForEditMP
export const getIsOrderEditMP = (state: RootState) => state.orderSlice.isOrderEditMP
export const getIsEditFormCreateOrder = (state: RootState) => state.orderSlice.isEditFormCreateOrder
export const getIsShowFormCreateOrderToWarehouse = (state: RootState) => state.orderSlice.isShowFormCreateOrderToWarehouse
export const getFilters = (state: RootState) => state.orderSlice.filters
export const getMaterialFilters = (state: RootState) => state.orderSlice.materialFilters
export const getProductionFilters = (state: RootState) => state.orderSlice.productionFilters
export const getDisabledFieldsFromEditOrder = (state: RootState) => state.orderSlice.orderToEdit?.disabledFields
export const getOrders = (state: RootState) => state.orderSlice.orders
export const getPageModuleOrders = (state: RootState) => state.orderSlice.pageModuleOrders
export const getFetchingModuleOrders = (state: RootState) => state.orderSlice.fetchingModuleOrders
