import React, { FC, useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'

import { IMaterialItem } from '../../../../models/IMaterial'
import { transformDate } from '../../../../helpers/transformDate'
import { useChangeMaterialMutation } from '../../../../services/materialOrder.api'
import { useCreateDocumentMutation } from '../../../../services/document.api'
import useOnClickOutside from '../../../../hooks/useOnClickOutside'
import { downloader } from '../../../../helpers/downloader'

import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { Button, Col, DatePicker, Form, Input, Modal, notification, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import MaterialCard from '../../../../components/MaterialCard/MaterialCard'

import s from '../AwaitingReceipt/AwaitingReceipt.module.scss'

interface IProps {
  item: IMaterialItem
  fetchOrder: (OrderId: number) => void
  moduleType?: 'archive'
}

const CardAwaiting: FC<IProps> = ({item, fetchOrder, moduleType}) => {
  const [isChange, setIsChange] = useState<boolean>(false)
  const [awaiting, setAwaiting] = useState(false)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [changeMaterial] = useChangeMaterialMutation()
  const [createDocument, {data}] = useCreateDocumentMutation()
  const [form] = Form.useForm()
  const refCard = useRef(null)

  useEffect(() => {
    if (data) {
      downloader(data[0].link, data[0].name, '_blank', false)
    }
  }, [data])

  useOnClickOutside(refCard, async (e) => {
    if (openCalendar || !isChange) return null

    if (form.isFieldsTouched()) {
      form.submit()
    } else {
      setIsChange(false)
    }
  })

  const onFinish = async (values: any) => {
    setAwaiting(true)

    try {
      const response = await changeMaterial({
        id: item.id,
        data: {
          quantity: values.quantity,
          delivery_date_to: values['date'].format('YYYY-MM-DD'),
          note: values.note
        }
      })
      // @ts-ignore
      if (response?.data) {
        api.success({
          message: 'Новые данные сохранены',
          placement: 'topRight',
          duration: 3
        })

        setIsChange(false)
      } else {
        api.error({
          message: 'Не удалось изменить данные',
          placement: 'topRight',
          duration: 3
        })
        setIsChange(false)
        form.resetFields()
      }

      setAwaiting(false)
    } catch (e) {
      console.log(e)
      api.error({
        message: 'Не удалось изменить данные',
        placement: 'topRight',
        duration: 3
      })
    }
  }

  const openChangeOrder = () => setIsChange(true)
  const closeChangeOrder = () => setIsChange(false)

  const onBlurCalendar = () => setOpenCalendar(false)
  const onFocusCalendar = () => setOpenCalendar(true)

  const confirmReceipt = () => {
    setAwaiting(true)
    setIsChange(false)

    changeMaterial({
      id: item.id,
      data: {
        status: 'loaded',
      }
    })
      .then((response) => {
        setIsChange(false)
        // @ts-ignore
        if (response?.data) {
          api.success({
            message: 'Подтверждение получения прошло успешно',
            placement: 'topRight',
            duration: 3
          })
        } else {
          api.error({
            message: 'Не удалось подтвердить получение',
            placement: 'topRight',
            duration: 3
          })
        }
      })
      .catch((err) => {
        console.log(err)
        api.error({
          message: 'Не удалось подтвердить получение',
          placement: 'topRight',
          duration: 3
        })
      })
      .finally(() => {
        setAwaiting(false)
        setConfirmModal(false)
      })

    createDocument({
      document_name: 'plate',
      material_order: item.hydraMemberID,
      product_order: item.productOrder.productOrderIRI
    })
  }
  const onCancelConfirm = () => {
    setConfirmModal(false)
    if (form.isFieldsTouched()) {
      setIsChange(true)
    }
  }
  const onConfirm = () => {
    setConfirmModal(true)
    setIsChange(false)
  }

  return (
    <>
      <div ref={refCard}
           style={{
             position: 'relative',
             border: moduleType === 'archive'
               ? 'none'
               : dayjs(item.deliveryDateTo).isBefore(dayjs()) ? '2px solid #810808' : 'none',
             borderRadius: '8px'
           }}>
        {awaiting && <LoaderFullDisplay/>}
        {contextHolder}
        <MaterialCard item={item}
                      closeChangeOrder={closeChangeOrder}
                      openChangeOrder={openChangeOrder}
                      fetchOrder={fetchOrder}
                      moduleType={moduleType}
        >
          <Form onFinish={onFinish}
                name={'getting-material'}
                form={form}
                layout="vertical"
                disabled={!isChange}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="quantity" label="Количество" initialValue={item.quantity}>
                  <Input style={{height: '44px'}}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="date"
                           label={item.status === 'loaded' ? 'Дата доставки' : 'Ожидаемая дата доставки'}
                           initialValue={dayjs(item.deliveryDateTo, 'YYYY.MM.DD')}
                           style={{position: 'relative'}}
                >
                  <DatePicker style={{width: '100%', height: '44px'}}
                              placeholder={'Выберите дату'}
                              suffixIcon={false}
                              format="DD.MM.YYYY"
                              showToday={false}
                              open={openCalendar}
                              onBlur={onBlurCalendar}
                              onFocus={onFocusCalendar}

                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="note"
                       label="Комментарий"
                       style={{marginBottom: 0}}
                       initialValue={item.note}
            >
              <TextArea style={{resize: moduleType === 'archive' ? 'none' : 'vertical'}}/>
            </Form.Item>
          </Form>

          <Row gutter={20} justify={'space-between'} align={'middle'}>
            <Col span={12} className={s.dateCreated}>
              Дата создания: {transformDate(item.createdAt)}
            </Col>

            {
              moduleType !== 'archive' && (
                <Col>
                  <Button type={'primary'}
                          size={'large'}
                          onClick={onConfirm}
                          disabled={isChange}
                  >Подтвердить получение</Button>
                </Col>
              )
            }
          </Row>
        </MaterialCard>
      </div>

      <Modal
        title="Подтвердите получение заказа"
        open={confirmModal}
        onOk={confirmReceipt}
        onCancel={onCancelConfirm}
        okText="Сохранить"
        cancelText="Отмена"
        centered
        closable={false}
      >
        <p>Отменить действие будет невозможно</p>
      </Modal>
    </>
  )
}

export default CardAwaiting