import React, { useRef } from 'react'
import { Button, notification } from 'antd'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { downloader } from '../../helpers/downloader'
import { baseUrlV1 } from '../../configs/api/api.config'
import { selectUser } from '../../store/reducers/user/user.slice'
import { useAppSelector } from '../../hooks/redux'
import { UserRoles } from '../../configs/enums/userRoles'

const DashboardMP = () => {
  const [api, contextHolder] = notification.useNotification()
  const token = localStorage.getItem('tokensLL')
  const user = useAppSelector(selectUser)
  const refMappings = useRef<HTMLInputElement | null>(null)

  const getFiles = async (link: string) => {
    try {
      const res = await fetch(link,
        {headers: {Authorization: `Bearer ${JSON.parse(token || '').token}`}}
      ).then(res => res.json())
      if (res?.file) {
        downloader(res.file, res.file.split('/').at(-1))
      }
    } catch (e) {
      api.error({message: 'Не удалось получить документ'})
    }
  }
  const onChange = async (e: any, address: string) => {
    if (!e.target?.files?.[0]) {
      return
    }
    const bodyFormData = new FormData()
    bodyFormData.append('file', e.target.files?.[0])

    try {
      const res = await fetch(
        address,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${JSON.parse(token || '').token}`,
          },
          body: bodyFormData
        }
      ).then((res) => res.json())

      if (res?.message) {
        api.success({message: res.message || 'Загрузка документа завершена'})
      }
      if (res?.errors) {
        api.error({
          message: <>
            <div>Не удалось загрузить документ</div>
            {
              Array.isArray(res.errors) && (
                <div>
                  {
                    res.errors.slice(0, 5).map((item: string, idx: number) => <div key={idx}>{item}</div>)
                  }
                  <div>...</div>
                </div>
              )
            }
          </>
        })
      }
    } catch (e) {
      api.error({message: 'Не удалось загрузить документ'})
    } finally {
      if (refMappings && refMappings.current) {
        refMappings.current.value = ''
      }
    }
  }

  if (!user.userRole?.userRoleIRI || user.userRole?.userRoleIRI !== UserRoles.ROLE_OWNER) {
    return null
  }

  return (
    <div className="bg-white rounded-[10px] p-[24px]">
      {contextHolder}
      <div className="mb-[48px]">
        <h2 className="font-semibold text-xl text-[#263B53] mb-[24px]">Справочник товаров на маркетплейсах</h2>
        <div className="flex gap-[24px]">
          <Button icon={<DownloadOutlined/>}
                  className="flex items-center text-[#263B53] text-base p-[8px_64px]"
                  onClick={() => getFiles(`${baseUrlV1}/export/marketplace-mappings`)}
          >Выгрузить справочник</Button>
          <Button icon={<UploadOutlined/>}
                  className="flex items-center text-[#263B53] text-base p-[8px_64px] relative"
          >
            Загрузить справочник
            <input
              ref={refMappings}
              type="file"
              accept=".xlsx"
              onChange={(e) => onChange(e, `${baseUrlV1}/import/marketplace-mappings`)}
              className="absolute top-0 left-0 w-full h-full z-[1] opacity-0"
            />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default DashboardMP