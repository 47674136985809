import React, { FC, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  ConfigProvider,
  Form,
  FormListFieldData,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Typography
} from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { ORDER_TYPES } from '../../../../../../../configs/enums/orderTypes'
import classNames from 'classnames'
import {
  useLazyGetProductPricesQuery,
  useLazyGetProductsQuery,
  useLazyGetProductStockItemsQuery
} from '../../../../../../../services/product.api'
import { IProduct, IProductOrderItem } from '../../../../../../../models/IProduct'

const {Text} = Typography

interface IProps {
  formName: string
  field: FormListFieldData
  indexField: number
  removeField: (index: number | number[]) => void
  sendOnBlurGoods: () => void
  deleteGoodOption: (id: number) => void
}

const RenderGoodItem: FC<IProps> = ({
                                      field,
                                      indexField,
                                      removeField,
                                      sendOnBlurGoods,
                                      deleteGoodOption
                                    }) => {
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [productsList, setProductsList] = useState<IProduct[]>([])
  const [isModalDeleteGoodOption, setIsModalDeleteGoodOption] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const [productStockItems, setProductStockItems] = useState<IProductOrderItem[]>([])
  const [getProductStockItems, {
    data: productStockItemsQuery,
    isSuccess: isSuccessProductStockItems
  }] = useLazyGetProductStockItemsQuery()
  const [getProductPrice, {data: productPrice}] = useLazyGetProductPricesQuery()
  const [
    getProducts, {data: products, isSuccess: isSuccessProducts}
  ] = useLazyGetProductsQuery()
  const form = Form.useFormInstance()
  const orderType = Form.useWatch(`orderType`, form)
  const good = Form.useWatch('good', form)
  const productName = form.getFieldValue(['good', indexField, 'productName'])
  const productId = form.getFieldValue(['good', indexField, 'productId'])

  useEffect(() => {
    if (products?.length && isSuccessProducts) {
      setProductsList(products)
    } else {
      setProductsList([])
    }
    // eslint-disable-next-line
  }, [products])

  useEffect(() => {
    if (good) {
      const price = good[field.name]?.price
      const quantity = good[field.name]?.quantity
      const discount = good[field.name]?.discount
      const productIRI = good[field.name].productIRI
      const materialPrice = good[field.name]?.price
      const optionIRI = good[indexField].productIRI
      const queryType = [ORDER_TYPES.RETAIL, ORDER_TYPES.STOCK].includes(orderType) ? 'single' : 'wholesale'

      if (indexField > 0 && (optionIRI || productIRI)) {
        getProductPrice({
          product: optionIRI || productIRI,
          type: queryType,
          materialPrice: `${materialPrice}`
        })
      }

      const comp = Number(price) * Number(quantity)
      const compSum = () => {
        const result = (discount && quantity && price)
          ? (comp - (comp * +discount) / 100).toFixed(0)
          : comp.toFixed(0)

        return !isNaN(+result) ? result : ''
      }

      form.setFieldValue(['good', field.name, 'cost'], comp || '')
      form.setFieldValue(['good', field.name, 'sum'], compSum() || '')
    }
    // eslint-disable-next-line
  }, [good])

  const handleSearch = (newValue: string) => {
    getProducts({name: newValue, type: 'option'})
    setProductsList([])
  }
  const onBlurProduct = () => {
    setIsOpenSearch(false)
    // sendOnBlurGoods()
  }

  const onDeleteGoodOption = async () => {
    setIsModalDeleteGoodOption(false)
    try {
      const res: any = await deleteGoodOption(productId)

      if ('error' in res) {
        api.error({
          message: `Не удалось удалить! Статус: ${res?.error?.status}`,
          duration: 3
        })
      } else {
        removeField(indexField)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const onRemoveField = () => {
    const productIdForDelete = form.getFieldValue('good')?.[field.name]?.productId
    if (productIdForDelete) {
      setIsModalDeleteGoodOption(true)
    } else {
      removeField(indexField)
    }
  }
  return (
    <>
      {contextHolder}
      <Row gutter={[12, 12]}>
        <Col flex={'56px'}>
          <Form.Item label={indexField === 0 ? <>№</> : ''}>
            <Input disabled={true}
                   className={classNames(
                     'w-[44px] h-[44px] flex items-center justify-center self-start',
                     'bg-[#F1F3F6] border border-[#F1F3F6] rounded-[8px] text-center p-[5px] text-base leading-[22px] text-baseColor')}
                   value={indexField + 1}/>
          </Form.Item>
        </Col>
        <Col flex={'1 0 286px'}>
          <ConfigProvider theme={{
            token: {
              controlHeight: 15,
            },
            components: {
              Select: {
                controlHeight: 44
              },
            },
          }}>
            <Form.Item name={[field.name, 'productName']}
              // style={{marginBottom: '12px'}}
                       label={indexField === 0 ? <>Наименование товара<Text type={'danger'}>*</Text></> : ''}
                       labelAlign={'left'}
            >
              <AutoComplete
                open={isOpenSearch}
                className="font-regular text-base leading-[22px] text-baseColor disabled:text-baseColor disabled:border-none"
                placeholder={'Выберите товар'}
                options={
                  productsList
                    ? productsList.map((item) => ({
                      key: item.id,
                      value: item.name,
                      label: item.name,
                      // key: item.product.productIRI,
                      // value: item.product.name,
                      // label: item.product.name,
                      // stockItemIRI: item.productIRI,
                      // id: item.product.id,
                      // productSizeValue: item.productSize.value,
                      // productSizeIRI: item.productSize.productSizeIRI,
                      // materials: item.materials,
                      // productSupport: item.productSupport.name,
                      // productSupportIRI: item.productSupport.productSupportIRI,
                      // hasBackwall: item.hasBackwall,
                      // price: item.price,
                    }))
                    : []
                }
                onSearch={handleSearch}
                // onSelect={onSelect}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearch(true)}
                onBlur={onBlurProduct}
                disabled={indexField === 0}
                // onChange={onChangeProductName}
              />
            </Form.Item>
          </ConfigProvider>
        </Col>
        <Col flex={'113px'}>
          <Form.Item name={[field.name, 'price']}
                     style={{marginBottom: '12px', alignItems: 'center'}}
                     rules={[{required: true, message: 'Обязательное поле'}]}
                     label={indexField === 0 ? <>Цена<Text type={'danger'}>*</Text></> : ''}
          >
            <Input type={'number'}
                   style={{height: '44px'}}
                   className="font-regular text-base leading-[22px] text-baseColor disabled:text-baseColor disabled:border-none"
                   onBlur={sendOnBlurGoods}
                   disabled={indexField === 0}
            />
          </Form.Item>
        </Col>
        <Col flex={'95px'}>
          <Form.Item name={[field.name, 'quantity']}
                     style={{marginBottom: '12px'}}
                     label={indexField === 0 ? 'Кол-во' : ''}
          >
            <InputNumber min={1} max={99999}
                         size={'large'}
                         className="font-regular text-base leading-[22px] text-baseColor disabled:text-baseColor disabled:border-none"
                         style={{width: '100%', height: '44px'}}
                         maxLength={5}
                         onBlur={sendOnBlurGoods}
                         disabled={indexField === 0}
            />
          </Form.Item>
        </Col>
        <Col flex={'113px'}>
          <Form.Item name={[field.name, 'cost']}
                     style={{marginBottom: '12px'}}
                     label={indexField === 0 ? 'Стоимость' : ''}
          >
            <Input type={'text'}
                   style={{height: '44px'}}
                   className="font-regular text-base leading-[22px] text-baseColor disabled:text-baseColor disabled:border-none"
                   disabled={true}
            />
          </Form.Item>
        </Col>
        <Col flex={'95px'}>
          <Form.Item name={[field.name, 'discount']}
                     style={{marginBottom: '12px', display: 'flex', alignItems: 'center'}}
                     label={indexField === 0 ? 'Скидка %' : ''}
          >
            <InputNumber min={1} max={100}
                         maxLength={3}
                         size={'large'}
                         className="font-regular text-base leading-[22px] text-baseColor disabled:text-baseColor disabled:border-none"
                         style={{width: '100%', height: '44px'}}
                         onBlur={sendOnBlurGoods}
                         disabled={indexField === 0}
            />
          </Form.Item>
        </Col>
        <Col flex={'150px'}>
          <Space direction={'horizontal'}>
            <Form.Item name={[field.name, 'sum']}
                       style={{marginBottom: '12px'}}
                       label={indexField === 0 ? 'Стоим. со скидкой' : ''}
            >
              <Input type={'text'}
                     className="font-regular text-base leading-[22px] text-baseColor disabled:text-baseColor disabled:border-none"
                     style={{height: '44px'}}
                     disabled={true}
              />
            </Form.Item>
            <Form.Item style={{marginBottom: '12px'}}>
              {
                indexField !== 0 && <Button type={'ghost'}
                                            style={{width: '20px'}}
                                            onClick={onRemoveField}
                                            icon={<CloseOutlined style={{height: '44px'}}/>}
                />
              }
            </Form.Item>
          </Space>
        </Col>
      </Row>

      <Modal
        open={isModalDeleteGoodOption}
        onOk={onDeleteGoodOption}
        onCancel={() => setIsModalDeleteGoodOption(false)}
        okText="Да"
        cancelText="Нет"
        centered
        closable={false}
      >
        Вы действительно хотите удалить {productName}?
      </Modal>
    </>
  )
}

export default RenderGoodItem