import React, { FC, useState } from 'react'
import { IconProps } from './icons.types'

const Basket: FC<IconProps> = ({
                                 width = 16,
                                 height = 17,
                                 fill = 'none',
                                 stroke = '#B2BAC3',
                                 strokeHover = '#4D92EE'
                               }) => {
  const [hover, setHover] = useState(false)

  return (
    <svg width={width}
         height={height}
         fill={fill}
         onMouseMove={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         viewBox="0 0 16 17"
         xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_131_10335)">
        <path d="M12.0001 2.17188V3.50521M4.00012 2.17188V3.50521" stroke={hover ? strokeHover : stroke} strokeLinecap="round"
              strokeLinejoin="round"/>
        <path
          d="M7.99705 9.50488H8.00303M7.99705 12.1715H8.00303M10.6607 9.50488H10.6667M5.33337 9.50488H5.33936M5.33337 12.1715H5.33936"
          stroke={hover ? strokeHover : stroke} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.33337 6.17188H13.6667" stroke={hover ? strokeHover : stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path
          d="M1.66675 9.00103C1.66675 6.09616 1.66675 4.64372 2.50149 3.74129C3.33624 2.83887 4.67974 2.83887 7.36675 2.83887H8.63341C11.3204 2.83887 12.6639 2.83887 13.4987 3.74129C14.3334 4.64372 14.3334 6.09616 14.3334 9.00103V9.34337C14.3334 12.2482 14.3334 13.7007 13.4987 14.6031C12.6639 15.5055 11.3204 15.5055 8.63341 15.5055H7.36675C4.67974 15.5055 3.33624 15.5055 2.50149 14.6031C1.66675 13.7007 1.66675 12.2482 1.66675 9.34337V9.00103Z"
          stroke={hover ? strokeHover : stroke} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.00012 6.17188H14.0001" stroke={hover ? strokeHover : stroke} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_131_10335">
          <rect width="16" height="16" fill="white" transform="translate(0 0.837891)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Basket