import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './api'
import { IUserRole } from '../models/IUser'
import { UserRoles } from '../configs/enums/userRoles'

export const userRolesApi = createApi({
  reducerPath: 'UserRoles',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['UserRoles'],
  endpoints: (build) => ({
    getUserRoles: build.query<IUserRole[], {
      codes?: string | string[]
    }>({
      query: ({codes}) => {
        let collectCodes = ''
        if (codes) {
          collectCodes += Array.isArray(codes)
            ? codes.reduce((acc, i, idx) => acc += `${idx === 0 ? '' : '&'}code[]=${i}`, '')
            : `code[]=${codes}`
        }

        return ({
          url: `/user-roles?${collectCodes ? collectCodes : ''}`
        })
      },
      transformResponse: (response: any) => {
        return response['hydra:member'].map((role: any) => ({
          userRoleIRI: role['@id'],
          id: role.id,
          name: role.name,
          code: role.code
        }))
      },
    })
  })
})

export const {useGetUserRolesQuery, useLazyGetUserRolesQuery} = userRolesApi
