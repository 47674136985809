import React, { FC, useEffect, useState } from 'react'
import { Form, notification, Radio, RadioChangeEvent } from 'antd'
import IndividualPerson from './components/IndividualPerson/IndividualPerson'
import LegalPerson from './components/LegalPerson/LegalPerson'
import { IOrder } from '../../../../models/IOrder'
import { getHasRights, getIsOrderEdit, getOrderToEdit } from '../../../../store/reducers/order/order.slice'
import { useAppSelector } from '../../../../hooks/redux'
import {
  useChangeContactMutation,
  useGetContactSourceQuery,
  useLazyGetContactsQuery
} from '../../../../services/contact.api'
import { useSelector } from 'react-redux'
import { useDebounce } from '../../../../hooks/useDebounce'
import { useChangeOrderMutation } from '../../../../services/order.api'
import { ContactType } from '../../../../configs/enums/contactType'

import s from './Client.module.scss'

interface IProps {
  orderData: IOrder | undefined
  formName?: string
  isEditable: boolean
}

const Client: FC<IProps> = ({ orderData, formName, isEditable}) => {
  const hasRightsToEditing = useAppSelector(getHasRights)
  const isOrderEdit = useAppSelector(getIsOrderEdit)
  const order = useSelector(getOrderToEdit)
  const [clientType, setClientType] = useState(ContactType[1])
  const [api, contextHolder] = notification.useNotification()
  const form = Form.useFormInstance()
  const lastName = Form.useWatch('contactLastName', form)
  const firstName = Form.useWatch('contactFirstName', form)
  const middleName = Form.useWatch('contactMiddleName', form)

  const [changeContact, {
    isSuccess: isSuccessChangeContactData,
    isError: isErrorChangeContactData
  }] = useChangeContactMutation()
  const [getContacts, {
    data: dataContact
  }] = useLazyGetContactsQuery()
  const {data: dataContactSource} = useGetContactSourceQuery()
  const [changeContactForOrder] = useChangeOrderMutation()

  useEffect(() => {
    if (isSuccessChangeContactData) {
      api.success({
        message: `Данные контрагента успешно изменены`,
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isSuccessChangeContactData])
  useEffect(() => {
    if (isErrorChangeContactData) {
      api.error({
        message: `Данные контрагента не удалось изменить`,
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isErrorChangeContactData])
  useEffect(() => {
    if (orderData?.contact) {
      setClientType(orderData.contact.type)
    }
  }, [orderData])
  useEffect(() => {
    if (isOrderEdit) {
      form.setFieldValue(['phone'], orderData?.contact?.phone)
      form.setFieldValue(['email'], orderData?.contact?.email)
      form.setFieldValue(['additionalContact'], orderData?.contact?.additionalContacts)
      form.setFieldValue(['deliveryAddress'], orderData?.contact?.address)

      if (clientType === ContactType[1] && orderData?.contact?.firstName) {
        form.setFieldValue(['contactFirstName'], orderData?.contact?.firstName)
        form.setFieldValue(['contactLastName'], orderData?.contact?.lastName)
        form.setFieldValue(['contactMiddleName'], orderData?.contact?.middleName)
      } else if (clientType === ContactType[2]) {
        form.setFieldValue(['fullName'], orderData?.contact?.fullName)
        form.setFieldValue(['additionalContact'], orderData?.contact?.additionalContacts)
        form.setFieldValue(['juriName'], orderData?.contact?.juriName)
        form.setFieldValue(['inn'], orderData?.contact?.inn)
        form.setFieldValue(['kpp'], orderData?.contact?.kpp)
        form.setFieldValue(['ogrn'], orderData?.contact?.ogrn)
        form.setFieldValue(['bik'], orderData?.contact?.bik)
        form.setFieldValue(['bank'], orderData?.contact?.bankName)
        form.setFieldValue(['corrAccount'], orderData?.contact?.corrAccount)
        form.setFieldValue(['giroAccount'], orderData?.contact?.payAccount)
        form.setFieldValue(['juriAddress'], orderData?.contact?.yuriAddress)
        form.setFieldValue(['deliveryAddress'], orderData?.contact?.address)
        form.setFieldValue(['fullNameDirector'], orderData?.contact?.managerFullname)
      }
    }
  }, [orderData, isOrderEdit, clientType])

  const onBlurField = (value: string, field: string) => {
    let dataFullName: { 'full_name'?: string } = {}
    const fullName = `${lastName} ${firstName} ${middleName}`
    if (['first_name', 'middle_name', 'second_name'].includes(field)) {
      dataFullName['full_name'] = fullName
    }

    if (orderData && isOrderEdit && hasRightsToEditing) {
      changeContact({
        id: orderData.contact.id,
        data: {
          contact: orderData.contact.hydraContactID,
          [field]: value,
          ...dataFullName
        }
      })
    }
  }

  const changeContactDataForOrder = (contactIRI: string) => {
    if (orderData && isOrderEdit && hasRightsToEditing) {
      changeContactForOrder({
        id: order.id,
        data: {
          contact: contactIRI
        }
      })
    }
  }

  const onSearchContact = useDebounce((value: any) => {
    getContacts({
      page: 1,
      [clientType === ContactType[1] ? 'phone' : 'juriName']: value,
      type: clientType
    })
  }, 1000)

  const onChangeClientType = (e: RadioChangeEvent) => {
    form.resetFields(['phone', 'email'])
    form.setFieldValue(['contactType'], e.target.value)
    setClientType(e.target.value)
  }

  return (
    <>
      {contextHolder}
      <div className={s.client}>
        {
          formName === 'newOrder' &&
          <Form.Item name="clientType"
                     initialValue={orderData?.contact?.type ? ContactType[orderData?.contact?.type] : clientType}
          >
            <Radio.Group onChange={onChangeClientType} className={s.clientType}>
              <Radio value={ContactType[1]}>Физ.лицо</Radio>
              <Radio value={ContactType[2]}>Юр.лицо</Radio>
            </Radio.Group>
          </Form.Item>
        }
        {
          clientType === ContactType[1]
            ? <IndividualPerson onBlurField={onBlurField}
                                onSearchContact={onSearchContact}
                                dataContact={dataContact || []}
                                dataContactSource={dataContactSource || []}
                                changeContactDataForOrder={changeContactDataForOrder}
                                formName={formName}
            />
            : <LegalPerson onBlurField={onBlurField}
                           onSearchContact={onSearchContact}
                           dataContact={dataContact || []}
                           dataContactSource={dataContactSource || []}
                           changeContactDataForOrder={changeContactDataForOrder}
                           formName={formName}
            />
        }
      </div>
    </>
  )
}

export default Client