import React, { FC, useEffect, useState } from 'react'
import { ConfigProvider, Input, notification, Table, TableProps } from 'antd'

import { useChangeFabricMutation, useLazyGetFabricsQuery } from '../../services/fabric.api'
import { IFabric } from '../../models/IFabric'

import { useAppSelector } from '../../hooks/redux'
import { getMaterialFilters } from '../../store/reducers/order/order.slice'

const QuantityCell: FC<{
  id: number,
  initialQuantity: number,
  api: any
}> = ({id, initialQuantity = 0, api}) => {
  const [quantity, setQuantity] = useState(initialQuantity)
  const [changeFabric] = useChangeFabricMutation()

  const changeMaterial = async () => {
    if (+initialQuantity === +quantity){
      return
    }

    try {
      const res = await changeFabric({id, data: {quantity}})

      if ('data' in res) {
        api.success({message: 'Количество ткани успешно изменено'})
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      changeMaterial()
    }
  }
  const onChange = (e: any) => {
    if (!(/^[0-9]*[.,]?[0-9]*$/.test(e.target.value))) {
      return
    }
    setQuantity(e.target.value)
  }
  const onBlur = async (e: any) => {
    setQuantity(e.target.value)
    changeMaterial()
  }

  return <Input value={quantity}
                style={{width: '100px'}}
                onKeyDown={onKeyDown}
                onChange={onChange}
                onBlur={onBlur}
                pattern="\d*"
  />
}

interface IProps {
  fetching: boolean
  stopFetching: () => void
  incrementPage: () => void
  setPage: (page: number) => void
  resetPage: () => void
  perPage: number
  page: number
}

const MaterialsInStock: FC<IProps> = (
  {fetching, stopFetching, incrementPage, setPage, resetPage, perPage = 10, page = 1}
) => {
  const filters = useAppSelector(getMaterialFilters)
  const [fabrics, setFabrics] = useState<IFabric[]>([])
  const [api, contextHolder] = notification.useNotification()
  const [getFabrics, {data: fabricsData, isLoading: isLoadingFabrics, isFetching}] = useLazyGetFabricsQuery()

  useEffect(() => {
    if (fabricsData) {
      setFabrics(state => [...state, ...fabricsData])
    }
  }, [fabricsData])

  useEffect(() => {
    setFabrics([])
    resetPage()
  }, [filters.name])
  useEffect(() => {
    if (fetching) {
      getFabrics({
        page: page,
        perPage: perPage,
        name: filters.name,
        sort: {
          quantity: 'desc',
          name: 'asc'
        }
      })
      incrementPage()
      stopFetching()
    }
  }, [fetching])
  useEffect(() => {
    if (isLoadingFabrics) {
      stopFetching()
    }
  }, [isLoadingFabrics])
  useEffect(() => {
    getFabrics({
      page: page,
      perPage: perPage,
      sort: {
        quantity: 'desc',
        name: 'asc'
      }
    })
    incrementPage()
  }, [])

  const columns: TableProps<IFabric>['columns'] = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      width: 230,
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'Поставщик',
      dataIndex: 'provider',
      width: 230,
      sortDirections: ['descend'],
    },
    {
      title: 'Цена (₽)',
      dataIndex: 'price',
      width: 230,
      sortDirections: ['descend'],
    },
    {
      title: 'В наличии (пог. м.)',
      dataIndex: 'quantity',
      width: 230,
      sorter: {
        compare: (a, b) => a.quantity - b.quantity
      },
      sortDirections: ['ascend', 'descend'],
      render: (text, row) => <QuantityCell initialQuantity={row.quantity} id={row.id} api={api}/>
    },
  ]

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            padding: 10
          },
        },
      }}
    >
      {contextHolder}
      <Table<IFabric>
        className="w-full"
        columns={columns}
        dataSource={
          fabrics ? fabrics.map((item, idx) => ({...item, key: idx})) : []
        }
        pagination={false}
        loading={isFetching}
        scroll={{x: 900}}
      />
    </ConfigProvider>
  )
}

export default MaterialsInStock