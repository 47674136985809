import React, { FC } from 'react'
import MaterialsInStock from './MaterialsInStock'
import InfiniteList from '../InfiniteList/InfiniteList'

const InfiniteListWrap = () => {
  return (
    <InfiniteList>
      {
        ({page, perPage, fetching, stopFetching, incrementPage, setPage, resetPage}:
           {
             page: number
             perPage?: number
             fetching: boolean
             stopFetching: () => void
             incrementPage: () => void
             setPage: (page: number) => void
             resetPage: () => void
           }) => (
          <MaterialsInStock
            fetching={fetching}
            stopFetching={stopFetching}
            incrementPage={incrementPage}
            setPage={setPage}
            resetPage={resetPage}
            page={page}
            perPage={50 || perPage}
          />
        )
      }
    </InfiniteList>
  )
}

export default InfiniteListWrap