import React, { FC } from 'react'
import type { TabsProps } from 'antd'
import { Tabs } from 'antd'

const defaultItems: TabsProps['items'] = [
  {
    key: '1',
    label: 'Tab 1',
    children: 'Content of Tab Pane 1',
  },
]

const TabsCustom: FC<{
  defaultActiveKey: string,
  items: TabsProps['items'],
  onChange?: (activeKey: string) => void,
}> = ({ defaultActiveKey, items, onChange }) => {
  const onChangeTab = (activeKey: string) => {
    if (onChange) {
      onChange(activeKey)
    }
  }

  return (
    <Tabs defaultActiveKey={defaultActiveKey || '1'}
          items={items || defaultItems}
          onChange={onChangeTab}
          animated={false}
    />
  )
}

export default TabsCustom