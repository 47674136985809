import React, { FC } from 'react'
import { Col, Row, Space, Spin } from 'antd'
import { useGetMaterialsQuery } from '../../../../services/materialOrder.api'
import CardAwaiting from '../CardAwaiting/CardAwaiting'

import s from './AwaitingReceipt.module.scss'

interface IProps {
  fetchOrder: (OrderId: number) => void
  moduleType?: 'archive'
}

const AwaitingReceipt: FC<IProps> = ({fetchOrder, moduleType}) => {
  // Требуется заказать - new
  // Ожидает поступления - waiting_for_load
  // Поступил - loaded
  const {data: materialsAwaiting, isLoading: isLoadingMaterial} = useGetMaterialsQuery({
    perPage: 150,
    page: 1,
    status: moduleType === 'archive' ? 'loaded' : 'waiting_for_load',
    sort: {
      [moduleType === 'archive' ? 'completedAt' : 'deliveryDateTo']: moduleType === 'archive' ? 'desc' : 'asc',
    }
  })

  return (
    <>
      <Space size={[12, 12]} wrap direction={'vertical'} className="!w-full">
        {
          moduleType !== 'archive' && (
            <div className={s.header}>
              <span>Ожидает поступления</span>
              <span>{materialsAwaiting?.length}</span>
            </div>
          )
        }
        {
          isLoadingMaterial &&
          <Row justify={'center'} align={'middle'} style={{width: '100%', height: '300px'}}>
            <Col span={2}>
              <Spin size="large"/>
            </Col>
          </Row>
        }
        <Row gutter={[24, 24]} justify={'space-between'}>
          {
            materialsAwaiting && materialsAwaiting.map(item => (
              <Col span={moduleType === 'archive' ? 12 : 24} key={item.id}>
                <CardAwaiting item={item} key={item.id} fetchOrder={fetchOrder} moduleType={moduleType}/>
              </Col>
            ))
          }
        </Row>
      </Space>
    </>
  )
}

export default AwaitingReceipt