export const optionNameCompany = [
  {value: 'liveline', label: 'ООО “Лив Лайн”'},
  {value: 'grachevV', label: 'ИП Грачев Владимир Владимирович'},
  {value: 'grachevV_c', label: '(с) ИП Грачев Владимир Владимирович'},
  {value: 'orfej', label: 'ООО “Орфей”'},
]

export const optionNameCompanyJur = [
  {value: 'liveline', label: 'ООО “Лив Лайн”'},
  {value: 'grachevV', label: 'ИП Грачев Владимир Владимирович'},
  {value: 'grachevV_c', label: '(с) ИП Грачев Владимир Владимирович'},
  {value: 'orfej', label: 'ООО “Орфей”'},
]
export const optionNameCompanyPhys = [
  {value: 'liveline', label: 'ООО “Лив Лайн”'},
  {value: 'grachevV', label: 'ИП Грачев Владимир Владимирович'},
  {value: 'grachevV_c', label: '(с) ИП Грачев Владимир Владимирович'},
]