import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../store/store'
import { baseUrlV1 } from '../configs/api/api.config'
import { logout, setCredentials } from '../modules/Authorization'
import { setUserData } from '../store/reducers/user/user.slice'

export interface IResponseWithIRI<T> {
  '@context': string
  '@id': string
  '@type': string
  'hydra:member': T[]
  'hydra:search': []
  'hydra:totalItems': number,
}

export const baseQuery = fetchBaseQuery({
  baseUrl: baseUrlV1,
  prepareHeaders: (headers, {getState, type}) => {
    const token = (getState() as RootState).authSlice.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    if (type === 'query') {
      headers.set('Accept', 'application/ld+json')
    }
    if (type === 'mutation') {
      headers.set('Accept', 'application/ld+json')
    }

    return headers
  }
})
export const baseQueryWithoutHeaders = fetchBaseQuery({
  baseUrl: baseUrlV1,
})

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401) {
    const refreshToken = (api.getState() as RootState).authSlice.refreshToken
    try {
      const refreshResult: any = await baseQueryWithoutHeaders(
        ({
          url: 'token/refresh',
          method: 'POST',
          body: {refresh_token: refreshToken},
        }),
        api,
        extraOptions
      )
      const me = await baseQuery(({
          url: '/me',
          method: 'GET',
        }),
        api,
        extraOptions
      )
      setUserData(me.data)

      if (refreshResult?.data) {
        api.dispatch(setCredentials({...refreshResult.data}))
        result = await baseQuery(args, api, extraOptions)
      } else {
        api.dispatch(logout())
      }
    } catch (e) {
      console.log(e)
    }
  }
  return result
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Contact',
    'Document',
    'Product',
    'Order',
    'Orders',
    'Materials',
    'Fabric',
    'UserRoles',
    'OrderProduction',
    'StockItems',
    'Users'
  ],
  endpoints: build => ({})
})
















