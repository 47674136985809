import React, { FC, useEffect } from 'react'
import { Col, DatePicker, Input, Row } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'

import { useAppDispatch } from '../../../hooks/redux'
import { resetFilters, setProductionFilters } from '../../../store/reducers/order/order.slice'
import Calendar from '../../../ui/icons/Calendar'

import s from './ProductionFilters.module.scss'

const {RangePicker} = DatePicker

const ProductionsFilters: FC<{ hideData?: boolean }> = ({hideData = false}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetFilters())
    }
  }, [])

  // Изменение фильтров для поиска заказов
  const onBlurInput = (e: React.ChangeEvent<HTMLInputElement>, key: 'contractNumber') => {
    dispatch(setProductionFilters({[key]: e.target.value}))
  }
  const onPressEnter = (e: any, key: 'contractNumber') => {
    dispatch(setProductionFilters({[key]: e.target.value}))
  }
  const onChangeDate = (values: any, formatString: [string, string]) => {
    dispatch(setProductionFilters({dateFrom: formatString[0], dateTo: formatString[1]}))
  }

  return (
    <Row gutter={[20, 20]} wrap={false} justify={'end'}>
      <Col flex={'0 0 170px'}>
        <Input className={s.field}
               placeholder="№ договора"
               onBlur={(e) => onBlurInput(e, 'contractNumber')}
               onPressEnter={(e) => onPressEnter(e, 'contractNumber')}
        />
      </Col>
      <Col flex={'0 0 300px'}>
        <RangePicker className={s.field}
                     locale={locale}
                     onChange={onChangeDate}
                     format="DD.MM.YYYY"
                     suffixIcon={<Calendar/>}
                     placeholder={['Начальная', 'конечная дата']}
        />
      </Col>
    </Row>
  )
}

export default ProductionsFilters