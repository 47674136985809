import { AutoComplete, Col, ConfigProvider, Form, Input, Row, Typography } from 'antd'
import React, { FC, useEffect, useRef, useState } from 'react'
import s from './LegalPerson.module.scss'
import { IContact, IContactSource } from '../../../../../../models/IContact'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { LabeledValue } from 'antd/es/select'
import Mail from '../../../../../../ui/icons/Mail'
import { PHONE_MASK } from '../../../../../../configs/phoneMask'
import classNames from 'classnames'
import { IMaskInput } from 'react-imask'
import useOnClickOutside from '../../../../../../hooks/useOnClickOutside'
import { getHasRights } from '../../../../../../store/reducers/order/order.slice'
import { useAppSelector } from '../../../../../../hooks/redux'
import { ORDER_TYPES } from '../../../../../../configs/enums/orderTypes'

const {Text} = Typography

interface IProps {
  formName?: string
  dataContact: IContact[]
  dataContactSource: IContactSource[]
  onBlurField: (e: string, fieldName: string) => void
  onSearchContact: (e: any) => void
  changeContactDataForOrder: (contactIRI: string) => void
}

const LegalPerson: FC<IProps> = ({
                                   dataContact,
                                   dataContactSource,
                                   onBlurField,
                                   onSearchContact,
                                   changeContactDataForOrder,
                                   formName
                                 }) => {
  const hasRightForEdit = useAppSelector(getHasRights)
  const [phoneError, setPhoneError] = useState(false)
  const [contact, setContact] = useState({} as IContact)
  const [focus, setFocus] = useState(false)
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const form = Form.useFormInstance()
  const phone = Form.useWatch('phone', form)
  const ref = useRef(null)

  useOnClickOutside(ref, () => setFocus(false))

  useEffect(() => {
    if (contact.phone) {
      form.setFieldValue(['contactIRI'], contact.hydraContactID)
      form.setFieldValue(['phone'], contact.phone)
      form.setFieldValue(['email'], contact.email)
      form.setFieldValue(['contacts'], contact.additionalContacts)
      form.setFieldValue(['addressDelivery'], contact.address)
      form.setFieldValue(['fullName'], contact.fullName)
      form.setFieldValue(['anotherContact'], contact.additionalContacts)
      // form.setFieldValue(['juriName'], contact.juriName)
      form.setFieldValue(['inn'], contact.inn)
      form.setFieldValue(['kpp'], contact.kpp)
      form.setFieldValue(['ogrn'], contact.ogrn)
      form.setFieldValue(['bik'], contact.bik)
      form.setFieldValue(['bank'], contact.bankName)
      form.setFieldValue(['corrAccount'], contact.corrAccount)
      form.setFieldValue(['giroAccount'], contact.payAccount)
      form.setFieldValue(['juriAddress'], contact.yuriAddress)
      form.setFieldValue(['deliveryAddress'], contact.address)
      form.setFieldValue(['fullNameDirector'], contact.managerFullname)
    }
  }, [contact.juriName])

  const onSelectContact = (option: any) => {
    form.setFieldValue(['juriName'], option.juriName)
    setContact(option)
    changeContactDataForOrder(option.hydraContactID)
  }

  const onSelectSource = (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['contactSource'], option.id)
    onBlurField(option.id, 'source')
  }

  return (
    <div className={s.legalPerson}>
      <Row className={s.marginBottom} gutter={[20, 12]}>
        <Col span={12}>
          <Text className={s.fieldLabel}>Источник клиента</Text>
          <Form.Item name={'contactSource'} initialValue={''}>
            <SelectCustom
              allowClear
              heightOptions={44}
              options={dataContactSource ? dataContactSource?.map(item => ({
                id: item.sourceIRI,
                value: item.sourceIRI,
                label: item.name
              })) : []}
              onSelect={onSelectSource}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Text className={s.fieldLabel}>
            ФИО контактного лица<Text type={'danger'} className={s.required}>*</Text>
          </Text>
          <Form.Item name={'fullName'}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e.target.value, 'full_name')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col span={7}>
          <Text className={s.fieldLabel}>
            Телефон<Text type={'danger'} className={s.required}>*</Text>
          </Text>
          <Form.Item name={'phone'}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <div className="relative" ref={ref}>
              <IMaskInput
                value={phone}
                mask={PHONE_MASK}
                placeholder="+7 (999) 999-99-90"
                onFocus={() => setFocus(true)}
                className={classNames(
                  'w-full h-[44px] p-[10px_15px] md:p-[8px_11px] rounded-[6px] text-baseGrey text-medium border border-[#d9d9d9] outline-none disabled:bg-[#0000000a] disabled:text-[#00000040]',
                  {
                    'border border-1 !border-[#4096ff]': focus,
                    // '!border-[#4096ff]': phoneError
                  }
                )}
                disabled={!hasRightForEdit && formName !== 'newOrder'}
              />
            </div>
          </Form.Item>
        </Col>

        <Col span={7}>
          <Text className={s.fieldLabel}>Почта</Text>
          <Form.Item name={'email'}>
            <Input type={'text'}
                   placeholder={'Введите почту'}
                   className={s.field}
                   prefix={<Mail/>}
                   onBlur={(e) => onBlurField(e.target.value, 'email')}
            />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Text className={s.fieldLabel}>Доп. контакты</Text>
          <Form.Item name={'additionalContact'}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e.target.value, 'additional_contacts')}/>
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col span={9}>
          <Text className={s.fieldLabel}>
            Юр. название<Text type={'danger'} className={s.required}>*</Text>
          </Text>
          <ConfigProvider theme={{
            token: {
              controlHeight: 15,
            },
            components: {
              Select: {
                controlHeight: 44
              },
            },
          }}>
            <Form.Item name={'juriName'} rules={[{required: true, message: 'Обязательное поле'}]}>
              <AutoComplete
                open={isOpenSearch}
                options={dataContact && dataContact.map((item) => ({
                  key: item.hydraContactID,
                  id: item.id,
                  value: item.juriName,
                  label: item.juriName,
                  data: item
                }))}
                onSearch={onSearchContact}
                onSelect={(val, option) => {
                  onSelectContact(option.data)
                }}
                backfill={true}
                defaultValue={''}
                onFocus={() => setIsOpenSearch(true)}
                onBlur={() => setIsOpenSearch(false)}
              />
            </Form.Item>
          </ConfigProvider>
        </Col>

        <Col span={5}>
          <Text className={s.fieldLabel}>ИНН</Text>
          <Form.Item name={'inn'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={12}
                   onBlur={(e) => onBlurField(e.target.value, 'inn')}
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Text className={s.fieldLabel}>КПП</Text>
          <Form.Item name={'kpp'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={9}
                   onBlur={(e) => onBlurField(e.target.value, 'kpp')}
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Text className={s.fieldLabel}>ОГРН/ОГРНИП</Text>
          <Form.Item name={'ogrn'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={13}
                   onBlur={(e) => onBlurField(e.target.value, 'ogrn')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col flex="155px">
          <Text className={s.fieldLabel}>БИК</Text>
          <Form.Item name={'bik'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={9}
                   onBlur={(e) => onBlurField(e.target.value, 'bik')}
            />
          </Form.Item>
        </Col>

        <Col flex="auto">
          <Text className={s.fieldLabel}>Банк</Text>
          <Form.Item name={'bank'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={50}
                   onBlur={(e) => onBlurField(e.target.value, 'bank_name')}
            />
          </Form.Item>
        </Col>

        <Col flex="auto">
          <Text className={s.fieldLabel}>Корреспондентский счёт</Text>
          <Form.Item name={'corrAccount'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={20}
                   onBlur={(e) => onBlurField(e.target.value, 'corr_account')}
            />
          </Form.Item>
        </Col>

        <Col flex="auto">
          <Text className={s.fieldLabel}>Расчётный счёт</Text>
          <Form.Item name={'giroAccount'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={20}
                   onBlur={(e) => onBlurField(e.target.value, 'pay_account')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col span={12}>
          <Text className={s.fieldLabel}>Юр. адрес</Text>
          <Form.Item name={'juriAddress'}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e.target.value, 'yuri_address')}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Text className={s.fieldLabel}>Адрес доставки<Text type={'danger'} className="leading-[17px]">*</Text></Text>
          <Form.Item name={'deliveryAddress'}
                     rules={[{required: true, message: 'Обязательное поле'}]}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e.target.value, 'address')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col span={24}>
          <Text className={s.fieldLabel}>ФИО директора</Text>
          <Form.Item name={'fullNameDirector'}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e.target.value, 'manager_fullname')}
            />
          </Form.Item>
        </Col>
      </Row>

    </div>
  )
}

export default LegalPerson