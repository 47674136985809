import React, { FC, useState } from 'react'
import { IconProps } from './icons.types'

const Basket: FC<IconProps> = ({
                                         width = 24,
                                         height = 25,
                                         fill = 'none',
                                         stroke = '#8A94A2',
                                         strokeHover = '#4D92EE'
                                       }) => {
  const [hover, setHover] = useState(false)

  return (
    <svg width={width}
         height={height}
         fill={fill}
         onMouseMove={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         viewBox="0 0 24 25"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 5.66895L18.8803 15.694C18.7219 18.2554 18.6428 19.5361 18.0008 20.4568C17.6833 20.912 17.2747 21.2962 16.8007 21.585C15.8421 22.1689 14.559 22.1689 11.9927 22.1689C9.42312 22.1689 8.1383 22.1689 7.17905 21.5839C6.7048 21.2946 6.296 20.9097 5.97868 20.4538C5.33688 19.5315 5.25945 18.2491 5.10461 15.6841L4.5 5.66895"
        stroke={hover ? strokeHover : stroke} strokeWidth="1.5" strokeLinecap="round"/>
      <path
        d="M3 5.66895H21M16.0557 5.66895L15.3731 4.26067C14.9196 3.3252 14.6928 2.85747 14.3017 2.56575C14.215 2.50105 14.1231 2.44349 14.027 2.39365C13.5939 2.16895 13.0741 2.16895 12.0345 2.16895C10.9688 2.16895 10.436 2.16895 9.99568 2.40306C9.8981 2.45495 9.80498 2.51484 9.71729 2.58211C9.32164 2.88565 9.10063 3.3705 8.65861 4.3402L8.05292 5.66895"
        stroke={hover ? strokeHover : stroke} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

export default Basket