import React, { Suspense } from 'react'

import Section from '../../components/Section/Section'
import PageHeader from '../../components/PageHeader/PageHeader'
import TableReshipment from '../../components/TableReshipment/TableReshipment'
import { useLocation } from 'react-router-dom'
import { useGetPagesQuery } from '../../services/pages.api'

const Reshipment = () => {
  const {pathname} = useLocation()
  const {data} = useGetPagesQuery()

  if (!data || data.find(item => '/' + pathname.split('/')[1] === item.url) === undefined) {
    return null
  }

  return (
    <Section>
      <Suspense fallback={<div>Загрузка....</div>}>
        <PageHeader title={'Модуль отгрузки'}/>
        <TableReshipment />
      </Suspense>
    </Section>
  )
}

export default Reshipment