import React, { FC, useEffect } from 'react'

import { IMaterialItem } from '../../../../models/IMaterial'
import { transformDate } from '../../../../helpers/transformDate'
import { useGetFabricsQuery } from '../../../../services/fabric.api'

import { Checkbox, Col, Form, Input, Row, Space, Spin } from 'antd'

import s from './CreateOrder.module.scss'

import { CloseOutlined } from '@ant-design/icons'
import { IFabric } from '../../../../models/IFabric'

interface IProps {
  materialItem: IMaterialItem
  closeModal: () => void
  children?: React.ReactNode
  isWriteOff: boolean
  setIsWriteOff: (flag: boolean) => void
  dataFabricInStock: IFabric[] | undefined
  isLoadingSearchFabrics: boolean
  materialSearch: string
  providerSearch: string
  form: any
}

const CreateOrder: FC<IProps> = ({
                                   isLoadingSearchFabrics,
                                   dataFabricInStock,
                                   isWriteOff,
                                   setIsWriteOff,
                                   materialItem,
                                   closeModal,
                                   children,
                                   providerSearch,
                                   materialSearch,
                                   form
                                 }) => {
  const {
    data: fabrics,
    isLoading: isLoadingFabrics
  } = useGetFabricsQuery(
    {name: materialItem.material.name, provider: materialItem.material.provider}
  )
  const productName = `${materialItem.productName}${(materialItem.status === 'new' && materialItem.productOrderItem?.quantity)
    ? `, ${materialItem.productOrderItem?.quantity}\u00A0шт`
    : ''}`
  const material = Form.useWatch('material', form)
  const provider = Form.useWatch('provider', form)
  const quantity = Form.useWatch('quantity', form)

  const hasMaterial = (): boolean => {
    const namesInStock = dataFabricInStock ? dataFabricInStock.map(item => item.name) : []
    const providersInStock = dataFabricInStock ? dataFabricInStock.map(item => item.provider) : []
    if (dataFabricInStock && (material || provider)) {
      return (
        dataFabricInStock?.[0] &&
        +dataFabricInStock?.[0]?.quantity >= +quantity &&
        namesInStock.includes(material) &&
        providersInStock.includes(provider)
      )
    }
    if (fabrics) {
      return (fabrics[0] && +fabrics[0].quantity >= +quantity)
    }
    return false
  }

  useEffect(() => {
    if (!hasMaterial()) {
      setIsWriteOff(false)
    }
  }, [hasMaterial])

  return (
    <div className={s.modal}>
      <div className={s.close} onClick={closeModal}>
        <CloseOutlined style={{fontSize: '12px', color: '#8A94A2', lineHeight: '12px'}}/>
      </div>

      <div className={s.header}>
        Договор {materialItem.productOrder.contractNumber || `- (${materialItem.productOrder.id})`}
      </div>
      <Row gutter={[12, 20]}
           justify={'space-between'}
           className={s.inStockInfo}
      >
        <div className={s.inStockMaterial}>
          Ткань: <span className={hasMaterial() ? s.inStock : s.notInStock}>
          {
            isLoadingSearchFabrics
              ? <Spin/>
              : <>{hasMaterial() ? 'в наличии' : 'нет в наличии'}</>
          }
        </span>
        </div>
        <Space size={12}>
          <div>
            <Checkbox
              onChange={(e) => setIsWriteOff(e.target.checked)}
              checked={isWriteOff}
              disabled={!hasMaterial()}
            />
          </div>
          <div className={s.checkboxMaterial}>Списать из наличия</div>
        </Space>
      </Row>
      <Space size={24} direction={'vertical'} style={{width: '100%'}}>
        <Space size={16} direction={'vertical'} style={{width: '100%'}}>
          <Row gutter={[12, 12]} align={'middle'}>
            <Col span={10} className={s.fieldName}>Товар</Col>
            <Col span={14}>
              <Input value={productName} disabled={true} className={s.field}/>
            </Col>
          </Row>
          <Row gutter={[12, 12]} align={'middle'}>
            <Col span={10} className={s.fieldName}>Размер</Col>
            <Col span={14}>
              <Input
                value={materialItem.productOrderItem.productSize.value || materialItem.productOrderItem.productSizeValue}
                disabled={true}
                className={s.field}/>
            </Col>
          </Row>
          <Row gutter={[12, 12]} align={'middle'}>
            <Col span={10} className={s.fieldName}>Дата изготовления</Col>
            <Col span={14}>
              <Input value={transformDate(materialItem.productionDateFrom)} disabled={true} className={s.field}/>
            </Col>
          </Row>
          <Row gutter={[12, 12]} align={'middle'}>
            <Col span={10} className={s.fieldName}>Материал</Col>
            <Col span={14}>
              <Input value={materialItem.material.material} disabled={true} className={s.field}/>
            </Col>
          </Row>
        </Space>
        {children}
      </Space>
    </div>
  )
}

export default CreateOrder