import React, { FC, useEffect } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import RenderGoodItem from './RenderGoodItem/RenderGoodItem'
import { PlusOutlined } from '@ant-design/icons'
import s from '../../../../../OrderCreating/components/Goods/components/Good/Good.module.scss'
import { useDeleteProductOrderItemOptionMutation } from '../../../../../../services/order.api'
import { OrderStatusesIRI } from '../../../../../../configs/enums/orderStatuses'

interface IProps {
  formName: string
  sendOnBlurGoods: () => void
}

const Good: FC<IProps> = ({formName, sendOnBlurGoods}) => {
  const [deleteGoodOption] = useDeleteProductOrderItemOptionMutation()
  const formInstance = Form.useFormInstance()
  const good = Form.useWatch(['good'], formInstance)

  useEffect(() => {
    if (good) {
      const sum = good?.reduce((acc: number, item: { sum: string }) => {
        return acc + Number(item?.sum)
      }, 0)
      formInstance.setFieldValue(['good', 0, 'summative'], sum || '')
      formInstance.setFieldValue(['cost'], sum || '')
    }
  }, [good])

  return (
    <div style={{padding: '8px 16px 0'}}>
      <Form.List name={['good']}>
        {(fields, {add, remove}) => (
          <>
            {
              fields.map((field, index) => (
                <RenderGoodItem key={field.name}
                                formName={formName}
                                field={field}
                                indexField={index}
                                removeField={remove}
                                sendOnBlurGoods={sendOnBlurGoods}
                                deleteGoodOption={deleteGoodOption}
                />
              ))
            }
            <Form.Item>
              <Row gutter={[12, 12]} justify={'space-between'} align={'middle'}>
                <Col span={5}>
                  <Button
                    type={'ghost'}
                    onClick={() => add({
                      productName: '',
                      price: '',
                      quantity: '1',
                      cost: '',
                      discount: '',
                      sum: '',
                    })}
                    icon={<PlusOutlined/>}
                    className={s.addFieldGood}
                    disabled={formInstance.getFieldValue('statusIRI') !== OrderStatusesIRI.NEW}
                  >
                    Добавить поле
                  </Button>
                </Col>

                <Col flex={'245px'}>
                  <Row gutter={[12, 0]}>
                    <Col flex={'95px'}>
                      <Form.Item name={[0, 'sumName']} style={{marginBottom: 'unset'}}>
                        <Input className="disabled:font-bold disabled:text-sm disabled:leading-[22px] disabled:text-baseGrey disabled:border-none"
                               disabled
                               size={'large'}/>
                      </Form.Item>
                    </Col>
                    <Col flex={'150px'}>
                      <Form.Item name={[0, 'summative']} style={{marginBottom: 'unset'}}>
                        <Input disabled
                               className="disabled:font-bold disabled:text-base disabled:leading-[22px] disabled:text-baseColor disabled:border-none"
                               size={'large'}/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  )
}

export default Good