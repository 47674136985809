export enum ColorForStatus {
  'new' = '#4D92EE',
  'waiting_for_payment' = '#736CF3',
  'production' = '#C86CF3',
  'waiting_for_afterpayment' = '#F0BF2E',
  'waiting_for_load' = '#736CF3',
  'loaded' = '#63B690',
  'cancel' = '#B66363',
  'deleted' = '#B66363',
  'ready' = '#63B690',
  'in_progress' = '#F0BF2E'
}