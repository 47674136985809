import React, { FC, useCallback, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { setOrderToEdit } from '../../../../store/reducers/order/order.slice'
import { useLazyGetOrderQuery } from '../../../../services/order.api'
import { OrderStatusForm } from '../../../../configs/enums/orderStatuses'

import { Col, Drawer, notification, Row } from 'antd'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { FormOrder } from '../../../OrderCreating'
import RequiredToOrder from '../RequiredToOrder/RequiredToOrder'
import AwaitingReceipt from '../AwaitingReceipt/AwaitingReceipt'

import s from './materials.module.scss'

const Materials: FC<{ moduleType?: 'archive', isEdit?: boolean }> = ({moduleType, isEdit = true}) => {
  const isOrderToEdit = useAppSelector(state => state.orderSlice.isOrderEdit)
  const [loading, setLoading] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()
  const [getOrder, {data: order}] = useLazyGetOrderQuery()
  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   console.log(requestId)
  //   if (order && isSuccess) {
  //     dispatch(setOrderToEdit({edit: true, hasRightsForEdit: true, orderToEdit: order}))
  //   } else if (errorOrder && 'status' in errorOrder) {
  //     console.log(errorOrder)
  //     api.error({
  //       message: `Ошибка получения данных. Статус: ${errorOrder?.status}`
  //     })
  //   }
  // }, [order, isSuccess, requestId])

  const closeForm = useCallback(() => {
    dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
  }, [])

  const fetchOrder = async (idOrder: number) => {
    setLoading(true)
    try {
      const res = await getOrder(idOrder)

      if (res?.data) {
        dispatch(setOrderToEdit({
          edit: true,
          hasRightsForEdit: false,
          orderToEdit: {
            ...res.data,
            disabledFields: {
              docsBtn: false,
              backwall: true,
              productSupport: true
            }
          }
        }))
      } else {
        dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
      }

      if (res.error) {
        api.error({
          // @ts-ignore
          message: `Ошибка получения данных. Статус: ${res.error?.status}`
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {contextHolder}
      {loading && <LoaderFullDisplay/>}

      <div className={s.materials}>
        <div>
          <Row gutter={[24, 24]} justify={'center'}>
            {
              moduleType !== 'archive' &&
              <Col span={12}>
                <RequiredToOrder fetchOrder={fetchOrder} isEdit={isEdit}/>
              </Col>
            }
            <Col span={moduleType !== 'archive' ? 12 : 24}>
              <AwaitingReceipt fetchOrder={fetchOrder} moduleType={moduleType}/>
            </Col>
          </Row>
        </div>
      </div>

      <Drawer open={isOrderToEdit}
              onClose={closeForm}
              width={985}
              closable={false}
              bodyStyle={{padding: 0}}
              destroyOnClose={true}
      >
        <FormOrder
          name={'orderChanging'}
          isShowForm={isOrderToEdit && !loading}
          closeForm={closeForm}
          statusForEditing={OrderStatusForm.EDIT_ORDER}
          data={order}
        />
      </Drawer>
    </>
  )
}

export default Materials