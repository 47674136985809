import { baseUrl } from '../configs/api/api.config'

export const downloader = (link: string, fileName: string, target: string = '_self', isDownload: boolean = true) => {
  if (!link) {
    return
  }
  const linkLayout = document.createElement('a')
  linkLayout.href = baseUrl + link
  linkLayout.target = target
  if (isDownload) {
    linkLayout.download = fileName
  }
  document.body.appendChild(linkLayout)
  linkLayout.click()
  document.body.removeChild(linkLayout)
}